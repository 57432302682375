<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <app-admin-menu *ngIf="config.isAdmin()"></app-admin-menu>

      <!-- <div class="footer t-c">
        &copy; {{config.currentYear}} InsIQ. All rights reserved
        <a href="https://www.devtom.ai" target="_blank">www.devtom.ai</a>
      </div> -->
    </div>

    <ng-template #dialogUpload>
      <div class="p-y-20">
        <div *ngIf="currentFile" class="progress">
          <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
            attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
            [ngStyle]="{ width: progress + '%' }">
            {{ progress }}%
          </div>
        </div>
        <div>
          <!-- <div class="col-xl-18 w-auto">
          <div class="d-flex">
            <label class="f-light w-auto t-normal align-content-lg-center">Upload .zip File with InsIQ Local Client .exe file and instructions .txt file.Provide different File name than that of previously uploaded versions to keep version Management</label>
            <div class="ib-m">
                <app-quick-help [layout]="projTitle"></app-quick-help> 
            </div>
          </div>
        </div> -->
          <div class="row m-t-20">
            <div class="col-xl-18">
              <div class="d-flex">
                <label class="bigLabel">Version Name</label>
                <i class="i i-info" matTooltipPosition="below"
                  title="Upload .zip File with InsIQ Business Intelligence .exe file and instructions .txt file.Provide different File name than that of previously uploaded versions to keep version Management"></i>
                <div class="ib-m">
                  <!-- <app-quick-help [layout]="projTitle"></app-quick-help> -->
                </div>
              </div>
              <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
                <input matInput id="version" (keyup)="onKey($event)"
                  placeholder="Provide a Version Name for this Update">
                <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
              </mat-form-field>
            </div>
            <div class="col-xl-18">
              <!-- <div class="d-flex">
              <label class="bigLabel">Upload File</label>
              <div class="ib-m">
                  <app-quick-help [layout]="projTitle"></app-quick-help> 
              </div>
            </div>-->
              <label class="custom-file-upload">
                <input type="file" (change)="selectFile($event)" />
                <mat-icon fontSet="i" fontIcon="i-publish" aria-label="edit"></mat-icon>Choose File
              </label>
              <!-- <input type="file" mat-raised-button class="curved m-r-10" color="accent" (change)="selectFile($event)" />-->
            </div>
          </div>
          <div class="row m-t-15">
            <div class="col-xl-8">
              <label class="bigLabel">Version Category</label>


              <mat-radio-group aria-label="Version Category" (change)="onCatChange($event)">
                <mat-radio-button value="true" [checked]="versionCategory">Windows</mat-radio-button>
                <mat-radio-button value="false" [checked]="!versionCategory" class="m-l-10">Mac
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <!--<label class="btn btn-default"></label>-->
          <div class="bar-T m-b-10 m-t-10">
            <button mat-raised-button class="curved m-r-10" color="primary" [disabled]="!selectedFiles||!activeflg"
              (click)="upload()">
              Upload
            </button>
          </div>
        </div>
        <div class="text-danger bold" role="alert">{{ message }}</div>

        <!--<div class="card">
          <div class="card-header">List of Files</div>
          <ul
            class="list-group list-group-flush"
            *ngFor="let file of fileInfos | async">
            <li class="list-group-item">
              <a href="{{ file.url }}">{{ file.name }}</a>
            </li>
          </ul>
        </div>-->
      </div>
    </ng-template>

    <div class="rightSection" tabindex="0">
      <div class="d-flex pageHeader">
        <h1 class="h1 smartHead">
          <i class="i i-user"></i>
          <span>
            <b>Manage</b>
            <small class="t-normal">InsIQ Business Intelligence</small>
          </span>
        </h1>
        <div class="spacer"></div>
        <span>
          <button mat-stroked-button class="curved btn-sm m-t-15" (click)="uploadnew()">New Upload</button>
        </span>
      </div>
      <!-- trans-light -->
      <div class="d-flex">
        <div class="spacer"></div>
        <!-- <mat-form-field>
          <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
        </mat-form-field> -->
      </div>
      <div class="mat-elevation-z8 rel">
        <!-- mat-elevation-z8 -->
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            No Records Found!!
          </div>
        </div> -->
        <table mat-table [dataSource]="historylist" matSort class="w-100 InsIQTable">
          <!-- <ng-container matColumnDef="sl">
            <th class="w-120px p-r-10 t-r" mat-header-cell *matHeaderCellDef mat-sort-header>Sl No.</th>
            <td class="w-80px p-r-10 t-r" mat-cell *matCellDef="let pu ; let i = index;">{{getIndex(i)}}</td>
          </ng-container> -->

          <ng-container matColumnDef="plancode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Version Name</th>
            <td mat-cell *matCellDef="let row">{{row.version}}</td>
          </ng-container>

          <ng-container matColumnDef="osversion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>OS Version</th>
            <td mat-cell *matCellDef="let row">{{getOs(row.osversion)}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Title </th>
            <td mat-cell *matCellDef="let row">{{row.title}}</td>
          </ng-container>-->
          <!-- description Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded On</th>
            <td mat-cell *matCellDef="let row">{{row.uploadDate}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
          </ng-container> -->
          <!-- <ng-container matColumnDef="startdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Active From</th>
            <td mat-cell *matCellDef="let row">{{getDate(row.startdate)}}</td>
          </ng-container>
          <ng-container matColumnDef="enddate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Till</th>
            <td mat-cell *matCellDef="let row">{{getDate(row.enddate)}}</td>
          </ng-container> -->
          <!-- <ng-container matColumnDef="planstat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
            <td mat-cell *matCellDef="let row" class="t-c">
              <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
              </mat-slide-toggle>
               {{row.status}} 
            </td>
          </ng-container> -->

          <ng-container matColumnDef="status">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Publish /<br>UnPublish</th>
            <td mat-cell *matCellDef="let pu">
              <!-- <button mat-icon-button (click)="publishPrice(pu)" title="Publish Now"
                matTooltipPosition="below" *ngIf="pu.active==0">
                <mat-icon fontSet="i" fontIcon="i-publish" aria-label="edit"></mat-icon>
              </button>
              <button mat-icon-button (click)="unPublishPrice(pu)" title="Un Publish" *ngIf="pu.active==1"
                matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-unpublished" aria-label="remove" class="i-1_3x">remove_circle_outline</mat-icon>
              </button> -->
              <!-- {{pu | json}} -->
              <!-- (change)="updatePlanStatus($event, 'pricing', pu)"-->
              <mat-slide-toggle class="InsIQToggle boolToggle" [checked]="pu.status==1 ? true: false"
                (change)="toggleSwitch($event, pu)"></mat-slide-toggle>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row; let i=index">
              <button mat-raised-button class="curved m-r-10" color="accent" (click)="downloadfile(row.filepath)"
                title="Download File" matTooltipPosition="below">
                <span class="ib-m p-l-5 t-normal">Download</span>
                <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['plancode','osversion','description','status','action']">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['plancode','osversion','description','status','action']"></tr>
        </table>
        <!--        <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
        </mat-paginator>-->
      </div>
    </div>
  </div>
</div>

<ng-template #projCust>
  <div class="helpContent">
    <h6 class="title">Who is your Customer?</h6>
    <!-- <div class="heading">
      Enter Customer Type
    </div>-->
    <cite>
      [Consumer]
    </cite>
    <div class="note">
      E.g.: -
    </div>
  </div>
</ng-template>