export const OAuthSettings = {
  appId: 'fec2ff3d-3c5b-4573-8011-730a3af0b708',
  scopes: [
    "user.read",
    "calendars.read",
    "User.Read.All",
    "Directory.Read.All",
    "User.ReadWrite.All",
    "Directory.ReadWrite.All",
    "Directory.AccessAsUser.All",
    "Group.ReadWrite.All"
    
  ]
};
