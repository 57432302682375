<div class="w-100 h-100 loginMain">
  <div class="loginWrapper">


    <div class="card loginCard">
      <div class="cardHeader">
        <div class="title">Forgot Password</div>
        <div class="description">
          <span>Have Password? </span>
          <a [routerLink]="['/login']" [queryParams]="{url: view.returnUrl}" class="link f-bold">Sign In</a>
        </div>
      </div>
      <div class="cardContent">
        <form [formGroup]="view.form" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-24">
              <div class="form-group">
                <div class="fc-group" [ngClass]="{hasError: view.required('loginKey')}">
                  <input appFcUi type="email" name="Email" id="email" inputmode="email" class="fc"
                    formControlName="loginKey" placeholder="Login Name">
                </div>
                <!-- <mat-error *ngIf="view.invalid('email')">{{view.error('email')}}</mat-error> -->
              </div>
            </div>


          </div>
          <div class="m-b-15 m-x--20">
            <p-captcha [siteKey]="captchaKey" (onResponse)="showResponse($event)"></p-captcha>
          </div>

          <div class="d-flex m-t-20">
            <button class="btn btn-{{btnStatus()}} btn-lg w-100 t-c" [disabled]="view.invalid() || !captchaValid()"
              matRipple>
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>