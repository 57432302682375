<div class="sideMenuBar">
  <div class="brand">
    <img src="./assets/images/logo-insiq.png" alt="InsIQ">
  </div>
  <nav>
    <ul>
      <li class="lg" title="Dashboard" matTooltipPosition="after">
        <button mat-button [routerLink]="['/dashboard']" routerLinkActive="active">
          <mat-icon aria-label="dashboard" fontSet="i" class="i-dashboard"></mat-icon>
        </button>
      </li>
      <li class="menuGroup">
        <div class="head">Planning</div>
        <ul>
          <li title="Discovery" matTooltipPosition="after">
            <button disabled="disabled" mat-button [routerLink]="['/discovery']" routerLinkActive="active">
              <mat-icon aria-label="discovery" fontSet="i" class="i-discovery"></mat-icon>
            </button>
          </li>
          <li title="Concept" matTooltipPosition="after">
            <button disabled="disabled" mat-button [routerLink]="['/concept']" routerLinkActive="active">
              <mat-icon aria-label="concept" fontSet="i" class="i-concept"></mat-icon>
            </button>
          </li>
        </ul>
      </li>
      <li class="menuGroup">
        <div class="head">Execution</div>
        <ul>
          <li title="Risk analysis" matTooltipPosition="after">
            <button disabled="disabled" mat-button [routerLink]="['/risk-analysis']" routerLinkActive="active">
              <mat-icon aria-label="Risk analysis" fontSet="i" class="i-riskAnalysis"></mat-icon>
            </button>
          </li>
          <li title="Detailed Design" matTooltipPosition="after">
            <button disabled="disabled" mat-button [routerLink]="['/detailed-design']"
              routerLinkActive="active">
              <mat-icon aria-label="Detailed Design" fontSet="i" class="i-detaildDesign"></mat-icon>
            </button>
          </li>
          <li title="Virtual Testing" matTooltipPosition="after">
            <button disabled="disabled" mat-button [routerLink]="['/virtual-testing']"
              routerLinkActive="active">
              <mat-icon aria-label="Virtual Testing" fontSet="i" class="i-virtualTesting"></mat-icon>
            </button>
          </li>
          <li title="Prototyping and Testing" matTooltipPosition="after">
            <button disabled="disabled" mat-button [routerLink]="['/prototype']"
              routerLinkActive="active">
              <mat-icon aria-label="Prototyping and Testing" fontSet="i" class="i-prototype"></mat-icon>
            </button>
          </li>
          <li title="Product build" matTooltipPosition="after">
            <button disabled="disabled" mat-button [routerLink]="['/product']"
              routerLinkActive="active">
              <mat-icon aria-label="Product build" fontSet="i" class="i-product"></mat-icon>
            </button>
          </li>
        </ul>
      </li>
      <li class="menuGroup">
        <div class="head">Review</div>
        <ul>
          <li title="Feedback" matTooltipPosition="after">
            <button disabled="disabled" mat-button [routerLink]="['/feedback']" routerLinkActive="active">
              <mat-icon aria-label="Feedback" fontSet="i" class="i-feedback"></mat-icon>
            </button>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>