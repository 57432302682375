import {
  OnInit,
  Component,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
} from '@angular/router';
import {
  Validators,
  FormBuilder,
  FormGroup
} from '@angular/forms';
import {
  MatTable,
  MatTableDataSource
} from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';

import * as _moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { AlertService } from '../../../_services';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';

import {
  View,
  Config,
  FormDefault,
  AppPattern
} from '../../../config/global.config';
import {
  WarningalertsComponent
} from '../../warningalerts/warningalerts.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyErrorStateMatcher } from 'src/app/_directives/validator.directive';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
interface Currency {
  value: string;
  viewValue: string;
}

const ELEMENT_DATA: any[] = [
  {},
];


@Component({
  selector: 'app-manageadmin',
  templateUrl: './manageadmin.component.html',
  styleUrls: ['./manageadmin.component.scss']
})
export class ManageadminComponent implements OnInit, AfterViewInit {
  matcher = new MyErrorStateMatcher();

  displayedColumns: string[] = ['select', 'position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  selection = new SelectionModel<any>(true, []);
  planPartial = 0;
  public view: View;

  trialflg = true;
  showPricing = false;
  showFeature = false;
  newfeatureflg = false;
  newpricingflg = false;
  featureList: any[];
  priceListAll: any[];
  featureFiltered: any;
  featureListAll: any[];
  featureListAllMain: any[];
  rolecode;
  selectedValue: string;
  isReadonly = false;
  public isPlanPublished = false;
  dsPriceListAll = new MatTableDataSource([]);
  featureDataTable = new MatTableDataSource([]);
  currentPlan;
  // For user role combo
  featureSelected: any; // temp variable to select users
  dsFeatureList = new MatTableDataSource([]);
  dsFeatureListAll = new MatTableDataSource([]);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('planFeatures', { static: false }) table: MatTable<any>;

  @ViewChild('dialogAddFeature', { static: false }) dialogAddFeature: TemplateRef<any>;
  @ViewChild('dialogAddPricing', { static: false }) dialogAddPricing: TemplateRef<any>;
  selected: number;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;

  constructor(
    public router: Router,
    public config: Config,
    public fb: FormBuilder,
    public http: HttpClient,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    this.view = new View(http, router, route, alertService);


    this.view.initForm('manageadmin', this.getNewForm(), this.getNewDefault(), true); // need to change
    //  this.view.refreshList = false;
    // this.pricingview.initForm('planpricing',this.getPriceForm());
    // this.view.controls['startdate'].setValue(this.view.value('startdate'));
  }

  currencys: Currency[] = [
    {
      value: '$ US Dollar',
      viewValue: '$ US Dollar'
    },
    {
      value: '₹ Indian Rupee',
      viewValue: '₹ Indian Rupee'
    },
    {
      value: '€ Euro',
      viewValue: '€ Euro'
    }
  ];

  getNewDefault() {
    return new FormDefault(['category'], ['1']);
  }



  ngAfterViewInit() {
    this.view.initPage(this.paginator, this.sort);
    // this.featureDataTable = new MatTableDataSource(this.getFeatureDataTable());
  }

  ngOnInit() {

    this.dataSource.paginator = this.paginator;
    // this.view.initForm('plan', this.getNewForm(),null,false);
    this.view.initForm('manageadmin', this.getNewForm(), this.getNewDefault(), true); // need to change

    this.view.get(`manageadmin/roles`).subscribe(res => {
      // console.log(res);
      this.rolecode = res;
    });
    //  this.pricingview.initForm('planpricing',this.getPriceForm());
  }

  onSubmit() {
    Config.loading = true;



    this.view.save();




  }
  publishPrice(pu) {

    this.testFn(pu, '1').subscribe(
      event => {

        const objIndex = this.view.list.findIndex((obj => obj.id === pu.id));
        this.view.list[objIndex].active = '1';

      },
      _err => {
        console.log(_err);

      });


  }
  testFn(pu, active) {
    const req = new HttpRequest('POST', `${Config.apiUrl}/apisachet/${active}/${pu.id}`, {
      responseType: 'json'
    });
    return this.http.request(req);
  }
  unPublishPrice(pu) {
    this.testFn(pu, '0').subscribe(
      event => {
        const objIndex = this.view.list.findIndex((obj => obj.id === pu.id));
        this.view.list[objIndex].active = '0';
      }, _err => {
        console.log(_err);
      });
  }
  editPlan(plan: any) {
    this.currentPlan = plan;

    //   plan.category = this.getStatus('category', plan.category);
    this.view.edit(plan);
    this.planPartial = 0;

    // console.log(this.planPartial);
  }

  getIndex(i: number) {
    let j: number;
    j = i + 1;
    return j;
  }

  openSnackBar(s: any) {
    s = (s === undefined || typeof s !== 'object') ? {
      duration: 3,
      type: 'simple',
      action: 'CLOSE',
      message: 'No More Features Available!'
    } : s;

    s.duration = s.duration === undefined ? 2000 : s.duration;
    s.message = s.message === undefined ? 'InsIQ Message' : s.message;
    s.action = s.action === undefined ? 'ok' : s.action;

    if (s.type === 'custom') {
      this._snackBar.openFromComponent(s.component, {
        duration: s.duration * 1000,
        panelClass: 'InsIQ'
      });
    } else {
      this._snackBar.open(s.message, s.action, {
        duration: s.duration,
        panelClass: 'InsIQ'
      });
    }
  }

  newplanCreate() {
    // console.log('ffffff');

    this.view.newform();

    // console.log( this.currentPlan);
  }

  getNewForm() {
    return this.fb.group({
      id: [],
      name: ['', Validators.required],
      rolecodevalue: ['', Validators.required],
      loginKey: ['', Validators.required],

      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(AppPattern.EMAIL)
      ])],
      inputPwd: ['', [
        Validators.required,
        Validators.minLength(6)]
      ],
      cpassword: ['']
      // plan: this.fb.group(this.view.get("plan/all"))
    }, { validators: this.checkPasswords });
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.inputPwd.value;
    const confirmPass = group.controls.cpassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }
  // planedit(row) {
  //   this.view.edit(row);
  //   // this.tab.index == 1
  // }

  deleteAdmin(row) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to delete this Admin ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    // console.log(this.chartCode);
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.view.delete(row.id);
      }
    });
  }




  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cancel() { }



  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dsFeatureListAll.data.length;
    return numSelected === numRows;
  }




  changed = (evt) => {
    //  console.log(evt);
  }

}
