<div class="container-fluid d-flex h-100" [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <app-admin-menu *ngIf="config.isAdmin()"></app-admin-menu>

      <!-- <div class="footer t-c">
        &copy; {{config.currentYear}} InsIQ. All rights reserved
        <a href="https://www.devtom.ai" target="_blank">www.devtom.ai</a>
      </div> -->
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex">
        <h1 class="h1 smartHead">
          <i class="i i-user"></i>
          <span>
            <small>User</small>
            <b>Feedback</b>
          </span>
        </h1>
        <div class="spacer"></div>
        <!--        <span>
          <button mat-stroked-button class="curved btn-sm m-t-15" (click)="view.newform()">New </button>
        </span>-->
      </div>
      <div class="d-flex">
        <!-- trans-light -->
        <div class="spacer"></div>
        <mat-form-field>
          <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
        </mat-form-field>
      </div>
      <mat-card class="InsIQCard mat-elevation-z8 rel p-a-0">
        <!-- mat-elevation-z8 -->
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            No Records Found!!
          </div>
        </div> -->
        <table mat-table [dataSource]="view.list" matSort class="w-100 InsIQTable">
          <!-- photo Column -->
          <ng-container matColumnDef="photo">
            <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
            <td mat-cell *matCellDef="let row">
              <figure>
                <img src="assets/images/users/default.png" alt="default">
              </figure>
            </td>
          </ng-container>


          <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
            <td mat-cell *matCellDef="let row">{{row.userId}}</td>
          </ng-container>



          <ng-container matColumnDef="rating">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rating</th>
            <td mat-cell *matCellDef="let row" title="User Rating: {{row.rating}}" matTooltipPosition="below">
              {{row.rating}}</td>
          </ng-container>
          <ng-container matColumnDef="experience">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Experience</th>
            <td mat-cell *matCellDef="let row">{{row.experience}}</td>
          </ng-container>
          <ng-container matColumnDef="suggestion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Suggestion</th>
            <td mat-cell *matCellDef="let row">{{row.suggestion}}</td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="['userId','rating','experience','suggestion']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['userId','rating','experience','suggestion']"></tr>
        </table>
        <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
        </mat-paginator>
      </mat-card>
    </div>
  </div>
</div>

<div class="container-fluid d-flex h-100" [ngClass]="view.isForm() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <button mat-icon-button class="close" color="warn" (click)="view.cancel()" title="Close"
        matTooltipPosition="below">
        <mat-icon fontSet="i" class="i-close"></mat-icon>
      </button>
      <ul class="quickyMenu">
        <li *ngFor="let user of view.list">
          <button matRipple class="btn btn-md btn-primary lighten t-c fw t-normal" (click)="view.edit(user)">
            <!-- <figure class="avatar circle">
              <img src="assets/images/users/default.png" alt="{{view.value('name')}}" title="{{view.value('name')}}">
            </figure> -->
            {{user.name}}
            <!-- <mat-icon fontSet="i">edit</mat-icon> -->
          </button>
        </li>
      </ul>
    </div>
    <!--    <div class="rightSection">
      
      <form [formGroup]="view.form" novalidate (ngSubmit)="onSubmit()">
        <div class="d-flex m-b-40">
          <h1 class="h1 smartHead">
            <i class="i i-user"></i>
            <span>
              <input type="hidden" formControlName="name">
              <small>{{view.value('name')}}</small>
              <b>Details</b>
            </span>
          </h1>
          <div class="spacer"></div>
          <span>
            <button *ngIf="!view.isNew()" mat-stroked-button class="curved btn-sm m-t-15" (click)="view.newform()">New</button>
          </span>
        </div>

        <mat-card class="w-100">
          <div class="row p-t-15">
            <div class="col-xl-5 col-lg-8 col-sm-9">
              <figure class="avatar circle">
                <img src="assets/images/users/default.png" alt="{{view.value('name')}}" title="{{view.value('name')}}">
              </figure>
            </div>

            <div class="col-xl-19 col-lg-16 col-sm-15">
              <mat-form-field class="w-100 m-b-10">
                <input type="text" matInput placeholder="Name" formControlName="name">
                <mat-error *ngIf="view.invalid('name')">{{view.error('name')}}</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100 m-b-10" *ngIf="view.isNew()">
                <input type="password" matInput placeholder="Password" formControlName="inputPwd" [readonly]="view.isEdit()">
                <mat-hint *ngIf="view.isEdit()"></mat-hint>
                <mat-error *ngIf="view.invalid('inputPwd')">{{view.error('inputPwd')}}</mat-error>
              </mat-form-field>
              <mat-form-field class="w-100 m-b-10">
                <input type="text" matInput placeholder="Email" formControlName="email">
                <mat-error *ngIf="view.invalid('email')">{{view.error('email')}}</mat-error>
              </mat-form-field>
               <mat-form-field class="w-100 m-b-10">
              <mat-select placeholder="Choose role" formControlName="role" [errorStateMatcher]="matcher">
                <mat-option value="0">Normal User</mat-option>
                <mat-option value="1">Administrator</mat-option>
              </mat-select>
              <mat-error *ngIf="view.required('role')">You must make a selection</mat-error>
            </mat-form-field> 

              <div class="t-c">
                <button type="button" (click)="view.cancel()" mat-raised-button color="warn" class="curved m-b-40 m-x-5">Cancel</button>
                <button type="submit" [disabled]="view.invalid()" *ngIf="view.isNew()" mat-raised-button color="primary"
                  class="curved m-x-5">Create</button>
                <button type="submit" [disabled]="view.invalid()" *ngIf="view.isEdit()" mat-raised-button color="primary"
                  class="curved m-x-5">Update</button>
              </div>
            </div>

          </div>
        </mat-card>
      </form>
    </div>-->
  </div>