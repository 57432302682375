<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <header>
      <figure class="logo">
        <img src="./assets/images/icon-insiq.png" alt="InsIQ">
      </figure>
      <div class="mr-auto">
        <button type="button" *ngIf="hasBackNav" class="btn btn-ico-label" matRipple (click)="onBackHandler($event)">
          <div class="ico">
            <i class="i i-keyboard_backspace"></i>
          </div>
          <small class="label">Back</small>
        </button>
      </div>

      <h1 class="title m-x-auto">{{title}}</h1>

    </header>
    <main class="contentSection">
      <ng-container *ngTemplateOutlet="layout ? layout: defaultLayout"></ng-container>
    </main>
  </div>
</div>

<ng-template #defaultLayout>InsIQ</ng-template>