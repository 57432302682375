<div class="container-fluid d-flex h-100" [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
          <img src="./assets/images/insiq.png" alt="">
        </figure> -->

      <app-admin-menu *ngIf="config.isAdmin()"></app-admin-menu>

      <!-- <div class="footer t-c">
          &copy; {{config.currentYear}} InsIQ. All rights reserved
          <a href="https://www.devtom.ai" target="_blank">www.devtom.ai</a>
        </div> -->
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex pageHeader">
        <h1 class="h1 smartHead">
          <i class="i i-user"></i>
          <span>
            <b>Manage</b>
            <small>User Sachet Pack</small>
          </span>
        </h1>
        <div class="spacer"></div>
        <span>
          <button mat-stroked-button class="curved btn-sm m-t-15" (click)="newplanCreate(); selected=-1">New
            Sachet</button>
        </span>
      </div>
      <!-- trans-light -->
      <div class="d-flex">
        <div class="spacer"></div>
        <!-- <mat-form-field>
            <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
          </mat-form-field> -->
      </div>
      <div class="mat-elevation-z8 rel">
        <!-- mat-elevation-z8 -->
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
              No Records Found!!
            </div>
          </div> -->
        <table mat-table [dataSource]="view.list" matSort class="w-100 InsIQTable">
          <!-- photo Column -->
          <!-- <ng-container matColumnDef="photo">
              <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
              <td mat-cell *matCellDef="let row">
                <figure>
                  <img src="assets/images/users/default.png" alt="default">
                </figure>
              </td>
            </ng-container>-->

          <!-- name Column -->
          <!-- <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
              <td mat-cell *matCellDef="let row">
                <span class="ib-m">{{row.name}}</span>
                <i *ngIf="row.new" class="ib-m i i-1_3x i-fiber_new"></i>
              </td>
            </ng-container> -->

          <ng-container matColumnDef="sachetName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sachet Name</th>
            <td mat-cell *matCellDef="let row">{{row.sachetName}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title </th>
              <td mat-cell *matCellDef="let row">{{row.title}}</td>
            </ng-container>-->
          <!-- description Column -->
          <ng-container matColumnDef="sachetLimit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>User Count</th>
            <td mat-cell *matCellDef="let row">{{row.sachetLimit}}</td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
            <td mat-cell *matCellDef="let row">{{row.price+' '+getCurrency1()}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="startdate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Active From</th>
              <td mat-cell *matCellDef="let row">{{getDate(row.startdate)}}</td>
            </ng-container>
            <ng-container matColumnDef="enddate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Till</th>
              <td mat-cell *matCellDef="let row">{{getDate(row.enddate)}}</td>
            </ng-container> -->
          <ng-container matColumnDef="planstat">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let pu">
              <!-- <button mat-icon-button (click)="publishPrice(pu)" title="Publish Now"
                  matTooltipPosition="below" *ngIf="pu.active==0">
                  <mat-icon fontSet="i" fontIcon="i-publish" aria-label="edit"></mat-icon>
                </button>
                <button mat-icon-button (click)="unPublishPrice(pu)" title="Un Publish" *ngIf="pu.active==1"
                  matTooltipPosition="below">
                  <mat-icon fontSet="i" fontIcon="i-unpublished" aria-label="remove" class="i-1_3x">remove_circle_outline</mat-icon>
                </button> -->
              <!-- {{pu | json}} -->
              <!-- <mat-slide-toggle class="InsIQToggle boolToggle" [checked]="pu.active==1 ? true: false"
                  (change)="updatePlanStatus($event, pu)"></mat-slide-toggle> -->

              <!-- {{pu.active}} -->
              <div class="label f-bold" [ngClass]="{
                  'text-success': pu.active === 1,
                  'text-danger': pu.active === 0
                }">
                <div>{{pu.active===1 ? 'Active' : 'Inactive'}}</div>
              </div>
              <!-- <div class="label f-bold text-danger" *ngIf="pu.active===0">
                  <div>Inactive</div>
                </div> -->
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row; let i=index">
              <button mat-icon-button (click)="editPlan(row); selected=i" title="Edit" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
              </button>
              <button mat-icon-button (click)="deletePlan(row)" title="Delete" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-trash" aria-label="delete" color="warn"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['sachetName','sachetLimit','price','planstat','action']">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['sachetName','sachetLimit','price','planstat','action']">
          </tr>
        </table>
        <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid d-flex h-100" [ngClass]="view.isForm() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <button mat-icon-button class="close" color="warn" (click)="view.cancel()" title="Close"
        matTooltipPosition="below">
        <mat-icon fontSet="i" class="i-close"></mat-icon>
      </button>
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
          <img src="./assets/images/insiq.png" alt="">
        </figure> -->

      <ul class="quickyMenu">
        <li *ngFor="let row of view.list; let i = index">
          <button class="btn btn-md btn-primary lighten t-c fw t-normal" [ngClass]="{'active': selected === i}"
            matRipple (click)="view.edit(row); selected = i;">
            {{row.sachetName}}
          </button>
        </li>
      </ul>
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex m-b-40">
        <h1 class="h1 smartHead">
          <i class="i" [ngClass]="{
              'i-add_circle': view.isNew(),
              'i-edit2': !view.isNew()
            }"></i>
          <span>
            <small *ngIf="view.isNew()">Add</small>
            <small *ngIf="!view.isNew()">Modify</small>
            <b>{{view.form.controls.sachetName.value}} <small> - User Sachet</small></b>

          </span>
        </h1>
        <div class="spacer"></div>
        <!-- <span>
            <button *ngIf="!view.isNew()" class="curved btn-sm m-t-15" mat-stroked-button (click)="newplanCreate()">New
              Plan</button>
          </span> -->
      </div>

      <mat-card class="InsIQCard mat-elevation-z8">
        <mat-tab-group mat-align-tabs="center" color="accent" [selectedIndex]="planPartial">
          <mat-tab label="User Sachet">
            <!-- {{view.form.value | json}} -->
            <form [formGroup]="view.form" (ngSubmit)="onSubmit()">
              <!-- Test print:{{view.value('category')}} -->

              <div class="">
                <!-- class="trans-light" -->
                <div class="row">
                  <div class="col-xl-8">
                    <div class="form-group">
                      <div class="d-flex">
                        <label class="bigLabel">User Sachet Name</label>
                        <div class="ib-m">
                          <!-- <app-quick-help [layout]="projTitle"></app-quick-help> -->
                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input matInput formControlName="sachetName" maxlength="256"
                          placeholder="Provide a short name for Sachet">
                        <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <div class="d-flex">
                        <label class="bigLabel">User Limit</label>

                        <div class="ib-m">
                          <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Provide User Limit" formControlName="sachetLimit"
                          (keypress)="numberOnly($event)">
                        <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <div class="d-flex">
                        <label class="bigLabel">Pricing in {{getCurrency1()}}</label>
                        <div class="ib-m">
                          <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input matInput (keypress)="onlyDecimalNumberKey($event)" placeholder="Provide Plan Pricing"
                          formControlName="price">
                        <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="view.isEdit()">
                  <div class="col-xl-8">
                    <div class="form-group d-flex align-items-center m-b-30">
                      <label class="m-a-0 p-b-0 p-r-30 f-medium text-accent">
                        <div class="h5 ib-m m-b-0">Status:</div>
                        <div class="f-bold ib-m m-x-10" [ngClass]="
                            (view.form.value.active === 1 || view.form.value.active === '1') ? 'text-success' : 'text-danger'
                          ">
                          {{(view.form.value.active === 1 || view.form.value.active === '1') ?
                          'Active' : 'Inactive'}}
                        </div>
                      </label>
                      <mat-slide-toggle class="InsIQToggle boolToggle"
                        [checked]="view.form.value.active == 1 ? true: false"
                        (change)="view.form.value.active == 1 ? view.set('active',0) : view.set('active',1)">
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>

                <!-- {{view.form.value | json}} -->

                <div class="bar-T m-b-10">
                  <button type="button" (click)="view.cancel()" mat-raised-button
                    class="curved m-x-5 btn-cancel">Back</button>
                  <button type="submit" [disabled]="view.invalid()" *ngIf="view.isNew()" mat-raised-button
                    color="primary" #create class="curved m-x-5">Create</button>
                  <button type="submit" [disabled]="!view.valid()" *ngIf="view.isEdit()" mat-raised-button
                    color="primary" #update class="curved m-x-5">Update</button>
                </div>
              </div>
            </form>
          </mat-tab>


        </mat-tab-group>
      </mat-card>
    </div>
  </div>
</div>




<br><br>