<div class="container-fluid d-flex h-100" [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <app-admin-menu *ngIf="config.isAdmin()"></app-admin-menu>


    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex pageHeader">
        <h1 class="h1 smartHead">
          <i class="i i-user"></i>
          <span>
            <b>Manager</b>
            <small>Emails</small>
          </span>
        </h1>

      </div>

      <div class="d-flex">
        <div class="spacer"></div>

      </div>
      <div class="mat-elevation-z8 rel m-t-30">

        <span>

          <small></small>
        </span>
        <div class="row col w-100 h-100 m-t-20 m-b-20 m-l-30">

          <mat-form-field appearance="outline" class="w-50 m-b--10 sm m-t-20">
            <!-- <mat-label>InsIQ AI Report Title</mat-label> -->
            <input matInput placeholder="Enter Email Id of the InsIQ Manager" #Aikeyword
              (keydown.backspace)="omit_special_char($event,Aikeyword.value.trim())"
              (keydown.space)="$event.preventDefault()" (keypress)="omit_special_char($event,Aikeyword.value.trim())"
              id="newHero">
            <!-- <mat-hint>Modify here to view AI Insights</mat-hint> -->
          </mat-form-field>

          <div class="col-auto p-l-0 m-b-5 m-t-20 m-l-10">
            <button mat-raised-button class="t-normal curved text-accent" *ngIf="mailStat"
              (click)="validateUser(Aikeyword.value.trim())" #search>
              <mat-icon class="i-1_5x ib-m">account_box</mat-icon>
              <span class="ib-m">Add Manager</span>
              <i class="i i-2x">serach</i>
            </button>

          </div>



        </div>



        <table mat-table [dataSource]="adminEmails" matSort class="w-100 InsIQTable">


          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let row">{{row.tomail}}</td>
          </ng-container>


          <ng-container matColumnDef="action">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row; let i=index">

              <button mat-icon-button (click)="deleteAdmin(row)" title="Delete" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-trash" aria-label="delete" color="warn"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['email','action']">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['email','action']">
          </tr>
        </table>
        <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>






<br><br>