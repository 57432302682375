import {
  OnInit,
  Component,
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  View,
  Config,
  AppPattern,
} from 'src/app/config';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import { User } from './user';
import * as moment from 'moment';
import { JsonRequest } from '../../config';
import { Teamdetails } from './teamdetails';
import { AuthService } from './auth.service';
import { AlertService } from '../../_services';
import { HttpClient } from '@angular/common/http';
import { ComboItem } from 'src/app/_services/ComboItem';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../_services/index';
import { CustomThemeService } from 'src/app/custom-theme.service';
import { CountryService } from 'src/app/_services/country.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MyErrorStateMatcher } from '../../_directives/validator.directive';
import { MsteamhelpDialogComponent } from '../msteamshelp/msteamhelp-dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { NotesDialogComponent } from '../notesalerts/notes-dialog';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  static appId: string;
  pageId = 0;
  mymsId: any;
  username: any;
  fstuser = true;
  currentUsr;
  msteams = false;
  logochange = false;
  usermsg: string;
  historylist: any;
  domainStatus = false;
  transactionhistorylist: any;
  refundhistorylist: any;
  refundlist = false;
  sachethistorylist: any;
  resetPass = false;
  timezoneflag = false;
  currentUserData: any;
  acntdeactivate = false;
  matcher = new MyErrorStateMatcher();
  disabledAgreement = true;
  changezoneflag = false;
  filteredCountry: any[];
  countryList: any[];
  themeList: any[];
  regionList: any[];
  projList;
  selectedFiles: FileList;
  currentFile: File;
  logoimg;
  teamsManager;
  domainverify = 0;
  dynamiclogo = false;
  base64Data: any;
  retrieveResonse: any;
  message;
  get authenticated(): boolean {
    return this.authService.authenticated;
  }
  // The user
  get user(): User {
    return this.authService.user;
  }
  get teamdetails(): Teamdetails {
    return this.authService.teamdetails;
  }
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  @ViewChild('comboTimezones') comboTimezones: any;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;

  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  timeZoneForm: any;
  billingcountries!: any[] | null;
  notesDialogRef: MatDialogRef<NotesDialogComponent, any>;
  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;

  countries!: any[] | null;
  timeZones: any[] | null = [];
  imageUrl: string | null = null;;
  selectedCountry: ComboItem = {
    id: '',
    value: ''
  };

  selectedTimeZone: ComboItem = {
    id: '',
    value: ''
  };

  localTime = `${moment().format(environment.format.time)}, ${moment.tz.guess()}`;
  targetTime = '-';
  // user;
  constructor(
    public view: View,
    public config: Config,
    public router: Router,
    public passView: View,
    public themeView: View,
    public http: HttpClient,
    private fb: FormBuilder,
    public billingView: View,
    public historyView: View,
    public dialog: MatDialog,
    public msaccountview: View,
    public route: ActivatedRoute,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    public alertService: AlertService,
    private countryService: CountryService,
    public customThemeService: CustomThemeService,
    private authenticationService: AuthenticationService


  ) {
    this.logoimg = this.imageUrl;
    // this.view.get('user/username/' + this.config.getUsername()).subscribe(res => {
    //   this.user = res;
    // });
    //  this.view.initForm('user', this.getNewForm());
    this.currentUserData = JSON.parse(localStorage.getItem('currentUserStore'));
    this.selectedFiles = undefined;

    this.passView.initForm('user/resetpwd', this.getNewPassForm());
    this.themeView.initForm('personalize/customize', this.getNewThemeForm());
    this.refundlist = false;

    this.view = new View(http, router, route, alertService);
    this.passView = new View(http, router, route, alertService);
    this.themeView = new View(http, router, route, alertService);

    this.billingView = new View(http, router, route, alertService);
    this.historyView = new View(http, router, route, alertService);
    this.msaccountview = new View(http, router, route, alertService);
    // this.featureview = new View(http, router, route, alertService);
    // this.passView.initForm('planpricing', this.getPriceForm(), null, false);
    // this.featureview.initForm('planfeature', this.getFeatureForm(), null, false);
    this.passView.initForm('user/resetpwd', this.getNewPassForm(), null, false);
    this.themeView.initForm('personalize/customize', this.getNewThemeForm(), null, false);

    this.msaccountview.initForm('msaccount', this.getNewMsAccountForm());
    this.billingView.initForm('billinginfo', this.getNewBillingForm(), null, false);
    this.view.initForm('user', this.getNewForm(), null, true);
    this.currentUsr = JSON.parse(localStorage.getItem('currentUserStore'));

    for (const item of this.view.formFields) {

      const currentUserStore = JSON.parse(localStorage.getItem('currentUserStore'));

      if (this.config.currentUser) {
        this.view.field(item).setValue(this.config.currentUser[item]);
      } else {
        this.view.field(item).setValue(currentUserStore[item]);

      }
    }
    this.view.state = 'edit';
    this.historylist = '';
    // this.historylist.paginator = this.paginator;

    this.sachethistorylist = '';
  }

  private initUserForm() {
    this.view.get('user/username/' + this.config.getUsername()).subscribe(res => {

      this.config.currentUser = res;
      localStorage.setItem('currentUserStore', JSON.stringify(res));
      this.view.initForm('user', this.getNewForm());
      for (const item of this.view.formFields) {
        const currentUserStore = JSON.parse(localStorage.getItem('currentUserStore'));

        if (this.config.currentUser) {
          this.view.field(item).setValue(this.config.currentUser[item]);
        } else {
          this.view.field(item).setValue(currentUserStore[item]);

        }
        // this.view.field(item).setValue(this.config.currentUser[item]);
      }
      this.changezoneflag = false;
      this.view.state = 'edit';
    });

  }

  private initMsForm() {
    this.msaccountview.initForm('msaccount', this.getNewMsAccountForm());
  }

  getUsername() {


    if (!this.config.currentUser) {
      //console.log(JSON.parse(localStorage.getItem('currentUserStore')));
      return JSON.parse(localStorage.getItem('currentUserStore'));
    } else {
      return this.config.currentUser;
    }
  }
  getProjName(project) {

    let projName: any;
    if (project.productNewOrExisting === 1) {
      //  projName = project.productName;
      projName = project.brandName || project.productName;

    } else if (project.productNewOrExisting === 2) {
      projName = project.brandName;
    }
    return projName;
  }
  yotubeClick() {
    let myurl = 'https://www.youtube.com/@devToM-AI/videos';
    window.open(myurl, '_blank');
  }
  acdemyClick() {
    let myurl = 'https://devtom-academy.getlearnworlds.com/course/elevate-your-product-strategy';
    window.open(myurl, '_blank');
  }
  chooseProject(project) {
    localStorage.setItem('projRouted', JSON.stringify(project));
    window.location.href = '/project';

  }
  getLabel() {
    if (JSON.parse(localStorage.getItem('currentUserStore'))?.pin != 'USR') {
      return 'Account Profile';
    } else {
      return 'Profile';
    }
  }

  getNewForm() {
    return this.fb.group({
      id: [''],
      name: ['', Validators.required],
      loginKey: ['', Validators.required],
      country: [],
      timezone: [],
      active: [1],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(AppPattern.EMAIL)
      ])],
    }, { validators: this.checkCountryTime });
  }
  featureChecker() {
    return this.config.featureChecker(21);
  }
  personlizefeatureChecker() {
    return this.config.featureChecker(22);
  }
  clientfeatureChecker() {
    return this.config.featureChecker(18);
  }
  onSubmit() {
    this.view.set('active', '1');
    if (this.changezoneflag) {
      this.view.set('country', this.selectedCountry?.value);
      this.view.set('timezone', this.selectedTimeZone?.value);
    }
    this.changezoneflag = false;


    this.view.save();

    // this.view.set('country', this.selectedCountry?.id);
    // this.view.set('timezone', this.selectedTimeZone.id);

  }
  customInsIQ() {

  }
  onidSubmit() {
    // this.msaccountview.set('userId', this.config.getUsername());
    this.msaccountview.save();
    this.fstuser = false;
  }
  resetPassword(value: boolean) {

    if (JSON.parse(localStorage.getItem('currentUserStore'))?.pin != 'USR') {
      this.pageId = 5;
    } else {
      this.pageId = 1;
    }

    this.msteams = false;
    this.acntdeactivate = false;
    this.resetPass = value;
    if (value === true) {
      this.passView.initForm('user/resetpwd', this.getNewPassForm());
      this.passView.state = 'edit';
    } else {
      this.initUserForm();
    }
  }
  personalize() {
    this.pageId = 6;
  }
  previewFile(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageUrl = reader.result as string;
    }
    reader.readAsDataURL(file);
    this.selectedFiles = event.target.files[0];
    this.currentFile = event.target.files[0];
    this.logochange = true;
  }
  upload() {



    //this.currentFile = this.selectedFiles.item(0);
    if (this.themeView.value('domainname') && !this.domainStatus) {

      if (this.themeView.value('domainname').length < 5 || this.themeView.value('domainname').length > 12) {
        this.alertService.error(
          'Domain name should between 5 and 12 characters',
          true, 10 * 1000
        );
      }
      else {

        this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
          data: { title: 'Confirm Sub domain', message: 'Your sub domain will be https://' + this.themeView.value('domainname').trim() + '.insiq.ai . Once a subdomain is created,you cannot modify. Do you want to proceed ?', cancelbtn: 'No', okbtn: 'Yes' },
        });
        this.warningDialogRef.afterClosed().subscribe(result => {
          if (result.data === 'confirm') {
            this.proceedtoUpdate();

          }
        });

      }
    }
    else {
      this.proceedtoUpdate();
    }


  }
  proceedtoUpdate() {
    Config.loading = true;
    if (this.logochange) {
      this.upload1(this.currentFile);

    }
    else {
      this.updatetheme();

    }
    this.selectedFiles = undefined;
  }
  updatetheme() {
    const formData: FormData = new FormData();
    let themeid = this.themeView.value('apptheme_id');
    formData.append('domainname', this.themeView.value('domainname') ? this.themeView.value('domainname') : '');
    formData.append('apptheme_id', themeid.id);
    formData.append('user', this.getUsername()?.loginKey);
    // const req = new HttpRequest('POST', `${Config.apiUrl}/personalize/customize`, formData, {
    //   reportProgress: true,
    //   responseType: 'json'
    // });
    const requestOption = JsonRequest.getHeader('');


    this.httpClient.post(`${Config.apiUrl}/personalize/customizenologo`, formData, { observe: 'response' })
      .subscribe((response) => {
        //console.log(response.status);
        Config.loading = false;
        this.alertService.success(
          'Personalization Updated Successfully.',
          true, 10 * 1000
        );
        this.domainalert();
      }, err => {

        Config.loading = false;

        this.alertService.error(
          err,
          true, 10 * 1000
        );
      }
      );
    //return this.http.request(req);
  }
  omit_special_char(event) {

    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 45 || k == 95);
  }
  upload1(file: File) {
    const formData: FormData = new FormData();
    let themeid = this.themeView.value('apptheme_id');
    formData.append('file', this.currentFile, this.currentFile.name);
    formData.append('domainname', this.themeView.value('domainname') ? this.themeView.value('domainname') : '');
    formData.append('apptheme_id', themeid.id);
    formData.append('user', this.getUsername().loginKey);
    // const req = new HttpRequest('POST', `${Config.apiUrl}/personalize/customize`, formData, {
    //   reportProgress: true,
    //   responseType: 'json'
    // });
    const requestOption = JsonRequest.getHeader('');


    this.httpClient.post(`${Config.apiUrl}/personalize/customize`, formData, { observe: 'response' })
      .subscribe((response) => {
        // console.log(response.status);
        Config.loading = false;
        this.view.get('personalize/whitelabels').subscribe(res => {

          // this.countryList=res;
          //console.log(res);

          if (res) {
            if (res?.apptheme) {
              let dynamiclogostat = false;
              if (res?.logopath) {
                dynamiclogostat = true;
              }
              this.customThemeService.changeTheme(res?.apptheme.themeName, 'data:image/png;base64,' + res?.logoImage, dynamiclogostat);

            }
            //  console.log(this.customThemeService.logo)

            // this.imageUrl = 'data:image/png;base64,' + res?.logoImage;
          }
          else {
            this.customThemeService.changeTheme('theme-default', 'logo.png', false);

          }

        });
        this.alertService.success(
          'Personalization Updated Successfully.',
          true, 10 * 1000
        );
        this.domainalert();
      },
        err => {

          Config.loading = false;

          this.alertService.error(
            err,
            true, 10 * 1000
          );
        }
      );
    //return this.http.request(req);
  }
  domainalert() {
    if (this.themeView.value('domainname') && !this.domainStatus) {
      this.domainStatus = true;

      this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
        data: { title: 'Domain Updated', message: 'Your InsIQ sub domain <a href="https://' + this.themeView.value('domainname').trim() + '.insiq.ai">https://' + this.themeView.value('domainname').trim() + '.insiq.ai</a> will be set up and active within the next 48 hours.\n If you have any questions or concerns, please contact our support team for assistance.', cancelbtn: 'Cancel', okbtn: 'Ok' },
      });


    }


  }
  msteamstab() {
    this.pageId = 4;
    this.msaccountview.initForm('msaccount', this.getNewMsAccountForm());
    this.msaccountview.get('msaccount/msaccountvalid').subscribe(res => {
      if (res == null) {
        this.fstuser = true;
      } else {
        this.fstuser = false;
        this.msaccountview.set('clientId', res.clientId);
        this.msaccountview.set('tenantId', res.tenantId);
        this.mymsId = res.id;
      }
    });
    this.msteams = true;
    this.resetPass = false;
    this.acntdeactivate = false;
  }
  billinginfoTab() {
    this.pageId = 1;
    this.billingView.initForm('billinginfo', this.getNewBillingForm());
    this.msaccountview.get('billinginfo').subscribe(res => {
      // console.log(res[0].billingName);
      if (res == null) {
      } else {
        this.billingView.set('billingName', res[0]?.billingName);
        this.billingView.set('phone', res[0]?.phone);
        this.billingView.set('address', res[0]?.address);
        this.billingView.set('email', res[0]?.email);
        this.billingView.set('company', res[0]?.company);
        this.billingView.set('vat', res[0]?.vat);
        this.billingView.set('city', res[0]?.city);
        this.billingView.set('zip', res[0]?.zip);
        // this.billingView.set('country', res[0]?.country);

        this.filteredCountry = this.countryList?.filter((pt: { name: string; }) =>
          pt?.name?.toLowerCase()?.indexOf(res[0]?.country.toLowerCase().trim()) >= 0
        );

        const result = [];
        const map = new Map();

        if (!map.has(res[0]?.country.toLowerCase().trim())) {
          map.set(res[0]?.country.toLowerCase().trim(), true);    // set any value to Map
          result.push({
            name: res[0]?.country
          });
        }

        //  this.countryList = result;
        this.billingView.set('country', res[0]?.country);
        this.billingView.set('region', res[0]?.region);
        this.billingView.set('city', res[0]?.city);
        this.billingView.set('zip', res[0]?.zip);
      }
    });
  }
  disablecheck() {
    if (!this.disabledAgreement) {
      return false;
    } else {
      return true;
    }
  }
  historyTab() {
    this.pageId = 2;
    this.historylist = '';
    this.sachethistorylist = '';
    this.historyView.get('paylog').subscribe(res => {
      // console.log(res);
      this.historylist = res;
      this.historylist = new MatTableDataSource(this.historylist);
      this.historylist.paginator = this.paginator;
      this.historyView.get('paylog/sachet').subscribe(r => {
        // console.log(res);
        this.sachethistorylist = r;
        this.sachethistorylist = new MatTableDataSource(this.sachethistorylist);
        this.sachethistorylist.paginator = this.paginatorPageSize;
        // if (res == null) {
        // } else {
        //   this.billingView.set('billingName', res[0].billingName);
        //   this.billingView.set('phone', res[0].phone);
        //   this.billingView.set('address', res[0].address);
        //   this.billingView.set('email', res[0].email);
        //   this.billingView.set('country', res[0].country);
        // }
      });
    });
  }

  historyTab1() {
    this.pageId = 3;
    this.transactionhistorylist = '';
    this.refundhistorylist = '';
    this.historyView.get('transactionlog').subscribe(res => {
      //console.log(res);
      this.historyView.get('transactionlog/refund').subscribe(res1 => {
        if (res1?.length != 0) {
          this.refundlist = true;

        }
        this.refundhistorylist = res1;
        this.refundhistorylist = new MatTableDataSource(this.refundhistorylist);
        this.refundhistorylist.paginator = this.paginator2;
      });
      // console.log(res);
      this.transactionhistorylist = res;
      this.transactionhistorylist = new MatTableDataSource(this.transactionhistorylist);
      this.transactionhistorylist.paginator = this.paginator1;
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.transactionhistorylist.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.refundhistorylist.filter = filterValue.trim().toLowerCase();
  }
  extractNumericValue(originalValue) {
    // Use regular expression to extract numeric part
    const numericPart = parseFloat(originalValue.replace(/[^0-9.]/g, ''));
    return isNaN(numericPart) ? undefined : numericPart;
  }

  applyInvoiceFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.historylist.filter = filterValue.trim().toLowerCase();
  }

  applySachetFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sachethistorylist.filter = filterValue.trim().toLowerCase();
  }
  billingInfoSubmit() {
    // console.log(this.billingView.value('country').name);
    const country = this.billingView.value('country');

    let region = null;
    if (country == 'UNITED STATES OF AMERICA') {
      region = this.billingView.value('region');
    }
    else {
      region = null;
    }
    this.billingView.set('country', this.billingView.value('country'));
    this.billingView.set('region', this.billingView.value('region'));
    this.billingView.set('city', this.billingView.value('city'));
    this.billingView.set('zip', this.billingView.value('zip'));
    this.billingView.save('billinginfo');

    const result = [];
    const map = new Map();

    if (!map.has(country.toLowerCase().trim())) {
      map.set(country.toLowerCase().trim(), true);    // set any value to Map
      result.push({
        name: country
      });
    }


    //  this.countryList = result;
    setTimeout(() => {

      this.billingView.set('country', country);
      this.billingView.set('region', region);
      this.billingView.set('city', this.billingView.value('city'));
      this.billingView.set('zip', this.billingView.value('zip'));
    }, 500);

  }

  cancelPlan() {
    this.view.get('subscription/cancelplan').subscribe(res => {

      //console.log(res);
      // let message = 'If you proceed with cancelling your subscription, your current plan will be terminated. This means you will lose access to your projects until you purchase a new plan, and your team members will no longer be able to log in. Are you certain you want to proceed with the cancellation?';
      if (!res) {
        this.message = 'You are not eligible for a refund.';
      }
      else {
        this.message = 'You are eligible for the refund';

      }
      this.notesDialogRef = this.dialog.open(NotesDialogComponent, {
        width: '75vw',
        maxHeight: '98vh',
        maxWidth: '1024px',
        minWidth: '1024px',
        data: this.STAT_POPUP
      });
      // this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      //   data: { title: 'Confirm Subscription Cancellation', message: message, cancelbtn: 'No', okbtn: 'Yes' },
      // });
      // this.warningDialogRef.afterClosed().subscribe(result => {
      //   if (result.data === 'confirm') {
      //     this.warningDialogRef.close();
      //     this.router.navigate(['/cancelsubscription']);

      //   }
      //   else {
      //     this.warningDialogRef.close();
      //     // this.router.navigate(['/']);
      //   }
      // });

    });

  }
  cancelConfirm() {
    this.dialog.closeAll();
    this.router.navigate(['/cancelsubscription']);
  }
  cancelpop() {
    this.dialog.closeAll();

  }
  upgradePlan() {
    localStorage.setItem('upgradeFlag', 'true');

    this.router.navigate(['/subscription']);
    localStorage.setItem('cond', 'true');


  }

  renewPlan() {
    localStorage.setItem('upgradeFlag', 'false');

    this.router.navigate(['/subscription']);
    localStorage.setItem('cond', 'true');

  }

  sachetsubscription(type: string) {
    this.dialog.closeAll();

    if (type === 'data') {
      this.router.navigate(['/sachetsubscription/data']);
    } else if (type === 'api') {
      this.router.navigate(['/sachetsubscription/api']);
    } else if (type === 'user') {
      this.view.get('apiusage/usercount').subscribe(res => {
        let userval;
        userval = res[0];

        let sachetpurchased = userval?.sachetpurchased;
        let sachetPossible = userval?.sachetuser;

        if (sachetpurchased >= sachetPossible) {
          this.alertService.error(
            'Your Sachet Limit for Current Plan Exhausted',
            true, 20 * 1000);
        }

        else {
          this.router.navigate(['/sachetsubscription/user']);

        }
      });
      // this.router.navigate(['/sachetsubscription/user']);
    }

    localStorage.setItem('cond', 'true');
  }

  getUsrMsg() {
    if (this.fstuser) {
      this.usermsg = 'Follow the procedures in Help Tip to get the Client Id and Tenant by Registering the app.';
    } else {
      this.usermsg = 'Please Sign In to Your Microsoft Account to Integrate with MS Teams';
    }
    return this.usermsg;
  }
  // deleteacnt() {
  //   this.acntdeactivate = true;
  //   this.msteams = false;
  // }
  msteamhelp() {
    const dialogRef = this.dialog.open(MsteamhelpDialogComponent, {
    });
  }
  changeCheck(event) {
    this.disabledAgreement = !event.checked;
  }
  cnfirmdel() {


    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Are you ABSOLUTELY SURE?', message: 'Deletion is permanent and is an irreversible process, which we cant revert even if you perform it by accident.Deleting your account will remove all your data associated with this account and  access to InsIQ.', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        Config.loading = true;

        this.view.delete(this.view.value(this.view.formFields[0]));
        this.removlogInAs(this.config.currentUser.loginKey);
        setTimeout(() => {
          Config.loading = false;
          this.authenticationService.logout();

        }, 2000);
      }
    });


  }
  removlogInAs(username: any) {
    // console.log('herehjjjjjjj');
    let array: any = [];
    let arraytemp: any = [];
    let arrayfin: any = [];
    if (localStorage.getItem('userlist')) {
      // exist data on storage
      // so grab the storage
      array = JSON.parse(localStorage.getItem('userlist'));
      // we overwrite the same array with the data from LocalStorage
    }
    arraytemp = array.filter((item: { loginkey: any; }) => item.loginkey !== username);
    // console.log(array); //Watching if have some old data!

    array = arraytemp;
    // So we push the newUser , if exist something on localStorage the if before actually have the data
    // console.log(array); //Watching with new data and old data
    localStorage.setItem('userlist', JSON.stringify(array)); // and save the data

    // exist data on storage
    // so grab the storage
    arrayfin = JSON.parse(localStorage.getItem('userlist'));
    // this.userhistory = arrayfin.filter((item: { loginkey: any; }) => item.loginkey !== this.config?.currentUser?.loginKey);


    // we overwrite the same array with the data from LocalStorage
  }

  changePwd() {
    // console.log('here');
    try {
      const myEncodedQueryParamValueCur = this.passView.value('currPwd').replace('+', '%2B');
      const myEncodedQueryParamValue = this.passView.value('inputPwd').replace('+', '%2B');

      this.passView.set('currPwd', myEncodedQueryParamValueCur);
      this.passView.set('inputPwd', myEncodedQueryParamValue);
      this.passView.set('cpassword', myEncodedQueryParamValue);

      this.passView.save('user/resetpwd', 'Password updated Successfully.');
      this.pageId = 0;
    } catch (e) {

    }

  }
  getNewPassForm() {
    return this.fb.group({
      currPwd: ['', [Validators.required, Validators.minLength(6)]],
      inputPwd: ['', [Validators.required, Validators.minLength(6)]],
      cpassword: ['']
    }, { validators: this.checkPasswords });
  }

  getNewThemeForm() {
    return this.fb.group({
      logopath: [''],
      apptheme_id: [''],
      domainname: ['']
    });
  }
  getNewBillingForm() {
    return this.fb.group({
      billingName: ['', Validators.required],
      phone: [],
      address: ['', Validators.required],
      email: [],
      country: ['', Validators.required],
      region: [],
      city: ['', Validators.required],
      zip: ['', Validators.required],
      company: ['', Validators.required],
      vat: []
    }, { validators: this.checkRegion });
  }

  checkRegion(group: FormGroup) { // here we have the 'passwords' group

    //console.log(group.controls.country.value);
    const country = group.controls.country.value;
    const region = group.controls.region.value;

    // console.log(country == 'UNITED STATES OF AMERICA' && (region === null || region === '' || region === undefined) ? { notSame: true } : null);
    return country == 'UNITED STATES OF AMERICA' && (region === null || region === '' || region === undefined) ? { notSame: true } : null;

  }

  checkCountryTime(group: FormGroup) {
    const country = group.controls.country.value;
    const timezone = group.controls.timezone.value;
    let retFlag = false;

    return (JSON.parse(localStorage.getItem('currentUserStore'))?.pin != 'USR' && ((country === null || country === '' || country === undefined) || (timezone === null || timezone === '' || timezone === undefined))) ? { notSame: true } : null;


  }
  getNewMsAccountForm() {
    // return this.feedbackForm;
    return this.fb.group({
      id: [],
      userId: [''],
      clientId: [''],
      tenantId: ['']
    });
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.inputPwd.value;
    const confirmPass = group.controls.cpassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
  setupbtn() {
    //   console.log('HEREEEEEE', this.authenticated);
    ProfileComponent.appId = 'd39209e1-94d4-4fdf-b3a8-3bbfc19652e6';
    this.mssignIn();
  }
  async mssignIn(): Promise<void> {
    await this.authService.signIn();
  }
  signOut(): void {
    this.authService.signOut();
  }
  removeMs() {
    this.msaccountview.delete(this.mymsId);
    // [routerLink]="['/profile']"
    this.fstuser = true;
    this.authService.signOut();
    window.location.reload();
    // this.authenticated=false;
  }

  tabClick(tab: { index: number; }) {
    // console.log(tab);
    this.pageId = tab.index;
    // console.log(this.pageId );

    if (JSON.parse(localStorage.getItem('currentUserStore'))?.pin != 'USR') {
      switch (this.pageId) {
        case 0: this.initUserForm();
          break;
        case 2: this.historyTab();
          break;
        case 3: this.historyTab1();
          break;
        case 4: this.msteamstab();
          break;
        case 1: this.billinginfoTab();
          break;
        case 5: this.resetPassword(true);
          break;
      }
    } else {
      switch (this.pageId) {
        case 0: this.initUserForm();
          break;

        case 1: this.resetPassword(true);
          break;

      }
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  invoiceDownload(row, type) {
    // this.view.get('subscription/users/export/pdf').subscribe(res => {
    //   console.log(res);
    //  // this.productTypes = res;
    // });

    let urlpar = '';
    if (type === 1) {
      urlpar = 'export';
    } else if (type === 2) {
      urlpar = 'sachetbill';
    }

    const url = `${Config.apiUrl}/subscription/users/` + urlpar + `/pdf/` + row.id + `/` + row.userId.id;

    const requestOption = JsonRequest.getHeader('');
    // requestOption.responseType='blob';
    this.http.get(url, { headers: requestOption.headers, params: requestOption.params, responseType: 'blob' }).subscribe(
      (data: Blob) => {
        const file = new Blob([data], { type: 'application/octet-stream' });
        const fileURL = URL.createObjectURL(file);
        Config.loading = false;
        // if you want to open PDF in new tab
        // window.open(fileURL);
        const a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'InsIQInvoice.pdf';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        console.log('Something went Wrong', error);
      }
    );
  }

  parseArrayToComboItems(array: any[]): ComboItem[] {
    const listItems: ComboItem[] = [];
    array.map((v: any, i: any) => {
      // console.log({ i, v });
      listItems.push({
        id: i,
        value: v
      });
    });
    return listItems;
  }

  onChange(opt: any) {
    // console.log(opt);
    this.selectedTimeZone = opt;
  }
  onThemeChange(opt: any) {
    // console.log(opt?.themeName);
    let logo = 'logo.png';
    let logoflag = false;
    if (this.customThemeService.dynamiclogo) {
      logo = this.customThemeService.logo;
      logoflag = true;
    }
    this.customThemeService.changeTheme(opt?.themeName, logo, logoflag);

  }
  onCountryChange(opt: any) {
    // console.log(opt);
    if (!opt) { return; }
    this.timeZones = [];
    this.timeZones = (moment?.tz?.zonesForCountry(opt?.id));
    const timeZoneList = [];

    for (const i in this.timeZones) {
      if (this.timeZones[i]) {
        timeZoneList
          .push(`(GMT${moment.tz(this.timeZones[i]).format('Z')}) ${this.timeZones[i]}`);
      }
    }
    let timeZoneNames: any[];
    timeZoneNames = timeZoneList.sort();
    // console.log(timeZoneNames);
    this.timeZones = this.parseArrayToComboItems(timeZoneNames);
    // console.log(this.timeZones);

    this.comboTimezones?.focus();

    this.selectedCountry = opt;
    this.selectedTimeZone = this.timeZones[0];

    // console.log('TIMEZONES: ', moment.tz.zonesForCountry(opt.id, true));
    // console.log(this.timeZones);

    if (this.view.form.value.country === null) {
      this.view.set('timezone', null);
    }

    this.view.form.get('timezone').patchValue([]);
  }

  filterCountry(event: { query: any; }) {
    let query = event?.query;
    query = query?.toLowerCase().trim();
    this.filteredCountry = this.countryList?.filter((pt: { name: string; }) => pt?.name?.toLowerCase()?.indexOf(query) >= 0);
    //  console.log(this.filteredCountry);
  }
  onCountryClear(opt: any) {
    this.view.form.get('timezone').patchValue([]);
  }

  changezone() {
    this.changezoneflag = true;
    this.view.set('country', null);
    this.view.set('timezone', null);
  }
  photoURL() {
    return this.sanitizer.bypassSecurityTrustUrl(this.imageUrl);
  }
  ngOnInit() {
    this.view.get('user/username/' + this.config.getUsername()).subscribe(res => {

      this.config.currentUser = res;
      localStorage.setItem('currentUserStore', JSON.stringify(res));

    });
    this.config.whilelabelhandler();

    this.projList = this.config.projlist;


    this.msaccountview.get('msaccount/msaccountvalid').subscribe(res => {
      if (res == null) {
        this.fstuser = true;
      } else {
        this.fstuser = false;
        this.teamsManager = res;
        //console.log(this.teamsManager);
      }
    });
    //console.log(this.getUsername());
    //this.imageUrl = "./assets/images/icon-insiq.png";
    this.username = this.getUsername();
    this.view.get('billinginfo/countrylist').subscribe(res => {

      // this.countryList=res;

      const result = [];
      const map = new Map();
      for (const item of res) {
        if (!map.has(item?.name?.toLowerCase().trim())) {
          map.set(item?.name?.toLowerCase().trim(), true);    // set any value to Map
          result.push({
            name: item?.name
          });
        }
      }

      this.countryList = result;
      // console.log(this.countryList);
    });

    this.view.get('personalize/theme').subscribe(res => {
      // this.countryList=res;
      // console.log(res);
      this.themeList = res;
      // console.log(this.countryList);
      this.themeView.set('apptheme_id', {
        "id": 4,
        "themeName": "theme-default",
        "themeCode": "Default",
        "createdBy": null,
        "updatedAt": null,
        "updatedBy": null
      });
    });

    this.view.get('personalize/whitelabels').subscribe(res => {



      if (res) {
        this.domainverify = res?.domainVerified;
        if (res?.domainname) {
          //  console.log(res?.domainname?.length)
          if (res?.domainname?.length !== 0) {
            this.themeView.set('domainname', res.domainname);
            this.domainStatus = true;

          }


        }
        else {
          this.themeView.set('domainname', '');

        }

        this.themeView.set('apptheme_id', res.apptheme);

        if (res?.logopath) {
          this.dynamiclogo = true;
          this.imageUrl = 'data:image/png;base64,' + res?.logoImage;

        }
        //this.imageUrl = 'data:image/png;base64,' + res?.logoImage;
      }
      // console.log(this.countryList);
      //this.themeView.set('apptheme_id', { "id": 4, "themeName": "theme-default", "themeCode": "T1", "createdBy": null, "updatedAt": null, "updatedBy": null });

    });

    this.view.get('billinginfo/regionlist').subscribe(res => {
      // console.log(res);

      const result = [];
      const map = new Map();
      for (const item of res) {
        if (!map.has(item?.name?.toLowerCase().trim())) {
          map.set(item?.name?.toLowerCase().trim(), true);    // set any value to Map
          result.push({
            name: item?.name
          });
        }
      }

      this.regionList = result;
    });
    this.timeZoneForm = this.fb.group({
      country: undefined,
      timezone: undefined
    });
    //console.log(this.countryService.getCountries());
    this.countries = this.countryService?.getCountries();
    this.timeZones = this.parseArrayToComboItems(moment?.tz?.names());
    //console.log(this.view.value('country'));
    let retarary = this.countries.find(e => e.value === this.view.value('country')); // { name: 'apples', quantity: 2 }
    // console.log(retarary);
    this.timeZones = [];
    try {
      this.timeZones = (moment?.tz?.zonesForCountry(retarary?.id));

    }
    catch (e) {

    }
    const timeZoneList = [];

    for (const i in this.timeZones) {
      if (this.timeZones[i]) {
        timeZoneList
          .push(`(GMT${moment?.tz(this.timeZones[i]).format('Z')}) ${this.timeZones[i]}`);
      }
    }
    let timeZoneNames: any[];
    timeZoneNames = timeZoneList.sort();
    // console.log(timeZoneNames);
    this.timeZones = this.parseArrayToComboItems(timeZoneNames);
    // setInterval(() => {
    //   this.localTime = `${moment().format(environment.format.time)}, ${moment.tz.guess()}`;
    //   if (this.selectedTimeZone && this.selectedTimeZone.value !== '') {
    //     // console.log('VALID', this.selectedTimeZone.value);
    //     this.targetTime = `${moment().tz(this.selectedTimeZone.value).format(environment.format.time)}, ${this.selectedTimeZone.value}`;
    //   }
    // }, 500);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
}
