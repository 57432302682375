<h2 matDialogTitle>{{data.title}}</h2>
<mat-card-content>
  <p [innerHTML]="data.message"></p>
</mat-card-content>
<mat-card-actions class="m-b-0 p-b-0">
  <div class="m-t-15 t-c">
    <button type="button" (click)="cancelAction()" matRipple *ngIf="data.cancelbtn !== null && data.cancelbtn !== ''"
      class="btn btn-lg btn-default lighten m-x-5 btn-cancel">{{data.cancelbtn}}</button>
    <button type="button" (click)="saveAction()" matRipple class=" m-x-5 btn btn-lg btn-primary">{{data.okbtn}}</button>
  </div>
</mat-card-actions>