import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {Validators, FormBuilder} from '@angular/forms';
import {Config, View} from '../../../config/global.config';

@Component({
  selector: 'app-requirementitem',
  templateUrl: './requirementitem.component.html',
  styleUrls: ['./requirementitem.component.scss']
})
export class RequirementItemComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  requirementTypeCodes;
  constructor(public view: View, private fb: FormBuilder, public config: Config) {
    this.view.initForm('requirementtypeitem', this.getNewForm());
  }

  ngAfterViewInit() {
    this.view.initPage(this.paginator, this.sort);
  }

  ngOnInit() {
    this.view.get('requirementtype/all').subscribe(res => {
      this.requirementTypeCodes = res;
    });
  }

  onSubmit() {
    this.view.save();
  }

  getNewForm() {
    return this.fb.group({
      id: ['', Validators.required],
      requirementTypeCode: ['', Validators.required],
      description: ['', Validators.required],
      active: ['1']
    });
  }
}
