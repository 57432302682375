<app-alert></app-alert>

<mat-sidenav-container class="mainContainer d-flex fh">
  <mat-sidenav mode="side" opened *ngIf="config.isProjectSelected()">
    <app-project-menu></app-project-menu>
  </mat-sidenav>
  <mat-sidenav-content class="fw">
    <app-header *ngIf="config.isLoggedIn()" (linkEvent)="ssp.linEvHand($event)"></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="expiresIn" tabindex="0"
  *ngIf="config.isLoggedIn()&&config?.currentUser?.currentPlan&&config?.currentUser?.redirectStatus===1&&config?.currentUser?.daysRemaining<=7&&!config?.currentUser?.gp">
  {{config?.currentUser?.currentPlan}}
  Plan: <strong>{{config?.currentUser?.daysRemaining}} Days</strong> left
  <span *ngIf="config.currentUser?.pin!='USR'" class="link" (click)="testClick()">Upgrade Now</span>
</div>

<div class="expiresIn" tabindex="0"
  *ngIf="config.isLoggedIn()&&config?.currentUser?.currentPlan&&config?.currentUser?.redirectStatus===1&&config?.currentUser?.gp">
  Your InsIQ Plan Expired. Your account get terminated by:
  <strong> {{config?.currentUser?.gp| date: 'dd/MM/yyyy'}} </strong>
  <span *ngIf="config.currentUser?.pin!='USR'" class="link" (click)="testClick()">Upgrade Now</span>
</div>

<div class="dev2mOverview" *ngIf="config.dev2MOverview">
  <a class="back" mat-button color="warn" (click)="toggleOverview()">
    <i class="i i-4x" style="line-height: 36px;">back</i>
    <!-- <span class="ib-m">Back</span> -->
  </a>
  <h1 class="pageHead">How InsIQ works</h1>

  <div class="d-flex">
    <mat-tab-group mat-stretch-tabs class="w-100 steps" [selectedIndex]="0" (selectedIndexChange)="tabChanged($event)">
      <mat-tab label="Evaluation">
        <ul class="steps noArrows">
          <li class="bigIcon" *ngIf="animoStats.addIdea" [@fadeIn]="animoStats.addIdea"
            (@fadeIn.done)="nextAnimation($event, 'idea')">
            <div class="iconHeader">
              <i class="i i-idea"></i>
              <div class="caption">
                Describe your<br>Product/ Idea
              </div>
            </div>
          </li>
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>Idea</div>
              </div>
            </div>
            <ul class="bubbleList from" *ngIf="animoStats.idea" [@listStaggerXS]="data.evaluation.idea.length"
              (@listStaggerXS.done)="nextAnimation($event, 'ai')">
              <li *ngFor="let d of data.evaluation.idea">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false"
                    [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul>
          </li>
          <li class="bigIcon" *ngIf="animoStats.ai" [@fadeIn]="animoStats.addIdea"
            (@fadeIn.done)="nextAnimation($event, 'aiInsights')">
            <div class="iconHeader bothSides">
              <i class="i i-ai"></i>
              <div class="caption">
                AI<br>
                Insights
              </div>
            </div>
          </li>
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>AI Insights</div>
              </div>
            </div>
            <ul class="bubbleList from" *ngIf="animoStats.aiInsights"
              [@listStaggerXS]='data.evaluation.aiInsights.length'
              (@listStaggerXS.done)="nextAnimation($event, 'whatToDo')">
              <li *ngFor="let d of data.evaluation.aiInsights">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false" [cssClass]="'midAlign'">
                  </app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul>
          </li>
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>What To Do</div>
              </div>
            </div>
            <ul class="bubbleList from" *ngIf="animoStats.whatToDo" [@listStaggerXS]='data.evaluation.whatToDo.length'
              (@listStaggerXS.done)="nextAnimation($event, '')">
              <li *ngFor="let d of data.evaluation.whatToDo">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false" [cssClass]="'midAlign'">
                  </app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul>
          </li>
        </ul>
      </mat-tab>
      <mat-tab label="Planning">
        <ul class="steps">
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>Discovery</div>
              </div>
            </div>
            <!-- TEMPORARY COMMENTED -->
            <!-- <ul class="bubbleList" *ngIf="animoStats.discovery"
              [@listStaggerXS]='data.planning.discovery.length'
              (@listStaggerXS.done)="nextAnimation($event, 'concept')">
              <li *ngFor="let d of data.planning.discovery">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [layout]="tipWhatIsKP" [icon]="false"
                    [tour]="false" [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul> -->
          </li>
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>Concept</div>
              </div>
            </div>
            <!-- TEMPORARY COMMENTED -->
            <!-- <ul class="bubbleList" *ngIf="animoStats.concept"
              [@listStaggerXS]='data.planning.concept.length'
              (@listStaggerXS.done)="nextAnimation($event, '')">
              <li *ngFor="let d of data.planning.concept">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [layout]="tipWhatIsKP" [icon]="false"
                    [tour]="false" [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul> -->
          </li>
        </ul>
        <h1 class="h1 text-accent t-c">COMING SOON</h1>
      </mat-tab>
      <mat-tab label="Execution">
        <ul class="steps">
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>Risk Analysis</div>
              </div>
            </div>
            <!-- TEMPORARY COMMENTED -->
            <!-- <ul class="bubbleList" *ngIf="animoStats.risk" [@listStaggerXS]='data.execution.risk.length'
              (@listStaggerXS.done)="nextAnimation($event, 'detailedDesign')">
              <li *ngFor="let d of data.execution.risk">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false"
                    [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul> -->
          </li>
          <li>
            <div class="iconHeader">
              <!-- <figure>
                <i class="i i-discovery"></i>
              </figure> -->
              <div class="caption">
                <div>Detailed Design</div>
              </div>
            </div>
            <!-- TEMPORARY COMMENTED -->
            <!-- <ul class="bubbleList" *ngIf="animoStats.detailedDesign"
              [@listStaggerXS]='data.execution.detailedDesign.length'
              (@listStaggerXS.done)="nextAnimation($event, 'virtualTesting')">
              <li *ngFor="let d of data.execution.detailedDesign">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false"
                    [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul> -->
          </li>
          <li>
            <div class="iconHeader">
              <!-- <figure>
                <i class="i i-discovery"></i>
              </figure> -->
              <div class="caption">
                <div>Virtual Testing</div>
              </div>
            </div>
            <!-- TEMPORARY COMMENTED -->
            <!-- <ul class="bubbleList" *ngIf="animoStats.virtualTesting"
              [@listStaggerXS]='data.execution.virtualTesting.length'
              (@listStaggerXS.done)="nextAnimation($event, 'prototype')">
              <li *ngFor="let d of data.execution.virtualTesting">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false"
                    [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul> -->
          </li>
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>Prototyping</div>
              </div>
            </div>
            <!-- TEMPORARY COMMENTED -->
            <!-- <ul class="bubbleList" *ngIf="animoStats.prototype"
              [@listStaggerXS]='data.execution.prototype.length'
              (@listStaggerXS.done)="nextAnimation($event, 'product')">
              <li *ngFor="let d of data.execution.prototype">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false"
                    [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul> -->
          </li>
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>Product</div>
              </div>
            </div>
            <!-- TEMPORARY COMMENTED -->
            <!-- <ul class="bubbleList" *ngIf="animoStats.product"
              [@listStaggerXS]='data.execution.product.length'
              (@listStaggerXS.done)="nextAnimation($event, '')">
              <li *ngFor="let d of data.execution.product">
                <figure [attr.aria-label]="d.highlight">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false"
                    [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul> -->
          </li>
        </ul>
        <h1 class="h1 text-accent t-c">COMING SOON</h1>
      </mat-tab>
      <mat-tab label="Review">
        <ul class="steps">
          <li>
            <div class="iconHeader">
              <div class="caption">
                <div>Feedback</div>
              </div>
            </div>
            <!-- TEMPORARY COMMENTED -->
            <!-- <ul class="bubbleList" *ngIf="animoStats.feedback"
              [@listStaggerXS]='data.review.feedback.length'
              (@listStaggerXS.done)="nextAnimation($event, '')">
              <li *ngFor="let d of data.review.feedback">
                <figure attr.aria-label="{{d.highlight}}">
                  <i class="i i-{{d.icon}}"></i>
                  <app-quick-help *ngIf="d.help !== null" [icon]="false" [tour]="false"
                    [cssClass]="'midAlign'"></app-quick-help>
                </figure>
                <div class="caption" [innerHTML]="d.text"></div>
              </li>
            </ul> -->
          </li>
        </ul>
        <h1 class="h1 text-accent t-c">COMING SOON</h1>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<app-support *ngIf="!appSupport"></app-support>

<app-feedback *ngIf="config.isLoggedIn()"></app-feedback>

<app-snackbar></app-snackbar>

<ng-template #tipWhatIsKP>
  <div class="helpContent">
    <div class="heading">
      Keyplayer
    </div>
    <p>
      Keyplayers for your product are your customers, suppliers, your investors,
      regulatory agencies, etc. In short, anyone who helps or influences your journey
      from idea to market
    </p>
    <!-- <div class="note">
      E.g.: - Parents, Kids
    </div> -->
  </div>
</ng-template>

<app-loading [canExit]="config.loader.canExit" [transparentLoader]="config.isTransparent()" (forceExit)="config.loader.forceExit($event)"
  [showProgress]="config.showProgress.getValue()" [progress]="config.loadingProgress.getValue()"
  [message]="config.getLoaderMessage()" [image]="config.getLoaderImage()" [loading]="config.isLoading()"
  *ngIf="config.isLoading()">
</app-loading>