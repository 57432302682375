<header class="header" [ngClass]="{
    projectSelected: config.isProjectSelected()
  }">
  <mat-toolbar *ngIf="!(config.isAdmin())">
    <div *ngIf="(config.currentUser?.planStatus === 1&&config.currentUser?.redirectStatus === 1 )">

      <button mat-button title="My Projects" matTooltipPosition="below" [routerLinkActiveOptions]="{exact: true}"
        routerLinkActive="active" (click)="linkClick1('project')">
        <mat-icon fontSet="i" fontIcon="i-home" aria-label="My Projects"></mat-icon>
      </button>
    </div>
    <span class="spacer"></span>
    <!-- <button mat-raised-button (click)="msteamhelp()"
     color="orange" class="curved m-l-10 m-r-20">
      <mat-icon fontSet="i" aria-label="MS Teams">group_work</mat-icon>
      <span class="ib-m p-l-5">MS Teams</span>
    </button> -->
    <button class="btn btn-details" [ngClass]="

      'btn-primary'
    " (click)="viewDetails({
      type: 'user',
      title: 'Team Member Usage'
    })">
      <div class="line">
        <strong>
          {{config.getUserCount() ? config.getUserCount()?.[0].totalused : dataUsagestore?.[0].totalused}}
        </strong><small>/{{config.getUserCount() ? config.getUserCount()?.[0].totaldisplay :
          apiUsagestore?.[0].totaldisplay}} </small>
      </div>
      <div class="line" [ngClass]="
        'text-primary'
      ">User Count</div>
      <!-- <i class="ico i i-keyboard_arrow_right"></i> -->
    </button>
    <button class="btn btn-details" *ngIf="featureChecker(18)" [ngClass]="{
      'btn-danger': getDataPercent() > 0.85,
      'btn-warning': getDataPercent() > 0.80,
      'btn-primary': getDataPercent() <= 0.80
    }" (click)="viewDetails({
      type: 'data',
      title: 'Data Consumption Details'
    })">
      <div class="line">
        <strong>
          {{config.getdataUsage() ? config.getdataUsage()?.[0].usage : dataUsagestore?.[0].usage}}
          {{config.getdataUsage() ? config.getdataUsage()?.[0].unitvalue : dataUsagestore?.[0].unitvalue}}
        </strong><small>/{{config.getdataUsage() ? config.getdataUsage()?.[0].total :
          apiUsagestore?.[0].total}} {{config.getdataUsage() ? config.getdataUsage()?.[0].unitvalue :
          dataUsagestore?.[0].unitvalue}}</small>
      </div>
      <div class="line" [ngClass]="{
        'text-danger': getDataPercent() > 0.85,
        'text-warning': getDataPercent() > 0.80
      }">Data Usage</div>
      <!-- <i class="ico i i-keyboard_arrow_right"></i> -->
    </button>

    <button class="btn btn-details" [ngClass]="{
      'btn-danger': getUsagePercent() > 0.85,
      'btn-warning': getUsagePercent() > 0.80,
      'btn-primary': getUsagePercent() <= 0.80
    }" (click)="viewDetails({
      type: 'api',
      title: 'API Usage Overview'
    });">
      <div class="line">
        <strong>
          {{config?.getapiUsage() ? config?.getapiUsage()?.[0]?.monthly?.split(".")?.[0] :
          apiUsagestore?.[0]?.monthly?.split(".")?.[0]}}
        </strong><small>/ {{config.getapiUsage() ? config.getapiUsage()?.[0].totalalloted :
          apiUsagestore?.[0]?.totalalloted}}</small>
      </div>
      <div class="line" [ngClass]="{
        'text-danger': usage.api.used / usage.api.total > 0.85,
        'text-warning': usage.api.used / usage.api.total > 0.80
      }">API Usage</div>
      <!-- <i class="ico i i-keyboard_arrow_right"></i> -->
    </button>
    <div class="b-x-1 iconLinks">

      <button mat-button title="Shared Projects" matTooltipPosition="below"
        *ngIf="getCloneFlag()&& (currentUsr?.planStatus === 1||config.currentUser?.planStatus === 1)"
        (click)="cloneApprove()">
        <mat-icon fontSet="i" fontIcon="i-product_box" aria-label="Notifications" class="text-warning blink-icon"
          matBadge="{{getConflen()}}" matBadgeColor="warn"></mat-icon>
      </button>
    </div>
    <!-- <div class="b-x-1 iconLinks">
      !-- <button mat-button title="Settings" matTooltipPosition="below">
        <mat-icon fontSet="i" fontIcon="i-settings" aria-label="settings"></mat-icon>
      </button>
      <button mat-button title="Open B2B" matTooltipPosition="below" (click)="openModalB2B('google.com')">
        <mat-icon fontSet="i" aria-label="Open B2B">open_b2b</mat-icon>
      </button> --

      !-- <mat-menu #helpMenu="matMenu" xPosition="before" [overlapTrigger]="false">
        <ng-template matMenuContent>
          <button mat-menu-item [routerLink]="['/terms-and-conditions']">Terms of Service</button>
          <button mat-menu-item (click)="toggleDev2MOverview()">How InsIQ Works</button>
        </ng-template>
      </mat-menu> --
      !-- <button mat-button title="Help" matTooltipPosition="below" [routerLink]="['/help']"
        [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
        <mat-icon fontSet="i" fontIcon="i-help" aria-label="help"></mat-icon>
      </button>
      <button mat-button title="Actions" matTooltipPosition="below">
        <mat-icon fontSet="i" fontIcon="i-action" aria-label="action"></mat-icon>
      </button>
      <button mat-button title="Messages" matTooltipPosition="below">
        <mat-icon fontSet="i" fontIcon="i-message" aria-label="message" matBadge="4" matBadgeColor="warn"
          matBadgeSize="small"></mat-icon>
      </button> --
      !-- COMMENTED TUTORIAL SECTION --
      !-- <button mat-button title="Tutorials" matTooltipPosition="below" (click)="openVideoPopup(videoId)">
        <mat-icon fontSet="i" fontIcon="i-ondemand_video" aria-label="tutorial"></mat-icon>
      </button> --
    </div> -->

    <button class="btn loginUser" matRipple [matMenuTriggerFor]="userMenu" color="accent">
      <div class="text">
        <span class="username" *ngIf="isLoggedIn()&&config.currentUser">{{config.currentUser?.name}}</span>
        <span class="username" *ngIf="isLoggedIn()&&!config.currentUser">{{currentUsr?.name}}</span>
        <small class="muted">{{currentUsr?.pin=='USR'?'Team Member':'Product Owner'}}</small>
      </div>
      <div class="avatar">
        <!-- <mat-icon fontSet="i" fontIcon="i-account_circle"></mat-icon> -->
        <img src="assets/images/users/avatar.png" [attr.alt]="config.currentUser?.name">
      </div>
      <mat-icon class="" fontSet="i" fontIcon="i-arrow_drop_down"></mat-icon>
    </button>

    <mat-menu #userMenu="matMenu" xPosition="before" [overlapTrigger]="false" class="headerMenu">
      <!-- <pre>{{config.currentUser | json}}</pre> -->
      <ng-template matMenuContent *ngIf="isLoggedIn()">
        <header>
          <button mat-menu-item *ngIf="config.currentUser" [routerLink]="['/profile']"
            [routerLinkActiveOptions]="{exact: true}" class="username">{{config?.currentUser?.loginKey}}</button>
        </header>

        <div class="menu">
          <button mat-menu-item
            *ngIf="((currentUsr?.pin!=='USR')&& (currentUsr?.planStatus === 1))||(config.currentUser?.pin!=='USR')&& (config.currentUser?.planStatus === 1)"
            [routerLink]="['/users']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
            <i class="ico i i-my_team"></i>
            <span>My Team</span>
          </button>

          <button mat-menu-item [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"
            (click)="linkClick('project')">
            <i class="ico i i-projects"></i>
            <span>My Projects</span>
          </button>

          <button mat-menu-item
            *ngIf="(currentUsr?.planStatus === 1||config.currentUser?.planStatus === 1)&&featureChecker(18)"
            (click)="InsIQLocal('.modal.modalB2B', b2bUrl,currentUsr?.aiid)">
            <i class="ico i i-download" aria-label="Open B2B"></i>
            <span class="t-normal">Download BI</span>
          </button>

          <button mat-menu-item title="Contact" matTooltipPosition="below" [routerLink]="['/contact']"
            [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
            <i class="ico i i-contact" aria-label="contact us"></i>
            <span>Contact</span>
          </button>

          <!-- <button mat-menu-item title="Shared Projects" matTooltipPosition="below"
            *ngIf="getCloneFlag()&& (currentUsr?.planStatus === 1||config.currentUser?.planStatus === 1)"
            (click)="cloneApprove()">
            <i class="ico i i-notifications_none" aria-label="Notifications" matBadge="{{getConflen()}}"
              matBadgeColor="warn"></i>
            <span>Notifications</span>
          </button> -->

          <button mat-menu-item title="Terms of Service" [routerLink]="['/terms-and-conditions']"
            [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
            <i class="ico i i-info_report" aria-label="Terms of Service"></i>
            <span>Terms of Service</span>
          </button>

          <button mat-menu-item title="Help" matTooltipPosition="below" (click)="toggleDev2MOverview()">
            <i class="ico i i-help" aria-label="help"></i>
            <span>Help</span>
          </button>

          <button mat-menu-item [routerLink]="['/profile']" [routerLinkActiveOptions]="{exact: true}"
            routerLinkActive="active">
            <i class="ico i i-my_account"></i>
            <span>My Account</span>
          </button>

          <!-- <button mat-menu-item [routerLink]="['/new-project']" [routerLinkActiveOptions]="{exact: true}"
            routerLinkActive="active" (click)="linkClick('new-project')">
            Add Project
          </button> -->
        </div>

        <!-- NEW LOGIN -->
        <div class="userMenu" *ngIf="userhistory.length > 0">
          <!-- <header class="user active" matRipple [routerLink]="['/profile']" [routerLinkActiveOptions]="{exact: true}"
            routerLinkActive="active">
            <div class="dtls">
              <figure>
                <img src="./assets/images/users/default.png" alt="User">
              </figure>
              <div class="ib-m">
                <div class="username" *ngIf="config.currentUser">{{config?.currentUser?.name}}</div>
                <div class="key" *ngIf="config.currentUser">{{config?.currentUser?.loginKey}}</div>

                <div class="username" *ngIf="!config.currentUser">{{currentUsr?.name}}</div>
                <div class="key" *ngIf="!config.currentUser">{{currentUsr?.loginKey}}</div>
              </div>
            </div>

            <div class="t-c">
              <button mat-stroked-button color="primary" class="curved w-100">Manage your account</button>
            </div>
          </header> -->

          <!-- <mat-divider class="m-y-10"></mat-divider> -->

          <div class="user" *ngFor="let row of userhistory">
            <div class="dtls" matRipple (click)="switchuser(row.loginkey,row.loginname)" tabindex="0">
              <figure>
                <img src="./assets/images/users/avatar.png" alt="User">
              </figure>
              <div class="userDtls">
                <div class="username">{{row?.loginname}}</div>
                <div class="key">{{row?.loginkey}}</div>
              </div>
            </div>

            <div class="actions">
              <button matRipple class="btn btn-primary lighten" (click)="switchuser(row.loginkey,row.loginname)">Switch
                User</button>
              <button matRipple class="btn btn-primary lighten" (click)="removlogInAs(row.loginkey)">Remove</button>
            </div>
          </div>
          <!-- <mat-divider class="m-y-10"></mat-divider> -->

          <!-- <button mat-menu-item [routerLink]="['/profile']" [routerLinkActiveOptions]="{exact: true}"
            routerLinkActive="active">
            <mat-icon>person_add</mat-icon>
            <span>Add Another Account</span>
          </button>-->

          <!-- <mat-divider class="m-y-10"></mat-divider>-->
          <!-- <div class="t-c m-t-15 m-b-10">
          </div> -->

        </div>
        <!-- NEW LOGIN -->

        <!-- <button mat-menu-item>Account</button> -->
        <!-- <button mat-menu-item [routerLink]="['/profile']" [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="active">Settings</button>
        <button mat-menu-item (click)="logout()">Logout</button> -->
        <!-- <pre>{{config.currentUser | json}}</pre> -->

        <footer>
          <button mat-menu-item (click)="logout()">
            <i class="ico i i-signout" aria-label="Signout"></i>
            <span>Signout</span>
          </button>
        </footer>
      </ng-template>
    </mat-menu>
  </mat-toolbar>

  <mat-toolbar *ngIf="config.isAdmin()">
    <span class="spacer"></span>
    <button class="loginUser" mat-button [matMenuTriggerFor]="menu" color="accent">
      <span class="ib-m" *ngIf="isLoggedIn()">{{config.getUsername()}}</span>
      <div class="ib-m avatar">
        <mat-icon fontSet="i" fontIcon="i-account_circle"></mat-icon>
      </div>
      <mat-icon class="ib-m" fontSet="i" fontIcon="i-arrow_drop_down"></mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="false">
      <ng-template matMenuContent let-name="name">
        <!-- <button mat-menu-item>Profile</button>
        <button mat-menu-item>Account</button> -->
        <button mat-menu-item (click)="logout()">Logout</button>
      </ng-template>
    </mat-menu>
  </mat-toolbar>
</header>

<div class="modal modalB2B">
  <div class="mdlHeader gradBG">
    <div class="d-flex">
      <button type="button" mat-button class="close ib-m m-t--5" title="Close" matTooltipPosition="below"
        (click)="closeModalB2B($event)">
        <i fontSet="i" class="ib-m i i-3_3x">exit_from_b2b</i>
      </button>
    </div>
  </div>
  <div class="mdlContent">
    <iframe [src]="b2bUrl | safe:'resourceUrl'" frameborder="0"></iframe>
  </div>
  <div class="mdlFooter">
    <div class="t-c p-t-5">
      &copy; 2019 InsIQ. All rights reserved <a href="https://www.insiq.ai">www.insiq.ai</a>
    </div>
  </div>
</div>

<ng-template #dataDetailDialog>
  <div class="dialogWrap">
    <ul class="dataList">
      <li>
        <div class="lbl">DATA Usage</div>
        <div class="val">
          <strong [ngClass]="{
            'text-danger': getDataPercent() > 0.85,
            'text-warning': getDataPercent() > 0.80,
            'text-accent': getDataPercent() <= 0.80
          }"> {{config.getdataUsage() ? config.getdataUsage()[0].usage : dataUsagestore[0].usage}}
            {{config.getdataUsage() ? config.getdataUsage()[0].unitvalue : dataUsagestore[0].unitvalue}}
          </strong><small>/{{config.getdataUsage() ? config.getdataUsage()[0].total :
            apiUsagestore[0].total}} {{config.getdataUsage() ? config.getdataUsage()[0].unitvalue :
            dataUsagestore[0].unitvalue}}</small>
        </div>
      </li>
    </ul>

    <h6 class="title text-accent">Current Plan</h6>

    <ul class="dataList">
      <li>
        <div class="lbl">Data Quota</div>
        <div class="val">
          <strong>{{config.getdataUsage() ? config.getdataUsage()[0].currentplan :
            dataUsagestore[0].currentplan}}</strong>
          <small>{{config.getdataUsage() ? config.getdataUsage()[0].unitvalue :
            dataUsagestore[0].unitvalue}}</small>
        </div>
      </li>
      <li>
        <div class="lbl">Additional Data Purchased</div>
        <div class="val">
          <strong>{{config.getdataUsage() ? config.getdataUsage()[0].sachet : dataUsagestore[0].sachet}}</strong>
          <small>{{config.getdataUsage() ? config.getdataUsage()[0].unitvalue :
            dataUsagestore[0].unitvalue}}</small>
        </div>
      </li>
      <li>
        <div class="lbl">Total Data Quota</div>
        <div class="val">
          <strong>{{config.getdataUsage() ? config.getdataUsage()[0].total : dataUsagestore[0].total}}</strong>
          <small>{{config.getdataUsage() ? config.getdataUsage()[0].unitvalue :
            dataUsagestore[0].unitvalue}}</small>
        </div>
      </li>
      <li>
        <div class="lbl">Data Left</div>
        <div class="val">
          <strong [ngClass]="{
            'text-danger': getDataPercent() > 0.85,
            'text-warning': getDataPercent()> 0.80,
            'text-accent': getDataPercent() <= 0.80
          }">{{config.getdataUsage() ? config.getdataUsage()[0].balance : dataUsagestore[0].balance}}</strong>
          <small>{{config.getdataUsage() ? config.getdataUsage()[0].unitvalue :
            dataUsagestore[0].unitvalue}}</small>
        </div>
      </li>
      <li>
        <div class="lbl">Total Used</div>
        <div class="val">
          <strong>{{config.getdataUsage() ? config.getdataUsage()[0].usage : dataUsagestore[0].usage}}</strong>
          <small>{{config.getdataUsage() ? config.getdataUsage()[0].unitvalue :
            dataUsagestore[0].unitvalue}}</small>
        </div>
      </li>
    </ul>
    <div class="action"
      *ngIf="((currentUsr?.pin!=='USR')&& (currentUsr?.daysRemaining>0)&& (currentUsr?.planStatus === 1)&&(currentUsr?.planType!=1)&&(currentUsr?.usertype!=='APPSUMO'))||(config.currentUser?.pin!=='USR')&& (config.currentUser?.daysRemaining>0)&& (config.currentUser?.planStatus === 1)&&(config.currentUser?.planType!=1)&&(currentUsr?.usertype!=='APPSUMO')">
      <button class="btn btn-accent btn-lg w-220px" matRipple (click)="sachetsubscription('data')">
        Buy Data Sachet</button>
    </div>
  </div>
</ng-template>
<ng-template #userDetailDialog>
  <div class="dialogWrap">
    <ul class="dataList">
      <li>
        <div class="lbl">User Count</div>
        <div class="val">
          <strong>
            {{config.getUserCount() ? config.getUserCount()?.[0].totalused : dataUsagestore?.[0].totalused}}
          </strong><small>/{{config.getUserCount() ? config.getUserCount()?.[0].totaldisplay :
            apiUsagestore?.[0].totaldisplay}} </small>
        </div>
      </li>
    </ul>

    <h6 class="title text-accent">Current Plan</h6>

    <ul class="dataList">

      <li>
        <div class="lbl">Free Team Members Allowed</div>
        <div class="val">
          <strong>{{config.getUserCount() ? config.getUserCount()[0].freeuser :
            config.getUserCount[0].freeuser}}</strong>

        </div>
      </li>

      <li>
        <div class="lbl">Maximum Sachet Purchase Allowed</div>
        <div class="val">
          <strong>{{config.getUserCount() ? config.getUserCount()[0].sachetuser :
            config.getUserCount[0].sachetuser}}</strong>

        </div>
      </li>


      <li>
        <div class="lbl">Total Used(Plan+Sachet)</div>
        <div class="val">
          <strong>{{config.getUserCount() ? config.getUserCount()[0].totalused :
            config.getUserCount[0].totalused}}</strong>

        </div>
      </li>
      <li>
        <div class="lbl">Total Alloted(Plan+Sachet)</div>
        <div class="val">
          <strong>{{config.getUserCount() ? config.getUserCount()[0].totalalloted :
            config.getUserCount()[0].totalalloted}}</strong>

        </div>
      </li>

      <li>
        <div class="lbl">Sachet Purchased</div>
        <div class="val">
          <strong [ngClass]="
            'text-accent'">{{config.getUserCount() ? config.getUserCount()[0].sachetpurchased :
            config.getUserCount()[0].sachetpurchased}}</strong>

        </div>
      </li>
      <li>
        <div class="lbl">Sachet Used</div>
        <div class="val">
          <strong>{{config.getUserCount() ? config.getUserCount()[0].sachetused :
            config.getUserCount()[0].sachetused}}</strong>

        </div>
      </li>
      <li>
        <div class="lbl">Sachet Remaining</div>
        <div class="val">
          <strong>{{config.getUserCount() ? config.getUserCount()[0].sachetremaining :
            dataUsagestore[0].sachetremaining}}</strong>

        </div>
      </li>
      <!-- <li>
        <div class="lbl">Total Available(Plan+Sachet)</div>
        <div class="val">
          <strong>{{config.getUserCount() ? config.getUserCount()[0].totaldisplay :
            dataUsagestore[0].totaldisplay}}</strong>

        </div>
      </li> -->
    </ul>
    <div class="action"
      *ngIf="(((currentUsr?.pin!=='USR')&& (currentUsr?.daysRemaining>0)&&(currentUsr?.planStatus === 1)&&(currentUsr?.planType!=1)&&(currentUsr?.usertype!=='APPSUMO'))||(config.currentUser?.pin!=='USR')&& (config.currentUser?.daysRemaining>0)&&(config.currentUser?.planStatus === 1)&&(config.currentUser?.planType!=1)&&(currentUsr?.usertype!=='APPSUMO'))&&config.currentUser?.currentPlan&&config.currentUser?.refund==0">
      <button class="btn btn-accent btn-lg w-220px" matRipple (click)="sachetsubscription('user')">
        Buy User Sachet</button>
    </div>
  </div>
</ng-template>
<ng-template #apiDetailDialog>
  <div class="dialogWrap">
    <ul class="dataList">
      <li>
        <div class="lbl">API Usage</div>
        <div class="val">
          <strong [ngClass]="{
            'text-danger': getUsagePercent() > 0.85,
            'text-warning': getUsagePercent() > 0.80,
            'text-accent': getUsagePercent() <= 0.80
          }">
            {{config.getapiUsage() ? config.getapiUsage()[0].monthly.split(".")[0] :
            apiUsagestore[0].monthly.split(".")[0]}}
          </strong><small>/ {{(config.getapiUsage() ? config.getapiUsage()[0].totalalloted :
            apiUsagestore[0].totalalloted) + ' ' + usage.api.units}}</small>
        </div>
      </li>
    </ul>

    <!-- <h6 class="title text-accent">Current Plan</h6> -->

    <ul class="dataList">
      <li>
        <div class="lbl">Monthly Quota as per Plan</div>
        <div class="val">
          <strong>{{config.getapiUsage()[0].currentplan!=='null'?config.getapiUsage()[0].currentplan:0.0}}</strong>
          <small> nos</small>
        </div>
      </li>
      <li>
        <div class="lbl">Additional API Sachet Quota</div>
        <div class="val">
          <strong>{{config.getapiUsage()[0].sachet!=='null'?config.getapiUsage()[0].sachet:0.0}}</strong>
          <small> nos</small>
        </div>
      </li>
      <li>
        <div class="lbl">API Usage <small>(This Month)</small></div>
        <div class="val">
          <strong>{{config.getapiUsage()[0].monthly!=='null'?config.getapiUsage()[0].monthly.split(".")[0]:0.0}}</strong>
          <small> nos</small>
        </div>
      </li>
      <li>
        <div class="lbl">API Left</div>
        <div class="val">
          <strong [ngClass]="{
            'text-danger': getUsagePercent() > 0.85,
            'text-warning': getUsagePercent()> 0.80,
            'text-accent': getUsagePercent() <= 0.80
          }">
            {{config.getapiUsage() ? config.getapiUsage()[0].balance : apiUsagestore[0].balance}}
          </strong>
          <small>nos</small>
        </div>
      </li>
    </ul>
    <ul class="dataList">
      <li>
        <div class="lbl">API Usage <small>(This Year)</small></div>
        <div class="val">
          <strong>{{config.getapiUsage()[0].annum!=='null'?config.getapiUsage()[0].annum.split(".")[0]:0.0}}</strong>
          <small> nos</small>
        </div>
      </li>
      <li>
        <div class="lbl"> API Usage <small>(LifeTime)</small></div>
        <div class="val">
          <strong>{{config.getapiUsage()[0].total!=='null'?config.getapiUsage()[0].total.split(".")[0]:0.0}}</strong>
          <small> nos</small>
        </div>
      </li>
    </ul>
    <div class="action"
      *ngIf="(((currentUsr?.pin!=='USR')&& (currentUsr?.daysRemaining>0)&& (currentUsr?.planStatus === 1)&&(currentUsr?.planType!=1)&&(currentUsr?.usertype!=='APPSUMO'))||(config.currentUser?.pin!=='USR')&& (config.currentUser?.daysRemaining>0)&& (config.currentUser?.planStatus === 1)&&(config.currentUser?.planType!=1)&&(currentUsr?.usertype!=='APPSUMO'))&&config.currentUser?.currentPlan&&config.currentUser?.refund==0">
      <button type="button" class="btn btn-accent btn-lg w-220px" matRipple (click)="sachetsubscription('api')">
        Buy API Sachet</button>
    </div>
  </div>
</ng-template>