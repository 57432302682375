import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Validators, FormBuilder } from '@angular/forms';
import { Config, View, AppPattern } from '../../../config/global.config';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'app-feedback-view',
  templateUrl: './feedback-view.component.html',
  styleUrls: ['./feedback-view.component.scss'],
  providers: [ConfirmationService]
})
export class FeedbackViewComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public view: View, private fb: FormBuilder, public config: Config, private confirmationService: ConfirmationService) {
    this.view.initForm('admin/userfeedback', this.getNewForm());
  }

  ngAfterViewInit() {
    this.view.initPage(this.paginator, this.sort);
  }

  ngOnInit() {
  }

  onSubmit() {
    this.view.save();
  }

  getNewForm() {
    return this.fb.group({
      id: [''],
      userId: ['', Validators.required],
      rating: ['', Validators.required],
      experience: [''],
      suggestion: [''],
    });
  }
}
