<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <div class="leftSection loginWrap">
      <div class="d-flex align-items-center h-100 t-c">
        <div class="form">
          <figure>
            <img src="./assets/images/icon-insiq.png" alt="">
          </figure>
          <!-- <figure>
            <img src="./assets/images/insiq.png" alt="">
          </figure> -->
          <h2 class="sr-only sr-only-focusable">Login</h2>

          <a [routerLink]="['/login']" [queryParams]="{url: view.returnUrl}" class="curved btn-link">Go to Login
            Page</a>
        </div>
      </div>
    </div>
    <div class="rightSection mainWrap" tabindex="0">
      <div class="describe">
        <picture>
          <source media="(max-width: 280px)" srcset="./assets/images/screenshot.png">
          <img src="./assets/images/screenshot.png" alt="screenshot">
        </picture>
        <h1 class="h1 f-bold">
          InsIQ
        </h1>
        <p>
          AI-powered Product Development for everyone. Everything you need to transform your idea into a successful
          product.
        </p>

        <div class="bar-T p-y-10 m-b-30">
          <a href="https://www.insiq/about/" target="_blank" class="p-r-20">About Us</a>
          <a [routerLink]="['/contact']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Contact Us</a>
          <a [routerLink]="['/terms-and-conditions']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Terms of
            Service</a>
          <a href="https://www.insiq.ai/product/#screenfq-Accordian" target="_blank" class="p-r-20">Help</a>
          <a href="https://www.insiq.ai/pricing/#faq_prcng" target="_blank" class="p-r-20">FAQ</a>
        </div>

        <div class="copyright">
          <span>&copy; {{config.currentYear}} InsIQ. All rights reserved</span>
          <br>
          <a href="https://www.insiq.ai" target="_blank">www.insiq.ai</a>
        </div>
      </div>
    </div>
  </div>
</div>