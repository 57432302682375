import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TagCloudComponent } from './tag-cloud.component';
import { SnackbarComponent } from './snackbar/snackbar.component';

@NgModule({
  declarations: [
    TagCloudComponent,
    SnackbarComponent
  ],
  imports: [
    BrowserModule,
    MatSnackBarModule
  ],
  entryComponents: [
    SnackbarComponent
  ],
  exports: [TagCloudComponent]
})
export class TagCloudModule { }
