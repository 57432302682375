import { Component, OnInit, OnDestroy } from '@angular/core';
import { MyErrorStateMatcher } from './../../_directives/validator.directive';
import {
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  View,
  Config,
  AppPattern
} from '../../config';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  captcha = false;
  matcher = new MyErrorStateMatcher();
  captchaKey = environment.captchaKey;

  constructor(
    public view: View,
    public config: Config,
    private fb: FormBuilder,
    public dialog: MatDialog,

  ) {
    this.captcha = false;
    this.view.initForm('sendemail/contactus', this.getNewForm());
    // this.view.state = 'edit';
  }

  ngOnInit() {
    document.querySelector('body').classList.add('xsEnabled');
    document.querySelector('html').classList.add('xsEnabled');
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('xsEnabled');
    document.querySelector('html').classList.remove('xsEnabled');
  }

  onSubmit() {
    this.view.saveAndAdd('sendemail/contactus', 'Thanks for contacting us! We will be in touch with you shortly.');
  }

  getNewForm() {
    return this.fb.group(
      {
        name: ['', Validators.required],
        email: ['', Validators.compose([
          Validators.required,
          Validators.pattern(AppPattern.EMAIL)
        ])],
        phone: [''],
        mess: ['', [Validators.required]]
      });
  }
  toggleDev2MOverview() {
    //  this.config.dev2MOverview = !this.config.dev2MOverview;
    window.open("https://www.insiq.ai/", "_blank");

  }
  openTnC(typeVal) {
    // const dialogRef =
    sessionStorage.setItem('tandclink', typeVal);

    sessionStorage.setItem('tandc', 'REG');
    this.dialog.open(TermsDialogComponent, {
      width: '98vw',
      height: '98vh',
      autoFocus: false,
      panelClass: 'TnC',
      disableClose: true
    });
  }
  error(name) {
    return this.view.error(name);
  }
  showResponse(event) {
    this.captcha = true;
  }
}
