<div class="container-fluid d-flex h-100" [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <app-admin-menu *ngIf="config.isAdmin()"></app-admin-menu>

      <!-- <div class="footer t-c">
        &copy; {{config.currentYear}} InsIQ. All rights reserved
        <a href="https://www.devtom.ai" target="_blank">www.devtom.ai</a>
      </div> -->
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex">
        <h1 class="h1 smartHead">
          <i class="i i-user"></i>
          <span>
            <small>All</small>
            <b>Subscribers</b>
          </span>
        </h1>
        <div class="spacer"></div>
        <span *ngIf="roleCode=='PADM'">
          <button mat-stroked-button class="curved btn-sm m-t-15" (click)="view.newform()">New Subscriber</button>
        </span>
      </div>
      <div class="d-flex">
        <!-- trans-light -->
        <div class="spacer"></div>
        <mat-form-field>
          <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
        </mat-form-field>
      </div>
      <div class="mat-elevation-z8 rel">
        <!-- mat-elevation-z8 -->
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            No Records Found!!
          </div>
        </div> -->
        <!-- {{getlist(view.list)}} -->
        <table mat-table [dataSource]="view.list" matSort class="w-100 InsIQTable">
          <!-- photo Column -->
          <ng-container matColumnDef="photo">
            <th class="w-100px" mat-header-cell *matHeaderCellDef [disabled]="true"></th>
            <td mat-cell *matCellDef="let row">
              <figure>
                <img src="assets/images/users/default.png" alt="default">
              </figure>
            </td>
          </ng-container>
          <!-- name Column -->
          <!-- <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
            <td mat-cell *matCellDef="let row">
              <span class="ib-m">{{row.name}}</span>
              <i *ngIf="row.new" class="ib-m i i-1_3x i-fiber_new"></i>
            </td>
          </ng-container> -->

          <!--  <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
          </ng-container>-->
          <!-- name Column -->
          <ng-container matColumnDef="usrtype">
            <th mat-header-cell *matHeaderCellDef> Usertype </th>
            <td mat-cell *matCellDef="let row">
              <figure>
                <img src="{{getImg(row.usertype)}}" title="{{getLabel(row.usertype)}}" alt="user">
              </figure>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
          </ng-container>

          <!-- email Column -->
          <!-- <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
            <td mat-cell *matCellDef="let row">{{row.email}}</td>
          </ng-container> -->
          <ng-container matColumnDef="loginKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Username</th>
            <td mat-cell *matCellDef="let row" title="Email Id: {{row.email}}" matTooltipPosition="below">
              {{row.loginKey}}</td>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
            <td mat-cell *matCellDef="let row">{{row.createdAt}}</td>
          </ng-container>
          <ng-container matColumnDef="lastLogin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Login</th>
            <td mat-cell *matCellDef="let row">{{getLastLogin(row.lastLogin)}}</td>
          </ng-container>
          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>Account Status</th>

            <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
              <div class="label f-bold" [ngClass]="{
                'text-success': pu.active === 1,
                'text-danger': pu.active === 0
              }">
                <div>{{pu.active===1 ? 'Active' : 'Inactive'}}</div>
              </div>

            </td>
          </ng-container>
          <ng-container matColumnDef="substatus">
            <th mat-header-cell *matHeaderCellDef>Subscription Valid Till</th>

            <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
              <div *ngIf="getTheme(pu.id)" class="label f-bold" [ngClass]="'text-danger'">
                <div>{{getlist(pu.id)| date: 'dd/MM/yyyy'}}</div>
              </div>
              <div *ngIf="!getTheme(pu.id)" class="label f-bold" [ngClass]="'text-success'">
                <div>{{getlist(pu.id)| date: 'dd/MM/yyyy'}}</div>
              </div>

            </td>
          </ng-container>
          <!-- email Column -->

          <!-- Action Column -->
          <ng-container matColumnDef="action" *ngIf="roleCode==='PADM'">
            <th class="w-160px" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row; let i=index;">
              <button mat-icon-button (click)="edit(row); selected = i;" title="Edit" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
              </button>
              <p-confirmDialog key="deleteusr" appendTo="body" [style]="{width: '50vw'}"></p-confirmDialog>
              <button mat-icon-button (click)="openDialog(row.id)" title="Delete" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-trash" aria-label="delete" color="warn"></mat-icon>
              </button>
              <button mat-icon-button (click)="edit(row)" title="AI Id Call" matTooltipPosition="below"
                *ngIf="!row.aiid">
                <mat-icon fontSet="i" fontIcon="i-flag" aria-label="edit" color="warn"></mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="action" *ngIf="roleCode==='EADM'||roleCode==='STAF'">
            <th class="w-160px" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row; let i=index;">
              <button mat-icon-button (click)="edit(row); selected = i;" title="View Details"
                matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-visibility" aria-label="edit"></mat-icon>
              </button>

            </td>
          </ng-container>
          <div>
            <tr mat-header-row
              *matHeaderRowDef="['usrtype','name','loginKey','createdAt','lastLogin','active','substatus','action']">
            </tr>
            <tr mat-row
              *matRowDef="let row; columns: ['usrtype','name','loginKey','createdAt','lastLogin','active','substatus','action']">
            </tr>
          </div>

          <!-- <div *ngIf="roleCode==='EADM'">
            <tr mat-header-row
              *matHeaderRowDef="['usrtype','name','loginKey','createdAt','lastLogin','active','substatus']"></tr>
            <tr mat-row
              *matRowDef="let row; columns: ['usrtype','name','loginKey','createdAt','lastLogin','active','substatus']">
            </tr>
          </div> -->
        </table>
        <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid d-flex h-100" [ngClass]="view.isForm() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <button mat-icon-button class="close" color="warn" (click)="view.cancel()" title="Close" *ngIf="view.isEdit()"
        matTooltipPosition="below">
        <mat-icon fontSet="i" class="i-close"></mat-icon>
      </button>
      <ul class="quickyMenu" *ngIf="view.isEdit()">
        <li *ngFor="let user of view.list; let i = index">
          <button matRipple class="btn btn-md btn-primary lighten t-c fw t-normal" [ngClass]="{active: selected === i}"
            (click)="edit(user);selected = i">
            <!-- <figure class="avatar circle">
              <img src="assets/images/users/default.png" alt="{{view.value('name')}}" title="{{view.value('name')}}">
            </figure> -->
            {{user.name}}
            <!-- <mat-icon fontSet="i">edit</mat-icon> -->
          </button>
        </li>
      </ul>
    </div>

    <div class="rightSection" tabindex="0">

      <div class="d-flex m-b-40">
        <form [formGroup]="view.form">
          <h1 class="h1 smartHead">
            <i class="i i-user"></i>
            <span>
              <input type="hidden" formControlName="name">
              <small>{{view.value('name')}}</small>
              <small *ngIf="view.isNew()">New</small>

              <b *ngIf="view.isEdit()">Details</b>
              <b *ngIf="view.isNew()">Subscriber</b>

            </span>
          </h1>
          <div class="spacer"></div>
        </form>
        <!-- <span>
            <button *ngIf="!view.isNew()" mat-stroked-button class="curved btn-sm m-t-15"
              (click)="view.newform()">New</button>
          </span> -->
      </div>

      <mat-card class="w-100">
        <mat-tab-group mat-align-tabs="center" color="accent" (selectedTabChange)="tabClick($event)"
          [selectedIndex]="pageId">
          <mat-tab label="User Profile" *ngIf="roleCode==='PADM'">
            <form [formGroup]="view.form" novalidate (ngSubmit)="onSubmit()">
              <div class="row p-t-15">
                <div class="col-xl-16 col-lg-16 col-sm-15">
                  <mat-form-field class="w-100 m-b-10">
                    <input type="text" matInput placeholder="Name" formControlName="name">
                    <mat-error *ngIf="view.invalid('name')">{{view.error('name')}}</mat-error>
                  </mat-form-field>

                  <!-- <mat-form-field class="w-100 m-b-10" *ngIf="view.isNew()">
                  <input type="password" matInput placeholder="Password" formControlName="inputPwd"
                    [readonly]="view.isEdit()">
                  <mat-hint *ngIf="view.isEdit()"></mat-hint>
                  <mat-error *ngIf="view.invalid('inputPwd')">{{view.error('inputPwd')}}</mat-error>
                </mat-form-field> -->
                  <mat-form-field class="w-100 m-b-10">
                    <input type="text" matInput placeholder="Email" formControlName="email">
                    <mat-error *ngIf="view.invalid('email')">{{view.error('email')}}</mat-error>
                  </mat-form-field>

                  <div class="row">

                    <label class="bigLabel m-l-20">Status</label>


                    <div class="m-t-5 m-b-20">
                      <div class="radioToggleButton m-t-15 m-b-30">
                        <label class="custom" [ngClass]="{
                          checked: (view.form.value.active==='1'||view.form.value.active===1)
                        }">
                          <input type="radio" name="active" formControlName="active" [value]='1' checked>
                          <i class="ico i i-idea"></i>
                          <div class="label">
                            <div>Active</div>
                            <!-- <div></div> -->
                          </div>
                        </label>
                        <label class="custom" [ngClass]="{
                          checked: (view.form.value.active==='0'||view.form.value.active===0)
                        }">
                          <input type="radio" name="active" formControlName="active" [value]='0'>
                          <i class="ico i i-payment"></i>
                          <div class="label">
                            <div>Inactive</div>
                            <!-- <div></div> -->
                          </div>
                        </label>
                      </div>

                      <mat-form-field class="w-100 m-b-10"
                        *ngIf="view.isEdit&& (view.form.value.active==='0'||view.form.value.active===0)">
                        <input type="text" matInput placeholder="Remarks (if any)" formControlName="remarks">
                        <mat-error *ngIf="view.invalid('remarks')">{{view.error('remarks')}}</mat-error>
                      </mat-form-field>


                    </div>
                  </div>
                  <!-- <mat-form-field class="w-100 m-b-10">
                  <mat-select placeholder="Choose role" formControlName="role" [errorStateMatcher]="matcher">
                    <mat-option value="0">Normal User</mat-option>
                    <mat-option value="1">Administrator</mat-option>
                  </mat-select>
                  <mat-error *ngIf="view.required('role')">You must make a selection</mat-error>
                </mat-form-field> -->

                  <!-- <div class="m-b-20">
                  <mat-checkbox formControlName="isbtob" (change)="b2bChange($event)">Allow InsIQ Local Client
                    <mat-error *ngIf="view.invalid('isbtob')">{{view.error('isbtob')}}</mat-error>
                  </mat-checkbox>
                </div> -->
                  <div class="m-b-20" *ngIf="!view.isNew()">
                    <button type="button" (click)="aiCall()" mat-raised-button color="primary"
                      *ngIf="!view.value('aiid')" class="curved m-b-40 m-x-5">Request UIID</button>

                  </div>
                  <div class="t-c">
                    <button type="button" (click)="resetPwd()" mat-raised-button color="primary"
                      class="curved m-b-40 m-x-5">Reset Password</button>
                    <button type="button" (click)="view.cancel()" mat-raised-button color="warn"
                      class="curved m-b-40 m-x-5">Cancel</button>
                    <button type="submit" [disabled]="view.invalid()" *ngIf="view.isNew()" mat-raised-button
                      color="primary" class="curved m-x-5">Create</button>
                    <button type="submit" [disabled]="view.invalid()" *ngIf="view.isEdit()" mat-raised-button
                      color="primary" class="curved m-x-5">Update</button>
                  </div>
                </div>

              </div>
            </form>
          </mat-tab>
          <mat-tab label="Subscription">

            <div class="row p-t-15">
              <div class="col-xl-8 col-lg-8 col-sm-9" *ngIf="currentplan&&view.isEdit()">
                <!-- <div class="total">Active Plan</div> -->

                <mat-card class="plan mat-elevation-z8">
                  <mat-card-header>
                    <mat-card-title>
                      {{this.currentplan.planId.plancode}}
                      <!-- <span>(monthly)</span>-->
                    </mat-card-title>
                  </mat-card-header>
                  <mat-card-content class="p-b-0">
                    <div class="billType">
                      Billed <span class="days">{{getPayMode(this.currentplan.pricingId.paymode)}}</span>
                    </div>


                    <div class="validTill">
                      Subscription Validity:
                      <span class="date"></span>
                    </div>
                    <div class="validity">
                      From
                      <span class="days">{{this.currentplan.activeFrom | date: 'dd/MM/yyyy'}}</span>
                      to
                      <span class="days">{{this.currentplan.activeTill | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <ul class="dataList m-t-30" *ngIf="this.currentplan.userId.country">
                      <li>
                        <div class="lbl">
                          Country: {{this.currentplan.userId.country}}
                        </div>
                      </li>
                      <li>

                        <div class="lbl">
                          Timezone: {{this.currentplan.userId.timezone}}
                        </div>
                      </li>
                    </ul>

                    <div class="message _warning" *ngIf="this.currentplan.status===0">
                      <i class="i i-warning"></i>
                      <span>Plan Inactive</span>
                    </div>
                    <div class="message _success" *ngIf="this.currentplan.status===1">
                      <i class="i i-check_circle"></i>
                      <span>Plan Active</span>
                    </div>
                    <!-- <h1 class="smartTitle h4 text-accent">
                        <i class="i i-data_usage ib-m"></i>
                        <span class="ib-m m-l-5">
                          <small class="ib-m">API Alloted </small>
                          <div class="val">
                            <strong>{{apiusage[0]?.currentplan!=='null'?apiusage[0]?.currentplan:0.0}}</strong>
                            <small> nos</small>
                          </div>
                        </span>
                      </h1> -->


                    <h6 class="title text-accent">Team Members Allotted</h6>

                    <ul class="dataList">
                      <li>
                        <div class="lbl">Team Member Quota Used</div>
                        <div class="val">
                          <strong>
                            {{usersubscriberlist.length}}
                          </strong><small>/ {{(this.currentplan.userLimit)}}</small>
                        </div>
                      </li>
                    </ul>

                    <!-- <div class="t-c m-t-30"></div> -->
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col-xl-8 col-lg-8 col-sm-9">
                <mat-card class="mat-elevation-z8">
                  <mat-card-header>
                    <mat-card-title>API Usage Overview</mat-card-title>
                  </mat-card-header>
                  <mat-card-content class="p-b-0">
                    <div class="dialogWrap">
                      <ul class="dataList">
                        <li>
                          <div class="lbl">API Usage</div>
                          <div class="val">
                            <strong [ngClass]="{
                                'text-danger': getUsagePercent() > 0.85,
                                'text-warning': getUsagePercent() > 0.80,
                                'text-accent': getUsagePercent() <= 0.80
                              }">
                              {{apiusage[0]?.monthly.split(".")[0]}}
                            </strong><small>/ {{(apiusage[0]?.totalalloted)}}</small>
                          </div>
                        </li>
                      </ul>

                      <!-- <h6 class="title text-accent">Current Plan</h6> -->

                      <ul class="dataList">
                        <li>
                          <div class="lbl">Monthly Quota as per Plan</div>
                          <div class="val">
                            <strong>{{apiusage[0]?.currentplan!=='null'?apiusage[0]?.currentplan:0.0}}</strong>
                            <small> nos</small>
                          </div>
                        </li>
                        <li>
                          <div class="lbl">Additional API Sachet Quota</div>
                          <div class="val">
                            <strong>{{apiusage[0]?.sachet!=='null'?apiusage[0]?.sachet:0.0}}</strong>
                            <small> nos</small>
                          </div>
                        </li>
                        <li>
                          <div class="lbl">API Usage <small>(This Month)</small></div>
                          <div class="val">
                            <strong>{{apiusage[0]?.monthly!=='null'?apiusage[0]?.monthly.split(".")[0]:0.0}}</strong>
                            <small> nos</small>
                          </div>
                        </li>
                        <li>
                          <div class="lbl">API Left</div>
                          <div class="val">
                            <strong [ngClass]="{
                                'text-danger': getUsagePercent() > 0.85,
                                'text-warning': getUsagePercent()> 0.80,
                                'text-accent': getUsagePercent() <= 0.80
                              }">
                              {{apiusage[0]?.balance}}
                            </strong>
                            <small>nos</small>
                          </div>
                        </li>
                      </ul>
                      <ul class="dataList">
                        <li>
                          <div class="lbl">API Usage <small>(This Year)</small></div>
                          <div class="val">
                            <strong>{{apiusage[0]?.annum!=='null'?apiusage[0]?.annum.split(".")[0]:0.0}}</strong>
                            <small> nos</small>
                          </div>
                        </li>
                        <li>
                          <div class="lbl"> API Usage <small>(LifeTime)</small></div>
                          <div class="val">
                            <strong>{{apiusage[0]?.total!=='null'?apiusage[0]?.total.split(".")[0]:0.0}}</strong>
                            <small> nos</small>
                          </div>
                        </li>
                      </ul>

                    </div>

                    <!-- <div class="t-c m-t-30"></div> -->
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col-xl-8 col-lg-8 col-sm-9">
                <mat-card class="mat-elevation-z8">
                  <mat-card-header>
                    <mat-card-title>Data Consumption Details</mat-card-title>
                  </mat-card-header>
                  <mat-card-content class="p-b-0">
                    <div class="dialogWrap">
                      <ul class="dataList">
                        <li>
                          <div class="lbl">DATA Usage</div>
                          <div class="val">
                            <strong [ngClass]="{
                          'text-danger': getDataPercent() > 0.85,
                          'text-warning': getDataPercent() > 0.80,
                          'text-accent': getDataPercent() <= 0.80
                        }"> {{datausage[0]?.usage}}
                              {{datausage[0]?.unitvalue}}
                            </strong><small>/{{datausage[0]?.total}} {{datausage[0]?.unitvalue}}</small>
                          </div>
                        </li>
                      </ul>

                      <h6 class="title text-accent">Current Plan</h6>

                      <ul class="dataList">
                        <li>
                          <div class="lbl">Data Quota</div>
                          <div class="val">
                            <strong>{{datausage[0]?.currentplan}}</strong>
                            <small>{{datausage[0]?.unitvalue}}</small>
                          </div>
                        </li>
                        <li>
                          <div class="lbl">Additional Data Purchased</div>
                          <div class="val">
                            <strong>{{datausage[0]?.sachet}}</strong>
                            <small>{{datausage[0]?.unitvalue}}</small>
                          </div>
                        </li>
                        <li>
                          <div class="lbl">Total Data Quota</div>
                          <div class="val">
                            <strong>{{datausage[0]?.total}}</strong>
                            <small>{{datausage[0]?.unitvalue}}</small>
                          </div>
                        </li>
                        <li>
                          <div class="lbl">Data Left</div>
                          <div class="val">
                            <strong [ngClass]="{
                          'text-danger': getDataPercent() > 0.85,
                          'text-warning': getDataPercent()> 0.80,
                          'text-accent': getDataPercent() <= 0.80
                        }">{{datausage[0]?.balance}}</strong>
                            <small>{{datausage[0]?.unitvalue}}</small>
                          </div>
                        </li>
                        <li>
                          <div class="lbl">Total Used</div>
                          <div class="val">
                            <strong>{{datausage[0]?.usage}}</strong>
                            <small>{{datausage[0]?.unitvalue}}</small>
                          </div>
                        </li>
                      </ul>

                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Payment Transactions" *ngIf="roleCode==='PADM'||roleCode==='ADMN'">
            <div class="row p-t-15">
              <div class="col-24" *ngIf="view.isEdit()">
                <div class="m-y-30">
                  <div class="total">Payment Transactions</div>
                  <div class="mat-elevation-z8 rel" *ngIf="historylist">
                    <div class="d-flex">
                      <div class="spacer"></div>
                      <mat-form-field>
                        <input matInput (keyup)="applyInvoiceFilter($event)" placeholder="Search Columns" #input />
                      </mat-form-field>
                    </div>
                    <table mat-table [dataSource]="historylist" matSort class="w-100 InsIQTable">
                      <ng-container matColumnDef="invoicenumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
                        <td mat-cell *matCellDef="let row">{{row.invoice.toUpperCase()}}</td>
                      </ng-container>
                      <ng-container matColumnDef="paydate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date </th>
                        <td mat-cell *matCellDef="let row">{{row.subscriptionlogId.activeFrom}}</td>
                      </ng-container>

                      <ng-container matColumnDef="planname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Plan Name</th>
                        <td mat-cell *matCellDef="let row" class="t-c">{{row.planId.plancode}}</td>
                      </ng-container>
                      <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                        <td mat-cell *matCellDef="let row">USD {{row.amountPaid}}</td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                        <td mat-cell *matCellDef="let row" class="text-success">{{row.payStatus}}</td>
                      </ng-container>

                      <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Transaction Type</th>
                        <td mat-cell *matCellDef="let row" class="t-c">{{row.transactionType}}</td>
                      </ng-container>
                      <ng-container matColumnDef="typeid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Transaction Id</th>
                        <td mat-cell *matCellDef="let row" class="t-c">{{row.transactionId.toUpperCase()}}</td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="planstat">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                      <td mat-cell *matCellDef="let row" class="t-c">
                        <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                        </mat-slide-toggle>
                        {{row.status}} 
                      </td>
                    </ng-container> -->
                      <ng-container matColumnDef="action">
                        <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let row">
                          <!-- <button mat-icon-button (click)="invoiceDownload(row)" title="Edit" matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                        </button> -->
                          <button mat-raised-button (click)="invoiceDownload(row,1)" color="accent"
                            class="curved m-r-10"><span class="ib-m p-l-5 t-normal">Invoice</span>
                            <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                          </button>
                          <!-- <button mat-icon-button (click)="view.deleteList(row.id)" title="Delete" matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="delete" color="warn"></mat-icon>
                        </button> -->
                        </td>
                      </ng-container>

                      <tr mat-header-row
                        *matHeaderRowDef="['invoicenumber','paydate','planname','amount','status','type','typeid','action']">
                      </tr>
                      <tr mat-row
                        *matRowDef="let row; columns: ['invoicenumber','paydate','planname','amount','status','type','typeid','action']">
                      </tr>
                    </table>
                    <mat-paginator #paginator2 [pageSizeOptions]="[4, 6, 8]" showFirstLastButtons></mat-paginator>

                    <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions"></mat-paginator> -->
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Transaction History" *ngIf="roleCode==='PADM'||roleCode==='ADMN'">
            <div class="row p-t-15">
              <div class="col-24" *ngIf="view.isEdit()">
                <div class="m-y-30">
                  <div class="total">Transaction History</div>
                  <div class="mat-elevation-z8 rel" *ngIf="transactionlog">
                    <div class="d-flex">
                      <div class="spacer"></div>
                      <mat-form-field>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search Columns" #input />
                      </mat-form-field>
                    </div>
                    <table mat-table [dataSource]="transactionlog" matSort class="w-100 InsIQTable">
                      <ng-container matColumnDef="invoicenumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Id</th>
                        <td mat-cell *matCellDef="let row">{{row.transactionId}}</td>
                      </ng-container>
                      <ng-container matColumnDef="paytype">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                        <td mat-cell *matCellDef="let row">{{row.transactionType}}</td>
                      </ng-container>

                      <ng-container matColumnDef="paydate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date</th>
                        <td mat-cell *matCellDef="let row">{{row.createdAt}}</td>
                      </ng-container>

                      <ng-container matColumnDef="planname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Payment Status</th>
                        <td mat-cell *matCellDef="let row" class="t-c" [ngClass]="{
                            'text-success': row.payStatus.trim()=== 'AUTHORIZED', 'text-danger' :
                            row.payStatus.trim()!='AUTHORIZED' }">{{row.payStatus}}</td>

                      </ng-container>
                      <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Price</th>
                        <td mat-cell *matCellDef="let row">USD {{row.planPrice}}</td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax Amount</th>
                        <td mat-cell *matCellDef="let row">USD {{row.taxAmount}}</td>
                      </ng-container>

                      <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Total Paid</th>
                        <td mat-cell *matCellDef="let row" class="t-c">USD {{row.amountPaid}}</td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="planstat">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                      <td mat-cell *matCellDef="let row" class="t-c">
                        <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                        </mat-slide-toggle>
                        {{row.status}} 
                      </td>
                    </ng-container> -->
                      <ng-container matColumnDef="action">
                        <th class="w-120px" mat-header-cell *matHeaderCellDef>Action/Updates</th>
                        <td mat-cell *matCellDef="let row">
                          <!-- <button mat-icon-button (click)="invoiceDownload(row)" title="Edit" matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                        </button> -->
                          <!-- <button mat-icon-button (click)="transactionDetail(row)" title="Manual Update Details"
                            *ngIf="row.adminup==1" matTooltipPosition="below">
                          </button> -->
                          <div *ngIf="row.adminup==1">
                            <mat-icon fontSet="i" fontIcon="i-speaker_notes" aria-label="speaker_notes"
                              class="text-danger" title="Manually Updated"></mat-icon>
                            <span class="text-danger">{{row.remarks}}</span>
                          </div>

                          <button mat-icon-button (click)="updateTransaction(row)" title="Edit Transaction"
                            *ngIf="row.payStatus.trim()!='AUTHORIZED'&&row.adminup==0" matTooltipPosition="below">
                            <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
                          </button>
                          <!-- <button mat-icon-button (click)="view.deleteList(row.id)" title="Delete" matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="delete" color="warn"></mat-icon>
                        </button> -->
                        </td>
                      </ng-container>

                      <tr mat-header-row
                        *matHeaderRowDef="['invoicenumber','paytype','paydate','planname','amount','status','type','action']">
                      </tr>
                      <tr mat-row
                        *matRowDef="let row; columns: ['invoicenumber','paytype','paydate','planname','amount','status','type','action']">
                      </tr>
                    </table>
                    <mat-paginator #paginator1 [pageSizeOptions]="[4, 6, 8]" showFirstLastButtons></mat-paginator>

                    <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions"></mat-paginator> -->
                  </div>
                </div>
                <div class="m-y-30">
                  <div class="total">Refund Transactions</div>
                  <div class="mat-elevation-z8 rel" *ngIf="refundhistorylist">
                    <div class="d-flex">
                      <div class="spacer"></div>
                      <mat-form-field>
                        <input matInput (keyup)="applyFilter1($event)" placeholder="Search Columns" #input />
                      </mat-form-field>
                    </div>
                    <table mat-table [dataSource]="refundhistorylist" matSort class="w-100 InsIQTable">
                      <ng-container matColumnDef="invoicenumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Id</th>
                        <td mat-cell *matCellDef="let row">{{row.paymentintentid}}</td>
                      </ng-container>
                      <ng-container matColumnDef="paytype">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                        <td mat-cell *matCellDef="let row">Refund</td>
                      </ng-container>

                      <ng-container matColumnDef="paydate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date</th>
                        <td mat-cell *matCellDef="let row">{{row.createdAt}}</td>
                      </ng-container>

                      <ng-container matColumnDef="planname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Payment Status</th>
                        <td mat-cell *matCellDef="let row" class="t-c" [ngClass]="{
                            'text-success': row.description.trim()=== 'succeeded', 'text-danger' :
                            row.description.trim()!='succeeded' }">{{row.description}}</td>

                      </ng-container>


                      <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Amount</th>
                        <td mat-cell *matCellDef="let row" class="t-c">USD {{extractNumericValue(row.amount)}}</td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="planstat">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                      <td mat-cell *matCellDef="let row" class="t-c">
                        <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                        </mat-slide-toggle>
                        {{row.status}} 
                      </td>
                    </ng-container> -->


                      <tr mat-header-row *matHeaderRowDef="['invoicenumber','paytype','paydate','planname','amount']">
                      </tr>
                      <tr mat-row
                        *matRowDef="let row; columns: ['invoicenumber','paytype','paydate','planname','amount']">
                      </tr>
                    </table>
                    <mat-paginator #paginator3 [pageSizeOptions]="[4, 6, 8]" showFirstLastButtons></mat-paginator>

                    <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions"></mat-paginator> -->
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Sachet Purchase Invoice" *ngIf="roleCode==='PADM'||roleCode==='ADMN'">
            <div class="row p-t-15">
              <div class="col-24" *ngIf="view.isEdit()">
                <div class="m-y-30">

                  <div class="total m-t-30 align-items-center">Sachet Purchase Invoice</div>
                  <div class="mat-elevation-z8 rel">
                    <div class="d-flex">
                      <div class="spacer"></div>
                      <mat-form-field>
                        <input matInput (keyup)="applySachetFilter($event)" placeholder="Search Columns" #input />
                      </mat-form-field>
                    </div>
                    <table mat-table [dataSource]="sachethistorylist" matSort class="w-100 InsIQTable">
                      <ng-container matColumnDef="invoicenumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
                        <td mat-cell *matCellDef="let row">{{row.invoice}}</td>
                      </ng-container>
                      <ng-container matColumnDef="paydate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date </th>
                        <td mat-cell *matCellDef="let row">{{row.sachetSubscriptionId.activeFrom}}</td>
                      </ng-container>

                      <ng-container matColumnDef="planname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sachet Name</th>
                        <td mat-cell *matCellDef="let row">{{row.sachetId.sachetName}}</td>
                      </ng-container>
                      <ng-container matColumnDef="plantype">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sachet Type</th>
                        <td mat-cell *matCellDef="let row">{{row.sachetId.sachetType}}</td>
                      </ng-container>
                      <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                        <td mat-cell *matCellDef="let row">USD {{row.amountPaid}}</td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                        <td mat-cell *matCellDef="let row" class="text-success">{{row.payStatus}}</td>
                      </ng-container>

                      <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Type</th>
                        <td mat-cell *matCellDef="let row">{{row.transactionType}}</td>
                      </ng-container>
                      <ng-container matColumnDef="typeId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Id</th>
                        <td mat-cell *matCellDef="let row">{{row.transactionId.toUpperCase()}}</td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="planstat">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
                      <td mat-cell *matCellDef="let row" class="t-c">
                        <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
                        </mat-slide-toggle>
                        {{row.status}} 
                      </td>
                    </ng-container> -->
                      <ng-container matColumnDef="action">
                        <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let row">
                          <!-- <button mat-icon-button (click)="invoiceDownload(row)" title="Edit" matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                        </button>-->

                          <button mat-raised-button (click)="invoiceDownload(row,2)" color="accent"
                            class="curved m-r-10"><span class="ib-m p-l-5 t-normal">Invoice</span>
                            <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="edit"></mat-icon>
                          </button>
                          <!-- <button mat-icon-button (click)="view.deleteList(row.id)" title="Delete" matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-get_app" aria-label="delete" color="warn"></mat-icon>
                        </button> -->
                        </td>
                      </ng-container>

                      <tr mat-header-row
                        *matHeaderRowDef="['invoicenumber','paydate','planname','plantype','amount','status','type','typeId','action']">
                      </tr>
                      <tr mat-row
                        *matRowDef="let row; columns: ['invoicenumber','paydate','planname','plantype','amount','status','type','typeId','action']">
                      </tr>
                    </table>
                    <mat-paginator #paginatorPageSize [pageSizeOptions]="[4, 6, 8]" showFirstLastButtons>
                    </mat-paginator>

                    <!-- <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
                  </mat-paginator> -->
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Team Members" *ngIf="roleCode==='PADM'">
            <div class="row p-t-15">
              <div class="col-24" *ngIf="view.isEdit()">
                <div class="m-y-30">
                  <div class="m-y-30" *ngIf="usersubscriberlist">
                    <div class="total">Team Members</div>
                    <ul class="dataList">
                      <li>
                        <div class="lbl">Team Member Quota Used</div>
                        <div class="val">
                          <strong>
                            {{usersubscriberlist?.length}}
                          </strong><small *ngIf="this?.currentplan?.userLimit">/
                            {{(this.currentplan?.userLimit)}}</small>
                        </div>
                      </li>
                    </ul>
                    <table mat-table [dataSource]="usersubscriberlist" matSort class="w-100 InsIQTable">
                      <!-- photo Column -->
                      <ng-container matColumnDef="photo">
                        <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
                        <td mat-cell *matCellDef="let row">
                          <figure>
                            <img src="assets/images/users/default.png" alt="default">
                          </figure>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let row">{{row.name}}</td>
                      </ng-container>

                      <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                        <td mat-cell *matCellDef="let row" title="Email: {{row.email}}" matTooltipPosition="below">
                          {{row.email}}</td>
                      </ng-container>


                      <ng-container matColumnDef="loginKey">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Login Key</th>
                        <td mat-cell *matCellDef="let row" title="Login Key: {{row.loginKey}}"
                          matTooltipPosition="below">
                          {{row.loginKey}}</td>
                      </ng-container>

                      <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
                        <td mat-cell *matCellDef="let row">{{row.createdAt}}</td>
                      </ng-container>

                      <ng-container matColumnDef="active">
                        <th class="w-120px" mat-header-cell *matHeaderCellDef>Status</th>

                        <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                          <div class="label f-bold" [ngClass]="{
                              'text-success': pu.active === 1,
                              'text-danger': pu.active === 0
                            }">
                            <div>{{pu.active===1 ? 'Active' : 'Inactive'}}</div>
                          </div>

                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="['name','email','loginKey','createdAt','active']"></tr>
                      <tr mat-row *matRowDef="let pu; columns: ['name','email','loginKey','createdAt','active']"></tr>
                    </table>
                    <!-- <mat-paginator [length]="userView.total" [pageSize]="userView.pageSize" [pageSizeOptions]="userView.pageSizeOptions"></mat-paginator>-->
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <div class="row p-t-15">


          <div class="col-24" *ngIf="view.isEdit()">
            <div class="m-y-30">


              <!-- <mat-paginator [length]="userView.total" [pageSize]="userView.pageSize" [pageSizeOptions]="userView.pageSizeOptions"></mat-paginator>-->
            </div>

          </div>
        </div>
      </mat-card>

    </div>
  </div>
</div>

<ng-template #dialogUpload>
  <div class="p-y-20">

    <div>
      <!-- <div class="col-xl-18 w-auto">
      <div class="d-flex">
        <label class="f-light w-auto t-normal align-content-lg-center">Upload .zip File with InsIQ Local Client .exe file and instructions .txt file.Provide different File name than that of previously uploaded versions to keep version Management</label>
        <div class="ib-m">
            <app-quick-help [layout]="projTitle"></app-quick-help> 
        </div>
      </div>
    </div> -->
      <div class="row m-t-20">
        <div class="col-xl-18">
          <div class="d-flex">
            <label class="bigLabel">Authorize Payment Status</label>
            <i class="i i-info" matTooltipPosition="below"
              title="Manual Updation is permanent and cannot be undone.Make sure with the Banking Transactions before updation the transaction Manually."></i>
            <div class="ib-m">
              <!-- <app-quick-help [layout]="projTitle"></app-quick-help> -->
            </div>
          </div>
          <mat-checkbox (change)="changeCheck($event)" class="w-100" style="white-space: break-spaces;">
            <div class="m-l-5" style="white-space: break-spaces;">Authorize Transaction
            </div>

          </mat-checkbox>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <input matInput id="remarks" placeholder="Remarks/Reference for Update">
            <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
          </mat-form-field>
        </div>

      </div>
      <!--<label class="btn btn-default"></label>-->
      <div class="bar-T m-b-10 m-t-20">
        <button mat-raised-button class="curved m-r-10" color="primary" [disabled]="!disabledAgreement"
          (click)="manualTransaction()">
          Update Transaction
        </button>
      </div>
    </div>
    <div class="text-danger bold" role="alert">Manual Updation is permanent and cannot be undone.Make sure with the
      Banking Transactions before updation the transaction Manually.</div>

    <!--<div class="card">
      <div class="card-header">List of Files</div>
      <ul
        class="list-group list-group-flush"
        *ngFor="let file of fileInfos | async">
        <li class="list-group-item">
          <a href="{{ file.url }}">{{ file.name }}</a>
        </li>
      </ul>
    </div>-->
  </div>
</ng-template>