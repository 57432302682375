import {
  OnInit,
  Component,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  FormBuilder
} from '@angular/forms';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  HttpClient
} from '@angular/common/http';
import {
  AlertService
} from '../../_services';
import {
  View
} from '../../config/global.config';

import { Config } from '../../config/global.config';
import { countryList } from 'src/app/core/core.module';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { environment } from 'src/environments/environment';

import { loadStripe } from '@stripe/stripe-js';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})

export class SubscriptionComponent implements OnInit {
  prevUrl: any;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  stripePromise = loadStripe(environment.stripe);

  backbtnflg: any;
  constructor(
    public view: View,
    public router: Router,
    public config: Config,
    public fb: FormBuilder,
    public http: HttpClient,
    public route: ActivatedRoute,
    public subscriptionview: View,
    public alertService: AlertService,
    public dialog: MatDialog,

  ) {
    this.tab.selected = 'month';
    this.view.initForm('subscription', this.getNewForm(), null, false);
    this.view.refreshList = false;
    // this.view = new View(http, router, route, alertService);
    // this.subscriptionview = new View(http, router, route, alertService);
    // this.subscriptionview.initForm('subscription', this.getSubscriptionForm(), null, false);
    this.backbtnflg = JSON.parse(localStorage.getItem('cond'));
    localStorage.setItem('cond', 'false');

  }
  tab = {
    left: 0,
    width: 0,
    selected: 'month'
  };
  sachetlist: any;
  totalAmount;
  initialSachetList: any;
  dataChanged = false;
  countOfStatus1;
  toggle = true;
  isMonth = true;
  checkoutFlag = false;
  waitflag = false;
  selectedPlan: any;
  selectedIndex = 0;
  toggleBool = false;
  isPlanSelected = false;
  filteredFeatures: any[];
  filterdPlanMonthly: any[];
  filterdPlanAnnualy: any[];
  countryList: any[];
  regionList: any[];
  filteredCountry: any[];
  avaTaxResponse;
  public countries = countryList;

  // @ViewChild('customTabs', { static: true }) customTabs: ElementRef<any>;
  @ViewChild('chooseYourPlan', { static: false }) chooseYourPlan: ElementRef<any>;

  disabledAgreement = true;

  getAvg(n: number) {
    return Math.floor((n + 1) / 2);
  }

  getNewForm() {
    return this.fb.group({
      id: [],
      phone: [],
      email: [],
      userId: [],
      status: [],
      planId: [],
      revision: [],
      pricingId: [],
      amountPaid: [],
      activeFrom: [],
      autoRenewal: [],
      activeTill: [''],
      address: [''],
      city: [''],
      zip: [''],
      country: [''],
      region: [''],
      billingName: [''],
      company: [],
      vat: []
    });
  }

  getCurrency() {
    return Config.subscriptionCurrency;
  }

  getPayMode(paymode) {
    let x: string;
    if (paymode == '1') {
      x = 'Monthly';
    } else if (paymode == '2') {
      x = 'Anually';
    }
    return x;
  }
  changeCheck(event) {
    this.disabledAgreement = !event.checked;
  }
  getPayValidity(paymode: string, price: string, trialdays: string) {
    let x: string;
    if (price == '0') {
      x = trialdays;
    } else if (paymode == '1') {
      x = '30';
    } else if (paymode == '2') {
      x = '365';
    }
    return x;
  }
  cancelview() {
    localStorage.setItem('cond', 'false');

    this.router.navigate(['/profile']);
  }

  disablecheck() {
    if (!this.disabledAgreement && (this.view.value('billingName') && this.view.value('country') && this.view.value('address') && this.view.value('company') && this.view.value('zip') && this.view.value('city'))) {
      return false;
    } else {
      return true;
    }
  }

  backtocheckout() {

    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Action', message: 'Are you sure want to abort the Subscription Transaction ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data == 'confirm') {
        this.checkoutFlag = false;
        this.waitflag = false;
        this.view.initForm('subscription', this.getNewForm(), null, false);

        this.reselectPlan();
      }
    });

    // let country = this.view.value('country').name;
    // let region = this.view.value('region').name;


    // const result = [];
    // const map = new Map();

    // if (!map.has(country.toLowerCase().trim())) {
    //   map.set(country.toLowerCase().trim(), true);    // set any value to Map
    //   result.push({
    //     name: country
    //   });
    // }

    // this.view.set('country', country);
    // this.view.set('region', region);

    //  this.countryList = result;

  }
  checkoutdisablecheck() {
    if (this.view.value('billingName') && this.view.value('country') && this.view.value('address') && this.view.value('company') && this.view.value('zip') && this.view.value('city')) {
      return false;
    } else {
      return true;
    }
  }

  filterCountry(event: { query: any; }) {
    let query = event.query;
    query = query?.toLowerCase().trim();
    this.filteredCountry = this.countryList?.filter((pt: { name: string; }) => pt?.name?.toLowerCase()?.indexOf(query) >= 0);
    //  console.log(this.filteredCountry);
  }
  checkout(plan: any) {
    //  console.log('checkoutbtn');
    this.view.set('userId', this.config?.currentUser?.id);
    this.view.set('id', '');

    let plan_price: string;
    if (plan.offerStatus === 1) {
      plan_price = plan.offerPrice;
    } else {
      plan_price = plan.price;
    }
    let plan_validity: string;
    if (plan.paymode === '1') {
      plan_validity = '30 Days Validity';
    } else {
      plan_validity = '365 Days Validity';
    }

    this.view.set('revision', '1');
    this.view.set('autoRenewal', '1');
    this.view.set('status', '0');

    this.view.set('planId', plan.planId.id);

    this.view.set('pricingId', plan.id);
    this.view.set('amountPaid', plan_price);

    if (plan.price === 0) {
      // this.view.set('billingName', 'InsIQ Trial User');
      // this.view.set('address', 'Lorum Ipsum');
      // this.view.set('country', 'USA');

      this.view.saveCallback(() => {
        window.location.href = '/project';
      }, this.view.url, '');

    } else {
      this.view.set('country', this.view.value('country'));
      this.view.set('region', this.view.value('region'));

      let country = this.view.value('country');
      let region = this.view.value('region');

      if (this.view.value('billingName') && this.view.value('country') && this.view.value('address') && this.view.value('company') && this.view.value('zip') && this.view.value('city')) {
        Config.loading = true;
        Config.setLoaderMessage('Proceeding to Checkout');
        this.waitflag = true;
        this.view.saveCallback(() => {

          Config.loading = true;
          Config.setLoaderMessage('Proceeding to Checkout');

          this.view.get('subscription/checkout').subscribe(res => {
            // console.log(res);
            Config.loading = true;
            Config.setLoaderMessage('Proceeding to Checkout');

            this.view.get('user/sachetlist').subscribe(res => {
              this.sachetlist = res;
              this.initialSachetList = this.sachetlist;
              const filteredRows = this.sachetlist.filter(row => row.status === 1);
              this.countOfStatus1 = filteredRows.length;

              this.totalAmount = this.sachetlist
                .filter(row => row.status === 1)
                .reduce((sum, row) => sum + row.price, 0);

            });

            //console.log(country);
            const result = [];
            const map = new Map();

            if (!map.has(country.toLowerCase().trim())) {
              map.set(country.toLowerCase().trim(), true);    // set any value to Map
              result.push({
                name: country
              });
            }


            //  this.countryList = result;

            this.view.set('country', country.toLowerCase().trim());
            this.view.set('region', region?.toLowerCase().trim());
            Config.loading = false;


            this.avaTaxResponse = res;
            this.checkoutFlag = true;
            this.waitflag = false;
          });




          //  window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + plan_price + '/1';

        }, this.view.url, '');

      } else {
        this.alertService.error(
          'Please Provide Mandatory Billing Address Details.',
          true, 10 * 1000);
      }
    }

  }
  compareData() {
    for (let i = 0; i < this.initialSachetList.length; i++) {
      if (
        this.initialSachetList[i].status != this.sachetlist[i].status
      ) {
        this.dataChanged = true;
        break;
      }
    }
  }
  handleCheckboxChange(row) {
    Config.loading = true;

    // console.log(row);
    row.status = row.status === 1 ? 0 : 1; // Toggle the status
    // this.dataChanged = true; // Set the flag to indicate data changes
    const filteredRows = this.sachetlist.filter(row => row.status === 1);
    this.countOfStatus1 = filteredRows.length;

    this.totalAmount = this.sachetlist
      .filter(row => row.status === 1)
      .reduce((sum, row) => sum + row.price, 0);

    this.compareData();
    this.view.get('subscription/updatesachet/' + row.id + '/' + row.status).subscribe(res => {
      Config.loading = false;


      this.avaTaxResponse = res;
      //  console.log(this.avaTaxResponse)
    });

  }
  loaderStart() {
    Config.loading = true;
    Config.setLoaderMessage('Proceeding to Checkout');
  }
  async payNowHere(): Promise<void> {
    // window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + this.avaTaxResponse?.totalAmount + '/1';
    // let amount: any = textbox.value;
    Config.loading = true;
    const payment = {
      userid: this.config?.currentUser?.id,
      name: this.view.value('billingName'),
      currency: 'usd',
      // amount on cents *10 => to be on dollar
      amount: this.avaTaxResponse?.totalAmount * 100,
      quantity: '1',
      paytype: '1'
      //cancelUrl: 'http://localhost:4200/cancel',
      // successUrl: 'http://localhost:4200/success',
    };

    const stripe = await this.stripePromise;

    // this is a normal http calls for a backend api
    this.http
      .post(`${environment.serverUrl}/payment`, payment)
      .subscribe((data: any) => {
        Config.loading = false;
        // I use stripe to redirect To Checkout page of Stripe platform
        // console.log(data);
        localStorage.setItem('paymentId', data.id);
        stripe.redirectToCheckout({
          sessionId: data.id,
        }).then(result => {
          console.log(result);
        })
          .catch(error => {
            console.error(error);
          });;
      });

  }
  openTnC(typeVal) {
    // const dialogRef =
    sessionStorage.setItem('tandclink', typeVal);

    sessionStorage.setItem('tandc', 'REG');
    this.dialog.open(TermsDialogComponent, {
      width: '98vw',
      height: '98vh',
      autoFocus: false,
      panelClass: 'TnC',
      disableClose: true
    });
  }
  subscribe(plan: any) {

    let plan_price: string;
    if (plan.offerStatus === 1) {
      plan_price = plan.offerPrice;
    } else {
      plan_price = plan.price;
    }
    let plan_validity: string;
    if (plan.paymode === '1') {
      plan_validity = '30 Days Validity';
    } else {
      plan_validity = '365 Days Validity';
    }

    this.view.set('revision', '1');
    this.view.set('autoRenewal', '1');
    this.view.set('status', '0');

    this.view.set('planId', plan.planId.id);

    this.view.set('pricingId', plan.id);
    this.view.set('amountPaid', plan_price);

    if (plan.price === 0) {
      // this.view.set('billingName', 'InsIQ Trial User');
      // this.view.set('address', 'Lorum Ipsum');
      // this.view.set('country', 'USA');

      this.view.saveCallback(() => {
        window.location.href = '/project';
      }, this.view.url, '');

    } else {
      this.view.set('country', this.view.value('country'));
      this.view.set('region', this.view.value('region'));

      if (this.view.value('billingName') && this.view.value('country') && this.view.value('address') && this.view.value('company') && this.view.value('city') && this.view.value('zip')) {
        Config.loading = true;
        Config.setLoaderMessage('Redirecting to Payment Gateway.');

        this.view.saveCallback(() => {
          window.location.href = Config.BRAINTREE_URL + '/' + this.config?.currentUser?.id + '/' + plan_price + '/1';

        }, this.view.url, '');

      } else {
        this.alertService.error(
          'Please Provide Mandatory Billing Address Details.',
          true, 10 * 1000);
      }
    }
  }

  // PDF INVOICE DOWNLOAD
  // getPdf()
  // {
  ////   this.view.get('subscription/users/export/pdf').subscribe(res => {
  ////        console.log(res);
  ////       // this.productTypes = res;
  ////      });
  //
  //            let url = `${Config.apiUrl}/subscription/users/export/pdf`;
  //
  //      let requestOption = JsonRequest.getHeader('');
  //      //requestOption.responseType='blob';
  //      this.http.get(url, { headers: requestOption.headers, params: requestOption.params, responseType: 'blob' }).subscribe(
  //        (data: Blob) => {
  //          var file = new Blob([data], { type: 'application/octet-stream' })
  //          var fileURL = URL.createObjectURL(file);
  //          Config.loading = false;
  //          // if you want to open PDF in new tab
  //          // window.open(fileURL);
  //          var a = document.createElement('a');
  //          a.href = fileURL;
  //          a.target = '_blank';
  //          a.download = 'InsIQInvoice.pdf';
  //          document.body.appendChild(a);
  //          a.click();
  //        },
  //        (error) => {
  //          console.log('Something went Wrong', error);
  //        }
  //      );
  // }

  getStatusflag(plan) {
    // console.log(plan)
    let pay: boolean;
    if (plan.price === 0 && plan.planId.platform === 0) {
      pay = false;
    } else {
      pay = true;
    }
    return pay;
  }
  getPayTxt(plan) {
    //  console.log(this.config.currentUser?.curPriceId);

    let pay: string;
    if (plan.price === 0) {
      pay = 'Start Free Trial';
    } else if (this.config.currentUser?.curPriceId === plan.id && this.config.currentUser?.refund == 0) {
      pay = 'Renew Plan';
    } else {
      pay = 'Pay Now';
    }
    return pay;
  }
  getback() {
    if (this.config.currentUser?.planStatus === 1) {
      return true;
    } else {
      return false;
    }

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  getPlanFeature(planId: any) {
    return this.filteredFeatures?.filter(u => u.planId.id === planId);
  }
  moveToSelectedTab() {
    this.toggle = !this.toggle;
    this.selectedIndex = 0;
  }
  moveToSelectedTab1() {
    this.toggle = !this.toggle;
    this.selectedIndex = 1;
  }

  reselectPlan() {
    this.dataChanged = false;

    this.checkoutFlag = false;
    this.waitflag = false;
    this.view.initForm('subscription', this.getNewForm(), null, false);

    //this.reselectPlan();
    this.isPlanSelected = false;
  }
  openDialog(id: any) {
    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        okbtn: 'Yes',
        cancelbtn: 'No',
        title: 'Delete Sachet User Are you ABSOLUTELY SURE?',
        message: 'User Sachet Subscription Deletion is permanent and cannot be undone. Once this subscription is deleted, the user can no longer use into InsIQ.'
      }
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.view.deleteList(id);
      }
    });
  }
  anualPricecalculator(price) {
    let montlypice = price / 12;
    let planPrice;

    if (!Number.isSafeInteger(montlypice)) {
      planPrice = montlypice.toFixed(2);
    }
    else {
      planPrice = montlypice;
    }
    return planPrice;

  }
  selectPlan(plan) {
    this.view.get('billinginfo').subscribe(res => {
      try {

        if (res && res !== null) {
          this.view.set('billingName', res[0]?.billingName);
          this.view.set('phone', res[0]?.phone);
          this.view.set('address', res[0]?.address);
          this.view.set('email', res[0]?.email);
          this.view.set('zip', res[0]?.zip);
          this.view.set('city', res[0]?.city);
          this.view.set('company', res[0]?.company);
          this.view.set('vat', res[0]?.vat);
          //  this.view.set('country', res[0].country);

          const result = [];
          const map = new Map();

          if (!map.has(res[0]?.country.toLowerCase().trim())) {
            map.set(res[0]?.country.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              name: res[0]?.country
            });
          }


          //  this.countryList = result;

          this.view.set('country', res[0]?.country);
          this.view.set('region', res[0]?.region);

        }
      }
      catch (e) { }


    });

    if (plan.price === 0) {
      this.subscribe(plan);
    } else {
      this.selectedPlan = plan;
      this.isPlanSelected = true;
    }
  }


  // @HostListener('window:resize', ['$event'])
  // resizeTabs(event: any) {
  //   // console.log('Tab Resized', event);
  //   if (this.customTabs === undefined) {
  //     return;
  //   }
  //   const tgt = this.customTabs.nativeElement.querySelectorAll('.customTabs button.active')[0];
  //   // console.log(tgt);
  //   this.choosePlan({ target: tgt }, tgt.dataset.plan);
  // }

  // choosePlan(e: any, opt: PlanType) {
  //   // console.log(e.target, opt);
  //   // console.log(this.customTabs);
  //   this.isMonth = opt === 'month' ? true : false;
  //   // this.customTabs.nativeElement.querySelectorAll('button').forEach(tab => {
  //   //   tab.classList.remove('active');
  //   // });
  //   // e.target.classList.add('active');
  //   this.tab.selected = opt;
  //   setTimeout(() => {
  //     this.getTabPos(e.target);
  //   }, 150);
  // }

  // getTabPos(tgt: any) {
  //   // console.log('ink', tgt.offsetLeft);
  //   this.tab.left = tgt.offsetLeft;
  //   this.tab.width = tgt.offsetWidth;
  // }
  disableStatus(plan) {
    if (this.config.currentUser?.curPriceId === plan.id && this.config.currentUser?.refund == 0) {
      if (localStorage?.getItem('upgradeFlag') === 'true') {
        return true;
      }
      else {
        return false;

      }
    } else {
      return false;
    }

  }
  ngOnInit(): void {
    this.view.get('planpricing/all/price').subscribe(res => {
      // this.view.list = res;
      this.filterdPlanMonthly = res.filter(u => u.paymode === '1');
      this.filterdPlanAnnualy = res.filter(u => u.paymode === '2');

      // if (localStorage?.getItem('upgradeFlag') === 'true') {
      //   this.filterdPlanMonthly = this.filterdPlanMonthly.filter(u => this.config.currentUser?.curPriceId != u.id);
      //   this.filterdPlanAnnualy = this.filterdPlanAnnualy.filter(u => this.config.currentUser?.curPriceId != u.id);
      // }
      // localStorage.setItem('upgradeFlag', 'false');

      // console.log('annual', this.filterdPlanAnnualy);

      this.view.get('billinginfo/countrylist').subscribe(res => {

        // this.countryList=res;

        const result = [];
        const map = new Map();
        for (const item of res) {
          if (!map.has(item.name.toLowerCase().trim())) {
            map.set(item.name.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              name: item.name
            });
          }
        }

        this.countryList = result;
        // console.log(this.countryList);
      });

      this.view.get('billinginfo/regionlist').subscribe(res => {
        // console.log(res);

        const result = [];
        const map = new Map();
        for (const item of res) {
          if (!map.has(item.name.toLowerCase().trim())) {
            map.set(item.name.toLowerCase().trim(), true);    // set any value to Map
            result.push({
              name: item.name
            });
          }
        }

        this.regionList = result;
      });
    });

    this.view.get('planfeature/all/feature').subscribe(res => {
      // this.view.list = res;
      this.filteredFeatures = res;
    });
    if (this.config.currentUser ? this.config.currentUser?.pin === 'USR' : JSON.parse(localStorage.getItem('currentUserStore'))?.pin === 'USR') {

      //this.router.navigate(['/login']);
    }
    // const tgt = this.customTabs.nativeElement.querySelector('button');
    // console.log(tgt);
    // this.choosePlan({ target: tgt }, tgt.dataset.plan);
  }

  // ngAfterViewInit(): void {
  //   console.log(this.chooseYourPlan);
  //   const tgt = this.customTabs.nativeElement.querySelector('button');
  //   console.log(tgt);
  //   this.choosePlan({ target: tgt }, tgt.dataset.plan);
  // }
}
