import {
  HttpParams,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  merge,
  BehaviorSubject,
  of as observableOf
} from 'rxjs';
import {
  map,
  first,
  startWith,
  switchMap,
  catchError
} from 'rxjs/operators';
import {
  Router,
  ActivatedRoute
} from '@angular/router';

import { FormGroup } from '@angular/forms';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';

import { User } from '../_models';
import { AlertService } from '../_services/alert.service';
import { environment } from 'src/environments/environment';
import { CustomThemeService } from '../custom-theme.service';

/**
 * CONFIG
 */
@Injectable({
  providedIn: 'root'
})
export class Config {

  projlist: any;
  static transparent: boolean;
  constructor(
    public view: View,
    public customThemeService: CustomThemeService
  ) {
    this.initUser();
  }
  static loading = false;
  static loaderImage: any;
  static loaderMessage: string;
  static customDomain = true;
  static pinned_proj = false;
  static cloneProject = false;
  static sharedData = '';
  //  public static AI_URL = environment.ai.api;
  //  public static AI_API_KEY = environment.ai.key;

  public static MSLOGIN_URL = environment.msUrl;

  public static GOOGLE_URL = environment.googleUrl;
  public static GOOGLELOGIN_URL = Config.GOOGLE_URL + 'loginSuccess';
  public static GOOGLEGIGNINONLY_URL = Config.GOOGLE_URL + 'signinonly';
  public static GOOGLELOGOUT_URL = Config.GOOGLE_URL + 'exit';

  public static LINKEDIN_URL = environment.linUrl;
  public static LINSIGNINONLY_URL = Config.LINKEDIN_URL + 'loginonly';

  public static LINKEDINLOGIN_URL = Config.LINKEDIN_URL + 'login';

  public static BRAINTREE_URL = environment.payUrl;

  public static MAINPROD_URL = 'app.devtom.ai';
  public static LocalhostUrl = environment.appUrl;
  public static baseUrl = environment.baseUrl;
  public static apiUrl = Config.baseUrl + '/api';
  public static imgPath = 'assets/images';
  // public static iconUrl = 'assets/icons';
  public static user: any; // bearer token user
  public static root = 'admin';
  public static roleCode: any;
  public static roleName: any;
  public static adminRole = ['PADM', 'ADMN', 'STAF', 'EADM'];

  public static insightId: any;
  public static isLoggedIn = false;
  public static isProjectSelected = false;
  public static subscriptionCurrency = '$';
  public static subscriptionCurrencytype = 'USD ($)';
  static apiUsage: any;
  static dataUsage: any;
  static UserCount: any;

  dev2MOverview: boolean;
  public currentUser: User | any; // user object
  public currentYear = new Date().getFullYear();
  loadingProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  showProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  loader = {
    canExit: true,
    forceExit: (e) => {
      // console.log(e);
    }
  };

  static setLoaderMessage(message: string) {
    Config.loaderMessage = message;
  }

  static setLoaderImage(path) {
    Config.loaderImage = path;
  }

  featureChecker(featureId) {
    let count = 0;
    for (let i = 0; i < JSON.parse(localStorage.getItem('currentUserStore'))?.planfeature?.length; i++) {
      if (JSON.parse(localStorage.getItem('currentUserStore'))?.planfeature[i]?.featureId.id === featureId) {
        count++;
      }

    }
    if (count === 0) {
      return false;
    } else {
      return true;
    }
  }

  projectClone() {

    this.view.get('project/clonelist').subscribe(res => {
      // Filter out items where projectId is null
      const filteredRes = res.filter(item => item.projectId !== null);

      if (filteredRes.length !== 0) {
        Config.cloneProject = true;
        Config.sharedData = filteredRes;
      } else {
        Config.cloneProject = false;
        //Config.sharedData = []; // Reset sharedData to an empty array if no valid data
      }
    });

  }
  whilelabelhandler() {
    this.view.get('personalize/whitelabels').subscribe(res => {

      // this.countryList=res;
      // console.log(res);

      if (res) {
        if (res?.apptheme) {
          let dynamiclogostat = false;
          if (res?.logopath) {
            dynamiclogostat = true;
          }
          this.customThemeService.changeTheme(res?.apptheme.themeName, 'data:image/png;base64,' + res?.logoImage, dynamiclogostat);

        }
        //  console.log(this.customThemeService.logo)

        // this.imageUrl = 'data:image/png;base64,' + res?.logoImage;
      }
      else {
        this.customThemeService.changeTheme('theme-default', 'logo.png', false);

      }

    });
  }
  userCount() {
    this.view.get('apiusage/usercount').subscribe(res => {

      if (res != '' && res) {
        this.setUserCount(res);
      } else {
        this.setUserCount('0');

      }
      localStorage.setItem('UserCount', this.getUserCount());
    });
  }
  apiUsage() {
    this.view.get('apiusage').subscribe(res => {

      if (res != '' && res) {
        this.setapiUsage(res);
      } else {
        this.setapiUsage('0.0');

      }
      localStorage.setItem('apiUsage', this.getapiUsage());
    });
  }

  dataUsage() {
    this.view.get('apiusage/datapack').subscribe(res => {

      // console.log(res);

      if (res != '' && res) {
        this.setdataUsage(res);
      } else {
        this.setdataUsage('0.0');

      }
      localStorage.setItem('dataUsage', this.getdataUsage());
    });
  }

  setLoaderPercentage(n: number) {
    this.loadingProgress.next(n);
  }
  getLoaderPercentage() {
    return this.loadingProgress.getValue();
  }

  setProgressVisibility(v: boolean) {
    this.showProgress.next(v);
  }
  getProgressVisibility() {
    return this.showProgress.getValue();
  }

  setdataUsage(message: string) {
    Config.dataUsage = message;
  }
  getdataUsage() {
    return Config.dataUsage;
  }

  setapiUsage(message: string) {
    Config.apiUsage = message;
  }
  getapiUsage() {
    return Config.apiUsage;
  }

  setUserCount(message: string) {
    Config.UserCount = message;
  }
  getUserCount() {
    return Config.UserCount;
  }

  isLoading() {
    if (!Config.loading) {
      Config.transparent = false;
      Config.setLoaderMessage('');
      Config.setLoaderImage(undefined);
    }

    return Config.loading;
  }

  isTransparent() {
    return Config.transparent;
  }

  getLoaderMessage() {
    return Config.loaderMessage;
  }

  getLoaderImage() {
    return Config.loaderImage;
  }

  start() {
    Config.loading = true;
  }

  end() {
    if (Config.loading) {
      setTimeout(() => {
        Config.loading = false;
        Config.setLoaderImage(undefined);
      }, 300);
    }
  }

  isLoggedIn() {
    return this.getUsername() !== '';
  }

  isProjectSelected() {
    return Config.isProjectSelected;
  }

  initUser() {
    Config.user = JSON.parse(localStorage.getItem('currentUser'));
    return Config.user;
  }
  isAdmin() {

    if (!Config.user || Config.user === null) {
      this.initUser();
    }
    //console.log(Config.roleCode);
    return Config.adminRole.includes(Config.roleCode);
  }
  getUsername() {
    if (Config.user && Config.user !== null) {
      return Config.user.username;
    }
    // else if (localStorage.getItem('currentProfile') !== undefined) {
    //   return JSON.parse(localStorage.getItem('currentProfile')).username;
    // }
    return '';
  }


  getBtob() {
    if (Config.user && Config.user !== null) {
      return Config.user.isbtob;
    }
    return 0;
  }
  designcatName(fl) {
    let categoryName = '';
    switch (fl) {
      case '2':
        categoryName = 'Product Requirements';
        break;
      case '3':
        categoryName = 'Test Methods';
        break;
      case '4':
        categoryName = 'Design Features';
        break;
      case '5':
        categoryName = 'Manufacturing Processes';
        break;
      case '6':
        categoryName = 'Intellectual Property';
        break;
      case '7':
        categoryName = 'Product Pricing';
        break;
      case '8':
        categoryName = 'Product Stakeholders';
        break;
    }
    return categoryName;
  }
  catName(fl, pdtType, flag) {
    let categoryName = '';
    switch (fl) {
      case '0':
        categoryName = 'Product and Competitor Sentiment';
        break;
      case '2':
        if (!flag) {
          categoryName = 'Similar Products or Projects';
        } else {
          categoryName = 'Product Requirements';

        }
        break;
      case '3':
        if (!flag) {
          if (pdtType == '1') {
            categoryName = 'Consumer Insights';
          } else if (pdtType == '2') {
            categoryName = 'Competitors';
          }

        } else {
          categoryName = 'Test Methods';
        }
        break;

      case '31':


        categoryName = 'Consumer Insights';


        break;

      case '32':


        categoryName = 'Competitors';


        break;

      case '4':
        if (!flag) {
          categoryName = 'Patents & Trademarks';
        } else {
          categoryName = 'Design Features';
        }
        break;
      case '5':
        if (!flag) {
          categoryName = 'Suppliers, logistics and related';

        } else {
          categoryName = 'Manufacturing Processes';
        }
        break;
      case '6':
        if (!flag) {
          categoryName = 'Blogs and Reviews';
        } else {
          categoryName = 'Intellectual Property';
        }
        break;
      case '7':
        if (!flag) {
          categoryName = 'Product Safety & Regulatory';
        } else {
          categoryName = 'Product Pricing';
        }
        break;
      case '8':
        if (!flag) {
          categoryName = 'Influencers & Experts';
        } else {
          categoryName = 'Product Stakeholders';
        }
        break;
      case '9':
        categoryName = 'Analyze your data';
        break;
      case '10':
        categoryName = 'Product News';
        break;
      case '11':
        categoryName = 'Scientific Research & Tech';
        break;
      case '12':
        categoryName = 'Trend Insights';
        break;
      case '111':
        categoryName = 'Deep Dive';
        break;
      case '101':
        categoryName = 'Pricing Insights';
        break;
      case '2A':
        categoryName = 'Product Requirements';
        break;
      case '3A':
        categoryName = 'Test Methods';
        break;
      case '4A':
        categoryName = 'Design Features';
        break;
      case '5A':
        categoryName = 'Manufacturing Processes';
        break;
      case '6A':
        categoryName = 'Intellectual Property ';
        break;
      case '7A':
        categoryName = 'Product Pricing';
        break;
      case '8A':
        categoryName = 'Product Stakeholders';
        break;
    }

    return categoryName;
  }
  catId(fl) {
    // console.log(fl);
    let categoryId = '';
    switch (fl) {
      case 'Similar Products or Projects':
        categoryId = '2';
        break;
      case 'Consumer Insights':

        categoryId = '31';

        break;
      case 'Competitors':

        categoryId = '32';

        break;
      case 'Patents & Applications':
        categoryId = '4';
        break;
      case 'Patents & Trademarks':
        categoryId = '4';
        break;

      case 'Patents and Applications':
        categoryId = '4';
        break;
      case 'Suppliers, logistics and related':
        categoryId = '5';
        break;
      case 'Blogs and Reviews':
        categoryId = '6';
        break;
      case 'Product Safety & Regulatory':
        categoryId = '7';
        break;
      case 'Influencers, SME’s and related':
        categoryId = '8';
        break;
      case 'Influencers & Experts':
        categoryId = '8';
        break;
      case 'Analyze your data':
        categoryId = '9';
        break;
      case 'Product News':
        categoryId = '10';
        break;
      case 'Scientific Research & Tech':
        categoryId = '11';
        break;

      case 'Deep Dive':
        categoryId = '111';
        break;
      case 'Trend Insights':
        categoryId = '12';
        break;

      case 'Product and Competitor sentiment':
        categoryId = '0';
        break;
      case 'Pricing Insights':
        categoryId = '101';
        break;

      case 'Average Price':
        categoryId = '101';
        break;

      case 'Product Requirements':
        categoryId = '2';
        break;
      case 'Test Methods':
        categoryId = '3';
        break;
      case 'Design Features':
        categoryId = '4';
        break;
      case 'Manufacturing Processes':
        categoryId = '5';
        break;
      case 'Intellectual Property ':
        categoryId = '6';
        break;
      case 'Product Pricing':
        categoryId = '7';
        break;
      case 'Product Stakeholders':
        categoryId = '8';
        break;

    }
    // console.log(categoryId);
    return categoryId;
  }

  promptTexts(field, pdtType, labelCat) {
    let prompttext = '';
    switch (labelCat) {
      case 199:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', is exploring a new idea for a product ';
            } else if (pdtType == 2) {
              prompttext = ' wants to ensure the success of our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;

          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = 'Kindly provide insights in ';
            } else if (pdtType == 2) {
              prompttext = '. We aim to gather insights on consumer usage and feedback to enhance our offering. Kindly provide your observations and suggestions in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' that could help us understand potential consumer needs, preferences, and behaviors in the ';
            } else if (pdtType == 2) {
              prompttext = ' to help us improve and optimize our product in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = 'market.';
            } else if (pdtType == 2) {
              prompttext = 'market.';
            }
            break;

        }
        break;
      case 31://Consumer Insights
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner,';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', is looking for consumer insights that could inform the development of a product ';
            } else if (pdtType == 2) {
              prompttext = ', is looking for consumer insights for our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We seek insights in ';
            } else if (pdtType == 2) {
              prompttext = '. Kindly provide insights in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' that could help us understand potential consumer needs, preferences, and behaviors in the ';
            } else if (pdtType == 2) {
              prompttext = ' to help us tailor our marketing strategies and improve customer satisfaction. We aim to understand consumer preferences, behaviors, and purchasing patterns in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = 'market.';
            } else if (pdtType == 2) {
              prompttext = 'market.';
            }
            break;

        }
        break;
      //Blogs & Reviews
      case 6:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', is looking for blogs and reviews related to products similar to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ', is looking for blogs and reviews related to our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described a ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We seek content in ';
            } else if (pdtType == 2) {
              prompttext = '. We seek content in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' that discusses and evaluates similar products, their features, and benefits in the ';
            } else if (pdtType == 2) {
              prompttext = ' that discusses and evaluates our product, its features, and benefits in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = ' market to help us shape our product concept. ';
            } else if (pdtType == 2) {
              prompttext = ' market to enhance our online presence and reputation. ';
            }
            break;

        }
        break;
      //Pricing Insights

      case 101:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', needs pricing insights for products similar to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ',  needs pricing insights for our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = 'We require detailed analysis in ';
            } else if (pdtType == 2) {
              prompttext = '. We require detailed analysis in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to help us understand the potential pricing strategies and market opportunities in the ';
            } else if (pdtType == 2) {
              prompttext = ' to help us establish competitive pricing strategies and identify potential opportunities in the';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = ' market.';
            } else if (pdtType == 2) {
              prompttext = ' market.';
            }
            break;

        }
        break;
      //Product News

      case 10:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', requests recent product news related to products similar to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ', requests recent product news related to our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = 'We seek updates in ';
            } else if (pdtType == 2) {
              prompttext = '. We seek updates in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' about industry developments, innovations, and events that may impact our new product idea in the ';
            } else if (pdtType == 2) {
              prompttext = ' about industry developments, innovations, and events that may impact our products performance in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = ' market. ';
            } else if (pdtType == 2) {
              prompttext = ' market. ';
            }
            break;

        }
        break;
      //Product and competitor sentiment

      case 0:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ',  is interested in understanding the sentiment towards products similar to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ', is interested in understanding the sentiment towards products similar to  our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = ' We are seeking sentiment analysis in ';
            } else if (pdtType == 2) {
              prompttext = '. We are seeking sentiment analysis in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to gauge consumer and market reactions towards similar products and their competitors in the ';
            } else if (pdtType == 2) {
              prompttext = ' to gauge consumer and market reactions towards similar products and their competitors in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = ' market. This information will help us anticipate potential reception of our product idea and shape our development and marketing strategies accordingly. ';
            } else if (pdtType == 2) {
              prompttext = ' market. This information will help us anticipate potential reception of our product and shape our development and marketing strategies accordingly. ';
            }
            break;
        }
        break;
      //Similar Products or Projects

      case 2:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ',  requires information on similar products  or projects to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ', requires information on similar products to  our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. Please provide insights in ';
            } else if (pdtType == 2) {
              prompttext = '. Please provide insights in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to help us identify potential strengths, weaknesses, opportunities, and threats.We are interested in understanding the competitive landscape in the ';
            } else if (pdtType == 2) {
              prompttext = ' to help us identify potential strengths, weaknesses, opportunities, and threats.We are interested in understanding the competitive landscape in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = 'market. ';
            } else if (pdtType == 2) {
              prompttext = 'market. ';
            }
            break;

        }
        break;
      //Competitors

      case 32:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ',  needs an overview of potential competitors for our new product idea ';
            } else if (pdtType == 2) {
              prompttext = ', needs an overview of our competitors for our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We seek competitor profiles in ';
            } else if (pdtType == 2) {
              prompttext = '. We seek competitor profiles in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to assess their strategies, strengths, and weaknesses in the ';
            } else if (pdtType == 2) {
              prompttext = ' to assess their strategies, strengths, and weaknesses in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = 'market.';
            } else if (pdtType == 2) {
              prompttext = 'market.';
            }
            break;

        }
        break;
      //Influencers and Experts

      case 8:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ',  is looking for influencers and experts related to our new product idea ';
            } else if (pdtType == 2) {
              prompttext = ', is looking for influencers and experts related to our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We request a list of relevant influencers and experts in ';
            } else if (pdtType == 2) {
              prompttext = '. We request a list of relevant influencers and experts in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' who could potentially help us promote our new product and improve its visibility in the ';
            } else if (pdtType == 2) {
              prompttext = ' who can help us promote our product and improve its visibility in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = 'market. ';
            } else if (pdtType == 2) {
              prompttext = 'market. ';
            }
            break;

        }
        break;
      //Scientific Reserch & Tech

      case 11:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = ' Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = ' Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', requests scientific research related to our new product idea ';
            } else if (pdtType == 2) {
              prompttext = ', requests scientific research related to our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = 'in the';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We seek research findings and studies in ';
            } else if (pdtType == 2) {
              prompttext = '. We seek research findings and studies in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to support our product development and ensure its potential efficacy and quality in the ';
            } else if (pdtType == 2) {
              prompttext = ' to support our product claims and ensure its efficacy and quality in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = ' market. ';
            } else if (pdtType == 2) {
              prompttext = ' market. ';
            }
            break;

        }
        break;
      //Product safety & Regulatory

      case 7:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', requires product safety information for similar products to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ', requires product safety information for our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We need insights in ';
            } else if (pdtType == 2) {
              prompttext = '. We aim to understand consumer preferences, behaviors, and purchasing We need insights in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to ensure our product concept complies with safety regulations and standards in the ';
            } else if (pdtType == 2) {
              prompttext = ' to ensure our product complies with safety regulations and standards in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = ' market. ';
            } else if (pdtType == 2) {
              prompttext = ' market. ';
            }
            break;

        }
        break;
      //Suppliers logistics and related

      case 5:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', is seeking information on suppliers and logistics for similar products to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ',  is seeking information on suppliers and logistics for our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We require a list of potential suppliers and logistics partners in ';
            } else if (pdtType == 2) {
              prompttext = '. We require a list of potential suppliers and logistics partners in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to help us understand the potential manufacturing and distribution processes for our new product in the ';
            } else if (pdtType == 2) {
              prompttext = ' to help us source materials, manufacture, and distribute our product efficiently and cost-effectively in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = 'market. ';
            } else if (pdtType == 2) {
              prompttext = 'market. ';
            }
            break;

        }
        break;
      //Patents and Trademarks

      case 4:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', is seeking information on patents and trademarks related to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ',  is seeking information on patents and trademarks related to our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are ';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We require insights in ';
            } else if (pdtType == 2) {
              prompttext = '. We require insights in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to ensure our new product concept complies with legal requirements and avoids potential infringements in the ';
            } else if (pdtType == 2) {
              prompttext = ' to ensure our product complies with legal requirements and avoids potential infringements in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = ' market.';
            } else if (pdtType == 2) {
              prompttext = ' market.';
            }
            break;

        }
        break;
      //Analyze your data

      case 9:
        switch (field) {
          case 1:
            if (pdtType == 1) {
              prompttext = 'Our project owner, ';
            } else if (pdtType == 2) {
              prompttext = 'Our project owner, ';
            }
            break;
          case 2:
            if (pdtType == 1) {
              prompttext = ', is looking to analyze data related to our new idea ';
            } else if (pdtType == 2) {
              prompttext = ', is looking to analyze data related to our ';
            }
            break;
          case 3:
            if (pdtType == 1) {
              prompttext = ' in the ';
            } else if (pdtType == 2) {
              prompttext = ' in the ';
            }
            break;
          case 4:
            if (pdtType == 1) {
              prompttext = ', which could be described as ';
            } else if (pdtType == 2) {
              prompttext = ', which is described as ';
            }
            break;
          case 5:
            if (pdtType == 1) {
              prompttext = ' and might emphasize ';
            } else if (pdtType == 2) {
              prompttext = ' and emphasizes ';
            }
            break;
          case 6:
            if (pdtType == 1) {
              prompttext = ' and could adress to ';
            } else if (pdtType == 2) {
              prompttext = '. Our Product Company Name is ';
            }
            break;
          case 7:
            if (pdtType == 1) {
              prompttext = '. Competitor Companies could be ';
            } else if (pdtType == 2) {
              prompttext = '. Competitor Companies are';
            }
            break;
          case 8:
            if (pdtType == 1) {
              prompttext = ' and competitor products could be ';
            } else if (pdtType == 2) {
              prompttext = ' and competitor products are ';
            }
            break;
          case 9:
            if (pdtType == 1) {
              prompttext = '. We need insights in ';
            } else if (pdtType == 2) {
              prompttext = '. We need insights in ';
            }
            break;
          case 10:
            if (pdtType == 1) {
              prompttext = ' to understand market trends, consumer behaviors, and competitive landscape in the ';
            } else if (pdtType == 2) {
              prompttext = ' to understand market trends, consumer behaviors, and competitive landscape in the ';
            }
            break;
          case 11:
            if (pdtType == 1) {
              prompttext = ' market to shape our new product idea. ';
            } else if (pdtType == 2) {
              prompttext = ' market to monitor our product. ';
            }
            break;

        }
        break;
    }
    return prompttext;
  }

}


export class AppPattern {
  static EMAIL =
    '[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?';
}

export class JsonRequest {
  static getHeader(paramStr, hasAuth = true, contentType = 'application/json') {
    let auth = '';
    if (hasAuth && Config.user && Config.user !== null) {
      auth = 'Bearer ' + Config.user.access_token;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': contentType,
        'Authorization': auth
      }),
      params: new HttpParams({
        fromString: paramStr
      })
    };
    return httpOptions;
  }
  static getRemote(http: HttpClient, url: string, servicePath: any, paramStr: any) {
    return this._get(http, servicePath, paramStr, url, false);
  }
  static get(http: HttpClient, servicePath: any, paramStr: any) {
    return this._get(http, servicePath, paramStr);
  }
  static _get(http: HttpClient, servicePath: any, paramStr: any, url = Config.apiUrl, hasAuth = true, contentType = 'application/json') {
    const httpOptions = this.getHeader(paramStr, hasAuth, contentType);
    // console.log(`${url}/${servicePath}`);
    return http.get<any>(`${url}/${servicePath}`,
      {
        headers: httpOptions.headers,
        params: httpOptions.params
      })
      .pipe(map(data => {
        return data;
      }));
  }

  static getById(http: HttpClient, servicePath) {
    const httpOptions = this.getHeader('');
    return http.get<any>(`${Config.apiUrl}/${servicePath}`,
      {
        headers: httpOptions.headers,
        params: httpOptions.params
      }
    ).pipe(map(data => {
      return data;
    }));
  }

  static post(http: HttpClient, servicePath, data) {
    return http.post<any>(`${Config.apiUrl}/${servicePath}`, data);
  }
}

// export class MultiPartJsonRequest extends JsonRequest {
//   constructor(method: RequestMethod | string, body: any, url: string) {
//     super();
//     this.method = method;
//     this.body = body;
//     this.url = url;
//     this.headers.append('Content-Type', 'multipart/form-data;boundary=gc0p4Jq0M2Yt08jU534c0p');
//     this.headers.append('Accept', 'application/json');
//     this.headers.append('enctype', 'multipart/form-data');
//   }
// }

export class Lang {
  public static en = {
    saveSuccess: 'Successfully Saved',
    saveFailed: 'Failed to Save',
    deleteSuccess: 'Successfully Deleted',
    deleteFailed: 'Failed to Delete',
    uploadFailed: 'Failed to Upload, Please check file type',
    selectUser: 'Select user to proceed',
    userExist: 'Login already exist',
    loginFailed: 'Incorrect username / password',
  };
}

@Injectable({
  providedIn: 'root'
})
export class View {
  @Output() linkEvent = new EventEmitter<any>();

  url: string;
  total: any;
  list: any = [];
  public form: FormGroup;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageSize = 10;
  state = 'list'; // default is list
  paginator: MatPaginator;
  returnUrl = '/';
  formFields = [];
  listParams;
  keyword = '';
  refreshList = true;
  refreshForm = false;
  formDefault: FormDefault;
  active: any;
  private _snackBar: MatSnackBar;

  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService
  ) {
    // //this.start();
    this.returnUrl = this.route.snapshot.queryParams['url'] || '/';
  }

  initForm(url: string, form: FormGroup, formDefault: FormDefault = null, refreshForm = true) {
    Config.isProjectSelected = false;
    this.url = url;
    this.form = form;
    this.state = 'new';
    this.formDefault = formDefault;
    this.formFields = [];
    this.refreshForm = refreshForm;
    for (const a in form.controls) {
      if (a) {
        this.formFields.push(a);
      }
    }
  }

  initPage(paginator: MatPaginator, sort: MatSort, refreshList = true) {
    this.paginator = paginator;
    this.refreshList = refreshList;
    sort.sortChange.subscribe(() => paginator.pageIndex = 0);

    merge(sort.sortChange, paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          // this.start();
          this.listParams = {
            active: sort.active || 'id',
            direction: sort.direction || 'asc',
            pageIndex: paginator.pageIndex || 0,
            size: paginator.pageSize,
            filter: this.keyword.toLowerCase()
          };
          return this.getPage(this.url, this.listParams);
        }),
        map(res => {
          this.state = 'list';
          return res;
        }),
        catchError(() => {
          this.end();
          return observableOf([]);
        })
      ).subscribe(res => {
        this.end();
        this.list = res;
      });
  }

  start() {
    Config.loading = true;
  }

  end() {
    if (Config.loading) {
      setTimeout(() => {
        Config.loading = false;
        Config.setLoaderImage(undefined);
      }, 300);
    }
  }

  filter() {
    this.listParams.pageIndex = 0;
    this.listParams.filter = this.keyword.toLowerCase();
    this.reloadList();
  }

  private reloadList() {
    // this.start();
    // console.log(this.url);
    return this.getPage(this.url, this.listParams)
      .subscribe(res => {
        this.end();
        this.list = res;
      }
      ),
      catchError(() => {
        this.end();
        return observableOf([]);
      });
  }

  newform(formDefault: FormDefault = null) {
    const fd: FormDefault = (formDefault || formDefault !== null) ? formDefault : this.formDefault;
    // console.log(this.form);
    this.reset();
    this.state = 'new';
    // console.log(fd);
    if (fd && fd !== null) {
      let i = 0;
      for (const k of fd.keys) {
        this.set(k, fd.values[i]);
        i++;
      }
    }
    // console.log(this.form.value);
    this.active = null;
  }

  edit(row) {
    this.reset();
    for (const item of this.formFields) {
      this.field(item).setValue(row[item]);
    }
    this.state = 'edit';
    this.active = row;
  }

  clone(row) {
    // console.log(row);
    this.reset();
    this.state = 'new';
    for (const item of this.formFields) {
      this.field(item).setValue(row[item]);
    }
    this.field('id').setValue(null);
    this.active = null;
  }

  editById(id: any) {
    this.getById(id).subscribe(data => {
      for (const item of this.formFields) {
        this.field(item).setValue(data[item]);
      }
      this.active = this.form.value;
      return data;
    });
    // TODO handle confirm dialog error and success message
  }

  delete(id: any, serviceUrl = null, mSuccess = null, mFailed = null, callback = null) {
    return this._delete(id, serviceUrl, mSuccess, mFailed, true, callback);
  }

  deleteList(id, serviceUrl = null, mSuccess = null, mFailed = null, callback = null) {
    return this._delete(id, serviceUrl, mSuccess, mFailed, false, callback);
  }
  _deleteProject(id: any, serviceUrl = null, mSuccess = null, mFailed = null, newform = true, callback = null) {
    this.start();
    const options = JsonRequest.getHeader('');
    serviceUrl = serviceUrl === null ? this.url : serviceUrl;
    return this.http.delete(`${Config.apiUrl}/${serviceUrl}/${id}`, { headers: options.headers, params: options.params })
      .subscribe(
        data => {
          this.list = this.list.filter(u => u.id !== id);
          this.end();
          this.total--;
          if (newform) {
            this.newform();
          }
          if (mSuccess === null) {
            mSuccess = 'Project Deleted Successfully';
          }
          this.active = null;
          sessionStorage.setItem('deleteData', 'deleted');
          this.linkEvent.emit(['project']);
          window.location.href = '/project';

          if (callback !== null) {
            callback();
          }
        },
        error => {
          this.end();
          if (mFailed === null) {
            mFailed = 'Failed to Delete';
          }
          this.alertService.fatal(error, mFailed);
        }
      );
  }
  _delete(id: any, serviceUrl = null, mSuccess = null, mFailed = null, newform = true, callback = null) {
    this.start();
    const options = JsonRequest.getHeader('');
    serviceUrl = serviceUrl === null ? this.url : serviceUrl;
    return this.http.delete(`${Config.apiUrl}/${serviceUrl}/${id}`, { headers: options.headers, params: options.params })
      .subscribe(
        data => {
          this.list = this.list.filter(u => u.id !== id);
          this.end();
          this.total--;
          if (newform) {
            this.newform();
          }
          if (mSuccess === null) {
            mSuccess = 'Successfully Deleted';
          }
          this.active = null;
          this.alertService.success(mSuccess, true);
          if (callback !== null) {
            callback();
          }
        },
        error => {
          this.end();
          if (mFailed === null) {
            mFailed = 'Failed to Delete';
          }
          this.alertService.fatal(error, mFailed);
        }
      );
  }

  saveRedirect(serviceUrl = null, navPath = null, mSuccess = null, mFailed = null) {
    this._save(serviceUrl, navPath, mSuccess, mFailed);
  }

  //  saveSet(setFields = null, serviceUrl = null, mSuccess = null, mFailed = null, loading = true) {
  //    this._save(serviceUrl, null, mSuccess, mFailed, setFields, null , null, loading);
  //  }
  saveSet(setFields = null, serviceUrl = null, mSuccess = null, mFailed = null, state = 'edit', callback = null, loading = true) {
    this._save(serviceUrl, null, mSuccess, mFailed, setFields, state, callback, loading);
  }

  saveSetCallback(callback = null, setFields = null, serviceUrl = null, mSuccess = null, mFailed = null, loading = true) {
    this._save(serviceUrl, null, mSuccess, mFailed, setFields, null, callback, loading);
  }
  saveSetAndRedirectToList(setFields = null, navPath = null, serviceUrl = null, mSuccess = null, mFailed = null, loading = true) {
    this._save(serviceUrl, navPath, mSuccess, mFailed, setFields, 'list');
  }

  saveCallback(callback, serviceUrl = null, mSuccess = null, mFailed = null) {
    this._save(serviceUrl, null, mSuccess, mFailed, null, null, callback);
  }

  saveSetAndAddCallback(callback = null, setFields = null, serviceUrl = null, mSuccess = null, mFailed = null, loading = true) {
    this._save(serviceUrl, null, mSuccess, mFailed, setFields, 'new', callback, loading);
  }

  saveAndAddCallback(callback, serviceUrl = null, mSuccess = null, mFailed = null, loading = true) {
    this._save(serviceUrl, null, mSuccess, mFailed, null, 'new', callback, loading);
  }

  save(serviceUrl = null, mSuccess = null, mFailed = null) {
    this._save(serviceUrl, null, mSuccess, mFailed);
  }


  saveAndAdd(serviceUrl = null, mSuccess = null, mFailed = null) {
    this._save(serviceUrl, null, mSuccess, mFailed, null, 'new');
  }

  private _save(
    serviceUrl = null,
    navPath = null,
    mSuccess = null,
    mFailed = null,
    setFields = null,
    state = 'edit',
    callback = null,
    loading = true
  ) {
    // this.loading = true;
    // console.log(serviceUrl);
    this.insertOrUpdate(serviceUrl)
      .pipe(first())
      .subscribe(
        data => {
          this.start();
          // console.log(mSuccess);
          if (mSuccess === null) {
            mSuccess = 'Successfully Saved';
            this.alertService.success(mSuccess, true);
          } else {
            if (mSuccess !== '') {
              this.alertService.success(mSuccess, true);
            }
          }

          try {
            this.reload(setFields, data, state, callback);

          } catch (e) {
            console.log(e);
          }
          if (loading) {
            this.end();
          }
          if (navPath !== null) {
            this.router.navigate([navPath]);
          }
        },
        error => {
          this.end();
          if (mFailed === null) {
            mFailed = 'Failed to Saved';
          }
          this.alertService.fatal(error, mFailed);
        }
      );
  }

  private reload(setFields, data, state, callback) {
    if (data && setFields && setFields !== null) {
      for (const item of setFields) {
        this.field(item).setValue(data[item]);
      }
    }
    if (data && this.refreshForm) {
      for (const item of this.formFields) {
        this.field(item).setValue(data[item]);
      }
    }
    const id = this.value('id', null);

    if (this.isEdit() && id != null) {
      let index = 0;
      for (const item of this.list) {
        if (item.id === id) {
          break;
        }
        index++;
      }
      this.list.splice(index, 1, this.form.value);
      this.active = this.form.value;
    } else {
      if (!this.refreshForm && data) {
        this.set('id', data['id']);
      }
      if (this.list === null) {
        this.list = [];
      }
      this.active = this.form.value;
      this.list.push(this.form.value);
    }
    this.state = state;
    if (this.isNew()) {
      this.newform();
    }

    if (this.refreshList) {
      try {
        this.reloadList();
      } catch (e) { }

    } // FIXME hack since data table doesn't update

    if (callback !== null) {
      //  console.log(data);
      callback(data);
    }

  }

  reset() {
    // console.log('RESET');
    Object.keys(this.form.controls).forEach((name) => {
      this.form.controls[name].setErrors(null);
    });
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
    this.form.reset();
    //  this.form.markAsUntouched();
    //  this.form.markAsPristine();
    this.state = 'new';
    // let control: AbstractControl = null;
    // this.form.reset();
    // this.form.markAsUntouched();
  }

  cancel(navPath = null) {
    this.reset();
    this.state = 'list';
    // console.log(navPath);
    if (navPath !== null) {
      this.router.navigate([navPath]);
    }
  }

  get(servicePath: string, paramStr = null, mFailed = null) {
    const options = JsonRequest.getHeader(paramStr);
    return this.http.get<any>(`${Config.apiUrl}/${servicePath}`, { headers: options.headers, params: options.params })
      .pipe(map(data => {
        return data;
      }, error => {
        this.end();
        if (mFailed === null) {
          mFailed = 'Failed to Select';
        }
        this.alertService.fatal(error, mFailed);
      }));
  }

  getParam(property, type) {
    let val;
    this.route.params.subscribe(params => {
      if (type === 'number') {
        val = +params[property]; // (+) converts string 'id' to a number
      } else {
        val = params[property];
      }
      return val;
    });
  }

  private getById(id: any, mFailed = null) {
    const options = JsonRequest.getHeader('');
    this.start();
    return this.http.get<any>(`${Config.apiUrl}/${this.url}/${id}`, { headers: options.headers, params: options.params })
      .pipe(map(data => {
        this.end();
        this.state = 'edit';
        return data;
      }, (error: any) => {
        this.end();
        if (mFailed === null) {
          mFailed = 'Failed to Delete';
        }
        this.alertService.fatal(error, mFailed);
      }));
  }

  private insertOrUpdate(serviceUrl = null) {
    if (this.form.invalid) {
      alert('Validation error'); // change with proper message
      return;
    }
    this.start();
    if (serviceUrl == null) {
      serviceUrl = this.url;
    }
    // console.log(this.form.value);
    const options = JsonRequest.getHeader('');
    if (this.isNew()) {
      return this.http.post(`${Config.apiUrl}/${serviceUrl}`, this.form.value, { headers: options.headers, params: options.params });
    } else {
      return this.http.put(`${Config.apiUrl}/${serviceUrl}`, this.form.value, { headers: options.headers, params: options.params });
    }
  }

  public fileUpload(serviceUrl = null, formData: FormData) {
    // this.start();
    if (serviceUrl == null) {
      serviceUrl = this.url;
    }
    // console.log(this.form.value);
    const options = JsonRequest.getHeader('', true, 'multipart/form-data');
    return this.http.post<any>(`${Config.apiUrl}/${serviceUrl}`, formData, {
      headers: options.headers, params: options.params
    });
  }

  private getPage(servicePath, params) {
    const paramStr =
      params ?
        `order=${params.direction}&sort=${params.active}&size=${params.size}&page=${params.pageIndex}&filter=${params.filter}` : '';
    const options = JsonRequest.getHeader(paramStr);
    return this.http.get<any>(`${Config.apiUrl}/${servicePath}`,
      {
        headers: options.headers,
        params: options.params,
        observe: 'response'
      }
    ).pipe(map(resp => {
      this.total = resp.headers.get('total');
      return (resp.body);
    }));
  }

  // navigate(navPath) {
  //   this.router.navigate([navPath], {
  //     queryParams: { 'url': this.returnUrl }// , fragment: 'hashLink'
  //   });
  // }

  // State methods
  setState(s: string) {
    this.state = s;
  }

  isList() {
    return this.state === 'list';
  }

  isForm() {
    return this.isNew() || this.isEdit();
  }

  isNew() {
    return this.state === 'new';
  }

  isEdit() {
    return this.state === 'edit';
  }

  error(name: string | number) {
    const field = this.form.controls[name];
    const s =
      field.hasError('required') ?
        'You must enter a value' :
        field.hasError('email') ?
          'Invalid email' :
          field.hasError('minlength') ?
            'Value is too Short' :
            field.hasError('maxlength') ?
              'Value is too Long' :
              field.hasError('pattern') ?
                'Value is Invalid' : '';
    // field.hasError('notEqual') ? 'notEqual' + name : '';
    // console.log('ddd=' + field.errors);
    return s;
  }

  field(name: string) {
    return this.form.controls[name];
  }

  set(name, value) {
    return this.field(name)?.setValue(value);
  }

  value(name, defaultValue = null) {
    const f = this.field(name);
    if (!f || f === null) {
      return defaultValue;
    }
    const val = f.value;
    if (!(val) || val === null) {
      return defaultValue;
    }
    return val;
  }

  // Validation methods
  required(name) {
    const field = this.form.controls[name];
    return (this.form.touched || this.form.dirty) && (field.errors !== null) ? field.errors.required : false;
  }
  email(name) {
    const field = this.form.controls[name];
    return (this.form.touched || this.form.dirty) && (field.errors !== null) ? field.errors.email : false;
  }
  pattern(name) {
    const field = this.form.controls[name];
    return (this.form.touched || this.form.dirty) && (field.errors !== null) ? field.errors.pattern : false;
  }

  min(name) {
    const field = this.form.controls[name];
    return (this.form.touched || this.form.dirty) && (field.errors !== null) ? field.errors.minLength : false;
  }

  max(name) {
    const field = this.form.controls[name];
    return (this.form.touched || this.form.dirty) && (field.errors !== null) ? field.errors.maxLength : false;
  }

  compose(name) {
    const field = this.form.controls[name];
    return (this.form.touched || this.form.dirty) && (field.errors !== null) ? field.errors.compose : false;
  }

  match(name) {
    const field = this.form.controls[name];
    return (this.form.touched || this.form.dirty) && (field.errors !== null) ? field.errors.match : false;
  }

  valid(name = null) {
    if (name !== null) {
      const field = this.form.controls[name];
      return ((this.form.touched || this.form.dirty) && (field.errors !== null)) ? false : true;
    }
    return this.form.valid;
  }

  invalid(name = null) {
    // console.log(this.form.errors);
    if (name !== null) {
      const field = this.form.controls[name];
      return (this.form.touched || this.form.dirty) && (field.errors !== null) ? true : false;
    } else {
      // console.log(this.form.invalid);
      return this.form.invalid || this.form.pristine;
    }
  }



  openSnackBar(s) {
    s = (s === undefined || typeof s !== 'object') ? {
      type: 'simple',
      message: 'No Relation Found!',
      action: 'CLOSE',
      duration: 3
    } : s;

    s.duration = s.duration === undefined ? 2000 : s.duration;
    s.message = s.message === undefined ? 'Message' : s.message;
    s.action = s.action === undefined ? 'action' : s.action;

    if (s.type === 'custom') {
      this._snackBar.openFromComponent(s.component, {
        duration: s.duration * 1000,
      });
    } else {
      this._snackBar.open(s.message, s.action, {
        duration: s.duration
      });
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class ViewLogin {
  // http: HttpClient;
  // url: string;
  loginAttempts = 0;
  form: FormGroup;
  returnUrl = '/';

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    this.returnUrl = this.route.snapshot.queryParams['url'] || '/';
    //console.log(this.returnUrl);
    this.route.queryParams.subscribe(params => {
      // console.log('here test');
      // console.log(params);
      if (params.token) {
        // console.log('passing');
        this.msaddlogin(params.token);
        // if(this.msaddlogin(params.token))
        // {
        //  console.log('sdfghsdzrg');
        //   window.location.reload();
        // }
      }
    });

    Config.isLoggedIn = false;
    this.loginAttempts = 0;
  }

  initForm(form: FormGroup) {
    // this.url = url;
    this.form = form;
    // if (this.authenticationService.isUserLoggedIn()) {
    //   this.router.navigate([this.route.snapshot.queryParams['url'] || '/']);
    // }
  }

  loginRedirect(mSuccess = null, mFailed = null) {
    Config.loading = true;
    if (this.form.invalid) {
      return;
    }
    this.login(
      this.form.get('username').value,
      this.form.get('password').value
    ).pipe(first())
      .subscribe(
        data => {
          // console.log(data);
          Config.loading = false;
          Config.isLoggedIn = true;
          Config.pinned_proj = true;

          this.router.navigate([this.returnUrl]);
          // /android|webos|iphone|ipod/i.test(navigator.userAgent.toLowerCase()) &&
          //   console.log(window.screen.availWidth);
          if (window.screen.availWidth < environment.supportedScreensize) {
            this.alertService.error(
              'InsIQ can only be accessed as a web app.<br>The mobile version will be available soon!',
              true, 20 * 1000);
          }
        },
        error => {
          this.loginAttempts++;
          if (this.form.get('username').value === 'admin' || this.form.get('username').value === 'ADMIN') {
            this.alertService.error('Wrong password. Try again .', true, 10 * 1000);
          } else {
            this.alertService.error('Wrong password. Try again or click Forgot password to reset it.', true, 10 * 1000);
          }
          Config.loading = false;
          Config.isLoggedIn = false;
        });

    return this;
  }

  reset() {
    this.form.reset();
    this.form.markAsUntouched();
  }

  cancel() {
    this.reset();
  }

  error(name) {
    const field = this.form.controls[name];
    const s =
      field.hasError('required') ? 'You must enter a value' :
        field.hasError('email') ? 'Not a valid email' :
          field.hasError('minlength') ? 'Too Short' :
            field.hasError('maxlength') ? 'Too Long' :
              field.hasError('pattern') ? 'Invalid input' : '';
    return s;
  }

  field(name: string | number) {
    return this.form.controls[name];
  }

  required(name) {
    const field = this.form.controls[name];
    return (this.form.touched || this.form.dirty) && (field.errors !== null) ? field.errors.required : false;
  }

  valid(name = null) {
    if (name !== null) {
      const field = this.form.controls[name];
      return ((this.form.touched || this.form.dirty) && (field.errors !== null)) ? false : true;
    }
    return this.form.valid;
  }

  invalid(name = null) {
    // console.log(this.form.errors);
    if (name !== null) {
      const field = this.form.controls[name];
      return (this.form.touched || this.form.dirty) && (field.errors !== null) ? true : false;
    } else {
      return this.form.invalid;
    }
  }


  msaddlogin(token) {
    // console.log('test print'+token);
    localStorage.setItem('currentUser', token);
    localStorage.setItem('useris', 'aduser');
    Config.isLoggedIn = true;
    Config.user = JSON.parse(token);
    Config.loading = false;

    this.router.navigate([this.returnUrl]);

    // /android|webos|iphone|ipod/i.test(navigator.userAgent.toLowerCase()) &&
    //  if (window.screen.availWidth < 768) {
    //    this.alertService.error(
    //      'devToM can only be accessed as a web app.<br>The mobile version will be available soon!',
    //      true, 20 * 1000);
    //  }
    return true;
  }
  login(username: string, password: string) {
    //    console.log(username, password);
    const header = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa('clientIdPassword' + ':' + 'secret'))
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const myEncodedQueryParamValue = password.replace("+", "%2B");

    const params = new HttpParams({
      fromString: `username=${username}&password=${encodeURIComponent(myEncodedQueryParamValue)}&grant_type=password`
    });
    return this.http.post<any>(`${Config.baseUrl}/oauth/token`, params, { headers: header })
      .pipe(map(user => {
        // console.log(user);
        if (user && user.access_token) {
          user.username = username;
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('useris', 'devtom');
          Config.isLoggedIn = true;
          Config.user = user;

          // localStorage.setItem('currentProfile', JSON.stringify(user));
          // this.http.get(`${Config.baseUrl}/user/username/` + username).subscribe(res => {
          //   console.log(res);
          //   // Config.currentUser = res;
          //   // this.view.set('projectOwner', this.config.currentUser);
          //  });
        } else {
          this.loginAttempts++;
        }
        return user;
      }));
  }
}

export class FormDefault {
  keys: any;
  values: any;
  constructor(keys: any, values: any) {
    this.keys = keys;
    this.values = values;
  }
}
