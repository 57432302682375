import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Config, View } from '../../config';
import { environment } from 'src/environments/environment';
import { CustomThemeService } from 'src/app/custom-theme.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  captcha = false;
  captchaKey = environment.captchaKey;
  isAdminUrl = false;

  url = 'sendemail/forget';
  constructor(public view: View, private fb: FormBuilder, private router: Router, public config: Config, public customThemeService: CustomThemeService,
  ) {
    this.captcha = false;
    this.view.initForm(this.url, this.getNewForm());
  }

  ngOnInit() {
    document.querySelector('body').classList.add('xsEnabled');
    document.querySelector('html').classList.add('xsEnabled');
  }

  ngOnDestroy() {
    document.querySelector('body').classList.remove('xsEnabled');
    document.querySelector('html').classList.remove('xsEnabled');
  }

  getNewForm() {
    return this.fb.group({
      loginKey: ['', Validators.compose([
        Validators.required
      ])],
    });
  }
  // Validators.pattern(AppPattern.EMAIL)
  onSubmit() {
    this.view.saveRedirect(
      `${this.url}`,
      '/login',
      'If there is an account associated with ' + this.view.value('loginKey') + ' you will receive an email with a link to reset your password',
      'Failed to Reset Password'
    );
  }
  toggleDev2MOverview() {
    //this.config.dev2MOverview = !this.config.dev2MOverview;
    window.open("https://www.insiq.ai/", "_blank");

  }
  btnStatus() {
    if (this.view.invalid() || !this.captchaValid()) {
      return '';
    }
    else {
      return 'primary';
    }
  }
  showResponse(event) {
    this.captcha = true;
  }

  captchaValid() {
    return (this.captcha);
  }
}
