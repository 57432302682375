import {
  OnInit,
  Component,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
} from '@angular/router';
import {
  Validators,
  FormBuilder,
  FormGroup
} from '@angular/forms';
import {
  MatTable,
  MatTableDataSource
} from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';

import * as _moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { AlertService } from '../../../_services';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';

import {
  View,
  Config,
  FormDefault,
  AppPattern
} from '../../../config/global.config';
import {
  WarningalertsComponent
} from '../../warningalerts/warningalerts.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyErrorStateMatcher } from 'src/app/_directives/validator.directive';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
interface Currency {
  value: string;
  viewValue: string;
}

const ELEMENT_DATA: any[] = [
  {},
];

@Component({
  selector: 'app-manageremail',
  templateUrl: './manageremail.component.html',
  styleUrls: ['./manageremail.component.scss']
})
export class ManageremailComponent implements OnInit {
  matcher = new MyErrorStateMatcher();


  public view: View;


  adminEmails;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  selected: number;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  mailStat = false;
  constructor(
    public router: Router,
    public config: Config,
    public fb: FormBuilder,
    public http: HttpClient,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    this.view = new View(http, router, route, alertService);


    // this.view.initForm('manageadmin', this.getNewForm(), this.getNewDefault(), true); // need to change
    //  this.view.refreshList = false;
    // this.pricingview.initForm('planpricing',this.getPriceForm());
    // this.view.controls['startdate'].setValue(this.view.value('startdate'));
  }

  omit_special_char(event, searchVal, fl = 1, commonflag = false) {
    //console.log('hereee')
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    // console.log(k);
    if (k === 44 || k === 59 || k === 37 || k === 38 || k === 43) {
      event.preventDefault();
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    this.mailStat = emailRegex.test(searchVal);


    return true;
  }




  // ngAfterViewInit() {
  //   this.view.initPage(this.paginator, this.sort);
  //   // this.featureDataTable = new MatTableDataSource(this.getFeatureDataTable());
  // }

  ngOnInit() {

    // this.dataSource.paginator = this.paginator;
    // // this.view.initForm('plan', this.getNewForm(),null,false);
    // this.view.initForm('manageadmin', this.getNewForm(), this.getNewDefault(), true); // need to change

    this.view.get(`admin/user/manageremail`).subscribe(res => {
      // console.log(res);
      this.adminEmails = res;
    });
    //  this.pricingview.initForm('planpricing',this.getPriceForm());
  }





  getIndex(i: number) {
    let j: number;
    j = i + 1;
    return j;
  }

  openSnackBar(s: any) {
    s = (s === undefined || typeof s !== 'object') ? {
      duration: 3,
      type: 'simple',
      action: 'CLOSE',
      message: 'No More Features Available!'
    } : s;

    s.duration = s.duration === undefined ? 2000 : s.duration;
    s.message = s.message === undefined ? 'InsIQ Message' : s.message;
    s.action = s.action === undefined ? 'ok' : s.action;

    if (s.type === 'custom') {
      this._snackBar.openFromComponent(s.component, {
        duration: s.duration * 1000,
        panelClass: 'InsIQ'
      });
    } else {
      this._snackBar.open(s.message, s.action, {
        duration: s.duration,
        panelClass: 'InsIQ'
      });
    }
  }



  deleteAdmin(row) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to delete this Admin ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    // console.log(this.chartCode);
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        Config.loading = true;

        this.view.get('admin/user/deletemanageremail/' + row.id).subscribe(res => {
          this.adminEmails = res;
          Config.loading = false;

          // this.countryList=res;
          //  console.log(res);

        });
      }
    });
  }




  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cancel() { }





  validateUser(searchVal) {
    Config.loading = true;
    (<HTMLInputElement>document.getElementById('newHero')).value = '';

    this.view.get('admin/user/newmanageremail/' + searchVal).subscribe(res => {
      this.adminEmails = res;
      Config.loading = false;

      // this.countryList=res;
      //  console.log(res);

    });
  }


}
