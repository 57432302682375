import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warningalerts',
  templateUrl: './warningalerts.component.html',
  styleUrls: ['./warningalerts.component.scss']
})
export class WarningalertsComponent implements OnInit {
  isCancelable = true;
  constructor(
    public dialogRef: MatDialogRef<WarningalertsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  close() {
    this.dialogRef.close({ data: 'close' });
  }

  ngOnInit(): void {
    //console.log(this.data.btnflg);
    if (this.data.btnflg != undefined) {
      //console.log('entreer');
      this.isCancelable = this.data.btnflg;
    } else {
      this.isCancelable = true;
    }
  }
  cancelAction() {
    this.dialogRef.close({ data: 'close' });
  }
  saveAction() {
    this.dialogRef.close({ data: 'confirm' });

  }
}
