/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

export class Teamdetails {
  teamId: string;
  displayName: string;
  description: string;
}


