import {
  Input,
  Directive,
  ElementRef,
  HostListener
} from '@angular/core';

@Directive({
  selector: '[appFcUi]'
})
export class FcUiDirective {
  @Input() appFcUi: any;

  constructor(private el: ElementRef) { }

  @HostListener('focus') onFocus() {
    this.el.nativeElement.closest('.fc-group').classList.add('focus');
  }
  @HostListener('blur') onBlur() {
    this.el.nativeElement.closest('.fc-group').classList.remove('focus');
  }
}
