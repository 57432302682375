import { AfterContentInit, Component, ContentChildren, HostBinding, Input, QueryList } from '@angular/core';
import { TabComponent } from './tab.component';

type TabType = 'horizontal' | 'vertical' | 'h' | 'v';

@Component({
  selector: 'nitro-tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {
  @Input() type: TabType = 'horizontal';
  @HostBinding('class') class = 'nitro-tabs';
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  constructor() {
    // console.log(this.tabs);
  }

  ngAfterContentInit() {
    // console.log(this.tabs);
    const activeTabs = this.tabs.filter(tab => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach(t => (t.active = false));
    tab.active = true;
  }

  isVertical() {
    return this.type === 'v' || this.type === 'vertical';
  }
}
