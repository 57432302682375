import {
  HttpClient,
  HttpParams,
  HttpHeaders
} from '@angular/common/http';
import { Config } from '../config';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private router: Router,
    private http: HttpClient,
  ) { }

  login(username: string, password: string) {
    // console.log(username, password);
    const header = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(`${'clientIdPassword'}:${'secret'}`))
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams({
      fromString: `username=${username}&password=${password}&grant_type=password`
    });

    return this.http.post<any>(`${Config.baseUrl}/oauth/token`, params, { headers: header })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          Config.isLoggedIn = true;
          Config.user = user;
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out

    localStorage.removeItem('currentUser');
   

    Config.isProjectSelected = false;
    Config.isLoggedIn = false;
    Config.user = null;
//console.log(localStorage.getItem('userint') );
    if (localStorage.getItem('userint') === 'GOOGLE') {
      localStorage.getItem('userint')
//console.log('kkkkk');
     window.location.href = Config.GOOGLELOGOUT_URL;
    }
    else{
          this.router.navigate(['/login']);

    } 
    localStorage.removeItem('userint');
    localStorage.removeItem('useris');
    localStorage.removeItem('currentUserStore');
    document.cookie = "JSESSIONID=; domain=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  isUserLoggedIn() {
    const sessionUser = JSON.parse(localStorage.getItem('currentUser'));
    // if (sessionUser && sessionUser.access_token) {
    //   return true;
    // } else {
    //   return false;
    // }
    return sessionUser && sessionUser.access_token;
  }
}
