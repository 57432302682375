import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Config } from '../../config';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  layout: TemplateRef<any>;

  @ViewChild('terms', { static: true })
  public termsTemplate: TemplateRef<any>;

  @ViewChild('privacyPolicy', { static: true })
  public privacyPolicyTemplate: TemplateRef<any>;

  @ViewChild('cookies', { static: true })
  public cookiesTemplate: TemplateRef<any>;

  @ViewChild('subterms', { static: true })
  public subTermsTemplate: TemplateRef<any>;

  @ViewChild('payterms', { static: true })
  public payTermsTemplate: TemplateRef<any>;

  @ViewChild('cancelterms', { static: true })
  public cancelTermsTemplate: TemplateRef<any>;
  regFlag = false;
  tandclink;
  styleFlag = true;
  constructor(
    public config: Config,
    public router: Router,
    private dialog: MatDialog
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.styleFlag = false;
        if (event.url.includes('privacy-policy')) {
          this.loadPage('privacy');
        } else if (event.url.includes('cookies-policy')) {
          this.loadPage('cookies');
        } else if (event.url.includes('subterms')) {
          this.loadPage('subterms');
        } else if (event.url.includes('payterms')) {
          this.loadPage('payterms');
        } else if (event.url.includes('cancelterms')) {
          this.loadPage('cancelterms');
        } else {
          this.loadPage('terms');
        }
      }
    });
  }
  getstyleFlag() {
    if (this.styleFlag) {
      return 'p-l-30';
    }

  }
  ngOnInit(): void {
    let data = sessionStorage.getItem('tandc');
    this.tandclink = sessionStorage.getItem('tandclink');
    // console.log(data);
    if (data == 'REG') {
      this.regFlag = true;
    }
    else {
      this.regFlag = false;
    }
    if (this.tandclink) {
      if (this.tandclink == 'privacy') {

        this.layout = this.privacyPolicyTemplate;
        sessionStorage.setItem('tandclink', '');
      }
      else if (this.tandclink == 'terms') {


        this.layout = this.termsTemplate;
        sessionStorage.setItem('tandclink', '');
      }
      else if (this.tandclink == 'subterms') {


        this.layout = this.subTermsTemplate;
        sessionStorage.setItem('tandclink', '');
      }
      else if (this.tandclink == 'payterms') {


        this.layout = this.payTermsTemplate;
        sessionStorage.setItem('tandclink', '');
      }
      else if (this.tandclink == 'cancelterms') {


        this.layout = this.cancelTermsTemplate;
        sessionStorage.setItem('tandclink', '');
      }
    }


  }
  closedialog() {
    this.dialog.closeAll();
    sessionStorage.setItem('tandc', 'CLOSEREG');
  }
  loadPage(id: string) {
    this.tandclink = id;
    // console.log(id);
    document.querySelectorAll('.menu button').forEach(el => {
      switch (id) {
        case 'privacy':
          this.layout = this.privacyPolicyTemplate;
          break;
        case 'cookies':
          this.layout = this.cookiesTemplate;
          break;
        case 'terms':
          this.layout = this.termsTemplate;
          break;
        case 'subterms':
          this.layout = this.subTermsTemplate;
          break;
        case 'payterms':
          this.layout = this.payTermsTemplate;
          break;
        case 'cancelterms':
          this.layout = this.cancelTermsTemplate;
          break;
        default:
          this.layout = this.termsTemplate;
          break;
      }

      // if (el['dataset'].menu !== id) {
      //   el.classList.remove('active');
      // } else {
      //   el.classList.add('active');
      // }
    });
  }

  // content(e, l: TemplateRef<any>) {
  //   document.querySelectorAll('.menu button').forEach(el => {
  //     el.classList.remove('active');
  //   });
  //   this.layout = l;
  //   // console.log(e);
  //   e.target.classList.add('active');
  // }
}
