import {
  OnInit,
  Output,
  Component,
  ViewChild,
  TemplateRef,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { JsonRequest } from '../../config';
import { Usage } from '../../_models/Usage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config, View } from '../../config/global.config';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { AlertService, AuthenticationService } from '../../_services/index';
import { UpgradePlanComponent } from '../upgrade-plan/upgrade-plan.component';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { CloneprojectapproveComponent } from '../cloneprojectapprove/cloneprojectapprove.component';
import { NavigationService } from 'projects/tag-cloud/src/lib/navigation-service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() linkEvent = new EventEmitter<any>();
  @ViewChild('apiDetailDialog', { static: false }) apiDetailDialog: TemplateRef<any>;
  @ViewChild('dataDetailDialog', { static: false }) dataDetailDialog: TemplateRef<any>;
  @ViewChild('userDetailDialog', { static: false }) userDetailDialog: TemplateRef<any>;

  videoId = environment.help.videoId;
  videoUrl = '//www.youtube.com/embed/';
  currentUsr: any;
  apiUsagestore: any;
  dataUsagestore: any;
  userUsagestore: any;

  cloneDialogRef: MatDialogRef<CloneprojectapproveComponent, any>;

  b2bUrl = '';
  userhistory: any = [];

  usage: {
    api: Usage;
    cloud: Usage;
  };

  constructor(
    public config: Config,
    public router: Router,
    public http: HttpClient,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    public cloneDialog: MatDialog,
    public view: View,
    private alertService: AlertService,
    private navigationService: NavigationService

  ) {
    this.config.apiUsage();
    this.config.dataUsage();
    this.config.userCount();
    this.currentUsr = JSON.parse(localStorage.getItem('currentUserStore'));
    this.apiUsagestore = localStorage.getItem('apiUsage');
    this.dataUsagestore = localStorage.getItem('dataUsage');
    this.userUsagestore = localStorage.getItem('UserCount');;
    this.usage = {
      cloud: {
        used: 2.5,
        total: 50,
        units: 'GB'
      },
      api: {
        used: 21,
        total: 100,
        units: 'nos'
      }
    };

    if (localStorage.getItem('userlist')) {
      // exist data on storage
      // so grab the storage\
      let array: any = [];
      let arraycurrent: any = [];
      array = JSON.parse(localStorage.getItem('userlist'));
      arraycurrent = JSON.parse(localStorage.getItem('currentUser'));

      const curlogin = arraycurrent.username;

      // console.log(this.config?.currentUser?.loginKey);
      // we overwrite the same arry with the data from LocalStorage
      this.userhistory = array.filter((item: { loginkey: any; }) => item.loginkey !== curlogin);
      // console.log( this.userhistory);
    }
    localStorage.removeItem('switchuser');
  }
  getCloneFlag() {
    return Config.cloneProject;
  }
  getConflen() {
    // console.log(Config.sharedData)
    return Config.sharedData.length;
  }
  cloneApprove() {

    this.view.get('project/clonelist').subscribe(res => {
      // Filter out items where projectId is null
      const filteredRes = res.filter(item => item.projectId !== null);

      console.log(filteredRes);

      if (filteredRes.length !== 0) {
        Config.cloneProject = true;
        Config.sharedData = filteredRes;
        this.cloneDialogRef = this.cloneDialog.open(CloneprojectapproveComponent, {
          width: '600px',
          maxWidth: '95vw',
          maxHeight: '95vh',
          data: filteredRes
        });
      } else {
        Config.cloneProject = false;
      }
    });
  }
  ngOnInit() {
  }
  getStatusFlag() {
    // console.log(typeof this.config.getapiUsage());
    if (typeof this.config.getapiUsage() === 'undefined' || this.config.getapiUsage() === 0.0 || this.config.getapiUsage() === '0.0') {
      // console.log("id is undefined...");
      return false;
    } else {
      return true;
    }
  }
  getUsagePercent() {
    let retVal;
    try {
      const avail = this?.config.getapiUsage() ? this.config?.getapiUsage()[0]?.monthly : this?.apiUsagestore[0]?.monthly;
      const total = this?.config.getapiUsage() ? this.config?.getapiUsage()[0].totalalloted : this?.apiUsagestore[0].totalalloted;
      retVal = avail / total;
    }
    catch (e) {
      retVal = 0.0;
    }
    return retVal;
  }

  getUserPercent() {
    let retVal;
    try {
      const avail = this?.config.getUserCount() ? this.config?.getUserCount()[0]?.totalused : this.config?.getUserCount[0]?.totalused;
      const total = this?.config.getUserCount() ? this.config?.getUserCount()[0].totaldisplay : this.config?.getUserCount[0].totaldisplay;
      retVal = avail / total;
    }
    catch (e) {
      retVal = 0.0;
    }
    return retVal;
  }


  getDataPercent() {
    let retValue = 0;
    let avail = 0;
    let total = 1;
    avail = this.config?.getdataUsage() ? this?.config?.getdataUsage()?.[0]?.usage : this?.dataUsagestore?.[0]?.usage;
    total = this.config?.getdataUsage() ? this?.config.getdataUsage()?.[0]?.total : this?.dataUsagestore?.[0]?.total;
    retValue = avail / total;
    return retValue;
  }
  viewDetails(opt: {
    title: any;
    type: string;
  }) {

    this.config.apiUsage();

    this.config.dataUsage();

    this.config.userCount();
    opt = {
      ...{
        type: 'api',
        title: 'Consumption Details'
      },
      ...opt
    };

    opt.type = opt.type.toLowerCase();

    this.dialog.open(CommonDialogComponent, {
      width: '460px',
      maxWidth: '98vw',
      minWidth: '320px',
      maxHeight: '98vh',
      panelClass: 'InsIQDialog',
      backdropClass: 'InsIQOverlay',
      data: {
        title: `${opt.title}`,
        template: this.getTemp(opt)
      }
    });
  }
  getTemp(opt) {
    if (opt.type === 'api') {
      return this.apiDetailDialog;
    }
    else if (opt.type === 'data') {
      return this.dataDetailDialog;
    }
    else if (opt.type === 'user') {
      return this.userDetailDialog
    }
  }
  sachetsubscription(type: string) {
    this.dialog.closeAll();

    if (type === 'data') {
      this.router.navigate(['/sachetsubscription/data']);
    } else if (type === 'api') {
      this.router.navigate(['/sachetsubscription/api']);
    } else if (type === 'user') {
      let userval;
      this.view.get('apiusage/usercount').subscribe(res => {

        userval = res[0];

        let sachetpurchased = userval?.sachetpurchased;
        let sachetPossible = userval?.sachetuser;

        if (sachetpurchased >= sachetPossible) {
          this.alertService.error(
            'Your Sachet Limit for Current Plan Exhausted',
            true, 20 * 1000);
        }

        else {
          this.router.navigate(['/sachetsubscription/user']);

        }
      });




    }

    localStorage.setItem('cond', 'true');
  }

  openVideoPopup(vId: string) {
    document.querySelector('.videoPopup').classList.add('open');
    if (document.querySelector('.videoPopup').classList.contains('open')) {
      this.videoUrl = this.videoUrl + vId + '?autoplay=1';
    }
  }

  closeVideo(e: any) {
    const ob = e.target || e;
    if (ob.classList.contains('open')) {
      ob.classList.remove('open');
      this.videoUrl = '//www.youtube.com/embed/';
    }
  }

  openModalB2B(ob: any, aiid: any) {
    //    this.http.get<any>(`${Config.AI_URL}/LoadUploadWidget?ukey=${aiid}&key=${environment.ai.key}`).subscribe(res => {
    //      Config.loading = false;
    //      // console.log(res);
    //      // console.log(res[0].url);
    //      this.b2bUrl = res[0].url;
    //      // this.setHasRecord();
    //    });
    //
    //    document.querySelector(ob).classList.add('open');
  }
  featureChecker(id: number) {
    return this.config.featureChecker(id);
  }
  InsIQLocal() {
    if (this.featureChecker(18)) {
      Config.loading = true;
      // console.log(window.navigator.platform);
      let osVersion;
      if (window.navigator.platform.toLowerCase().includes("mac")) {
        osVersion = "2";
      }
      else {
        osVersion = "1";
      }
      this.http.get<any>(`${Config.apiUrl}/localclient/InsIQlocalclient/${osVersion}`).subscribe(res => {
        Config.loading = false;
        if (res != null) {
          this.getFiletodwnld1(res.filepath);
        }
        else {
          this.openSnackBar({
            type: 'simple',
            duration: 3000,
            action: 'CLOSE',
            message: 'Business Intelligence File Not available at the Moment !!'
          });

        }

      });
    } else {
      // this.openSnackBar({
      //   type: 'simple',
      //   duration: 3000,
      //   action: 'CLOSE',
      //   message: 'Local Client Feature Not Available in your current plan. Please upgrade your Plan.'
      // });

      UpgradePlanComponent.prototype.title = `Upgrade your plan to get this feature`;
      UpgradePlanComponent.prototype.message = `<strong>${'Business Intelligence'}</strong> feature is not available in your current plan. Please upgrade your Plan!`;

      this.dialog.open(CommonDialogComponent, {
        width: '600px',
        maxWidth: '98vw',
        minWidth: '320px',
        maxHeight: '98vh',
        panelClass: 'InsIQDialog',
        backdropClass: 'InsIQOverlay',
        data: {
          title: `Upgrade Plan`,
          component: UpgradePlanComponent
        }
      });
    }
  }

  openSnackBar(s: any) {
    s = (s === undefined || typeof s !== 'object') ? {
      duration: 3,
      type: 'simple',
      action: 'CLOSE',
      message: 'No Relation Found!'
    } : s;

    s.duration = s.duration === undefined ? 2000 : s.duration;
    s.message = s.message === undefined ? 'InsIQ Message' : s.message;
    s.action = s.action === undefined ? 'ok' : s.action;

    if (s.type === 'custom') {
      this._snackBar.openFromComponent(s.component, {
        duration: s.duration * 1000,
        panelClass: 'InsIQ'
      });
    } else {
      this._snackBar.open(s.message, s.action, {
        duration: s.duration,
        panelClass: 'InsIQ'
      });
    }
  }

  closeModalB2B(e: any) {
    this.b2bUrl = '';
    // console.log(e.target);
    e.target.closest('.modal').classList.remove('open');
  }

  getFiletodwnld1(filename: string) {
    window.location.href = `${Config.apiUrl}/localclient/files/` + filename;
  }

  getFiletodwnld(filename: string) {
    // this.view.get('subscription/users/export/pdf').subscribe(res => {
    //   console.log(res);
    //  // this.productTypes = res;
    // });
    Config.loading = true;
    Config.setLoaderMessage('Please wait until your InsIQ Business Intelligence starts Downloading.');
    const url = `${Config.apiUrl}/localclient/files/` + filename;

    const requestOption = JsonRequest.getHeader('');
    // requestOption.responseType='blob';
    this.http.get(url, { headers: requestOption.headers, params: requestOption.params, responseType: 'arraybuffer' }).subscribe(
      (data: ArrayBuffer) => {
        const file = new Blob([data], { type: 'application/zip' });
        const fileURL = URL.createObjectURL(file);
        Config.loading = false;
        // if you want to open PDF in new tab
        // window.open(fileURL);
        const a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        //
        a.download = 'InsIQ Business Intelligence.zip';
        document.body.appendChild(a);
        a.click();
      }, (error) => {
        console.log('Something went Wrong', error);
      }
    );
  }

  toggleDev2MOverview() {
    window.open('https://www.insiq.ai', '_blank');
    // this.config.dev2MOverview = !this.config.dev2MOverview;
  }


  linkClick(s: any) {
    localStorage.setItem('projLoc', "homeUp");

    // console.log('jjjjjjjj')
    this.linkEvent.emit([s]);

    // window.location.href = '/project';
    this.navigationService.triggerOnInit();
    this.router.navigate(['/project']);
  }


  linkClick1(s: any) {
    this.linkEvent.emit([s]);
    localStorage.setItem('projLoc', "header");

    // window.location.href = '/project';
    this.navigationService.triggerOnInit();
    this.router.navigate(['/project']);
  }

  logout() {
    let array: any = [];


    array = JSON.parse(localStorage.getItem('currentUserStore'));
    //console.log(array.usertype);
    if (array?.usertype === "GOOGLE") {
      this.googleSessionKill();
    }
    else if (array?.usertype === "LIN") {
      this.linSessionKill();
    }
    this.config.setapiUsage('0.0');
    localStorage.setItem('apiUsage', this.config.getapiUsage());
    // localStorage.setItem('currentUserStore', this.config.getapiUsage());

    this.authenticationService.logout();
    // console.log(localStorage.getItem('userint'));
    // window.location.href = "https://appengine.google.com/_ah/logout?continue=https://localhost:4200/login";
    // setTimeout(() => {
    //   this.router.navigate(['/login']);
    // }, 2000);
    // let headers = new HttpHeaders({
    //   'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    // });


    localStorage.removeItem('currentUserStore');
  }


  linSessionKill() {
    const logoutLinkedin = (url) => {
      // Invisible window popup
      const win = window.open(
        url,
        '_blank',
        'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none'
      );

      setTimeout(() => {
        win.close();
      }, 3000);
    };
    logoutLinkedin('https://linkedin.com/m/logout'); //This will logs out user from linked in profile
    //document.location.href = "https://accounts.google.com/Logout?continue=https%3A%2F%2Faccounts.google.com%2FServiceLogin%3Fsacu%3D1&il=true&zx=icxpgruz0yao&continue=www.4le.com";

  }
  googleSessionKill() {
    const logoutGoogle = (url) => {
      // Invisible window popup
      const win = window.open(
        url,
        '_blank',
        'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=0, top=0, width=0, height=0, visible=none'
      );

      setTimeout(() => {
        win.close();
      }, 1000);
    };
    logoutGoogle('https://mail.google.com/mail/u/0/?logout&hl=en'); //This will logs out user from linked in profile

  }

  removlogInAs(username: any) {
    // console.log('herehjjjjjjj');
    let array: any = [];
    let arraytemp: any = [];
    let arrayfin: any = [];
    if (localStorage.getItem('userlist')) {
      // exist data on storage
      // so grab the storage
      array = JSON.parse(localStorage.getItem('userlist'));
      // we overwrite the same array with the data from LocalStorage
    }
    arraytemp = array.filter((item: { loginkey: any; }) => item.loginkey !== username);
    // console.log(array); //Watching if have some old data!

    array = arraytemp;
    // So we push the newUser , if exist something on localStorage the if before actually have the data
    // console.log(array); //Watching with new data and old data
    localStorage.setItem('userlist', JSON.stringify(array)); // and save the data

    // exist data on storage
    // so grab the storage
    arrayfin = JSON.parse(localStorage.getItem('userlist'));
    this.userhistory = arrayfin.filter((item: { loginkey: any; }) => item.loginkey !== this.config?.currentUser?.loginKey);
    // we overwrite the same array with the data from LocalStorage
  }

  switchuser(loginkey: any, loginname: any) {
    const array: any = [];
    array.push({
      'loginname': loginname,
      'loginkey': loginkey
    });
    // So we push the newUser , if exist something on localStorage the if before actually have the data
    // console.log(array); //Watching with new data and old data
    localStorage.setItem('switchuser', JSON.stringify(array));
    this.logout();
  }

  isLoggedIn() {
    return this.authenticationService.isUserLoggedIn();
  }

  msteamhelp() {
  }
}
