import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models';
import { Config, JsonRequest } from '../config';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }
    user: User[];

    // getPage(dataSource: MatTableDataSource<any>) {
    //     return JsonRequest.getPage(this.http, '/user', dataSource);
    // }

    // getPage(http: HttpClient, page: Paging, params) {
    //     return page.getPage(http, params);
    // }

    get(http: HttpClient, servicePath, paramStr) {
        return JsonRequest.get(http, servicePath, paramStr);
    }

    // getById(http, servicePath: String, id) {
    //     // return this.http.get(`${Config.apiUrl}/user/` + id);
    //     return JsonRequest.getById(http, `${servicePath}/${id}`);
    // }

    add(http, servicePath, user: User) {
        // return this.http.post(`${Config.apiUrl}/user/create`, user);
        return JsonRequest.post(http, servicePath, user);
    }

    create(user: User) {
        return this.http.post(`${Config.apiUrl}/user/create`, user);
    }

    update(user: User) {
        return this.http.put(`${Config.apiUrl}/user/` + user.id, user);
    }

    delete(id: number) {
        return this.http.delete(`${Config.apiUrl}/user/` + id);
    }
}
