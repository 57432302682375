<button mat-raised-button color="warn" class="feedbackBtn" (click)="formVisibility = !formVisibility"
  *ngIf="!this.config.isAdmin()">
  <mat-icon class="i-feedback i-1_5x i-rotate-90 m-r-5"></mat-icon>
  Feedback
</button>

<div class="feedBackForm" *ngIf="formVisibility">
  <button mat-mini-fab color="primary" (click)="formVisibility = !formVisibility">
    <mat-icon class="i-1_5x">close</mat-icon>
  </button>
  <h5 class="heading">How would you rate your<br>experience?</h5>
  <form [formGroup]="view.form" (ngSubmit)="onSubmit()">
    <div class="rating m-b-20">
      <mat-button-toggle-group formControlName="rating" aria-label="Rating" #ratingGroup="matButtonToggleGroup">
        <mat-error *ngIf="view.invalid('rating')">{{view.error('rating')}}</mat-error>
        <mat-button-toggle value="1" color="primary">
          <mat-icon>sentiment_very_dissatisfied</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="2" color="primary">
          <mat-icon>sentiment_dissatisfied</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="3" color="primary">
          <mat-icon>sentiment_neutral</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="4" color="primary">
          <mat-icon>sentiment_satisfied</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="5" color="primary">
          <mat-icon>sentiment_very_satisfied</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <!-- <div class="t-c text-warning m-y-10 bold t-capitalize">{{getRating(ratingGroup.value)}}</div>-->
    </div>

    <mat-form-field appearance="outline" class="w-100 m-b-20" floatLabel="auto">
      <textarea matInput formControlName="experience" placeholder="Tell us about your experience"></textarea>
      <mat-error *ngIf="view.invalid('experience')">{{view.error('experience')}}</mat-error>
      <!-- <mat-hint class="text-danger" *ngIf="true">Put Validation Error here</mat-hint>-->
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100 m-b-20" floatLabel="auto">
      <textarea matInput formControlName="suggestion" placeholder="Do you have any suggestions?"></textarea>
      <mat-error *ngIf="view.invalid('suggestion')">{{view.error('suggestion')}}</mat-error>
      <!-- <mat-hint class="text-danger" *ngIf="true">Put Validation Error here</mat-hint>-->
    </mat-form-field>

    <div class="d-flex">
      <button type="button" matRipple class="btn btn-lg btn-primary" (click)="formVisibility = !formVisibility">Cancel</button>
      <div class="m-x-auto"></div>
      <button matRipple class="btn btn-lg btn-primary" type="submit" [disabled]="view.invalid()">Send</button>
    </div>
  </form>
  <!-- <pre>{{feedbackForm.value | json}}</pre>-->
</div>