import {
  OnInit,
  Component,
  ViewChild,
  AfterViewInit,
  TemplateRef
} from '@angular/core';

import { JsonRequest } from '../../../config';
import { MatSort, Sort } from '@angular/material/sort';
import { ConfirmationService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../../../_services';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Config, View, AppPattern } from '../../../config/global.config';
import { WarningalertsComponent } from '../../warningalerts/warningalerts.component';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss'],
  providers: [ConfirmationService]
})
export class SubscribersComponent implements OnInit, AfterViewInit {
  pageId = 0;
  selected = 0;
  apiusage = [];
  datausage = [];
  subscriptionslist;
  isbtob = false;
  historylist: any;
  transactionlog;
  roleCode;
  finallist;
  currentplan: any;
  public view: View;
  private aiView: View;
  private resetView: View;
  sachethistorylist: any;
  refundhistorylist: any;

  disabledAgreement = false;
  private userView: View;
  manualtransactionId;
  currentUserid;
  url = 'sendemail/forget';
  usersubscriberlist: any;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatSort, { static: false }) userSort: MatSort;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) userPaginator: MatPaginator;
  @ViewChild('dialogUpload', { static: false }) dialogUpload: TemplateRef<any>;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator3') paginator3: MatPaginator;

  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  constructor(
    public router: Router,
    public config: Config,
    private fb: FormBuilder,
    public http: HttpClient,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public alertService: AlertService,
    public commonDialogRef: MatDialogRef<CommonDialogComponent>

  ) {
    this.view = new View(http, router, route, alertService);
    this.userView = new View(http, router, route, alertService);
    this.aiView = new View(http, router, route, alertService);
    this.resetView = new View(http, router, route, alertService);

    this.aiView.initForm('admin/user', this.getAiForm());
    this.resetView.initForm('sendemail/forget', this.getResetForm());

    this.view.initForm('admin/user', this.getNewForm());
    this.userView.initForm('admin/user', this.getNewForm());
    this.refreshB2B();

  }

  onSubmit() {
    this.view.form.value.isbtob = this.isbtob ? '1' : '0';

    this.view.save();
    if (this.view.isNew()) {
      this.selected = this.view.list.length;

    }
  }
  sortData() {
    const data = this.view.list.slice();
    // if (!sort.active || sort.direction === '') {
    this.view.list = data;
    //   return;
    // }

    this.view.list = data.sort((a, b) => {
      const isAsc = 'desc';

      // Move null values to the end
      if (a.lastLogin === null) return isAsc ? 1 : -1;
      if (b.lastLogin === null) return isAsc ? -1 : 1;

      // Convert date strings to Date objects for comparison
      const dateA = new Date(a.lastLogin);
      const dateB = new Date(b.lastLogin);

      if (dateA < dateB) {
        return isAsc ? -1 : 1;
      } else if (dateA > dateB) {
        return isAsc ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  b2bChange(e: { checked: boolean; }) {
    const v = (e.checked) ? 1 : 0;
    this.isbtob = e.checked;
    this.view.form.value.isbtob = v;
    // console.log(v);
    // console.log(this.isbtob);
  }

  refreshB2B() {
    this.isbtob = (this.view.form.value.isbtob === 1) ? true : false;
    // console.log(this.isbtob);
  }

  getResetForm() {
    return this.fb.group({
      loginKey: ['', Validators.compose([
        Validators.required
      ])],
    });
  }

  getAiForm() {
    return this.fb.group({
      id: ['']
    });
  }

  getNewForm() {
    return this.fb.group({
      id: [''],
      aiid: [],
      isbtob: [],
      status: ['1'],
      active: ['1'],
      lastLogin: [''],
      createdAt: [''],
      remarks: [],
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(AppPattern.EMAIL)
      ])]
      // inputPwd: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  transactionDetail(row) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {

        cancelbtn: 'OK',
        title: 'Manually Updated Transaction',
        message: ' Manual Updation is permanent and cannot be undone.Make sure with the Banking Transactions before updation the transaction Manually.'
      }
    });

  }
  updateTransaction(row) {
    // console.log('WarningalertsComponent');

    this.manualtransactionId = row.id;
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        okbtn: 'Yes',
        cancelbtn: 'No',
        title: 'Manual Transaction Update Are you ABSOLUTELY SURE?',
        message: ' Manual Updation is permanent and cannot be undone.Make sure with the Banking Transactions before updation the transaction Manually.'
      }
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.dialog.open(CommonDialogComponent, {
          width: '95vw',
          maxHeight: '98vh',
          maxWidth: '1024px',
          minWidth: '1024px',
          panelClass: 'commonDialog',
          data: {
            title: 'Manual Transaction Update',
            template: this.dialogUpload
          }
        });
      }
    });
  }
  getImg(row) {
    switch (row) {
      case "APPSUMO":
        return './assets/images/appsumo.png'
        break;
      case "GOOGLE":
        return './assets/images/google.svg'
        break;
      case "LIN":
        return './assets/images/lin.png'
        break;
      case "MS":
        return './assets/images/msteams.png'
        break;
      case "PH":
        return './assets/images/pdthnt.png'
        break;
      default:
        return './assets/images/icon-insiq.png';
        break;
    }


  }
  getLabel(row) {
    switch (row) {
      case "APPSUMO":
        return 'AppSumo'
        break;
      case "GOOGLE":
        return 'Google'
        break;
      case "LIN":
        return 'LinkedIn'
        break;
      case "MS":
        return 'Microsoft'
        break;
      case "PH":
        return 'Product Hunt'
        break;
      default:
        return 'InsIQ';
        break;
    }
  }

  manualTransaction() {
    let remarks = ((document.getElementById('remarks') as HTMLInputElement).value);
    if (remarks == null || remarks == undefined || remarks == '') {
      remarks = 'Manual update';
    }
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        okbtn: 'Yes',
        cancelbtn: 'No',
        title: 'Final Confirmation. Are you ABSOLUTELY SURE?',
        message: ' Once submitted,Alteration is not allowed.'
      }
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        Config.loading = true;
        this.userView.get('transactionlog/manaulupdate/' + this.manualtransactionId + '/' + remarks).subscribe(res => {
          this.dialog.closeAll();
          this.warningDialogRef.close();
          this.userView.get('transactionlog/all/' + this.currentUserid).subscribe(res => {
            Config.loading = false;
            this.transactionlog = res;
            //console.log(this.transactionlog);
          });
        });
      }
    });

  }
  openDialog(id: any) {
    // console.log('WarningalertsComponent');
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        okbtn: 'Yes',
        cancelbtn: 'No',
        title: 'Delete Account Are you ABSOLUTELY SURE?',
        message: ' Deletion is permanent and cannot be undone. Once this account is deleted, the subscriber can no longer log into InsIQ and all their Data associated with this account will also be removed.'
      }
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.view.deleteList(id);
      }
    });
  }
  getLastLogin(row) {
    // console.log(typeof row)
    return !row?.includes("2000-01-01") ? row?.toLocaleString() : '';
  }
  getTheme(id) {
    let activeTill = this?.subscriptionslist?.find(x => x?.userId.id == id)?.activeTill;
    // console.log(); // 👉️ true
    function isInThePast(date) {
      const today = new Date();

      today.setHours(0, 0, 0, 0);

      return date < today;
    }
    // console.log(this.view.list)

    // this.view.list = this.view.list.map(item => {
    //   if (item.createdAt === null) {
    //     item.createdAt = farFutureDate;
    //   }
    //   return item;
    // });
    // return isInThePast(new Date(activeTill));



  }
  clickcheck() {
    //console.log('hhh', this.view.list)
  }
  getlist(id) {
    // let farFutureDate = '2000-01-07 16:03:46';
    // this.view.list = this.view.list.map(item => {
    //   if (item.lastLogin === null) {
    //     item.lastLogin = farFutureDate;
    //   }
    //   return item;
    // });
    return this?.subscriptionslist?.find(x => x?.userId.id == id)?.activeTill;
  }

  edit(user: { id: string; }) {
    // console.log("hereeeeeeeeeeee")
    this.pageId = 0;
    this.refreshB2B();
    this.view.edit(user);

    ;
    this.currentUserid = user.id;

    this.userView.get('admin/user/usersubscriber/' + user.id).subscribe(res => {
      this.usersubscriberlist = res;
    });

    this.userView.get('admin/user/usersubscription/' + user?.id).subscribe(res => {
      this.currentplan = res;
    });
    this.userView.get('paylog/all/' + user.id).subscribe(res => {
      this.historylist = res;
      this.historylist = new MatTableDataSource(this.historylist);
      this.historylist.paginator = this.paginator2;
    });

    this.userView.get('transactionlog/all/' + user.id).subscribe(res => {
      this.transactionlog = res;
      this.transactionlog = new MatTableDataSource(this.transactionlog);
      this.transactionlog.paginator = this.paginator1;
    });
    this.userView.get('transactionlog/refundlist/' + user.id).subscribe(res => {
      this.refundhistorylist = res;
      this.refundhistorylist = new MatTableDataSource(this.refundhistorylist);
      this.refundhistorylist.paginator = this.paginator3;
    });
    this.userView.get('paylog/sachetall/' + user.id).subscribe(res => {
      this.sachethistorylist = res;
      this.sachethistorylist = new MatTableDataSource(this.sachethistorylist);
      this.sachethistorylist.paginator = this.paginatorPageSize;
    });
    this.userView.get('apiusage/usage/' + user.id).subscribe(res => {
      this.apiusage = res;
      // console.log(this.apiusage);

      // console.log('deyyyyyy');
      // console.log(this.apiusage);
    });
    this.userView.get('apiusage/datausage/' + user.id).subscribe(res => {
      this.datausage = res;
      // console.log(this.apiusage);

      // console.log('deyyyyyy');
      // console.log(this.apiusage);
    });
  }
  extractNumericValue(originalValue) {
    // Use regular expression to extract numeric part
    const numericPart = parseFloat(originalValue.replace(/[^0-9.]/g, ''));
    return isNaN(numericPart) ? undefined : numericPart;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.transactionlog.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.refundhistorylist.filter = filterValue.trim().toLowerCase();
  }

  applyInvoiceFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.historylist.filter = filterValue.trim().toLowerCase();
  }

  applySachetFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sachethistorylist.filter = filterValue.trim().toLowerCase();
  }
  getDataPercent() {
    const avail = this.datausage[0]?.usage;
    const total = this.datausage[0]?.total;
    return avail / total;
  }
  getUsagePercent() {
    let retVal;
    try {
      const avail = this.apiusage[0]?.monthly;
      const total = this.apiusage[0]?.totalalloted;
      retVal = avail / total;
    }
    catch (e) {
      retVal = 0.0;
    }
    return retVal;
  }

  getPayMode(paymode: string) {
    let mode: string;
    if (paymode === '1') {
      mode = 'Monthly ';
    } else if (paymode === '2') {
      mode = 'Annually';
    }
    return mode;
  }

  changeCheck(event) {
    this.disabledAgreement = event.checked;
  }


  tabClick(tab: { index: number; }) {
    // console.log(tab);
    this.pageId = tab.index;
  }
  invoiceDownload(row, type) {
    // this.view.get('subscription/users/export/pdf').subscribe(res => {
    //   console.log(res);
    //  // this.productTypes = res;
    // });

    let urlpar = '';
    if (type === 1) {
      urlpar = 'export';
    } else if (type === 2) {
      urlpar = 'sachetbill';
    }

    const url = `${Config.apiUrl}/subscription/users/` + urlpar + `/pdf/` + row.id + `/` + row.userId.id;

    const requestOption = JsonRequest.getHeader('');
    // requestOption.responseType='blob';
    this.http.get(url, { headers: requestOption.headers, params: requestOption.params, responseType: 'blob' }).subscribe(
      (data: Blob) => {
        const file = new Blob([data], { type: 'application/octet-stream' });
        const fileURL = URL.createObjectURL(file);
        Config.loading = false;
        // if you want to open PDF in new tab
        // window.open(fileURL);
        const a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'InsIQInvoice.pdf';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        console.log('Something went Wrong', error);
      }
    );
  }

  aiCall() {
    this.view.save('admin/user/updateAid/');
  }
  resetPwd() {


    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        okbtn: 'Yes',
        cancelbtn: 'No',
        title: 'Reset Password Are you ABSOLUTELY SURE?',
        message: ' This action will send a reset password link to the subscriber. Proceed? '
      }
    });
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.resetView.initForm('sendemail/forget', this.getResetForm());
        this.resetView.set('loginKey', this.view.value('email'));
        this.resetView.save(
          `${this.url}`,

          '' + this.view.value('email') + ' will receive an email with a link to reset the password',
          'Failed to Reset Password'
        );

      }
    });


  }
  ngAfterViewInit() {

    this.view.initPage(this.paginator, this.sort);
    this.userView.initPage(this.userPaginator, this.userSort);
    this.userView.get('admin/user/subscriptions').subscribe(res => {
      this.subscriptionslist = res;

    });


  }

  ngOnInit() {
    this.roleCode = Config.roleCode;

  }
}
