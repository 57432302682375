import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-menu',
  templateUrl: './project-menu.component.html',
  styleUrls: ['./project-menu.component.scss']
})
export class ProjectMenuComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
