import {
  Input,
  Output,
  OnInit,
  Component,
  OnDestroy,
  ElementRef,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../config/global.config';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() loading: boolean;
  @Input() message: any;
  @Input() image: any;
  @Input() progress = 0;
  @Input() canExit = false;
  @Input() showProgress = true;
  @Input() transparentLoader: boolean;
  @Output() forceExit = new EventEmitter<any>();

  pieChart: any;
  exitFlagTimer: NodeJS.Timeout;

  exitLoader = false;

  constructor(
    public config: Config,
    public router: Router,
    public elementRef: ElementRef
  ) {
  }

  exitLoading(event: any) {
    this.config.end();
    this.forceExit.emit([event]);
    this.router.navigate([this.router.url]);
  }

  figureCallBack() {
    this.pieChart = this.elementRef.nativeElement.querySelector('svg#pie .bar');
    if (this.pieChart) {
      this.updateChart(this.pieChart);
    }
  }

  private updateChart(chart: any) {
    // console.log(chart);
    // this.progress = this.config.getLoaderPercentage();
    if (isNaN(this.progress)) {
      this.progress = 100;
    } else {
      const r = chart.getAttribute('r');
      const c = Math.PI * (r * 2);
      let p = 0;

      if (this.progress < 0) { this.progress = 0; }
      if (this.progress > 100) { this.progress = 100; }

      this.progress = Math.round(this.progress * 100) / 100;

      p = ((100 - this.progress) / 100) * c;
      chart.style.strokeDashoffset = p;

      this.elementRef.nativeElement.querySelector('.progressStatus').setAttribute('data-percentage', this.progress);
    }
  }

  ngOnInit() {
    this.progress = 0;
    this.exitLoader = false;
    this.config.setLoaderPercentage(this.progress);

    // this.image = (this.image === undefined) ? (this.showProgress ? './assets/images/devToM_ai_insights.png' : this.image) : this.image;

    this.exitFlagTimer = setInterval(() => {
      this.exitLoader = true;
    }, 30 * 1000);

    // console.log(this.image);
    // this.timer = setInterval(() => {
    //   if (this.progress <= 100) {
    //     this.progress += 5;
    //   }
    //   this.progress = (this.progress > 100) ? 100 : this.progress;
    //   this.config.loadingProgress.next(this.progress);
    // }, 300);
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    clearInterval(this.exitFlagTimer);
    this.config.setLoaderPercentage(0);
  }
}
