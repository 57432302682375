import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config, View } from 'src/app/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-paymentsucess',
  templateUrl: './paymentsucess.component.html',
  styleUrls: ['./paymentsucess.component.scss']
})
export class PaymentsucessComponent implements OnInit {
  transactionLog: any;

  constructor(private http: HttpClient, public config: Config, private route: ActivatedRoute, public router: Router, public view: View,

  ) {
    Config.loading = true;

  }


  payNowHere() {
    this.route.paramMap.subscribe(params => {
      Config.loading = true;
      if (params.get('paytype') == '3') {
        this.router.navigate(['/users']);
      }
      else {
        this.router.navigate(['/']);
      }

    });
  }
  ngBeforeViewInit(): void {
    Config.loading = true;

  }
  ngOnInit(): void {
    Config.loading = true;

    this.route.paramMap.subscribe(params => {
      Config.loading = true;
      if (params.get('paytype') == '3') {
        this.callHandleSuccessRenew(params.get('paytype'));

      }
      else {
        this.callHandleSuccess(params.get('paytype'));

      }
    });
  }

  callHandleSuccess(payType) {
    Config.loading = true;

    const serverUrl = `${environment.serverUrl}`;
    const currentUserStore = JSON.parse(localStorage.getItem('currentUserStore'));
    const endpoint = '/success/' + currentUserStore?.id + '/' + payType;

    this.http.get(serverUrl + endpoint).subscribe(
      (response) => {
        this.transactionLog = response;
        Config.loading = false; // Set loading to false after receiving the API response.
      },
      (error) => {
        console.error('Error:', error);
        Config.loading = false; // Handle errors and set loading to false.
      }
    );
  }
  callHandleSuccessRenew(payType) {
    Config.loading = true;

    const serverUrl = `${environment.serverUrl}`;
    const currentUserStore = JSON.parse(localStorage.getItem('renewUserId'));
    const endpoint = '/successrenew/' + currentUserStore + '/' + payType;

    this.http.get(serverUrl + endpoint).subscribe(
      (response) => {
        this.transactionLog = response;
        Config.loading = false; // Set loading to false after receiving the API response.
      },
      (error) => {
        console.error('Error:', error);
        Config.loading = false; // Handle errors and set loading to false.
      }
    );
  }
}
