import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormBuilder
} from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../_services';
import { Config, View } from '../../config';
import { HttpClient } from '@angular/common/http';

enum Rating {
  hate = 1,
  dislike,
  neutral,
  like,
  love
}

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  formVisibility = false;

  url = 'userfeedback';
  constructor(
    http: HttpClient,
    public view: View,
    public config: Config,
    public router: Router,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    public alertService: AlertService
  ) {
    this.view = new View(http, router, route, alertService);
    // this.feedbackForm.controls.userId.setValue(config.getUsername());
    this.view.initForm(this.url, this.getNewForm(), null, false);
    this.view.set('userId', config.getUsername());
  }

  onSubmit() {
    //  console.log(this.feedbackForm.value);
    // this.http.post('userfeedback', this.feedbackForm.value, );
    //   const options = JsonRequest.getHeader('');
    //  const serviceUrl = 'userfeedback';
    //    return this.http.post(
    //      `${Config.apiUrl}/${serviceUrl}`,
    //      this.feedbackForm.value,
    //      { headers: options.headers, params: options.params });
    //
    // console.log(msg);
    // console.log(this.view);
    // console.log(this.view.);
    //   this.view.form = this.feedbackForm;
    //  console.log(this.view.form );
    this.view.saveAndAddCallback(() => {
      this.formVisibility = false;
      this.view.initForm(this.url, this.getNewForm(), null, false);
      this.view.set('userId', this.config.getUsername());
    },this.url,'Thank you for your feedback! and we appreciate your taking the time to fill out.');
   
  }

  getRating(v: string | number) {
    return Rating[v];
  }

  getNewForm() {
    // return this.feedbackForm;
    return this.fb.group({
      id: [],
      userId: ['', Validators.required],
      rating: ['5', Validators.required],
      experience: ['', Validators.required],
      suggestion: ['', Validators.required]
    });

  }
  ngOnInit() {
  }

}
