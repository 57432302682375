import {
  Routes,
  RouterModule
} from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './_guards/index';
import { UserComponent } from './components/user/user.component';


import { ContactComponent } from './components/contact/contact.component';

import { ProfileComponent } from './components/profile/profile.component';
import { ResetpwdComponent } from './components/resetpwd/resetpwd.component';



import { UserexistComponent } from './components/userexist/userexist.component';
import { ApisachetComponent } from './components/admin/apisachet/apisachet.component';
import { KeyplayerComponent } from './components/admin/keyplayer/keyplayer.component';
import { DatasachetComponent } from './components/admin/datasachet/datasachet.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { UsersachetComponent } from './components/admin/usersachet/usersachet.component';

import { SubscribersComponent } from './components/admin/subscribers/subscribers.component';
import { RequirementComponent } from './components/admin/requirement/requirement.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { PaymentsucessComponent } from './components/paymentsucess/paymentsucess.component';
import { ManageadminComponent } from './components/admin/manageadmin/manageadmin.component';
import { InvaliddomainComponent } from './components/invaliddomain/invaliddomain.component';
import { ManageremailComponent } from './components/admin/manageremail/manageremail.component';
import { FeedbackViewComponent } from './components/admin/feedback-view/feedback-view.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AccountactivateComponent } from './components/accountactivate/accountactivate.component';
import { CancellationformComponent } from './components/cancellationform/cancellationform.component';
import { RequirementItemComponent } from './components/admin/requirementitem/requirementitem.component';
import { SachetsubscriptionComponent } from './components/sachetsubscription/sachetsubscription.component';
import { SubscriptionplanComponent } from './components/admin/subscriptionplan/subscriptionplan.component';
import { DevTomLocalClientComponent } from './components/admin/devtomlocalclient/devtomlocalclient.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'login',
  //   component: LoginComponent
  // },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'subscription',
    component: SubscriptionComponent
  },
  {
    path: 'resetpwd',
    component: ResetpwdComponent
  },
  {
    path: 'resetpassword',
    component: ResetpasswordComponent
  }, {
    path: 'invaliddomain',
    component: InvaliddomainComponent
  },
  {
    path: 'register',
    component: TermsAndConditionsComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'userexist',
    component: UserexistComponent
  },
  {
    path: 'devtomactivate',
    component: AccountactivateComponent
  },
  {
    path: 'help',
    component: TermsAndConditionsComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'privacy-policy',
    component: TermsAndConditionsComponent
  },
  {
    path: 'cookies-policy',
    component: TermsAndConditionsComponent
  },
  {
    path: 'subterms',
    component: TermsAndConditionsComponent
  }, {
    path: 'payterms',
    component: TermsAndConditionsComponent
  }, {
    path: 'cancelterms',
    component: TermsAndConditionsComponent
  },
  {
    path: 'project',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-project',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-project',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'apisachet',
    component: ApisachetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'usersachet',
    component: UsersachetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'datasachet',
    component: DatasachetComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sachetsubscription',
    component: SachetsubscriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sachetsubscription/:id',
    component: SachetsubscriptionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'paysucess/:paytype',
    component: PaymentsucessComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cancelsubscription',
    component: CancellationformComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'discovery',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'concept',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'risk-analysis',
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'keyplayer',
    component: KeyplayerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'requirement',
    component: RequirementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'requirementitem',
    component: RequirementItemComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'subscribers',
    component: SubscribersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'feedbacks',
    component: FeedbackViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'localclient',
    component: DevTomLocalClientComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manageadmin',
    component: ManageadminComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'manageremail',
    component: ManageremailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'subscriptionplan',
    component: SubscriptionplanComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  { path: '**', redirectTo: '' }
  // { path: '',   redirectTo: '/project', pathMatch: 'full' },
  // { path: '**', component: PageNotFoundComponent }
];

// .==--. PRODUCTION .--==.
// export const routing = RouterModule.forRoot(appRoutes, {
//   onSameUrlNavigation: 'reload'
//   // enableTracing: true, // DEBUG purpose
//   // useHash: true
// });

// Only for development
// export const routing = RouterModule.forRoot(appRoutes,
//   { useHash: true }
// );

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
