import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
  MatDialogRef,
  MatDialogModule,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  // fakeBackendProvider, // used to create fake backend
  JwtInterceptor,
  ErrorInterceptor
} from './_helpers/index';
import {
  UserService,
  AlertService,
  AuthenticationService
} from './_services/index';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MatRippleModule,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS
} from '@angular/material/core';
import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  InteractionType,
  BrowserCacheLocation,
  PublicClientApplication
} from "@azure/msal-browser";
import { ChartsModule } from 'ng2-charts';
import { MsalModule } from "@azure/msal-angular";

import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { CaptchaModule } from 'primeng/captcha';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { ModalModule } from './components/_modal/';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultilineModule } from "ngx-nitro-multiline";
import { AppRoutingModule } from './app-routing.module';
import { TabsModule } from 'projects/tabs/src/lib/tabs.module';
import { TagCloudModule } from 'projects/tag-cloud/src/lib/tag-cloud.module';

import { AuthGuard } from './_guards/index';
import { AppComponent } from './app.component';
import { SafePipe } from './_directives/safe.pipe';
import { AlertComponent } from './_directives/index';
import { ValidatorComponent } from './_validators/index';

import { OAuthSettings } from './components/profile/oauth';
import { CustomThemeService } from "./custom-theme.service";
import { TimeZoneService } from './_services/time-zone.service';

import { UserComponent } from './components/user/user.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FocusNextDirective } from './_directives/focus-next.directive';


import { ProfileComponent } from './components/profile/profile.component';
import { ContactComponent } from './components/contact/contact.component';
import { SupportComponent } from './components/support/support.component';
import { LoadingComponent } from './_directives/loading/loading.component';
import { SnackbarComponent } from './_helpers/snackbar/snackbar.component';
import { FeedbackComponent } from './components/feedback/feedback.component';


import { NotesDialogComponent } from './components/notesalerts/notes-dialog';
import { ResetpwdComponent } from './components/resetpwd/resetpwd.component';
import { AlertsComponent } from './components/profile/alerts/alerts.component';
import { UserexistComponent } from './components/userexist/userexist.component';


import { PatentDialogComponent } from './components/patentalerts/patent-dialog';
import { PageTemplateComponent } from './page-template/page-template.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { DocConvertorComponent } from './doc-convertor/doc-convertor.component';
import { ProjectMenuComponent } from './menu/project-menu/project-menu.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { SmartTreeComponent } from './_directives/smart-tree/smart-tree.component';

import { ConfirmationDialogComponent } from './components/alerts/confirmation-dialog';
import { MsteamhelpDialogComponent } from './components/msteamshelp/msteamhelp-dialog';
import { UpgradePlanComponent } from './components/upgrade-plan/upgrade-plan.component';
import { TermsDialogComponent } from './components/terms-dialog/terms-dialog.component';
import { TeammessageDialogComponent } from './components/teammessage/teammessage-dialog';
import { TreePreviewComponent } from './_directives/tree-preview/tree-preview.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';



import { InvaliddomainComponent } from './components/invaliddomain/invaliddomain.component';
import { AssetManagerComponent } from './_directives/asset-manager/asset-manager.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { WarningalertsComponent } from './components/warningalerts/warningalerts.component';


import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

import { AccountactivateComponent } from './components/accountactivate/accountactivate.component';
import { SachetsubscriptionComponent } from './components/sachetsubscription/sachetsubscription.component';
import { CloneprojectapproveComponent } from './components/cloneprojectapprove/cloneprojectapprove.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

// ADMIN COMPONENTS
import { CoreModule } from './core/core.module';
import { PaginatorModule } from 'primeng/paginator';
import { KeyplayerComponent } from './components/admin/keyplayer/keyplayer.component';
import { ApisachetComponent } from './components/admin/apisachet/apisachet.component';
import { AdminMenuComponent } from './components/admin/admin-menu/admin-menu.component';
import { DatasachetComponent } from './components/admin/datasachet/datasachet.component';
import { ManageadminComponent } from './components/admin/manageadmin/manageadmin.component';
import { RequirementComponent } from './components/admin/requirement/requirement.component';
import { SubscribersComponent } from './components/admin/subscribers/subscribers.component';
import { UsersachetComponent } from './components/admin/usersachet/usersachet.component';
import { PaymentsucessComponent } from './components/paymentsucess/paymentsucess.component';

import { ManageremailComponent } from './components/admin/manageremail/manageremail.component';
import { FeedbackViewComponent } from './components/admin/feedback-view/feedback-view.component';
import { CancellationformComponent } from './components/cancellationform/cancellationform.component';
import { RequirementItemComponent } from './components/admin/requirementitem/requirementitem.component';
import { SubscriptionplanComponent } from './components/admin/subscriptionplan/subscriptionplan.component';
import { DevTomLocalClientComponent } from './components/admin/devtomlocalclient/devtomlocalclient.component';
import { HtmlToMarkdownPipe } from './html-to-markdown.pipe';

export const MY_FORMATS = {
  parse: {
    dateInput: ['DD-MM-YYYY']
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD-MM-YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD-MM-YYYY',
  }
};

@NgModule({
  declarations: [
    SafePipe,
    TermsAndConditionsComponent,
    AppComponent,
    TermsAndConditionsComponent,
    UserComponent,
    AlertComponent,
    AlertsComponent,
    HeaderComponent,
    FooterComponent,
    ProfileComponent,
    ContactComponent,
    SupportComponent,
    TermsAndConditionsComponent,
    LoadingComponent,
    TermsAndConditionsComponent,
    TermsAndConditionsComponent,
    FeedbackComponent,
    SnackbarComponent,
    ResetpwdComponent,
    TermsAndConditionsComponent,
    KeyplayerComponent,
    TermsAndConditionsComponent,
    SmartTreeComponent,
    AdminMenuComponent,
    TermsAndConditionsComponent,
    ValidatorComponent,
    SubHeaderComponent,
    FocusNextDirective,
    ApisachetComponent,
    UserexistComponent,
    DatasachetComponent,
    NotesDialogComponent,
    TermsAndConditionsComponent,
    RequirementComponent,
    TreePreviewComponent,
    ProjectMenuComponent,
    SubscribersComponent,
    ManageadminComponent,
    UpgradePlanComponent,
    TermsDialogComponent,
    PatentDialogComponent,
    FeedbackViewComponent,
    AssetManagerComponent,
    TermsAndConditionsComponent,
    SubscriptionComponent,
    PageTemplateComponent,
    CommonDialogComponent,
    DocConvertorComponent,
    TermsAndConditionsComponent,
    ResetpasswordComponent,
    WarningalertsComponent,
    TermsAndConditionsComponent,
    TermsAndConditionsComponent,
    ForgotPasswordComponent,
    AccountactivateComponent,
    RequirementItemComponent,
    TermsAndConditionsComponent,
    MsteamhelpDialogComponent,
    SubscriptionplanComponent,
    DevTomLocalClientComponent,
    TeammessageDialogComponent,
    SachetsubscriptionComponent,
    TermsAndConditionsComponent,
    ConfirmationDialogComponent,
    InvaliddomainComponent,
    TermsAndConditionsComponent,
    CloneprojectapproveComponent,
    ManageremailComponent,
    TermsAndConditionsComponent,
    PaymentsucessComponent,
    UsersachetComponent,
    CancellationformComponent,
    HtmlToMarkdownPipe,
  ],
  imports: [
    CoreModule,
    TabsModule,
    FormsModule,
    CommonModule,
    MatIconModule,
    BrowserModule,
    MatMenuModule,
    MatSortModule,
    MatCardModule,
    MatTabsModule,
    MatListModule,
    MatChipsModule,
    MatBadgeModule,
    MatTableModule,
    MatRadioModule,
    MatInputModule,
    NgSelectModule,
    CdkTableModule,
    TagCloudModule,
    MultilineModule,
    MatDialogModule,
    MatButtonModule,
    MatSliderModule,
    MatSelectModule,
    MatRippleModule,
    MatDividerModule,
    MatToolbarModule,
    AppRoutingModule,
    HttpClientModule,
    MatStepperModule,
    MatSidenavModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatGridListModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    BrowserAnimationsModule,
    PaginatorModule,
    // Primeng module
    TableModule,
    ModalModule,
    ChartsModule,
    SliderModule,
    CaptchaModule,
    CalendarModule,
    DropdownModule,
    FileUploadModule,
    SplitButtonModule,
    MultiSelectModule,
    RadioButtonModule,
    SelectButtonModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    MatProgressBarModule,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        clientId: OAuthSettings.appId,

      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback: () => { },
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: undefined
    })
  ],
  exports: [
    SafePipe,
    CommonModule,
    MatDialogModule,
    TermsAndConditionsComponent
  ],
  entryComponents: [
    AppComponent,
    HeaderComponent,
    SnackbarComponent,
    NotesDialogComponent,
    SubscribersComponent,
    TermsAndConditionsComponent,
    TermsDialogComponent,
    PatentDialogComponent,
    TermsAndConditionsComponent,
    MsteamhelpDialogComponent,
    TeammessageDialogComponent,
    ConfirmationDialogComponent
  ],
  providers: [
    AuthGuard,
    UserService,
    AlertService,
    CustomThemeService,
    AuthenticationService,
    // {
    //   provide: TITLE,
    //   useValue: ''
    // },
    // {
    //   provide: MESSAGE,
    //   useValue: ''
    // },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {} // Add any data you wish to test if it is passed/used correctly
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },

    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-US'
    },
    {
      provide: 'TimeZoneService',
      useClass: TimeZoneService
    }
    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [
    AppComponent,
    SnackbarComponent
  ]
})
export class AppModule { }
