<nav class="treePreview">
  <ul class="tree">
    <li>
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/subscribers']">Subscribers</button>
    </li>
    <!-- <li>
      <button type="button" mat-stroked-button color="primary" class="curved fw" [matMenuTriggerFor]="masterData">Master Data</button>
    </li>-->
    <!-- overlapTrigger="false" -->
    <!-- <mat-menu #masterData="matMenu" xPosition="before">
      <button mat-menu-item [routerLink]="['/keyplayer']">Key player category</button>
      <button mat-menu-item [routerLink]="['/requirement']">Requirement type</button>
      <button mat-menu-item [routerLink]="['/requirementitem']">Requirement sub-type</button>
    </mat-menu> -->
    <li *ngIf="roleCode==='PADM'">
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/feedbacks']">User
        Feedback</button>
    </li>
    <li *ngIf="roleCode==='PADM'">
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/subscriptionplan']">Manage Subscriptions</button>
    </li>
    <li *ngIf="roleCode==='PADM'">
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/apisachet']">API Sachet Pack</button>
    </li>
    <li *ngIf="roleCode==='PADM'">
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/usersachet']">User Sachet Pack</button>
    </li>
    <li *ngIf="roleCode==='PADM'">
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/datasachet']">Data Sachet Pack</button>
    </li>
    <li *ngIf="roleCode==='STAF'||roleCode==='PADM'">
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/localclient']">InsIQ Business Intelligence</button>
    </li>
    <li *ngIf="roleCode==='PADM'">
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/manageadmin']">Manage Admins</button>
    </li>
    <li *ngIf="roleCode==='PADM'">
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLinkActive]="'active'"
        [routerLink]="['/manageremail']">Manager Email</button>
    </li>
    <!-- <li>
      <button type="button" mat-stroked-button color="primary" class="curved fw" [routerLink]="['/planfeatures']">Plan Features</button>
    </li>-->
  </ul>
</nav>