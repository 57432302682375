<app-page-template title="Choose Your Plan" [layout]="chooseYourPlan" [hasBackNav]="getback()"
  (closeHandler)="cancelview()" *ngIf="!isPlanSelected">
  <ng-template #chooseYourPlan>
    <!-- Monthly Plans -->
    <nitro-tabs class="InsIQNitroTabs" type="v">
      <nitro-tab [active]="isMonth" label="Monthly Plans">
        <div class="flex-xy-center" *ngIf="config?.currentUser?.pin=='USR'">
          <figure>
            <img src=" ./assets/images/infographics/no_plans_available.png" alt="No Plans Found!!!">
          </figure>

          <h4 class="h4 f-bold text-danger">
            No Active Plans at the Moment
          </h4>
          <p>
            Contact your Team Manger for more info.
          </p>
        </div>
        <ul class="plan" *ngIf="config?.currentUser?.pin!='USR' && filterdPlanMonthly">
          <li *ngFor="let plan of filterdPlanMonthly; index as i;"
            [ngClass]="{active: i===(getAvg(filterdPlanMonthly.length)-1)}">
            <!-- {{filterdPlanMonthly.length}} / {{i+1}} -->
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{plan.planId.plancode}}</mat-card-title>
                <span class="currency m-l-10"
                  *ngIf="config.currentUser?.curPriceId === plan.id&&config.currentUser?.refund==0"> (Current
                  Plan)</span>
              </mat-card-header>

              <mat-card-content>
                <div class="billType" *ngIf="getStatusflag(plan)">
                  Billed {{getPayMode(plan.paymode)}}
                </div>
                <div class="validity" *ngIf="getStatusflag(plan)">
                  <span class="days">{{getPayValidity(plan.paymode,plan.price,plan.trialdays)}} Days</span> Validity
                </div>

                <!-- Original Price -->
                <div class="pricing old" *ngIf="plan.offerStatus==1">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{plan.price}}</span>
                  <span class="per" *ngIf="plan.paymode === '1'">/mo</span>
                  <span class="per" *ngIf="plan.paymode === '2'">/yr</span>
                </div>

                <!-- Offer Price -->
                <div class="pricing" *ngIf="plan.offerStatus==1">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{plan.offerPrice}}</span>
                  <span class="per" *ngIf="plan.paymode === '1'">/mo</span>
                  <span class="per" *ngIf="plan.paymode === '2'">/yr</span>
                </div>

                <!-- TRIAL -->
                <div class="pricing" *ngIf="plan.offerStatus==2">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{plan.price}}</span>
                  <span class="per" *ngIf="plan.paymode === '1'">/mo</span>
                  <span class="per" *ngIf="plan.paymode === '2'">/yr</span>
                </div>

                <div class="offer" *ngIf="plan.offerStatus==1">
                  <small>Available</small>
                  <span>Special Offer!</span>
                </div>

                <button type="button" class="btn btn-primary" [disabled]="disableStatus(plan)"
                  (click)="selectPlan(plan)">{{getPayTxt(plan)}}</button>

                <div class="description">{{plan.planId.description}}</div>
                <div class="description">Max <b>{{plan.apiUsageMonthly}}</b> Queries per month</div>

                <div class="description" *ngIf="plan.dataUsage">InsIQ Business Intelligence <b>{{plan.dataUsage}}
                    {{plan.dataUnit}}</b> of data upload allowed</div>
                <div class="description" *ngIf="plan?.userLimit!=0">Up to <b>{{plan.userLimit}}</b> Users</div>

                <div class="features">
                  <div class="title">Features:</div>
                  <ul>
                    <li *ngFor="let features of plan.planId.planFeaturesList">
                      {{features.featureId.feature}}
                      <i class="i i-info o-70 text-accent" matTooltipPosition="below"
                        title="{{features.featureId.featureDescription}}"></i>
                    </li>
                  </ul>
                </div>
              </mat-card-content>
            </mat-card>
          </li>
          <li>
            <mat-card>
              <mat-card-header>
                <mat-card-title>Custom</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="description m-t-20">For organizations seeking enterprise level customization</div>
                <figure>
                  <img src="./assets/images/icon-insiq.png" alt="">
                </figure>
                <!-- <figure>
                  <img src="./assets/images/insiq.png" alt="">
                </figure> -->
                <div class="description m-t-20">Contact us at <a
                    href="mailto:support@devtom.ai?Subject=Custom Plan Enquiry" target="_top">support@devtom.ai</a><br>
                  for
                  a custom quote.</div>
              </mat-card-content>
            </mat-card>
          </li>
        </ul>
      </nitro-tab>

      <!-- Annual Plans -->
      <nitro-tab [active]="!isMonth" label="Annual Plans">
        <div class="flex-xy-center" *ngIf="config?.currentUser?.pin=='USR'">
          <figure>
            <img src="./assets/images/infographics/no_plans_available.png" alt="No Plans Found!!!">
          </figure>

          <h4 class="h4 f-bold text-danger">
            No Active Plans at the Moment
          </h4>
          <p>
            Contact your Team Manger for more info.
          </p>
        </div>

        <ul class="plan" *ngIf="config?.currentUser?.pin!='USR'&& filterdPlanAnnualy">
          <li *ngFor="let plan of filterdPlanAnnualy; index as i;"
            [ngClass]="{active: i===getAvg(filterdPlanAnnualy.length)}">
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{plan.planId.plancode}}</mat-card-title>
                <span class="currency m-l-10"
                  *ngIf="config.currentUser?.curPriceId === plan.id&&config.currentUser?.refund==0"> (Current
                  Plan)</span>

              </mat-card-header>
              <mat-card-content>
                <div class="billType">
                  Billed {{getPayMode(plan.paymode)}}
                </div>
                <div class="validity">
                  <span class="days">{{getPayValidity(plan.paymode,plan.price,plan.trialdays)}} Days</span> Validity
                </div>

                <!-- Original Price -->
                <div class="pricing old" *ngIf="plan.offerStatus==1">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{anualPricecalculator(plan.price)}}</span>
                  <span class="per">/mo</span>
                  <!-- <span class="per" *ngIf="plan.paymode === '2'">/yr</span> -->
                </div>

                <!-- Offer Price -->
                <div class="pricing" *ngIf="plan.offerStatus==1">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{anualPricecalculator(plan.offerPrice)}}</span>
                  <span class="per">/mo</span>
                  <!-- <span class="per" *ngIf="plan.paymode === '2'">/yr</span> -->
                </div>

                <!-- TRIAL -->
                <div class="pricing" *ngIf="plan.offerStatus==2">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{anualPricecalculator(plan.price)}}</span>
                  <span class="per">/mo</span>
                  <!-- <span class="per" *ngIf="plan.paymode === '2'">/yr</span> -->
                </div>

                <div class="offer" *ngIf="plan.offerStatus==1">
                  <small>Available</small>
                  <span>Special Offer!</span>
                </div>

                <button type="button" class="btn btn-primary" [disabled]="disableStatus(plan)"
                  (click)="selectPlan(plan)">{{getPayTxt(plan)}}</button>

                <div class="description">{{plan.planId.description}}</div>
                <div class="description">Max <b>{{plan.apiUsageAnually}}</b> Queries per Annum</div>
                <div class="description" *ngIf="plan.dataUsage">InsIQ Business Intelligence <b>{{plan.dataUsage}}
                    {{plan.dataUnit}}</b> of data upload allowed</div>
                <div class="description" *ngIf="plan?.userLimit!=0">Up to <b>{{plan.userLimit}}</b> Users</div>

                <div class="features">
                  <div class="title">Features:</div>


                  <ul>
                    <li *ngFor="let features of plan.planId.planFeaturesList">
                      {{features.featureId.feature}}
                      <i class="i i-info o-70 text-accent" matTooltipPosition="below"
                        title="{{features.featureId.featureDescription}}"></i>
                    </li>
                  </ul>

                </div>
              </mat-card-content>
            </mat-card>
          </li>
          <li>
            <mat-card>
              <mat-card-header>
                <mat-card-title>Custom</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="description m-t-20">For organizations seeking enterprise level customization</div>
                <figure>
                  <img src="./assets/images/icon-insiq.png" alt="">
                </figure>
                <!-- <figure>
                  <img src="./assets/images/insiq.png" alt="">
                </figure> -->
                <div class="description m-t-20">Contact us at
                  <a href="mailto:support@devtom.ai?Subject=Custom Plan Enquiry" target="_top">support@devtom.ai</a><br>
                  for a custom quote.
                </div>
              </mat-card-content>
            </mat-card>
          </li>
        </ul>
      </nitro-tab>
    </nitro-tabs>

  </ng-template>
</app-page-template>

<app-page-template title="Billing Your Plan" [layout]="billingYourPlan" [hasBackNav]="false" *ngIf="isPlanSelected">
  <ng-template #billingYourPlan>
    <div class="billingYourPlan">
      <div class="row">
        <div class="offset-xl-2 col-xl-7 offset-lg-1 offset-md-1 col-lg-7 col-md-22">
          <mat-card class="mat-elevation-z8">
            <mat-card-content>
              Selected Plan Details
              <div class="selectedPlan" matRipple (click)="reselectPlan()">
                <div class="dtl">
                  <div class="title">{{selectedPlan.planId.plancode}}</div>
                  <div class="validity">Billed {{getPayMode(selectedPlan.paymode)}} <span
                      class="days">{{getPayValidity(selectedPlan.paymode,selectedPlan.price,selectedPlan.trialdays)}}
                      Days</span> Validity
                  </div>
                </div>
                <div class="action">
                  <button type="button" class="btn btn-primary curved m-l-10">Change Plan</button>
                </div>
              </div>

              <div class="description">
                {{selectedPlan.planId.description}}
              </div>

              <div class="price old" *ngIf="selectedPlan.offerStatus==1">${{selectedPlan.price}}</div>
              <div class="price" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}} USD <span class="per"
                  *ngIf="selectedPlan.paymode === '1'">/month</span>
                <span class="per" *ngIf="selectedPlan.paymode === '2'">/year</span>
              </div>

              <div class="price" *ngIf="selectedPlan.offerStatus==2">{{selectedPlan.price}} USD <span class="per"
                  *ngIf="selectedPlan.paymode === '1'">/month</span>
                <span class="per" *ngIf="selectedPlan.paymode === '2'">/year</span>
              </div>

            </mat-card-content>
          </mat-card>

        </div>
        <!-- <mat-card class="mat-elevation-z8" *ngIf="waitflag">
            <mat-card-content>

              <div class="selectedPlan" matRipple>
                <div class="dtl">
                  <div class="title">Please Wait</div>
                  <div class="validity">Checkout in Progress.Do not press back button or refresh page.
                  </div>
                </div>
                <div class="action">
                  <mat-icon class="i-2x" color="primary">timer
                  </mat-icon>
                </div>
              </div>


            </mat-card-content>
          </mat-card> -->

        <!-- <pre>{{view.form.value | json}}</pre> -->
        <div class=" col-xl-13 col-lg-15" *ngIf="!checkoutFlag">
          <mat-card class="mat-elevation-z8 billingInfo">
            <mat-card-header>Billing Information</mat-card-header>
            <mat-card-content>
              <form [formGroup]="view.form" novalidate>
                <div class="row">
                  <div class="col-md-12">
                    <label for="name" class="mandatory d-block m-b-5">Name</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Name" maxlength="100" formControlName="billingName">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <label for="email" class="d-block m-b-5">Email</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Email" maxlength="100" formControlName="email">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <label for="contactNumber" class="d-block m-b-5">Contact Number</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Contact Number" maxlength="100" formControlName="phone">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                    <label for="VAT Number" class=" d-block m-b-5">VAT Number</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter VAT Number" maxlength="100" formControlName="vat">
                    </mat-form-field>
                    <label for="country" class="mandatory d-block m-b-5">Country</label>
                    <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Country" maxlength="100" formControlName="country">
                    </mat-form-field> -->

                    <!-- <p-autoComplete formControlName="country" [suggestions]="filteredCountry" styleClass="w-100"
                      (completeMethod)="filterCountry($event)" [minLength]="0" placeholder="Enter country."
                      autocomplete="off" forceSelection="true" field="name" [dropdown]="true">
                    </p-autoComplete> -->

                    <ng-select [items]="countryList" appearance="outline" #comboCountries bindValue="name"
                      bindLabel="name" labelForId="country" [virtualScroll]="true" dropdownPosition="auto"
                      placeholder="Select country" formControlName="country" (change)="filterCountry($event)"
                      (clear)="onCountryClear($event)" appendTo="body">
                      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        {{item.name}}
                      </ng-template>
                    </ng-select>
                    <label for="Region" class="mandatory d-block m-b-5"
                      *ngIf="view.value('country')=='UNITED STATES OF AMERICA'">Region</label>
                    <ng-select [items]="regionList" appearance="outline" #comboregion bindValue="name"
                      *ngIf="view.value('country')=='UNITED STATES OF AMERICA'" bindLabel="name" labelForId="region"
                      [virtualScroll]="true" dropdownPosition="auto" placeholder="Select Region"
                      formControlName="region" (change)="filterCountry($event)" (clear)="onCountryClear($event)"
                      appendTo="body">
                      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        {{item.name}}
                      </ng-template>
                    </ng-select>
                    <label for="address" class="mandatory d-block m-b-5 m-t">Zip Code</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter ZIP Code" maxlength="255" rows="7" formControlName="zip"
                        type="text">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                    <!-- <mat-error>Error Message</mat-error> -->

                    <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                        <input type="text" matInput formControlName="country" required [matAutocomplete]="autoGroup"
                          placeholder="Choose Country">
                        <mat-autocomplete #autoGroup="matAutocomplete">
                          <mat-option *ngFor="let name of countries" [value]="name">{{name}}</mat-option>
                        </mat-autocomplete>
                      </mat-form-field> -->
                  </div>
                  <div class="col-md-12">
                    <label for="Company/Organization Name" class="mandatory m-b-5">Company/Organization
                      Name</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Company/Organization Name" maxlength="100"
                        formControlName="company">
                    </mat-form-field>
                    <label for="address" class="mandatory d-block m-b-5">Address Line 1</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Address" maxlength="255" rows="7" formControlName="address">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                    <label for="address" class="mandatory d-block m-b-5">City</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter City" maxlength="255" rows="7" formControlName="city">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>

                  </div>

                </div>
                <!-- <small class="text-danger f-light info">Paid plans are only available to customers in the UK, US,
                  and EU countries.</small> -->
                <div class="t-c" *ngIf="!waitflag">
                  <button type="button" [routerLink]="['/project']" matRipple
                    class="btn btn-default btn-lg m-x-5">Back</button>
                  <button type="button" (click)="checkout(selectedPlan)" matRipple class="btn btn-primary btn-lg m-x-5"
                    [disabled]="checkoutdisablecheck()">Checkout</button>
                </div>
                <div class="t-c" *ngIf="waitflag">
                  <small class="text-accent f-light info">Check out in Progress. Please Wait.</small>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-7" *ngIf="checkoutFlag&&sachetlist?.length!=0">
          <mat-card class="mat-elevation-z8 billPayment">
            <mat-card-header>Sachet User List</mat-card-header>
            <mat-card-content>
              <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                 <mat-label>Choose an option</mat-label> 
                <mat-select>
                  <mat-option value="month" selected>Monthly Plan</mat-option>
                  <mat-option value="annual">Annual Plan</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="d-flex text-primary m-b-20 f-medium">
                <span>Switch to annual and save</span>
                <span class="m-l-auto f-bold">US$60.00/yr</span>
              </div> -->
              <!-- -->

              <div class="row">
                <!-- Assuming your HTML template is inside an Angular component -->
                <div class="col-12" *ngFor="let item of sachetlist">
                  <mat-checkbox class="subCard" [checked]="item.status === 1" (change)="handleCheckboxChange(item)">
                    <div class="h4">{{ item.teamuserId.name }}</div>
                    <div class="h5">{{ item.teamuserId.loginKey }}</div>
                    <div class="h6">{{ item.teamuserId.email }}</div>
                    <div class="h3">{{ item.price}} USD</div>
                  </mat-checkbox>
                </div>



              </div>


              <!-- <table mat-table [dataSource]="sachetlist" matSort class="w-100 InsIQTable">
                <ng-container matColumnDef="slNo">
                  <th mat-header-cell *matHeaderCellDef>Sl No</th>
                  <td mat-cell *matCellDef="let row; let i = index">{{ i + 1 }}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                  <td mat-cell *matCellDef="let row">{{row.teamuserId.name}}</td>
                </ng-container>
                <ng-container matColumnDef="loginkey">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Login Key </th>
                  <td mat-cell *matCellDef="let row">{{row.teamuserId.loginKey}}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Email </th>
                  <td mat-cell *matCellDef="let row">{{row.teamuserId.email}}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                  <td mat-cell *matCellDef="let row">{{row.price}} USD</td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th class="w-120px" mat-header-cell *matHeaderCellDef style="text-align: center;">Actve</th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox class="w-100" style="white-space: break-spaces;" [checked]="row.status === 1"
                      (change)="handleCheckboxChange(row)">
                    </mat-checkbox>
                  </td>

                </ng-container>


                <tr mat-header-row *matHeaderRowDef="['slNo','name','loginkey','email','amount','action']">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['slNo','name','loginkey','email','amount','action']">
                </tr>
              </table> -->
              <div class="total-amount h4  m-y-20">
                Total Amount<span *ngIf="countOfStatus1 !== 0">
                  ({{countOfStatus1}}*{{totalAmount/countOfStatus1}} USD)
                </span>:<span class="text-accent f-black">{{ totalAmount }} USD</span>

              </div>

              <div class="message _warning">
                <i class="i i-warning"></i>
                <span>Once you remove a user from the Sachet List, that user can no longer use InsIQ.</span>
              </div>
              <div class="t-c">
                <!-- <button type="button" (click)="backtocheckout()" matRipple class="btn btn-danger m-x-5">Cancel
                  Payment</button> -->
                <!-- <button type="button" (click)="payNowHere(selectedPlan)" matRipple class="btn btn-primary m-x-5"
                  [disabled]="!dataChanged">Update Subscription</button> -->
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6" *ngIf="checkoutFlag">
          <mat-card class="mat-elevation-z8 billPayment">
            <mat-card-header>Billing</mat-card-header>
            <mat-card-content>
              <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                 <mat-label>Choose an option</mat-label> 
                <mat-select>
                  <mat-option value="month" selected>Monthly Plan</mat-option>
                  <mat-option value="annual">Annual Plan</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="d-flex text-primary m-b-20 f-medium">
                <span>Switch to annual and save</span>
                <span class="m-l-auto f-bold">US$60.00/yr</span>
              </div> -->
              <div class="d-flex text-dark m-y-20 h4">
                <span class="f-light">Plan Amount</span>
                <!-- <span class="m-l-auto" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}}
                  USD</span> -->
                <span class="m-l-auto">{{avaTaxResponse?.actual}} USD</span>
              </div>
              <div class="d-flex text-dark m-y-20 h4">
                <span class="f-light">User Sachet<span *ngIf="countOfStatus1 !== 0">
                    ({{countOfStatus1}}*{{totalAmount/countOfStatus1}} USD)
                  </span></span>
                <!-- <span class="m-l-auto" *ngIf="selectedPlan.offerStatus==1">0
                  USD</span> -->
                <span class="m-l-auto">{{avaTaxResponse?.sachetamount}} USD</span>
              </div>
              <div class="d-flex text-dark m-y-20 h4">
                <span class="f-light">Total Tax</span>
                <!-- <span class="m-l-auto" *ngIf="selectedPlan.offerStatus==1">0
                  USD</span> -->
                <span class="m-l-auto">{{avaTaxResponse?.taxCalculated}} USD</span>
              </div>

              <hr>
              <div class="d-flex text-dark m-y-20 h4">
                <span class="f-medium">Total Amount to Pay</span>
                <!-- <span class="m-l-auto f-black" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}}
                  USD</span> -->
                <span class="m-l-auto text-accent f-black">{{avaTaxResponse?.totalAmount}} USD</span>
              </div>

              <hr>
              <mat-checkbox (change)="changeCheck($event)" class="w-100" style="white-space: break-spaces;">
                <div class="m-l-5" style="white-space: break-spaces;">By continuing, you are agreeing to our
                  <a href="javascript:;" (click)="openTnC('subterms')" class="link">Subscription terms</a> and our
                  <a href="javascript:;" (click)="openTnC('payterms')" class="link">Payment Terms.</a>
                </div>
                <div class="m-l-5" style="white-space: break-spaces;">Any requests for refunds should be made within 7
                  days of the billing date.
                </div>
                <div class="m-l-5" style="white-space: break-spaces;">You may cancel at any time prior to the next
                  billing cycle.
                </div>
              </mat-checkbox>

              <div class="message _warning">
                <i class="i i-warning"></i>
                <span>Don't refresh your page on payment submit</span>
              </div>
              <div class="t-c">
                <button type="button" (click)="backtocheckout()" matRipple class="btn btn-danger m-x-5">Cancel
                  Payment</button>
                <button type="button" (click)="payNowHere(selectedPlan)" matRipple class="btn btn-primary m-x-5"
                  [disabled]="disablecheck()">Proceed to Pay</button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

      </div>
    </div>
  </ng-template>
</app-page-template>