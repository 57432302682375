<div class="container-fluid d-flex h-100" [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <app-admin-menu *ngIf="config.isAdmin()"></app-admin-menu>

      <!-- <div class="footer t-c">
        &copy; {{config.currentYear}} InsIQ. All rights reserved
        <a href="https://www.devtom.ai" target="_blank">www.devtom.ai</a>
      </div> -->
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex pageHeader">
        <h1 class="h1 smartHead">
          <i class="i i-user"></i>
          <span>
            <b>Manage</b>
            <small>Subscriptions</small>
          </span>
        </h1>
        <div class="spacer"></div>
        <span>
          <button mat-stroked-button class="curved btn-sm m-t-15" (click)="newplanCreate(); selected=-1">New
            Plan</button>
        </span>
      </div>
      <!-- trans-light -->
      <div class="d-flex">
        <div class="spacer"></div>
        <!-- <mat-form-field>
          <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
        </mat-form-field> -->
      </div>
      <div class="mat-elevation-z8 rel">
        <!-- mat-elevation-z8 -->
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            No Records Found!!
          </div>
        </div> -->
        <table mat-table [dataSource]="view.list" matSort class="w-100 InsIQTable">
          <!-- photo Column -->
          <!-- <ng-container matColumnDef="photo">
            <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
            <td mat-cell *matCellDef="let row">
              <figure>
                <img src="assets/images/users/default.png" alt="default">
              </figure>
            </td>
          </ng-container>-->

          <!-- name Column -->
          <!-- <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
            <td mat-cell *matCellDef="let row">
              <span class="ib-m">{{row.name}}</span>
              <i *ngIf="row.new" class="ib-m i i-1_3x i-fiber_new"></i>
            </td>
          </ng-container> -->

          <ng-container matColumnDef="plancode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Name</th>
            <td mat-cell *matCellDef="let row">{{row.plancode}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Title </th>
            <td mat-cell *matCellDef="let row">{{row.title}}</td>
          </ng-container>-->
          <!-- description Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Information</th>
            <td mat-cell *matCellDef="let row">{{row.description}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
          </ng-container> -->
          <!-- <ng-container matColumnDef="startdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Active From</th>
            <td mat-cell *matCellDef="let row">{{getDate(row.startdate)}}</td>
          </ng-container>
          <ng-container matColumnDef="enddate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Till</th>
            <td mat-cell *matCellDef="let row">{{getDate(row.enddate)}}</td>
          </ng-container> -->
          <!-- <ng-container matColumnDef="planstat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="t-c">Publish / UnPublish</th>
            <td mat-cell *matCellDef="let row" class="t-c">
              <mat-slide-toggle [ngModel]="row.status === '1'? true: false" class="InsIQToggle boolToggle">
              </mat-slide-toggle>
               {{row.status}} 
            </td>
          </ng-container> -->
          <ng-container matColumnDef="action">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row; let i=index">
              <button mat-icon-button (click)="editPlan(row); selected=i" title="Edit" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
              </button>
              <button mat-icon-button (click)="deletePlan(row)" title="Delete" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-trash" aria-label="delete" color="warn"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['plancode','description','action']">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['plancode','description','action']"></tr>
        </table>
        <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid d-flex h-100" [ngClass]="view.isForm() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <button mat-icon-button class="close" color="warn" (click)="view.cancel()" title="Close"
        matTooltipPosition="below">
        <mat-icon fontSet="i" class="i-close"></mat-icon>
      </button>
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <ul class="quickyMenu">
        <li *ngFor="let row of view.list; let i = index">
          <button class="btn btn-md btn-primary lighten t-c fw t-normal" [ngClass]="{'active': selected === i}"
            matRipple (click)="editPlan(row); selected = i;">
            {{row.plancode}}
          </button>
        </li>
      </ul>
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex m-b-40">
        <h1 class="h1 smartHead">
          <i class="i" [ngClass]="{
            'i-add_circle': view.isNew(),
            'i-edit2': !view.isNew()
          }"></i>
          <span>
            <small *ngIf="view.isNew()">Add</small>
            <small *ngIf="!view.isNew()">Modify</small>
            <b>
              {{view.form.controls.plancode.value}}
              <small *ngIf="planPartial === 0"> - Plan Details</small>
              <small *ngIf="planPartial === 1"> - Plan Pricing</small>
              <small *ngIf="planPartial === 2"> - Plan Features</small>
              <small *ngIf="planPartial === 3"> - API Sachet Packs</small>
              <small *ngIf="planPartial === 4"> - Data Sachet Packs</small>
              <small *ngIf="planPartial === 5"> - User Sachet Packs</small>

            </b>
          </span>
        </h1>
        <div class="spacer"></div>
        <span>
          <button *ngIf="!view.isNew()" class="curved btn-sm m-t-15" mat-stroked-button (click)="newplanCreate()">New
            Plan</button>
        </span>
      </div>

      <mat-card class="InsIQCard mat-elevation-z8">
        <mat-tab-group (selectedTabChange)="tabClick($event)" mat-align-tabs="center" color="accent"
          [selectedIndex]="planPartial">
          <mat-tab label="Plan Details">
            <!-- {{view.form.value | json}} -->
            <form [formGroup]="view.form" (ngSubmit)="onSubmit()">
              <!-- Test print:{{view.value('category')}} -->

              <!-- class="trans-light" -->
              <div class="row">
                <div class="col-xl-8">
                  <label class="bigLabel">Type of Plan</label>


                  <div class="m-t-5 m-b-20" *ngIf="!view.isEdit()">
                    <div class="radioToggleButton m-t-15 m-b-30">
                      <label class="custom" [ngClass]="{
                        checked: (view.form.value.category==='1'||view.form.value.category===1)
                      }">
                        <input type="radio" name="category" formControlName="category" [value]='1' checked>
                        <i class="ico i i-idea"></i>
                        <div class="label">
                          <div>Trial Plan</div>
                          <!-- <div></div> -->
                        </div>
                      </label>
                      <label class="custom" [ngClass]="{
                        checked: (view.form.value.category==='2'||view.form.value.category===2)
                      }">
                        <input type="radio" name="category" formControlName="category" [value]='2'>
                        <i class="ico i i-payment"></i>
                        <div class="label">
                          <div>Paid Plan</div>
                          <!-- <div></div> -->
                        </div>
                      </label>
                    </div>


                  </div>

                  <div class="planType m-t-15 m-b-30" *ngIf="view.isEdit()">
                    <i class="ico i" [ngClass]="{
                      'i-idea': (view.form.value.category==='1'||view.form.value.category===1),
                      'i-payment': (view.form.value.category==='2'||view.form.value.category===2)
                    }"></i>
                    <div class="label" *ngIf="(view.form.value.category==='1'||view.form.value.category===1)">
                      <div>Trial</div>
                      <div>Plan</div>
                    </div>
                    <div class="label" *ngIf="(view.form.value.category==='2'||view.form.value.category===2)">
                      <div>Paid</div>
                      <div>Plan </div>
                    </div>

                  </div>

                </div>
                <div class="spacer"></div>
                <span>
                  <!-- *ngIf="!newpricingflg" -->
                  <button mat-stroked-button class="curved btn-md" color="primary" (click)="addRedumption()"
                    *ngIf="!redemption">
                    Add Redemption code
                  </button>
                  <div class="bar-T m-b-10" *ngIf="redemption">
                    <div class="col-xl-18">
                      <div class="d-flex">
                        <label class="bigLabel">Number of Codes Required</label>
                        <div class="ib-m">
                          <!-- <app-quick-help [layout]="projTitle"></app-quick-help> -->
                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input matInput id="codeno" (keypress)="onlyDecimalNumberKey($event)" maxlength="5"
                          placeholder="Number of Codes Required">
                        <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
                      </mat-form-field>
                    </div>
                    <div class="bar-T m-b-40 m-l-auto m-t-20">
                      <!--  -->

                      <button type="button" (click)="redeemCode()" [disabled]="getButton()" *ngIf="view.isEdit()"
                        mat-raised-button color="primary" #update class="curved m-x-5">Add</button>
                    </div>
                  </div>
                </span>
              </div>
              <div class="row">
                <div class="col-xl-18">
                  <div class="d-flex">
                    <label class="bigLabel">Plan Name</label>
                    <div class="ib-m">
                      <!-- <app-quick-help [layout]="projTitle"></app-quick-help> -->
                    </div>
                  </div>
                  <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                    matTooltipPosition="above">
                    <input matInput formControlName="plancode" maxlength="256"
                      placeholder="Provide a short code to easily identify your plan">
                    <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
                  </mat-form-field>
                </div>


              </div>

              <div class="d-flex">
                <label class="bigLabel">Plan Information</label>
                <!-- <app-quick-help [layout]="projDesc"></app-quick-help>-->
              </div>

              <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="description"
                  autocomplete="off" #productDescription
                  placeholder="Here you can elaborate a bit. Again, it’s not an essay! Try stating what your plan features."
                  cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                <!-- <mat-error *ngIf="view.invalid('productDescription')">{{view.error('productDescription')}}</mat-error> -->
              </mat-form-field>

              <!-- {{view.form.value | json}} -->

              <div class="bar-T m-b-10">
                <button type="button" (click)="view.cancel()" mat-raised-button
                  class="curved m-x-5 btn-cancel">Back</button>
                <button type="submit" [disabled]="view.invalid()" *ngIf="view.isNew()" mat-raised-button color="primary"
                  #create class="curved m-x-5">Create</button>
                <button type="submit" [disabled]="!view.valid()" *ngIf="view.isEdit()" mat-raised-button color="primary"
                  #update class="curved m-x-5">Update</button>
              </div>
            </form>

          </mat-tab>
          <mat-tab label="Plan Pricing" *ngIf="view.isEdit()">
            <!-- // Planpricing -->
            <!-- trans-light -->
            <div class="d-flex m-y-15">
              <!-- <div>

                <mat-card class="h-50px w-auto p-a-10" color="accent">
                  <h1 class="smartTitle h4 text-accent">


                    <span class="ib-m m-l-5">
                      <small class="ib-m">Type of Plan</small>
                      <b class="ib-m text-primary p-x-5 m-l-5" *ngIf="view.form.value.category"
                        style="border-radius: 5px;">Paid</b>
                      <b class="ib-m text-primary p-x-5 m-l-5" *ngIf="!view.form.value.category"
                        style="border-radius: 5px;">Trial</b>

                    </span>
                  </h1>
                </mat-card>
              </div> -->

              <div class="planType m-t-15 m-b-30" *ngIf="view.isEdit()">
                <i class="ico i" [ngClass]="{
                  'i-idea': (view.form.value.category==='1'||view.form.value.category===1),
                  'i-payment': (view.form.value.category==='2'||view.form.value.category===2)
                }"></i>
                <div class="label" *ngIf="(view.form.value.category==='1'||view.form.value.category===1)">
                  <div>Trial</div>
                  <div>Plan</div>
                </div>
                <div class="label" *ngIf="(view.form.value.category==='2'||view.form.value.category===2)">
                  <div>Paid</div>
                  <div>Plan </div>
                </div>
              </div>

              <div class="spacer"></div>
              <!-- <mat-form-field>
                <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
              </mat-form-field>
              <div class="spacer"></div> -->

              <span>
                <!-- *ngIf="!newpricingflg" -->
                <button mat-stroked-button class="curved btn-md" color="primary" (click)="addNewPricing()">
                  Add Pricing
                </button>
              </span>
              <!-- <span>
                <button mat-icon-button class="close" color="warn" *ngIf="newpricingflg" (click)="closenewPricing()"
                  title="Close" matTooltipPosition="below">
                  <mat-icon fontSet="i" class="i-close"></mat-icon>
                </button>
              </span>
              <button mat-stroked-button class="curved btn-sm m-t-15" *ngIf="newfeatureflg"(click)="closenewFeature()">Close Feature</button>-->
            </div>

            <!-- class="trans-light" -->
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="dsPriceListAll" matSort class="w-100 InsIQTable">
                <!-- photo Column -->
                <ng-container matColumnDef="photo">
                  <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
                  <td mat-cell *matCellDef="let row">
                    <figure>
                      <img src="assets/images/users/default.png" alt="default">
                    </figure>
                  </td>
                </ng-container>

                <ng-container matColumnDef="sl">
                  <th class="w-80px p-r-10 t-r" mat-header-cell *matHeaderCellDef mat-sort-header>Sl No.</th>
                  <td mat-cell *matCellDef="let pu ; let i = index;">{{getIndex(i)}}</td>
                </ng-container>

                <ng-container matColumnDef="mode">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Billing</th>
                  <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                    {{getPayMode(pu.paymode)}}</td>
                </ng-container>

                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Price in {{getCurrency1()}} </th>
                  <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                    {{getCurrency()}} {{pu.price}}</td>
                </ng-container>
                <ng-container matColumnDef="sdate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Active From</th>
                  <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                    {{pu.startdate | date: 'dd/MM/yyyy'}}</td>
                </ng-container>
                <ng-container matColumnDef="edate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Till</th>
                  <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                    {{pu.enddate | date: 'dd/MM/yyyy'}}</td>
                </ng-container>
                <!-- <ng-container matColumnDef="feature">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Feature</th>
                  <td mat-cell *matCellDef="let pu" title="Feature: {{pu.feature}}"
                    matTooltipPosition="below">{{pu.feature}}</td>
                </ng-container> -->
                <ng-container matColumnDef="offer">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Offer</th>
                  <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                    {{getOfferMode(pu.offerStatus)}}</td>
                </ng-container>
                <ng-container matColumnDef="offerprice">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Offer Price in {{getCurrency1()}}</th>
                  <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                    {{getCurrency()}} {{pu.offerPrice}}
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="planstatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                  <td mat-cell *matCellDef="let pu" title="Feature: {{pu.active}}" matTooltipPosition="below">{{pu.active}}</td>
                </ng-container> -->

                <ng-container matColumnDef="planstatus">
                  <th class="w-120px" mat-header-cell *matHeaderCellDef>Status</th>
                  <!-- <td mat-cell *matCellDef="let pu"> -->
                  <!-- <button mat-icon-button (click)="publishPrice(pu)" title="Publish Now"
                      matTooltipPosition="below" *ngIf="pu.active==0">
                      <mat-icon fontSet="i" fontIcon="i-publish" aria-label="edit"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="unPublishPrice(pu)" title="Un Publish" *ngIf="pu.active==1"
                      matTooltipPosition="below">
                      <mat-icon fontSet="i" fontIcon="i-unpublished" aria-label="remove" class="i-1_3x">remove_circle_outline</mat-icon>
                    </button> -->
                  <!-- {{pu | json}} -->


                  <!-- <div class="m-t-5 m-b-20">
                      <div class="radioToggleButton m-t-15 m-b-30">
                        <label class="custom" [ngClass]="{
                          checked: (pu.active==='1'||pu.active===1)
                        }">
                          <input type="radio" name="category" [value]='1' checked>
                          <i class="ico i i-idea"></i>
                          <div class="label">
                            <div>Published</div>
                          </div>
                        </label>
                        <label class="custom" [ngClass]="{
                          checked: (pu.active==='0'||pu.active===0)
                        }">
                          <input type="radio" name="category" [value]='0'>
                          <i class="ico i i-payment"></i>
                          <div class="label">
                            <div>UnPublished</div>
                          </div>
                        </label>
                      </div>


                    </div> -->
                  <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                    <div class="label f-bold" [ngClass]="{
                      'text-success': pu.active === 1,
                      'text-danger': pu.active === 0
                    }">
                      <div>{{pu.active===1 ? 'Active' : 'Inactive'}}</div>
                    </div>
                    <!-- <div class="label f-bold text-danger" *ngIf="pu.active===0">
                      <div>Inactive</div>
                    </div> -->
                    <!-- <mat-slide-toggle class="InsIQToggle boolToggle" [checked]="pu.active==1 ? true: false"
                      (change)="updatePlanStatus($event, 'pricing', pu)"></mat-slide-toggle> -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let pu">
                    <button mat-icon-button (click)="priceEdit(pu)" title="Edit" matTooltipPosition="below">
                      <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="priceDelete(pu)" title="Remove" matTooltipPosition="below">
                      <mat-icon fontSet="i" aria-label="remove" class="i-1_3x text-danger">trash
                      </mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row
                  *matHeaderRowDef="['sl','mode','price','sdate','edate','offer','offerprice','planstatus','action']">
                </tr>
                <tr mat-row
                  *matRowDef="let pu; columns: ['sl','mode','price','sdate','edate','offer','offerprice','planstatus','action']">
                </tr>

              </table>
            </div>
          </mat-tab>
          <mat-tab label="Plan Features" *ngIf="view.isEdit()">
            <div *ngIf="showFeature">
              <!-- trans-light -->
              <div class="d-flex m-y-15">
                <!-- <div class="spacer"></div>
                <mat-form-field>
                  <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
                </mat-form-field> -->
                <div class="spacer"></div>
                <span>

                  <button mat-stroked-button class="curved btn-md" (click)="addnewFeature()" color="primary">Add
                    Feature</button>
                </span>
                <!-- <span>
                  <button mat-icon-button class="close" color="warn" *ngIf="newfeatureflg" (click)="closenewFeature()"
                    title="Close" matTooltipPosition="below">
                    <mat-icon fontSet="i" class="i-close"></mat-icon>
                  </button>
                </span> -->
                <!-- <button mat-stroked-button class="curved btn-sm m-t-15" *ngIf="newfeatureflg"(click)="closenewFeature()">Close Feature</button> -->
              </div>

              <!-- trans-light -->
              <div>
                <table mat-table [dataSource]="dsFeatureList" matSort class="w-100 InsIQTable mat-elevation-z8">
                  <!-- photo Column -->
                  <ng-container matColumnDef="photo">
                    <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
                    <td mat-cell *matCellDef="let row">
                      <figure>
                        <img src="assets/images/users/default.png" alt="default">
                      </figure>
                    </td>
                  </ng-container>

                  <!-- <ng-container matColumnDef="name">
                    <th class="w-100px p-r-10 t-r" mat-header-cell *matHeaderCellDef mat-sort-header> Sl No.</th>
                    <td class="w-100px p-r-10 t-r" mat-cell *matCellDef="let pu; let i = index;">{{getIndex(i)}}</td>
                  </ng-container>-->

                  <ng-container matColumnDef="loginKey">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Feature</th>
                    <td mat-cell class="f-medium" *matCellDef="let pu" matTooltipPosition="below">
                      {{pu.featureId?.feature}}</td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                    <td mat-cell *matCellDef="let pu" matTooltipPosition="below">{{pu.featureId.featureDescription}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let pu">
                      <button mat-icon-button (click)="removeFeature(pu)" title="Remove" matTooltipPosition="below">
                        <mat-icon fontSet="i" aria-label="remove" class="i-1_3x text-danger">trash
                        </mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="['loginKey','description','action']"></tr>
                  <tr mat-row *matRowDef="let pu; columns: ['loginKey','description','action']"></tr>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="API Sachet Packs" *ngIf="view.isEdit()">
            <div>
              <label class="subtitle f-bold">List of available API packs, please select required pack to add to this
                plan.</label>

              <!-- trans-light -->
              <div class="d-flex m-y-15">
                <!-- <div class="spacer"></div>
                <mat-form-field>
                  <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
                </mat-form-field> -->
                <div class="spacer"></div>

                <!-- <button mat-icon-button class="close" color="warn" *ngIf="newfeatureflg" (click)="closenewFeature()"
                  title="Close" matTooltipPosition="below">
                  <mat-icon fontSet="i" class="i-close"></mat-icon>
                </button> -->

                <!-- <button mat-stroked-button class="curved btn-sm m-t-15" *ngIf="newfeatureflg"(click)="closenewFeature()">Close Feature</button> -->
              </div>

              <!-- trans-light -->
              <div>
                <table mat-table [dataSource]="apiSachetList" matSort class="w-100 InsIQTable">
                  <!-- photo Column -->
                  <!-- <ng-container matColumnDef="photo">
                      <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
                      <td mat-cell *matCellDef="let row">
                        <figure>
                          <img src="assets/images/users/default.png" alt="default">
                        </figure>
                      </td>
                    </ng-container>-->

                  <!-- name Column -->
                  <!-- <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                      <td mat-cell *matCellDef="let row">
                        <span class="ib-m">{{row.name}}</span>
                        <i *ngIf="row.new" class="ib-m i i-1_3x i-fiber_new"></i>
                      </td>
                    </ng-container> -->

                  <ng-container matColumnDef="sachetName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sachet Name</th>
                    <td mat-cell *matCellDef="let row">{{row.sachetName}}</td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title </th>
                      <td mat-cell *matCellDef="let row">{{row.title}}</td>
                    </ng-container>-->
                  <!-- description Column -->
                  <ng-container matColumnDef="sachetLimit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>API Limit</th>
                    <td mat-cell *matCellDef="let row">{{(row.sachetLimit)+' '+(row.dataUnit!=null?row.dataUnit:'')}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
                    <td mat-cell *matCellDef="let row">{{row.price+' '+getCurrency1()}}</td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="startdate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active From</th>
                      <td mat-cell *matCellDef="let row">{{getDate(row.startdate)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="enddate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Till</th>
                      <td mat-cell *matCellDef="let row">{{getDate(row.enddate)}}</td>
                    </ng-container> -->

                  <ng-container matColumnDef="active">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let pu">
                      <!-- <div class="label f-bold text-primary" *ngIf="pu.enabledStatus===1">
                        <div>Active</div>
                      </div> -->
                      <div class="label f-bold" [ngClass]="{
                        'text-success': pu.enabledStatus === 1,
                        'text-danger': pu.enabledStatus !== 1
                      }">
                        <div>{{pu.enabledStatus===1 ? 'Active' : 'Inactive'}}</div>
                      </div>
                      <!-- <div class="label f-bold text-danger" *ngIf="pu.enabledStatus!==1">
                        <div>Inactive</div>
                      </div> -->
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="planstat">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef>Add /<br>Remove</th>
                    <td mat-cell *matCellDef="let pu">
                      <!-- <button mat-icon-button (click)="publishPrice(pu)" title="Publish Now"
                          matTooltipPosition="below" *ngIf="pu.active==0">
                          <mat-icon fontSet="i" fontIcon="i-publish" aria-label="edit"></mat-icon>
                        </button>
                        <button mat-icon-button (click)="unPublishPrice(pu)" title="Un Publish" *ngIf="pu.active==1"
                          matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-unpublished" aria-label="remove" class="i-1_3x">remove_circle_outline</mat-icon>
                        </button> -->
                      <!-- {{pu | json}} -->
                      <!-- <mat-slide-toggle class="InsIQToggle boolToggle" [checked]="pu.enabledStatus==1 ? true: false"
                        (change)="updateSachet($event, pu)"></mat-slide-toggle> -->

                      <button mat-icon-button (click)="updateSachet(pu,1)" title="Publish Now"
                        matTooltipPosition="below" *ngIf="pu.enabledStatus!==1">
                        <mat-icon fontSet="i" aria-label="add" class="i-1_3x text-primary">
                          add_circle_outline</mat-icon>
                      </button>
                      <button mat-icon-button (click)="updateSachet(pu,2)" title="Un Publish Now"
                        matTooltipPosition="below" *ngIf="pu.enabledStatus==1">
                        <mat-icon fontSet="i" aria-label="remove" class="i-1_3x text-danger">remove_circle_outline
                        </mat-icon>
                      </button>
                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="['sachetName','sachetLimit','price','active','planstat']">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['sachetName','sachetLimit','price','active','planstat']">
                  </tr>
                </table>
                <mat-paginator [length]="view.total" [pageSize]="view.pageSize"
                  [pageSizeOptions]="view.pageSizeOptions">
                </mat-paginator>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Data Sachet Packs" *ngIf="view.isEdit()">
            <div>
              <label class="subtitle f-bold">List of available data packs, please select required pack to add to this
                plan.</label>

              <!-- trans-light -->
              <div class="d-flex m-y-15">
                <!-- <div class="spacer"></div>
                <mat-form-field>
                  <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
                </mat-form-field> -->
                <div class="spacer"></div>
                <!-- <span>
                  <button mat-stroked-button class="curved btn-md" (click)="addnewFeature()" color="primary">Add
                    Feature</button>
                </span> -->
                <!-- <span>
                  <button mat-icon-button class="close" color="warn" *ngIf="newfeatureflg" (click)="closenewFeature()"
                    title="Close" matTooltipPosition="below">
                    <mat-icon fontSet="i" class="i-close"></mat-icon>
                  </button>
                </span> -->
                <!-- <button mat-stroked-button class="curved btn-sm m-t-15" *ngIf="newfeatureflg"(click)="closenewFeature()">Close Feature</button> -->
              </div>

              <!-- trans-light -->
              <div>
                <table mat-table [dataSource]="datasachetList" matSort class="w-100 InsIQTable">
                  <!-- photo Column -->
                  <!-- <ng-container matColumnDef="photo">
                      <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
                      <td mat-cell *matCellDef="let row">
                        <figure>
                          <img src="assets/images/users/default.png" alt="default">
                        </figure>
                      </td>
                    </ng-container>-->

                  <!-- name Column -->
                  <!-- <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                      <td mat-cell *matCellDef="let row">
                        <span class="ib-m">{{row.name}}</span>
                        <i *ngIf="row.new" class="ib-m i i-1_3x i-fiber_new"></i>
                      </td>
                    </ng-container> -->

                  <ng-container matColumnDef="sachetName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sachet Name</th>
                    <td mat-cell *matCellDef="let row">{{row.sachetName}}</td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title </th>
                      <td mat-cell *matCellDef="let row">{{row.title}}</td>
                    </ng-container>-->
                  <!-- description Column -->
                  <ng-container matColumnDef="sachetLimit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Sachet Limit</th>
                    <td mat-cell *matCellDef="let row">{{row.sachetLimit+' '+(row.dataUnit!=null?row.dataUnit:'')}}</td>
                  </ng-container>
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
                    <td mat-cell *matCellDef="let row">{{row.price+' '+getCurrency1()}}</td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="startdate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active From</th>
                      <td mat-cell *matCellDef="let row">{{getDate(row.startdate)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="enddate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Till</th>
                      <td mat-cell *matCellDef="let row">{{getDate(row.enddate)}}</td>
                    </ng-container> -->

                  <ng-container matColumnDef="active">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let pu">
                      <div class="label f-bold" [ngClass]="{
                        'text-success': pu.enabledStatus === 1,
                        'text-danger': pu.enabledStatus !== 1
                      }">
                        <div>{{pu.enabledStatus===1 ? 'Active' : 'Inactive'}}</div>
                      </div>

                      <!-- <div class="label f-bold text-primary" *ngIf="pu.enabledStatus===1">
                        <div>Active</div>
                      </div>
                      <div class="label f-bold text-danger" *ngIf="pu.enabledStatus!==1">
                        <div>Inactive</div>
                      </div> -->
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="planstat">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef>Add /<br>Remove</th>
                    <td mat-cell *matCellDef="let pu">
                      <!-- <button mat-icon-button (click)="publishPrice(pu)" title="Publish Now"
                          matTooltipPosition="below" *ngIf="pu.active==0">
                          <mat-icon fontSet="i" fontIcon="i-publish" aria-label="edit"></mat-icon>
                        </button>
                        <button mat-icon-button (click)="unPublishPrice(pu)" title="Un Publish" *ngIf="pu.active==1"
                          matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-unpublished" aria-label="remove" class="i-1_3x">remove_circle_outline</mat-icon>
                        </button> -->
                      <!-- {{pu | json}} -->

                      <button mat-icon-button (click)="updateSachet(pu,1)" title="Publish Now"
                        matTooltipPosition="below" *ngIf="pu.enabledStatus!==1">
                        <mat-icon fontSet="i" aria-label="add" class="i-1_3x text-primary">add_circle_outline</mat-icon>
                      </button>
                      <button mat-icon-button (click)="updateSachet(pu,2)" title="Un Publish Now"
                        matTooltipPosition="below" *ngIf="pu.enabledStatus===1">
                        <mat-icon fontSet="i" aria-label="remove" class="i-1_3x text-danger">remove_circle_outline
                        </mat-icon>
                      </button>
                      <!-- <mat-slide-toggle class="InsIQToggle boolToggle" [checked]="pu.enabledStatus==1 ? true: false"
                        (change)="updateSachet($event, pu)"></mat-slide-toggle> -->
                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="['sachetName','sachetLimit','price','active','planstat']">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['sachetName','sachetLimit','price','active','planstat']">
                  </tr>
                </table>
                <mat-paginator [length]="view.total" [pageSize]="view.pageSize"
                  [pageSizeOptions]="view.pageSizeOptions">
                </mat-paginator>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="User Sachet Packs" *ngIf="view.isEdit()">
            <div>
              <label class="subtitle f-bold">List of available user packs, please select required pack to add to this
                plan.</label>

              <!-- trans-light -->
              <div class="d-flex m-y-15">
                <!-- <div class="spacer"></div>
                <mat-form-field>
                  <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
                </mat-form-field> -->
                <div class="spacer"></div>
                <!-- <span>
                  <button mat-stroked-button class="curved btn-md" (click)="addnewFeature()" color="primary">Add
                    Feature</button>
                </span> -->
                <!-- <span>
                  <button mat-icon-button class="close" color="warn" *ngIf="newfeatureflg" (click)="closenewFeature()"
                    title="Close" matTooltipPosition="below">
                    <mat-icon fontSet="i" class="i-close"></mat-icon>
                  </button>
                </span> -->
                <!-- <button mat-stroked-button class="curved btn-sm m-t-15" *ngIf="newfeatureflg"(click)="closenewFeature()">Close Feature</button> -->
              </div>

              <!-- trans-light -->
              <div>
                <table mat-table [dataSource]="usersachetList" matSort class="w-100 InsIQTable">
                  <!-- photo Column -->
                  <!-- <ng-container matColumnDef="photo">
                      <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
                      <td mat-cell *matCellDef="let row">
                        <figure>
                          <img src="assets/images/users/default.png" alt="default">
                        </figure>
                      </td>
                    </ng-container>-->

                  <!-- name Column -->
                  <!-- <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                      <td mat-cell *matCellDef="let row">
                        <span class="ib-m">{{row.name}}</span>
                        <i *ngIf="row.new" class="ib-m i i-1_3x i-fiber_new"></i>
                      </td>
                    </ng-container> -->

                  <ng-container matColumnDef="sachetName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sachet Name</th>
                    <td mat-cell *matCellDef="let row">{{row.sachetName}}</td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title </th>
                      <td mat-cell *matCellDef="let row">{{row.title}}</td>
                    </ng-container>-->
                  <!-- description Column -->
                  <ng-container matColumnDef="sachetLimit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Sachet Limit</th>
                    <td mat-cell *matCellDef="let row">{{row.sachetLimit+' '+(row.dataUnit!=null?row.dataUnit:'')}}</td>
                  </ng-container>
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
                    <td mat-cell *matCellDef="let row">{{row.price+' '+getCurrency1()}}</td>
                  </ng-container>

                  <ng-container matColumnDef="limit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Maximum Limit Allowed</th>
                    <td mat-cell *matCellDef="let row" class="m-t-20 w-100px"> <mat-form-field class="fw flat"
                        floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
                        <input matInput (keypress)="onlyDecimalNumberKey($event)" maxlength="9"
                          placeholder="Provide Maximum Limit" formControlName="price" maxlength="256">
                        <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
                      </mat-form-field></td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="startdate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active From</th>
                      <td mat-cell *matCellDef="let row">{{getDate(row.startdate)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="enddate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Till</th>
                      <td mat-cell *matCellDef="let row">{{getDate(row.enddate)}}</td>
                    </ng-container> -->

                  <ng-container matColumnDef="active">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let pu">
                      <div class="label f-bold" [ngClass]="{
                        'text-success': pu.enabledStatus === 1,
                        'text-danger': pu.enabledStatus !== 1
                      }">
                        <div>{{pu.enabledStatus===1 ? 'Active' : 'Inactive'}}</div>
                      </div>

                      <!-- <div class="label f-bold text-primary" *ngIf="pu.enabledStatus===1">
                        <div>Active</div>
                      </div>
                      <div class="label f-bold text-danger" *ngIf="pu.enabledStatus!==1">
                        <div>Inactive</div>
                      </div> -->
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="planstat">
                    <th class="w-120px" mat-header-cell *matHeaderCellDef>Add /<br>Remove</th>
                    <td mat-cell *matCellDef="let pu">
                      <!-- <button mat-icon-button (click)="publishPrice(pu)" title="Publish Now"
                          matTooltipPosition="below" *ngIf="pu.active==0">
                          <mat-icon fontSet="i" fontIcon="i-publish" aria-label="edit"></mat-icon>
                        </button>
                        <button mat-icon-button (click)="unPublishPrice(pu)" title="Un Publish" *ngIf="pu.active==1"
                          matTooltipPosition="below">
                          <mat-icon fontSet="i" fontIcon="i-unpublished" aria-label="remove" class="i-1_3x">remove_circle_outline</mat-icon>
                        </button> -->
                      <!-- {{pu | json}} -->

                      <button mat-icon-button (click)="updateSachet(pu,1)" title="Publish Now"
                        matTooltipPosition="below" *ngIf="pu.enabledStatus!==1">
                        <mat-icon fontSet="i" aria-label="add" class="i-1_3x text-primary">add_circle_outline</mat-icon>
                      </button>
                      <button mat-icon-button (click)="updateSachet(pu,2)" title="Un Publish Now"
                        matTooltipPosition="below" *ngIf="pu.enabledStatus===1">
                        <mat-icon fontSet="i" aria-label="remove" class="i-1_3x text-danger">remove_circle_outline
                        </mat-icon>
                      </button>
                      <!-- <mat-slide-toggle class="InsIQToggle boolToggle" [checked]="pu.enabledStatus==1 ? true: false"
                        (change)="updateSachet($event, pu)"></mat-slide-toggle> -->
                    </td>
                  </ng-container>


                  <tr mat-header-row
                    *matHeaderRowDef="['sachetName','sachetLimit','price','limit','active','planstat']">
                  </tr>
                  <tr mat-row
                    *matRowDef="let row; columns: ['sachetName','sachetLimit','price','limit','active','planstat']">
                  </tr>
                </table>
                <mat-paginator [length]="view.total" [pageSize]="view.pageSize"
                  [pageSizeOptions]="view.pageSizeOptions">
                </mat-paginator>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #dialogAddPricing>
  <form class="p-y-25" [formGroup]="pricingview.form" (ngSubmit)="onPriceSubmit()">
    <!-- <pre>{{pricingview.form.value | json}}</pre> -->
    <div>
      <div class="row">
        <div class="col-xl-24 d-flex align-items-center m-b-20">
          <label class="bigLabel m-a-0 p-b-0 p-r-20 f-medium">Billing Mode:</label>
          <div>
            <!-- {{pricingview.form.value.paymode}} -->
            <!-- {{getStatus('paymode', pricingview.form.value.paymode)}} -->
            <div class="radioToggleButton m-t-15 m-b-30">
              <label class="custom" [ngClass]="{
                checked: (pricingview.form.value.paymode==='1'||pricingview.form.value.paymode===1)
              }">
                <input type="radio" name="paymode" formControlName="paymode" [value]='1' checked>
                <i class="ico i i-idea"></i>
                <div class="label">
                  <div>Monthly</div>
                </div>
              </label>
              <label class="custom" [ngClass]="{
                checked: (pricingview.form.value.paymode==='2'||pricingview.form.value.paymode===2)
              }">
                <input type="radio" name="paymode" formControlName="paymode" [value]='2'>
                <i class="ico i i-payment"></i>
                <div class="label">
                  Annually
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8">
          <label class="bigLabel">Active From</label>
          <!-- <app-quick-help [layout]="intentMarket"></app-quick-help>-->
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <!-- <mat-label>Choose a date</mat-label> -->
            <input matInput [matDatepicker]="dpStartDate" formControlName="startdate">
            <mat-datepicker-toggle matSuffix [for]="dpStartDate"></mat-datepicker-toggle>
            <mat-datepicker #dpStartDate></mat-datepicker>
            <!-- <input matInput input type="date" formControlName="startdate"
              placeholder="Market Introductory date of this Price" useValueAsDate> -->
            <!-- <mat-error *ngIf="view.invalid('customer')">{{view.error('customer')}}</mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-xl-8">
          <div class="d-flex">
            <label class="bigLabel">Pricing in {{getCurrency1()}}</label>
            <div class="ib-m">
              <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
            </div>
          </div>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <input matInput (keypress)="onlyDecimalNumberKey($event)" maxlength="9" placeholder="Provide Plan Pricing"
              formControlName="price" [readonly]="isReadonly" maxlength="256">
            <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
          </mat-form-field>
        </div>
        <div class="col-xl-8">
          <label class="bigLabel">Active Till</label>
          <!-- <app-quick-help [layout]="projCust"></app-quick-help>-->
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <!-- <input matInput input type="date" formControlName="enddate" placeholder="Market Witdrawal Date of the Price"
              useValueAsDate> -->
            <input matInput [matDatepicker]="dpEndDate" formControlName="enddate">
            <mat-datepicker-toggle matSuffix [for]="dpEndDate"></mat-datepicker-toggle>
            <mat-datepicker #dpEndDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-xl-12">
          <label class="bigLabel">Pricing Active From</label>
          <app-quick-help [layout]="intentMarket"></app-quick-help>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
            matTooltipPosition="above">
            <input matInput placeholder="Plan Price Introductory date" formControlName="startdate">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
            <input matInput placeholder="Market Introductory date of this Plan">
            <mat-error *ngIf="view.invalid('customer')">{{view.error('customer')}}</mat-error> 
          </mat-form-field>
        </div>
        <div class="col-xl-12">
          <label class="bigLabel">Pricing Active Till</label>
          <app-quick-help [layout]="projCust"></app-quick-help>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
            matTooltipPosition="above">
            <input matInput placeholder="Plan Pricing Witdrawal Date" formControlName="enddate">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
            <mat-error *ngIf="view.invalid('customer')">{{view.error('customer')}}</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <div class="row" *ngIf="this.view.form.value.category===2 || this.view.form.value.category==='2'">
        <div class="col-xl-8">
          <label class="bigLabel m-t-10">Have an Offer?</label>
          <div class="m-t-5 m-b-20">
            <!-- <p-radioButton styleClass="m-r-5" labelStyleClass="m-r-10" name="offerStatus" value="2" label="No"
              formControlName="offerStatus"></p-radioButton>
            <p-radioButton styleClass="m-r-5" labelStyleClass="m-r-10" name="offerStatus" value="1" label="Yes"
              formControlName="offerStatus" checked></p-radioButton> -->
            <!-- {{pricingview.form.value.offerStatus}} -->
            <!-- === {{getStatus('offerStatus', pricingview.form.value.offerStatus)}} -->
            <!-- <div class="d-flex align-items-center m-b-30">
              <span class="m-r-10" [ngClass]="{
                'text-danger f-medium': !getStatus('offerStatus', pricingview.form.value.offerStatus)
              }">No</span>
              <mat-slide-toggle formControlName="offerStatus" class="InsIQToggle boolToggle"
                [checked]="!getStatus('offerStatus', pricingview.form.value.offerStatus)"></mat-slide-toggle>
              <span class="m-l-10" [ngClass]="{
                'text-success f-medium': getStatus('offerStatus', pricingview.form.value.offerStatus)
              }">Yes</span>
            </div> -->


            <div class="m-t-5 m-b-20">
              <div class="radioToggleButton m-t-15 m-b-30">
                <label class="custom" [ngClass]="{
                  checked: (pricingview.form.value.offerStatus==='1'||pricingview.form.value.offerStatus===1)
                }">
                  <input type="radio" name="offerStatus" formControlName="offerStatus" [value]='1' checked>
                  <i class="ico i i-idea"></i>
                  <div class="label">
                    <div>Yes</div>
                  </div>
                </label>
                <label class="custom" [ngClass]="{
                  checked: (pricingview.form.value.offerStatus==='2'||pricingview.form.value.offerStatus===2)
                }">
                  <input type="radio" name="offerStatus" formControlName="offerStatus" [value]='2'>
                  <i class="ico i i-payment"></i>
                  <div class="label">
                    <div>No</div>
                  </div>
                </label>
              </div>


            </div>


          </div>
        </div>
        <!-- {{getStatus('offerStatus', pricingview.form.value.offerStatus)}} -->
        <div class="col-xl-8" *ngIf="getStatus('offerStatus', pricingview.form.value.offerStatus)">
          <div class="d-flex">
            <label class="bigLabel">Offer Price in {{getCurrency1()}}</label>
            <div class="ib-m">
              <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
            </div>
          </div>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <input matInput placeholder="Provide Offer Price" formControlName="offerPrice"
              (keypress)="onlyDecimalNumberKey($event)" maxlength="256">
            <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
          </mat-form-field>
        </div>
        <div class="col-xl-8" *ngIf="getStatus('offerStatus', pricingview.form.value.offerStatus)">
          <label class="bigLabel">Offer Valid Till</label>
          <mat-form-field class="fw flat" appearance="fill" floatLabel="never">
            <!-- <input matInput input type="date" formControlName="offerEnddate" placeholder="offerEndDate" useValueAsDate> -->
            <input matInput [matDatepicker]="dpOfferEnddate" formControlName="offerEnddate">
            <mat-datepicker-toggle matSuffix [for]="dpOfferEnddate"></mat-datepicker-toggle>
            <mat-datepicker #dpOfferEnddate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="this.view.form.value.category===1 || this.view.form.value.category==='1'">

        <div class="col-xl-8">
          <div class="d-flex">
            <label class="bigLabel">Trial Days</label>
            <div class="ib-m">
              <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
            </div>
          </div>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <input matInput placeholder="Provide Trial Days" formControlName="trialdays"
              (keypress)="numberOnly($event)">
            <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col-xl-8"
          *ngIf="this.pricingview.form.value.paymode==='1'||this.pricingview.form.value.paymode===1">
          <div class="d-flex">
            <label class="bigLabel">Montlhy API Usage</label>

            <div class="ib-m">
              <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
            </div>
          </div>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <input matInput placeholder="Provide API Usage Limit" formControlName="apiUsageMonthly"
              (keypress)="numberOnly($event)">
            <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
          </mat-form-field>
        </div>

        <div class="col-xl-8"
          *ngIf="this.pricingview.form.value.paymode==='2'||this.pricingview.form.value.paymode===2">
          <div class="d-flex">
            <label class="bigLabel">Annual API Usage</label>

            <div class="ib-m">
              <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
            </div>
          </div>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <input matInput placeholder="Provide API Usage Limit" formControlName="apiUsageAnually"
              (keypress)="numberOnly($event)">
            <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
          </mat-form-field>
        </div>

        <div class="col-xl-8">
          <div class="d-flex">
            <label class="bigLabel"> Data Upload Limit</label>

            <div class="ib-m">
              <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
            </div>
          </div>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <input matInput placeholder="Data Upload Limit" formControlName="dataUsage" (keypress)="numberOnly($event)">
            <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
          </mat-form-field>


        </div>


        <mat-form-field *ngIf="this.pricingview.form.value.dataUsage!==null||this.pricingview.form.value.dataUsage">
          <mat-select name="dataUnit" formControlName="dataUnit" placeholder="Unit">
            <mat-option [value]="'GB'">GB</mat-option>
            <mat-option [value]="'MB'">MB</mat-option>

          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-xl-8">
          <div class="d-flex">
            <label class="bigLabel">User Limit</label>

            <div class="ib-m">
              <!-- <app-quick-help [layout]="projTitle"></app-quick-help>-->
            </div>
          </div>
          <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title="" matTooltipPosition="above">
            <input matInput placeholder="Provide User Limit" formControlName="userLimit"
              (keypress)="numberOnly($event)">
            <!-- <mat-error *ngIf="view.invalid('productName')">{{view.error('productName')}}</mat-error>-->
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="view.isEdit()">
        <div class="col-xl-8">
          <div class="form-group d-flex align-items-center m-b-30">
            <label class="m-a-0 p-b-0 p-r-30 f-medium text-accent">
              <div class="h5 ib-m m-b-0">Status:</div>
              <div class="f-bold ib-m m-x-10" [ngClass]="
              (pricingview.form.value.active === 1 || pricingview.form.value.active === '1') ? 'text-success' : 'text-danger'
              ">
                {{
                (pricingview.form.value.active === 1 || pricingview.form.value.active === '1') ? 'Active' : 'Inactive'
                }}
              </div>
            </label>

            <mat-slide-toggle class="InsIQToggle boolToggle"
              [checked]="(pricingview.form.value.active === 1 || pricingview.form.value.active === '1') ? true: false"
              (change)="changeplanstatus()">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="row">
        <label *ngIf="pricingview.isEdit()&&pricingview.form.value.active===1" class="subtitle f-medium text-danger">
          A published plan should be unpublished before making an update.
        </label>
        <div class="col-xl-24 p-t-15 t-r">
          <button type="submit" [disabled]="!pricingview.valid()" *ngIf="pricingview.isNew()" mat-raised-button
            color="primary" class="curved m-b-20 m-x-5">Add Pricing</button>
          <button type="submit" [disabled]="!pricingview.valid()"
            *ngIf="pricingview.isEdit()&&(pricingview.form.value.active===0||pricingview.form.value.active==='0'||!pricingview.form.value.active)"
            mat-raised-button color="primary" #update class="curved m-x-5">Update</button>

          <button type="submit" *ngIf="pricingview.isEdit()&&pricingview.form.value.active===1&&activePublish"
            [disabled]="!pricingview.valid()" mat-raised-button color="primary" class="curved m-x-5">Publish</button>
          <!-- <button type="button"  mat-raised-button color="warn()" (click)="cancelpricing()" class="curved m-b-20 m-x-5">Cancel</button>-->
          <!-- <button type="button" (click)="closePricingDialog()" mat-raised-button
            class="curved m-x-5 btn-cancel">Back</button>-->
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #dialogAddFeature>
  <!-- *ngIf="newfeatureflg" -->
  <div class="p-y-25">
    <div class="mat-elevation-z8 rel">
      <table mat-table [dataSource]="dsFeatureListAll">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="sl">
          <th class="w-80px t-c" mat-header-cell *matHeaderCellDef>Sl. No</th>
          <td class="w-80px t-c" mat-cell *matCellDef="let row; let i = index;">{{getIndex(i)}}</td>
        </ng-container>
        <!-- <ng-container matColumnDef="action">
          <th class="w-120px" mat-header-cell *matHeaderCellDef class="t-r">Action</th>
          <td mat-cell *matCellDef="let row" class="t-r">
           <input type="checkbox" [(ngModel)]="theCheckbox"  data-md-icheck (change)="toggleVisibility($event)" ng-click="$event.stopPropagation()"/>
             <button mat-icon-button (click)="view.deleteList(row.id)" title="Delete" matTooltipPosition="below">
              <mat-icon fontSet="i" fontIcon="i-trash" aria-label="delete" color="warn"></mat-icon>
            </button> 
          </td>
        </ng-container> -->
        <ng-container matColumnDef="feature">
          <th class="w-300px" mat-header-cell *matHeaderCellDef>Features</th>
          <td class="w-300px f-medium" mat-cell *matCellDef="let row">{{row.feature}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th class="w-400px" mat-header-cell *matHeaderCellDef>Feature Description</th>
          <td class="w-400px" mat-cell *matCellDef="let row">{{row.featureDescription}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['select','sl','feature','description']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['select','sl','feature','description']"></tr>
      </table>
      <!--  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
    </div>
    <div class="row">
      <div class="col-xl-24 p-t-25 t-r">
        <button type="button" [disabled]="!selection.hasValue()" (click)="logSelection();" mat-raised-button
          color="primary" class="curved m-b-20 m-x-5">Add Features</button>
      </div>
    </div>
  </div>
</ng-template>