<div class="container-fluid d-flex h-100" [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure [routerLink]="['/project']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"
        (click)="linkClick('project')" style="cursor:pointer">
        <img src="./assets/images/icon-insiq.png" alt="dev2m" *ngIf="!customThemeService.dynamiclogo">
        <img src="{{customThemeService.logo}}" alt="dev2m" *ngIf="customThemeService.dynamiclogo">
      </figure>
      <!-- <figure *ngIf="!customThemeService.dynamiclogo">
        <img src="./assets/images/insiq.png" alt="dev2m">
      </figure> -->

      <nav class="nav">
        <div class="title" [routerLink]="['/project']" [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="active" (click)="linkClick('project')" style="cursor:pointer">
          <i class="i i-projects"></i>
          <span>Projects</span>
        </div>

        <ul class="stackMenu" *ngIf="projList">
          <li *ngFor="let project of projList;">
            <button type="button" class="btn" [ngClass]="{active: project?.id === view.active?.id}"
              (click)="chooseProject(project);">
              <!-- <i class="i ico" [ngClass]="{
                'i-idea': project.productNewOrExisting==1,
                'i-monitor_product': project.productNewOrExisting==2
              }"></i> -->
              {{getProjName(project)}}
            </button>
          </li>
        </ul>
      </nav>

      <div class="actions">
        <button type="button" class="btn btn-primary btn-lg lighten justify-content-center" matRipple (click)="this.view.state =  'new' ; 
          " [routerLink]="['/new-project']">
          <i class="i i-add"></i>
          <span>Add Project</span>
        </button>
      </div>

      <div class="sidebar-footer">
        <button type="button" matRipple (click)="yotubeClick()" class="btn  btn-link">
          <mat-icon fontSet="i" class="i-youtube i-1_7x ib-m"></mat-icon>
          <span class="ib-m m-x-5">Watch our How-To Videos</span>
        </button>

        <button type="button" class="btn btn-primary btn-xl curved m-y-10 justify-content-center " matRipple
          (click)="acdemyClick()">

          <span>Join our free academy</span>
        </button>

      </div>
    </div>

    <!--  [ngClass]="!aduser ? 'active' : 'hidden'" -->
    <div class="rightSection" *ngIf="!aduser" tabindex="0">

      <div class="pageWrapper">
        <div class="toolBar">
          <div class="title">All Team Members</div>
          <div class="spacer"></div>
          <mat-form-field floatLabel="never" appearance="outline" class="disableMatError">
            <mat-icon fontIcon="i-search" matPrefix></mat-icon>
            <input matInput (keyup)="view.filter()" placeholder="Search for a team member" [(ngModel)]="view.keyword" />
          </mat-form-field>
          <div class="action">
            <button matRipple [matMenuTriggerFor]="teamMenu" class="btn btn-lg btn-primary lighten" *ngIf="hasMs">
              <i class="i i-member_add"></i>
              <span>Add</span>
            </button>

            <button matRipple class="btn btn-lg btn-primary lighten" (click)="newmember()" *ngIf="!hasMs">
              <i class="i i-member_add" aria-label="Add Member"></i>
              <span>New Member</span>
            </button>

            <mat-menu #teamMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="newmember()">
                <i class="i i-member_add" aria-label="Add Member"></i>
                <span>New Member</span>
              </button>
              <button mat-menu-item (click)="MSAduser()">
                <i class="i i-vertical_align_bottom" aria-label="Import AD Team Member"></i>
                <span>Import AD Team Member</span>
              </button>
            </mat-menu>

          </div>
        </div>
        <!-- [ngClass]="{
          'd-none': view.list === null || view.list === undefined || view.list.length <= 0
        }"
         -->
        <ul class="cardWrapper">
          <!-- <pre>{{view.list | json}}</pre> -->
          <li *ngFor="let user of view.list; let i = index;">
            <div class="card userCard" (click)="edit(user,i)" [ngClass]="{
              inActive: user.active!==1
            }">
              <div class="cardHeader">
                <figure>
                  <img src="assets/images/users/default.png" alt="img">
                </figure>
              </div>
              <div class="cardcontent">
                <div class="name">
                  {{user.name}}
                </div>
                <div class="designation">
                  {{user.email}}
                </div>
              </div>
            </div>
          </li>
        </ul>

        <!-- <div class="d-flex">
          <h1 class="h1 smartHead">
            <i class="i i-user"></i>
            <span>
              <small>Team</small>
              <b>Members</b>
            </span>
          </h1>
          <div class="spacer"></div>

          <span>
            <button class="curved btn btn-primary m-t-15" (click)="view.newform();newmember()">
              New Member
            </button>
            <button *ngIf="hasMs" (click)="MSAduser()" class="curved btn btn-primary m-t-15 m-l-20">
              Import AD Team member
            </button>
          </span>

        </div>
        <div class="d-flex m-t-30 m-b-5">
          <p class="m-b-0">All Team Members</p>
          <div class="spacer"></div>
          <mat-form-field>
            <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
          </mat-form-field>
        </div> -->

        <!-- <pre>
          length: {{view.list.length}}
          null: {{view.list !== null}}
          undefined: {{view.list !== undefined}}
        </pre>
        <pre>{{view.list | json}}</pre> -->

        <mat-card *ngIf="
        view.list === null ||
        view.list === undefined ||
        view.list.length <= 0">
          <mat-card-content>
            <div class="t-c p-y-30">
              <img src="./assets/images/norecordfound.png" alt="Your list is empty!">
              <h4 class="h1 text-accent m-t-15 m-b-0">Your list is empty!</h4>
            </div>
          </mat-card-content>
        </mat-card>

        <div style="display: none;">
          <table mat-table [dataSource]="view.list" matSort class="w-100 InsIQTable">
            <ng-container matColumnDef="photo">
              <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
              <td mat-cell *matCellDef="let row">
                <figure *ngIf="!customThemeService.dynamiclogo">
                  <img src="./assets/images/icon-insiq.png" alt="dev2m">
                </figure>
                <figure *ngIf="customThemeService.dynamiclogo">
                  <img src="{{customThemeService.logo}}" alt="dev2m">
                </figure>

              </td>
            </ng-container>

            <ng-container matColumnDef="usertype">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-100px">Member Type</th>
              <td mat-cell *matCellDef="let row" class="t-c">
                <i class="i i-2x o-80" [ngClass]="getType(row?.usertype).icon"
                  [attr.aria-label]="getType(row?.usertype).label"></i>
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-80px"> Id </th>
              <td mat-cell *matCellDef="let row">{{row.id}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
              <td mat-cell *matCellDef="let row">{{row.email}}</td>
            </ng-container>
            <ng-container matColumnDef="loginKey">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
              <td mat-cell *matCellDef="let row">{{row.loginKey}}</td>
            </ng-container>
            <ng-container matColumnDef="active">
              <th class="w-120px" mat-header-cell *matHeaderCellDef>Status</th>

              <td mat-cell *matCellDef="let pu" matTooltipPosition="below">
                <div class="label f-bold" [ngClass]="{
                  'text-success': pu.active === 1,
                  'text-danger': pu.active === 0
                }">
                  <div>{{pu.active===1 ? 'Active' : 'Inactive'}}</div>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row ; let i = index;">
                <button mat-icon-button (click)="edit(row,i)">
                  <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
                </button>
                <button mat-icon-button (click)="delUser(row.id,row?.integrationid)">
                  <mat-icon fontSet="i" fontIcon="i-delete_sweep" aria-label="delete"></mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['usertype','id','name','email','loginKey','active','action']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['usertype','id','name','email','loginKey','active','action']">
            </tr>
          </table>
          <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
          </mat-paginator>
        </div>
      </div>

    </div>

    <!-- [ngClass]="aduser ? 'active' : 'hidden'" -->
    <div class="rightSection" *ngIf="aduser" tabindex="0">
      <div class="d-flex m-b-30">
        <!-- <div class="spacer"></div> -->
        <button matRipple class="btn btn-lg m-t-15" (click)="aduser = false">
          <i class="i i-back"></i>
          Back to All Members
        </button>
      </div>
      <mat-card class="m-t-30" [class.mat-elevation-z24]="true">
        <mat-card-content>
          <!-- {{list | json}} -->
          <div *ngIf="list.length > 0">
            <table mat-table [dataSource]="dataSource" matSort class="w-100 InsIQTable">
              <!-- photo Column -->
              <ng-container matColumnDef="photo">
                <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
                <td mat-cell *matCellDef="let row">
                  <figure *ngIf="!customThemeService.dynamiclogo">
                    <img src="./assets/images/icon-insiq.png" alt="dev2m">
                  </figure>
                  <!-- <figure *ngIf="!customThemeService.dynamiclogo">
                    <img src="./assets/images/insiq.png" alt="dev2m">
                  </figure> -->
                  <figure *ngIf="customThemeService.dynamiclogo">
                    <img src="{{customThemeService.logo}}" alt="dev2m">
                  </figure>

                </td>
              </ng-container>
              <!-- name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let row">{{row.displayName}}</td>
              </ng-container>

              <!-- email Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let row">{{getKey(row.mail,row.userPrincipalName)}}</td>
              </ng-container>

              <!-- Action Column -->
              <ng-container matColumnDef="action">
                <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let row; let i=index">
                  <button matRipple
                    (click)="adduser(row.displayName,row.id,getKey(row.mail,row.userPrincipalName)); selected = i"
                    class="btn btn-lg btn-primary lighten addBtn">
                    <mat-icon fontSet="i" aria-label="MS Teams">person_add</mat-icon>
                    <span class="ib-m p-l-5">Add Member</span>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['name','email','action']"></tr>
              <tr mat-row *matRowDef="let row; columns: ['name','email','action']"></tr>
            </table>
            <!-- <mat-paginator [length]="list.length" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
            </mat-paginator> -->
          </div>

          <div class="t-c p-y-30 m-y-30" *ngIf="list.length === 0">
            <img src="./assets/images/norecordfound.png" alt="Your list is empty!">
            <h1 class="h1 text-accent m-t-30 m-b-0">No AD Team members found!!!</h1>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div class="container-fluid d-flex h-100" [ngClass]="view.isForm() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <!-- <button mat-icon-button class="close" color="warn" (click)="view.cancel()" title="Close"
        matTooltipPosition="below">
        <mat-icon fontSet="i" class="i-close"></mat-icon>
      </button> -->
      <figure *ngIf="!customThemeService.dynamiclogo">
        <img src="./assets/images/icon-insiq.png" alt="dev2m">
      </figure>
      <!-- <figure *ngIf="!customThemeService.dynamiclogo">
        <img src="./assets/images/insiq.png" alt="dev2m">
      </figure> -->
      <figure *ngIf="customThemeService.dynamiclogo">
        <img src="{{customThemeService.logo}}" alt="dev2m">
      </figure>

      <ul class="quickyMenu" *ngIf="!checkoutFlag">
        <li *ngFor="let user of view.list; let i = index;">
          <button matRipple class="btn btn-md btn-primary lighten fw t-c" [ngClass]="{'active': selected === i}"
            (click)="edit(user,i)">
            <!-- <figure class="avatar circle">
              <img src="assets/images/users/default.png" alt="{{view.value('name')}}" title="{{view.value('name')}}">
            </figure> -->
            {{user.name}}
            <!-- <mat-icon fontSet="i">edit</mat-icon> -->
          </button>
        </li>
      </ul>
    </div>
    <form [formGroup]="view.form" novalidate class="rightSection" (ngSubmit)="onSubmit()" tabindex="0">

      <div class="d-flex m-b-30">
        <div class="m-r-auto">
          <button type="button" (click)="!checkoutFlag?view.cancel():checkoutFlag=false" matRipple
            class="m-x-5 btn-cancel btn btn-xl btn-primary btn-link">
            <i class="i i-back"></i>
            Back
          </button>
        </div>
        <!-- <span class="m-l-auto">
          <button class="btn btn-primary m-t-15" (click)="view.newform();addmember()" *ngIf="view.isEdit()">
            New Member
          </button>
        </span> -->
      </div>
      <div class="pageWrapper">
        <div class="InsIQCard">
          <div class="toolBar">
            <div class="title" *ngIf="view.isNew()">
              <i class="i i-add"></i>
              <span>Add New Member</span>
            </div>
            <div class="title" *ngIf="view.isEdit()">
              <i class="i i-my_team m-r-5"></i>
              <span *ngIf="!checkoutFlag">Member Details</span>
              <span *ngIf="checkoutFlag">User Sachet Renewal</span>

            </div>
          </div>
          <div class="row p-t-15">
            <div class="col-lg-12" *ngIf="!checkoutFlag">
              <!-- <figure class="avatar circle">
              <img src="assets/images/users/default.png" alt="{{view.value('name')}}" title="{{view.value('name')}}">
            </figure> -->
              <label for="name" class="mandatory d-block m-b-5">Name</label>
              <mat-form-field class="w-100 m-b-10 fw flat" floatLabel="never" appearance="fill">
                <input type="text" matInput placeholder="Name" formControlName="name">
                <mat-error *ngIf="view.invalid('name')">{{view.error('name')}}</mat-error>
              </mat-form-field>
              <label for="email" class="mandatory d-block m-b-5">Email</label>
              <mat-form-field class="w-100 m-b-10 fw flat" floatLabel="never" appearance="fill">
                <input type="text" matInput placeholder="Email" formControlName="email">
                <mat-error *ngIf="view.invalid('email')">{{view.error('email')}}</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100 m-b-10"
                *ngIf="view.isEdit&& (view.form.value.active==='0'||view.form.value.active===0)">
                <input type="text" matInput placeholder="Remarks (if any)" formControlName="remarks">
                <mat-error *ngIf="view.invalid('remarks')">{{view.error('remarks')}}</mat-error>
              </mat-form-field>

            </div>
            <div class="col-lg-12" *ngIf="!checkoutFlag">
              <label for="loginKey" class="mandatory d-block m-b-5">Username (Modification is not allowed)</label>
              <mat-form-field class="w-100 m-b-10 fw flat" floatLabel="never" appearance="fill">
                <input type="text" matInput placeholder="Username (Modification is not allowed)" onCopy="return false"
                  onDrag="return false" onDrop="return false" onPaste="return false" (keydown)="onKeydown($event)"
                  formControlName="loginKey" [readonly]="view.isEdit()">
                <mat-hint *ngIf="view.isEdit()"></mat-hint>
                <mat-error *ngIf="view.invalid('loginKey')">{{view.error('loginKey')}}</mat-error>
              </mat-form-field>

              <label class="mandatory">Status</label>
              <div class="m-b-20">
                <div class="radioToggleButton m-b-30">
                  <label class="custom" [ngClass]="{
                    checked: (view.form.value.active==='1'||view.form.value.active===1)
                  }">
                    <input type="radio" name="active" formControlName="active" [value]='1' checked>
                    <!-- <i class="ico i i-check_circle"></i> -->
                    <div class="label">
                      <div>Active</div>
                      <!-- <div></div> -->
                    </div>
                  </label>
                  <label class="custom" [ngClass]="{
                    checked: (view.form.value.active==='0'||view.form.value.active===0)
                  }">
                    <input type="radio" name="active" formControlName="active" [value]='0'>
                    <!-- <i class="ico i i-cancel"></i> -->
                    <div class="label">
                      <div>Inactive</div>
                      <!-- <div></div> -->
                    </div>
                  </label>
                </div>


              </div>
              <!-- <div class="row">

            </div> -->
              <!-- <div class="m-b-20" *ngIf="config.currentUser?.isbtob==1">
              <mat-checkbox formControlName="isbtob" (change)="b2bChange($event)">Allow Local Client
                <mat-error *ngIf="view.invalid('isbtob')">{{view.error('isbtob')}}</mat-error>
              </mat-checkbox>

            </div> -->
              <!-- <mat-form-field class="w-100 m-b-10">
              <mat-select placeholder="Choose role" formControlName="role" [errorStateMatcher]="matcher">
                <mat-option value="0">Normal User</mat-option>
                <mat-option value="1">Administrator</mat-option>
              </mat-select>
              <mat-error *ngIf="view.required('role')">You must make a selection</mat-error>
            </mat-form-field> -->

            </div>
            <!-- <pre>{{view.active|json}}</pre> -->
            <div class="col-xl-12 col-lg-12"
              *ngIf="view.active&&(view?.active?.price)&&view?.active?.status == 1&&!checkoutFlag">
              <div class="card fieldsetCard planCard">
                <div class="cardHeader">
                  <div class="title">Sachet User</div>
                </div>
                <div class="cardContent">
                  <div class="col">
                    <div class="validTill">
                      <div *ngIf="config?.currentUser?.gp&&currentUsr?.usertype!=='APPSUMO'">Your InsIQ Sachet Pack
                        Expired</div>
                      <div *ngIf="currentUsr?.usertype=='APPSUMO'||currentUsr?.daysRemaining>500">Lifetime
                        Validity</div>
                      <div *ngIf="currentUsr?.usertype!=='APPSUMO'&&currentUsr?.daysRemaining<500">
                        <div>Subscription Valid till: </div>
                        <div class="date">{{(view?.active?.activeTill)?.split(' ')[0]}}</div>
                      </div>
                    </div>


                  </div>
                  <div class="col t-r">
                    <div class="action" *ngIf="view?.active?.daysRemaining<=7">


                      <button type="button" matRipple class="btn btn-info _flat" (click)="renewClick()"
                        *ngIf="getRenew(view?.active?.activeFrom,view?.active?.activeTill)">
                        RENEW PLAN
                      </button>

                    </div>

                    <!-- <small class="footnote" *ngIf="currentUsr?.pin!='USR'&&currentUsr?.usertype!=='APPSUMO'">
                    * Paid plans are only available to customers in the UK, US, and EU countries.
                  </small> -->
                  </div>

                </div>
                <div class="message _warning">
                  <!-- <i class="i i-warning"></i> -->
                  <span *ngIf="view?.active?.daysRemaining>0">Expires in
                    {{view?.active?.daysRemaining}} Days</span>
                  <span *ngIf="view?.active?.daysRemaining<=0">Account Expired</span>
                </div>

              </div>

            </div>

            <mat-card class="mat-elevation-z8 h-100" *ngIf=" checkoutFlag">
              <mat-card-content>
                Sachet User Details
                <div class="selectedPlan" matRipple>
                  <div class="dtl">
                    <div class="title">{{view?.active?.name}}</div>
                    <div class="billType">
                      {{view?.active?.loginKey}}

                    </div>
                    <div class="billType">
                      {{view?.active?.email}}

                    </div>



                    <div class="price"> <span class="m-l-auto text-accent f-black">{{view?.active?.price}} USD</span>
                    </div>
                  </div>

                </div>

                <mat-card class="mat-elevation-z8 billPayment" *ngIf="checkoutFlag">
                  <mat-card-header>Sachet Renewal Billing</mat-card-header>
                  <mat-card-content>
                    <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                         <mat-label>Choose an option</mat-label> 
                        <mat-select>
                          <mat-option value="month" selected>Monthly Plan</mat-option>
                          <mat-option value="annual">Annual Plan</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="d-flex text-primary m-b-20 f-medium">
                        <span>Switch to annual and save</span>
                        <span class="m-l-auto f-bold">US$60.00/yr</span>
                      </div> -->
                    <div class="d-flex text-dark m-y-20 h4">
                      <span class="f-light">Plan Amount</span>
                      <!-- <span class="m-l-auto" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}}
                          USD</span> -->
                      <span class="m-l-auto">{{view?.active?.price}} USD</span>
                    </div>

                    <hr>
                    <div class="d-flex text-dark m-y-20 h4">
                      <span class="f-medium">Total Amount to Pay</span>
                      <!-- <span class="m-l-auto f-black" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}}
                          USD</span> -->
                      <span class="m-l-auto text-accent f-black">{{view?.active?.price}} USD</span>
                    </div>

                    <hr>
                    <mat-checkbox (change)="changeCheck($event)" class="w-100" style="white-space: break-spaces;">
                      <div class="m-l-5" style="white-space: break-spaces;">By continuing, you are agreeing to our
                        <a href="javascript:;" (click)="openTnC('subterms')" class="link">Subscription terms</a> and our
                        <a href="javascript:;" (click)="openTnC('payterms')" class="link">Payment Terms.</a>
                      </div>

                    </mat-checkbox>

                    <div class="message _warning m-l-20 m-r-20">
                      <i class="i i-warning"></i>
                      <span>Don't refresh your page on payment submit</span>
                    </div>
                    <div class="t-c">
                      <button type="button" (click)="backtocheckout()" matRipple class="btn btn-danger m-x-5">Cancel
                        Payment</button>
                      <button type="button" (click)="payNowHere()" matRipple class="btn btn-primary m-x-5"
                        [disabled]="disablecheck()">Proceed to Pay</button>
                    </div>
                  </mat-card-content>
                </mat-card>
              </mat-card-content>
            </mat-card>


            <div class="col-lg-24 m-t-30" *ngIf="!checkoutFlag">
              <div class="actionWrap">
                <!-- <button type="button" (click)="view.cancel()" matRipple
                  class="m-x-5 btn-cancel btn btn-xl btn-primary lighten ib-m">
                  <i class="i i-back"></i>
                  Back
                </button> -->
                <button type="submit" [disabled]="view.invalid()" *ngIf="view.isNew()" matRipple
                  class="btn btn-xl btn-primary m-x-5 ib-m">Create</button>
                <button type="submit" [disabled]="view.invalid()||view?.active?.status == 0" *ngIf="view.isEdit()"
                  matRipple class="btn btn-xl btn-primary m-x-5 ib-m">Update</button>
                <button type="button" matRipple class="btn btn-xl btn-danger lighten m-x-5 ib-m" *ngIf="view.isEdit()"
                  (click)="delUser(view.form.value.id,view.form.value?.integrationid)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>