import {
  View,
  Config
} from '../../../config/global.config';
import {
  HttpEvent,
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEventType
} from '@angular/common/http';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { JsonRequest } from '../../../config';
import { AlertService } from '../../../_services';
import { ConfirmationService } from 'primeng/api';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';

import {
  OnInit,
  Component,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-devtomlocalclient',
  templateUrl: './devtomlocalclient.component.html',
  styleUrls: ['./devtomlocalclient.component.scss'],
  providers: [ConfirmationService]
})
export class DevTomLocalClientComponent implements OnInit {
  public view: View;
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';
  historylist: any;
  versionName;
  versionCategory;
  versionOS;
  activeflg: boolean;
  fileInfos: Observable<any>;
  @ViewChild('dialogUpload', { static: false }) dialogUpload: TemplateRef<any>;

  constructor(
    public config: Config,
    public router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public alertService: AlertService,
    public commonDialogRef: MatDialogRef<CommonDialogComponent>
  ) {
    this.activeflg = false;
    this.view = new View(http, router, route, alertService);

    this.refreshlist();
  }

  ngOnInit() {
    // this.fileInfos = this.uploadService.getFiles();
    this.versionCategory = true;
    this.versionOS = 1;
  }
  refreshlist() {
    this.view.get('localclient').subscribe(res => {
      this.historylist = res;
    });
  }
  selectFile(event: { target: { files: FileList; }; }) {
    this.selectedFiles = event.target.files;
  }
  downloadfile(row: any) {
    this.getFiletodwnld(row);
  }
  // downloadFile(filename: string) {
  //  return this.http.get(`${Config.apiUrl}/localclient/files/` + filename, {
  //    responseType: 'arraybuffer'
  //  });
  //
  // }
  uploadnew() {
    this.message = '';
    this.dialog.open(CommonDialogComponent, {
      width: '95vw',
      maxHeight: '98vh',
      maxWidth: '1024px',
      minWidth: '1024px',
      panelClass: 'commonDialog',
      data: {
        title: 'Upload New Business Intelligence Installation File',
        template: this.dialogUpload
      }
    });
  }

  getIndex(i: number) {
    let j: number;
    j = i + 1;
    return j;
  }

  upload() {
    this.message = '';
    // var version=((document.getElementById("version") as HTMLInputElement).value);
    this.progress = 0;

    this.currentFile = this.selectedFiles.item(0);
    Config.loading = true;


    this.upload1(this.currentFile).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
          Config.setLoaderMessage('Please wait your InsIQ Business Intelligence is uploading ' + this.progress + ' %');

        } else if (event instanceof HttpResponse) {
          this.message = event.body.message;
          this.fileInfos = this.getFiles();
          this.refreshlist();
          this.dialog.closeAll();
          Config.loading = false;
        }


      },
      _err => {
        this.progress = 0;
        this.message = 'Could not upload the file!';
        this.currentFile = undefined;
        Config.loading = false;
        this.dialog.closeAll();
      });

    this.selectedFiles = undefined;

  }

  onKey(event: any) { // without type info
    if (event.target.value) {
      this.versionName = event.target.value;
      this.activeflg = true;
    } else {
      this.activeflg = false;
    }
  }
  onCatChange(mrChange: MatRadioChange) {
    console.log(mrChange.value);
    if (mrChange.value == 'true') {
      this.versionOS = 1;
    }
    if (mrChange.value == 'false') {
      this.versionOS = 2;
    }
    console.log(this.versionOS);
  }
  toggleSwitch(event: { checked: any; }, data: any) {
    let status;
    if (event.checked) {
      status = 1;
    } else {
      status = 0;
    }
    this.statusUpdator(status, data.id, data.osversion).subscribe(_event => {
      this.refreshlist();
    });
  }
  upload1(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('version', this.versionName);
    formData.append('osversion', this.versionOS);

    const req = new HttpRequest('POST', `${Config.apiUrl}/localclient/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getOs(osversion) {
    let versionName;
    if (osversion == 1) {
      versionName = "Windows";
    }
    else if (osversion == 2) {
      versionName = "MAC";

    }
    return versionName;
  }
  statusUpdator(status, id, osversion): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('status', status);
    formData.append('id', id);
    formData.append('osversion', osversion);

    const req = new HttpRequest('POST', `${Config.apiUrl}/localclient/status`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${Config.apiUrl}/localclient/files`);
  }


  getFiletodwnld(filename) {
    // this.view.get('subscription/users/export/pdf').subscribe(res => {
    //   console.log(res);
    //  // this.productTypes = res;
    // });

    const url = `${Config.apiUrl}/localclient/files/` + filename;

    const requestOption = JsonRequest.getHeader('');
    // requestOption.responseType='blob';
    this.http.get(url, { headers: requestOption.headers, params: requestOption.params, responseType: 'arraybuffer' }).subscribe(
      (data: ArrayBuffer) => {
        const file = new Blob([data], { type: 'application/zip' });
        const fileURL = URL.createObjectURL(file);
        Config.loading = false;
        // if you want to open PDF in new tab
        // window.open(fileURL);
        const a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        //
        a.download = 'InsIQ Business Intelligence.zip';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        console.log('Something went Wrong', error);
      }
    );
  }
}
