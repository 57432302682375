<div class="container-fluid d-flex h-100">
  <div class="wrapper">
    <div class="rightSection mainWrap noSidebar">
      <div class="row justify-content-center">
        <div class="col-xl-24 col-lg-24 col-md-24">
          <h5 class="pageHeading">InsIQ Payment Confirmation</h5>
          <div class="t-c m-b-30" *ngIf="!transactionLog">
            <h5 class="text-success f-medium">Payment Processing</h5>
            <h5 class="text-danger f-medium">Please Wait.</h5>


          </div>

          <div class="t-c m-b-30" *ngIf="transactionLog">
            <h5 class="text-danger f-medium" *ngIf="transactionLog?.status!='succeeded'">Payment Failed</h5>
            <h5 class="text-success f-medium" *ngIf="transactionLog?.status=='succeeded'">Payment Successful</h5>

            <p>
              If you have any furthur concerns or queries
              regarding the subscription, please feel free to reach out us at
              <a href="mailto:support@devtom.ai" class="link underline">support@devtom.ai</a>.
            </p>

            <hr class="m-b-20">
          </div>

          <!-- <div class="t-c m-b-30" *ngIf="norefund&&!showStatus">
      <button type="button" (click)="payNowHere()" class="btn btn-primary btn-lg">Proceed to InsIQ</button>
    </div> -->

          <!-- <mat-card class="mat-elevation-z8" *ngIf="waitflag">
      <mat-card-content>

        <div class="selectedPlan" matRipple>
          <div class="dtl">
            <div class="title">Please Wait</div>
            <div class="validity">Checkout in Progress.Do not press back button or refresh page.
            </div>
          </div>
          <div class="action">
            <mat-icon class="i-2x" color="primary">timer
            </mat-icon>
          </div>
        </div>
      </mat-card-content>
    </mat-card> -->

        </div>
        <div class="col-xl-11 col-lg-10 col-md-12">
          <mat-card class="mat-elevation-z8 billPayment p-a-0" *ngIf="transactionLog">
            <mat-card-header>Transaction Details</mat-card-header>
            <mat-card-content class="p-a-30">

              <div class="d-flex text-dark m-y-20 h5 flex-wrap">
                <span class="p-r-5">Transaction Id</span>
                <span class="m-l-auto f-medium">{{transactionLog?.id}}</span>
              </div>

              <div class="d-flex text-dark m-y-20 h5">
                <span class="p-r-5">Payment Status</span>
                <!-- <span class="m-l-auto f-black" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}} USD</span> -->

                <span class="m-l-auto f-medium text-danger" [ngClass]="{
            'text-danger': transactionLog?.status!='succeeded'?true:false,
            'text-success': transactionLog?.status=='succeeded'?true:false
          }">{{transactionLog?.status}}</span>
              </div>

              <hr>
              <div class="d-flex text-dark m-y-20 h5">
                <span class="p-r-5">Amount Received</span>
                <span class="m-l-auto f-medium">{{transactionLog?.amount_received/100}} USD</span>
              </div>

              <hr>

              <div class="t-c p-t-20">
                <button type="button" (click)="payNowHere()" class="btn btn-primary btn-lg">Proceed to InsIQ</button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->