import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';

import { AlertService } from '../../_services';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('alertStat', [
      state('inactive', style({
        transform: 'translateY(-100%)'
      })),
      state('active', style({
        transform: 'translateY(0%)'
      })),
      transition('inactive => active', animate('300ms ease-in')),
      transition('active => inactive', animate('300ms ease-out'))
    ])
  ]
})
export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message = {
    type: '',
    text: ''
  };
  public alertStat = 'inactive';

  constructor(private alertService: AlertService) {
  }

  toggleState() {
    this.alertStat = (this.alertStat === 'active') ? 'inactive' : 'active';
  }

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      this.message = message;
      // console.log(this.message);
      this.alertStat = (message) ? 'active' : 'inactive';
      if (message) {
        this.alertStat = (message.type !== '') ? 'active' : 'inactive';
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
