import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, TemplateRef, OnInit, Optional, ViewChild, NgZone } from '@angular/core';
import { Config, View } from 'src/app/config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/_services';
@Component({
  selector: 'app-cloneprojectapprove',
  templateUrl: './cloneprojectapprove.component.html',
  styleUrls: ['./cloneprojectapprove.component.scss']
})
export class CloneprojectapproveComponent implements OnInit {
  cloneDialogRef: MatDialogRef<CloneprojectapproveComponent, any>;
  mailStat = false;
  validFlag = false;
  InsIQUser = false;
  projlist = [];
  constructor(
    public router: Router,
    public dialog: MatDialog,
    private http: HttpClient,
    private fb: FormBuilder,
    public config: Config,
    public shareProjView: View,
    public alertService: AlertService,
    public view: View,
    private ngZone: NgZone,
    public route: ActivatedRoute,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<CloneprojectapproveComponent>
  ) {
    this.layout = this.data;
    this.mailStat = false;
    this.validFlag = false;
    this.InsIQUser = false;
    this.shareProjView = new View(http, router, route, alertService);
    this.view = new View(http, router, route, alertService);

    //
    //  console.log('test', this.data.projectId);

    for (let i = 0; i < this.data.length; i++) {
      this.projlist.push(this.data[i].projectId);

    }
    // console.log(this.projlist)
  }
  @ViewChild('secondDialog', { static: true }) STAT_POPUP: TemplateRef<any>;
  layout: TemplateRef<any>;
  ngOnInit() {
  }
  getProjName(project) {
    //console.log(project)
    let projName: any;
    if (project.productNewOrExisting === 1) {
      projName = project.productName;
    } else if (project.productNewOrExisting === 2) {
      projName = project.brandName;
    }
    return projName;
  }
  backtoPage() {
    this.dialogRef.close();
  }

  backtoHome() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
  omit_special_char(event, searchVal, fl = 1, commonflag = false) {
    //console.log('hereee')
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    this.mailStat = emailRegex.test(searchVal);


    return true;
  }

  getNewProjectShareForm() {
    return this.fb.group({
      projectId: ['']
    });
  }
  acceptProject(project) {


    Config.loading = true;
    this.shareProjView.get('project/acceptproject/' + project.id).subscribe(res => {

      // this.countryList=res;
      //  console.log(res);

      if (res?.length != 0) {
        Config.loading = false;

        this.projlist = this.projlist.filter(project1 => project1.id !== project.id);

        this.config.projectClone();

        // this.shareProjView.save('project/acceptproject', 'Project Added Successfully');

        this.dialogRef.close();

        this.router.navigate(['/'])
          .then(() => {
            window.location.reload();
          });
      }


    });
  }

  rejectProject(project) {
    // console.log(project);
    // let projId = this.data.id;
    //this.getNewProjectShareForm();
    Config.loading = true;
    this.shareProjView.get('project/rejectproject/' + project.id).subscribe(res => {

      // this.countryList=res;
      //  console.log(res);

      if (res?.length != 0) {
        Config.loading = false;

        this.projlist = this.projlist.filter(project1 => project1.id !== project.id);

        this.config.projectClone();

        // this.shareProjView.save('project/acceptproject', 'Project Added Successfully');

        this.dialogRef.close();
        // this.router.navigate(['/']);
      }


    });


  }
}
