import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, TemplateRef, Optional } from '@angular/core';


@Component({
  selector: 'app-notes-dialog',
  templateUrl: 'notes-dialog.html',
  styleUrls: ['notes-dialog.scss']
})
export class NotesDialogComponent implements OnInit {
  layout: TemplateRef<any>;
  ngOnInit() {

  }

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // console.log(this.data);
    this.layout = this.data;
  }

  backtoPage() {
    this.dialogRef.close();
  }
  backtoHome() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }

}
