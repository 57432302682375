import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/config/global.config';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {
  roleCode;
  constructor(public config: Config,) {

  }

  ngOnInit() {
    //  console.log(Config.roleCode);
    this.roleCode = Config.roleCode;
  }

}
