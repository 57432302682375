<figure>
  <img src="assets/images/infographics/{{infoGraphic}}" alt="UPGRADE your Plan">
</figure>

<h4 class="h4 f-bold text-danger">
  {{title || 'Upgrade your plan to get this feature'}}
</h4>

<p [innerHTML]="message || 'This feature is not available in your current plan. Please upgrade your Plan!'"></p>

<!-- <button type="button" matRipple class="btn btn-accent btn-lg curved p-x-30" (click)="upgrade()">
  Upgrade Now
</button> -->