<!-- <pre>
  {{message | json}}
</pre> -->
<div *ngIf="message" [@alertStat]="alertStat" [ngClass]="{
  'alert': message,
  'active alert-info': message.type === 'info',
  'active alert-danger': message.type === 'error',
  'active alert-warning': message.type === 'warning',
  'active alert-success': message.type === 'success'
}">
  <div class="slice">
    <i class="i ico" [ngClass]="{
      'i-info': message.type === 'info',
      'i-error': message.type === 'error',
      'i-warning': message.type === 'warning',
      'i-check_circle': message.type === 'success'
    }"></i>
    <span [innerHTML]="message.text"></span>
    <button mat-icon-button class="close" (click)="toggleState()" *ngIf="message.type !== ''" title="Close"
      matTooltipPosition="below">
      <mat-icon matPrefix fontSet="i" fontIcon="i-close"></mat-icon>
    </button>
  </div>
</div>