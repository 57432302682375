<div class="d-block p-a-0">

  <h4 class="text-accent">
    <button class="closeBtn mat-fab mat-button-base mat-warn mdlbtn" (click)="backtoPage()">
      <mat-icon class="mat-icon notranslate i mat-icon-no-color" role="img" aria-hidden="true">close</mat-icon>
    </button>
  </h4>
  <mat-card-header>
    <div mat-card-avatar class="b-a-1 t-c"><i class="i i-ms-team i-2_5x text-primary"></i></div>
    <mat-card-title>Steps to register InsIQ application in Azure AD</mat-card-title>
    <mat-card-subtitle>Follow the below-listed steps to register the application.</mat-card-subtitle>
  </mat-card-header>

  <div class="alertdiv">
    <h2 class="subTitle">Step 1</h2>
    <p>
      Log into the Azure portal using your Azure account.
    </p>
    <p>URL - <a href="https://portal.azure.com/" target="_blank">https://portal.azure.com/</a></p>

    <h2 class="subTitle">Step 2</h2>
    <p>From the dashboard,click the Azure Active Directory menu.</p>
    <figure>
      <img src="./assets/images/msteams/msteam1.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 3</h2>
    <p>Under Azure Active Directory page, click the “App registrations” from the left panel, as shown below.</p>
    <figure>
      <img src="./assets/images/msteams/msteam2.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 4</h2>
    <p>From App Registration pane, click New Registration option.
    </p>
    <figure>
      <img src="./assets/images/msteams/msteam3.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 5</h2>
    <p>On the "Registration" pane, type in the following information and then click the "Register" button.</p>
    <table style="width:100%">
      <tr>
        <th>Parameter</th>
        <th>Value</th>
      </tr>
      <tr>
        <td>Name</td>
        <td>InsIQ</td>
      </tr>
      <tr>
        <td>Supported account types</td>
        <td>Based on your choice of Who can use this application or access this API.</td>
      </tr>
      <tr>
        <td>Redirect URI</td>
        <td>Single page Application(SPA) {{geturl()}}</td>
      </tr>
    </table>
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam4.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 6</h2>
    <p>Now you will get the App dashboard as below.Then, we need to add the permissions. From Registered App pane,
      click the "View API Permissions" option.
    </p>
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam5.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 7</h2>
    <p>From API Permissions pane, click the "Add a Permission" option.</p>
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam6.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 8</h2>
    <p>Click the "Microsoft Graph API" option from the pane.</p>
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam7.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 9</h2>
    <p>Click the "Delegated Permissions" option from the pane and Enable the permissions as shown below.</p>
    <!--  <figure class="m-t-10">
            <img src="./assets/images/msteams/msteam8.png" alt="Help Figure">
             <figcaption>Figure 1.1</figcaption> 
          </figure>-->
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam9.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 9</h2>
    <p>Click the "Application Permissions" option from the pane and Enable the permissions as shown below and click
      "Add Permissions" button at the bottom.</p>
    <!--  <figure class="m-t-10">
            <img src="./assets/images/msteams/msteam8.png" alt="Help Figure">
             <figcaption>Figure 1.1</figcaption> 
          </figure>-->
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam10.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 10</h2>
    <p>Click the "Grand Admin Consent for InsIQ" option from the permission dashboard as shown below.</p>
    <!--  <figure class="m-t-10">
            <img src="./assets/images/msteams/msteam8.png" alt="Help Figure">
             <figcaption>Figure 1.1</figcaption> 
          </figure>-->
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam11.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <p>After granting permissions successfully, the dashboard will be as shown below.</p>
    <!--  <figure class="m-t-10">
            <img src="./assets/images/msteams/msteam8.png" alt="Help Figure">
             <figcaption>Figure 1.1</figcaption> 
          </figure>-->
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam12.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 11</h2>
    <p>Select the Authentication Tab, Enable the "Access Token and ID Tokens" as Shown below and Click "Save" option.
    </p>
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam13.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
    <h2 class="subTitle">Step 12</h2>
    <p>From API Overview pane, Copy the "Client Id" and "Tenant Id" to clipboard and submit those values to the InsIQ
      application
      field for Succesfull MS Teams Integration with InsIQ.</p>
    <figure class="m-t-10">
      <img src="./assets/images/msteams/msteam14.png" alt="Help Figure">
      <!-- <figcaption>Figure 1.1</figcaption> -->
    </figure>
  </div>
</div>