import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nitro-tab',
  template: `
     <div [hidden]="!active" class="nitro-tab-pane">
      <ng-content></ng-content>
    </div>
  `,
  styles: [`
    :host {
      flex-grow: 1;
      display: flex;
    }
  `]
})
export class TabComponent implements OnInit {
  @Input()
  label: string;
  @Input()
  @HostBinding('class.active') active = false;

  constructor() {

  }

  ngOnInit(): void {
  }

}
