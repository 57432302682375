<div class="container-fluid d-flex h-100" [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <app-admin-menu *ngIf="config.isAdmin()"></app-admin-menu>

      <!-- <div class="footer t-c">
        &copy; {{config.currentYear}} InsIQ. All rights reserved
        <a href="https://www.devtom.ai" target="_blank">www.devtom.ai</a>
      </div> -->
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex">
        <h1 class="h1 smartHead">
          <i class="i i-requirement"></i>
          <span>
            <small>Requirement</small>
            <b>Sub-type</b>
          </span>
        </h1>
        <div class="spacer"></div>
        <span>
          <button mat-stroked-button class="curved btn-sm m-t-15" (click)="view.newform()">New</button>
        </span>
      </div>
      <div class="d-flex">
        <!-- trans-light -->
        <div class="spacer"></div>
        <mat-form-field>
          <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
        </mat-form-field>
      </div>
      <div class="mat-elevation-z8 rel">
        <!--  -->
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            No Records Found!!
          </div>
        </div> -->
        <table mat-table [dataSource]="view.list" matSort class="w-100 InsIQTable">
          <!-- photo Column -->
          <!-- <ng-container matColumnDef="photo">
          <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
          <td mat-cell *matCellDef="let row">
            <figure>
              <img src="assets/images/users/default.png" alt="default">
            </figure>
          </td>
        </ng-container>-->

          <!-- name Column -->
          <!-- <ng-container matColumnDef="name">
                 <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
                 <td mat-cell *matCellDef="let row">
                   <span class="ib-m">{{row.name}}</span>
                   <i *ngIf="row.new" class="ib-m i i-1_3x i-fiber_new"></i>
                 </td>
               </ng-container> -->

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Code</th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
          </ng-container>
          <!-- description Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let row">{{row.description}}</td>
          </ng-container>

          <ng-container matColumnDef="requirementType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Requirement Type</th>
            <td mat-cell *matCellDef="let row">{{row.requirementTypeCode?.description}}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="view.edit(row)" title="Edit" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
              </button>
              <button mat-icon-button (click)="view.deleteList(row.id)" title="Delete" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-trash" aria-label="delete" color="warn"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['id','description','requirementType','action']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['id','description','requirementType','action']"></tr>
        </table>
        <mat-paginator [length]="view.total" [pageSize]="view.pageSize"
          [pageSizeOptions]="view.pageSizeOptions"></mat-paginator>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid d-flex h-100" [ngClass]="view.isForm() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <button mat-icon-button class="close" color="warn" (click)="view.cancel()" title="Close"
        matTooltipPosition="below">
        <mat-icon fontSet="i" class="i-close"></mat-icon>
      </button>
      <ul class="quickyMenu">
        <li *ngFor="let row of view.list">
          <button matRipple class="btn btn-md btn-primary lighten t-c fw t-normal" (click)="view.edit(row)">
            {{row.description}}
          </button>
        </li>
      </ul>
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex m-b-40">
        <h1 class="h1 smartHead">
          <i class="i i-requirement"></i>
          <span>
            <small>Requirement Item</small>
            <b>Type</b>
          </span>
        </h1>
        <div class="spacer"></div>
        <span>
          <button *ngIf="!view.isNew()" mat-stroked-button class="curved btn-sm m-t-15"
            (click)="view.newform()">New</button>
        </span>
      </div>
      <form [formGroup]="view.form" novalidate (ngSubmit)="onSubmit()">
        <mat-card class="w-100">
          <mat-form-field class="w-100 m-b-10">
            <input type="text" matInput placeholder="Code" formControlName="id" [readonly]="view.isEdit()"
              maxlength="4">
            <mat-error *ngIf="view.invalid('id')">{{view.error('id')}}</mat-error>
          </mat-form-field>

          <mat-form-field class="w-100 m-b-10">
            <input type="text" matInput placeholder="Description" formControlName="description" maxlength="64">
            <mat-error *ngIf="view.invalid('description')">{{view.error('description')}}</mat-error>
          </mat-form-field>

          <div class="m-b-30">
            <label class="d-block"><small>Requirement Type</small></label>
            <p-dropdown [options]="requirementTypeCodes" formControlName="requirementTypeCode"
              placeholder="Requirement Type" optionLabel="description"></p-dropdown>
            <small class="mat-error"
              *ngIf="view.invalid('requirementTypeCode')">{{view.error('requirementTypeCode')}}</small>
          </div>

          <div class="t-c">
            <button type="button" (click)="view.cancel()" mat-raised-button color="warn"
              class="curved m-x-5">Cancel</button>
            <button type="submit" [disabled]="view.invalid()" *ngIf="view.isNew()" mat-raised-button color="primary"
              class="curved m-x-5">Create</button>
            <button type="submit" [disabled]="view.invalid()" *ngIf="view.isEdit()" mat-raised-button color="primary"
              class="curved m-x-5">Update</button>
          </div>
        </mat-card>
      </form>
    </div>
  </div>
</div>