export const environment = {
  production: true,
  help: {
    videoId: '91wLpvW1GLY'
  },
  supportedScreensize: 768,
  captchaKey: '6Ldr80MUAAAAAOP9_ubASL3ew5Nh5Q8zoXab64yG',
  baseUrl: 'https://insiq-dev-api.infospica.com/baseservice',
  appUrl: 'https://insiq-dev.infospica.com',
  appUrlLogin: 'https://insiq-dev.infospica.com/login',
  serverUrl: 'https://insiq-dev-pay.infospica.com/api',
  stripe: 'pk_test_51MdswFIiJhSZGGzAgEwGO6kD39BSGUu5nWipnKgyS77NjY3Je6SiTYZvWgEWfZTDuhzAkvVs8l9Dv2cH1cQpZ6CQ005rqS4lVb',

  payUrl: 'https://insiq-dev-pay.infospica.com/checkouts',
  msUrl: 'https://insiq-dev-ms.infospica.com/',
  googleUrl: 'https://insiq-dev-go.infospica.com/',
  linUrl: 'https://insiq-dev-fb.infospica.com/',
  tidio: '//code.tidio.co/9yrz9vzkypqrkplb9zp7l4kg3h0w4o0i.js',
  //  ai: {
  //    key: 'SK2a0747eba6abf96b7e',
  //    api: 'https://aiclient30.azurewebsites.net/api'
  //  },
  format: {
    time: 'YYYY MMMM DD hh:mm:ss a'
  }
};
