<header>
  <h1 mat-dialog-title>{{layout.title}}</h1>
  <button class="btn btn-ico btn-danger" title="Close" aria-label="Close" matRipple (click)="closeDialog()">
    <i class="i i-close"></i>
  </button>
</header>

<mat-dialog-content>
  <ng-container *ngIf="layout.template" [ngTemplateOutlet]="layout ? layout.template : defaultTemp"></ng-container>
  <div class="" *ngIf="layout.component">
    <ng-container *ngComponentOutlet="layout?.component;
    injector: myInjector;
    content: myContent"></ng-container>
  </div>
  <!-- <ng-container *ngIf="layout.component" [ngComponentOutlet]="layout?.component"></ng-container> -->

  <div class="p-y-30" *ngIf="layout.message">
    {{layout.message}}
  </div>
</mat-dialog-content>

<ng-template #defaultTemp>Default</ng-template>