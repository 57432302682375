<app-page-template title="BUY YOUR SACHET" [layout]="chooseYourPlan" [hasBackNav]="true" (closeHandler)="cancelview()"
  *ngIf="!isPlanSelected">
  <ng-template #chooseYourPlan>

    <nitro-tabs class="InsIQNitroTabs" type="v">
      <nitro-tab [active]="isApi" label="API Sachet">
        <!-- <pre>
          filterdApiSachet {{filterdDataSachet.length}}
          filterdDataSachet {{filterdDataSachet?.length}}
          currentUserPIN: {{config?.currentUser?.pin}}
        </pre> -->

        <div class="flex-xy-center"
          *ngIf="(filterdApiSachet && filterdApiSachet.length <= 0) || config?.currentUser?.pin=='USR'">
          <figure>
            <img src="./assets/images/infographics/no_plans_available.png" alt="No Plans Found!!!">
          </figure>

          <h4 class="h4 f-bold text-danger">
            No Sachet Packs
          </h4>
          <p>
            Available at the Moment !
          </p>
        </div>

        <ul class="plan" *ngIf="config?.currentUser?.pin!='USR' && filterdApiSachet && filterdApiSachet.length > 0">
          <li *ngFor="let plan of filterdApiSachet; index as i;">
            <!-- {{filterdPlanMonthly.length}} / {{i+1}} -->
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{plan.apiSachetId.sachetName}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="billType">
                  Maximum
                </div>
                <div class="validity">
                  <span class="days">{{plan.apiSachetId.sachetLimit}} API</span> Calls
                </div>

                <!-- TRIAL -->
                <div class="pricing">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{plan.apiSachetId.price}}</span>
                  <!-- 
                  <span class="per" *ngIf="plan.paymode === '1'">/mo</span>
                  <span class="per" *ngIf="plan.paymode === '2'">/yr</span>
                  -->
                </div>

                <button type="button" class="btn btn-accent" (click)="selectPlan(plan)">Pay Now</button>

              </mat-card-content>
            </mat-card>
          </li>
        </ul>

      </nitro-tab>
      <nitro-tab [active]="isUser" label="User Sachet">
        <!-- <pre>
          filterdApiSachet {{filterdDataSachet.length}}
          filterdDataSachet {{filterdDataSachet?.length}}
          currentUserPIN: {{config?.currentUser?.pin}}
        </pre> -->

        <div class="flex-xy-center"
          *ngIf="(filterdUserSachet && filterdUserSachet.length <= 0) || config?.currentUser?.pin=='USR'">
          <figure>
            <img src="./assets/images/infographics/no_plans_available.png" alt="No Plans Found!!!">
          </figure>

          <h4 class="h4 f-bold text-danger">
            No Sachet Packs
          </h4>
          <p>
            Available at the Moment !
          </p>
        </div>

        <ul class="plan" *ngIf="config?.currentUser?.pin!='USR' && filterdUserSachet && filterdUserSachet.length > 0">
          <li *ngFor="let plan of filterdUserSachet; index as i;">
            <!-- {{filterdPlanMonthly.length}} / {{i+1}} -->
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{plan.apiSachetId.sachetName}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="billType">
                  Add
                </div>
                <div class="validity">
                  <span class="days">{{plan.apiSachetId.sachetLimit}} </span> User
                </div>

                <!-- TRIAL -->
                <div class="pricing">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{plan.apiSachetId.price}}</span>
                  <div class="validity" *ngIf="plan.paymode == 1">
                    /month
                  </div>
                  <div class="validity" *ngIf="plan.paymode == 2">
                    /year
                    <span class="per">({{getCurrency()}} {{plan.apiSachetId.price/12}}/mo)</span>
                  </div>
                  <!-- 
                  <span class="per" *ngIf="plan.paymode === '1'">/mo</span>
                  <span class="per" *ngIf="plan.paymode === '2'">/yr</span>
                  -->
                </div>

                <button type="button" class="btn btn-accent" (click)="UserSachet(plan)">Pay Now</button>

              </mat-card-content>
            </mat-card>
          </li>
        </ul>

      </nitro-tab>
      <nitro-tab [active]="isData" label="Data Sachet" *ngIf=" clientfeatureChecker()">
        <!-- <pre>
          filterdApiSachet {{filterdDataSachet.length}}
          filterdDataSachet {{filterdDataSachet?.length}}
          currentUserPIN: {{config?.currentUser?.pin}}
        </pre> -->
        <div class="flex-xy-center"
          *ngIf="(filterdDataSachet && filterdDataSachet.length <= 0) || config?.currentUser?.pin=='USR'">
          <figure>
            <img src="./assets/images/infographics/no_plans_available.png" alt="No Plans Found!!!">
          </figure>

          <h4 class="h4 f-bold text-danger">
            No Sachet Packs
          </h4>
          <p>
            Available at the Moment !
          </p>
        </div>

        <ul class="plan" *ngIf="config?.currentUser?.pin!='USR' && filterdDataSachet && filterdDataSachet.length > 0">
          <li *ngFor="let plan of filterdDataSachet; index as i;">
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{plan.apiSachetId.sachetName}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="billType">
                  Additional
                </div>
                <div class="validity">
                  <span class="days">{{plan.apiSachetId.sachetLimit+' '+plan.apiSachetId.dataUnit}} Data</span> Upload
                </div>

                <!-- TRIAL -->
                <div class="pricing">
                  <span class="currency">{{getCurrency()}}</span>
                  <span class="price">{{plan.apiSachetId.price}}</span>
                  <!-- <span class="per" *ngIf="plan.paymode === '1'">/mo</span>
                    <span class="per" *ngIf="plan.paymode === '2'">/yr</span> -->
                </div>

                <button type="button" class="btn btn-accent" (click)="selectPlan(plan)">Pay Now</button>

              </mat-card-content>
            </mat-card>
          </li>
        </ul>

      </nitro-tab>
    </nitro-tabs>
  </ng-template>
</app-page-template>

<app-page-template title="Sachet Pack Billing" [layout]="billingYourPlan" [ngClass]="{'d-none': !isPlanSelected}">
  <ng-template #billingYourPlan>
    <div class="billingYourPlan">
      <div class="row">
        <div class="offset-xl-2 col-xl-12 offset-lg-2 col-lg-12 col-md-12">
          <mat-card class="mat-elevation-z8">
            <mat-card-content>
              Selected Sachet Details
              <div class="selectedPlan" matRipple (click)="reselectPlan()">
                <div class="dtl">
                  <div class="title">{{selectedPlan?.apiSachetId.sachetName}}</div>
                  <div class="billType" *ngIf="selectedPlan?.apiSachetId.sachetTypeid===1">
                    Maximum
                    <span class="validity" *ngIf="selectedPlan?.apiSachetId.sachetTypeid===1">
                      <span class="days">{{selectedPlan?.apiSachetId.sachetLimit}} API</span> Calls
                    </span>
                  </div>

                  <div class="billType" *ngIf="selectedPlan?.apiSachetId.sachetTypeid===2">
                    Additional
                  </div>
                  <div class="validity" *ngIf="selectedPlan?.apiSachetId.sachetTypeid===2">
                    <span class="days">
                      {{selectedPlan?.apiSachetId.sachetLimit+' '+selectedPlan?.apiSachetId.dataUnit}}
                      Data</span> Upload
                  </div>

                  <div class="price">{{selectedPlan?.apiSachetId.price}} USD</div>
                </div>
                <!-- <div class="action">
                  <button type="button" class="btn btn-primary">Change Sachet</button>
                </div> -->
              </div>

              <!-- <div class="description">
                {{selectedPlan?.planId.description}}
              </div> -->

              <!-- <div class="price old" *ngIf="selectedPlan?.offerStatus==1">${{selectedPlan?.price}}</div>
              <div class="price" *ngIf="selectedPlan?.offerStatus==1">${{selectedPlan?.offerPrice}} USD <span class="per"
                  *ngIf="selectedPlan?.paymode === '1'">/month</span>
                <span class="per" *ngIf="selectedPlan?.paymode === '2'">/year</span>
              </div> -->
            </mat-card-content>
          </mat-card>


        </div>
        <div class="col-10" *ngIf="!checkoutFlag">
          <mat-card class="mat-elevation-z8 billingInfo">
            <mat-card-header>Billing Information</mat-card-header>
            <mat-card-content>
              <form [formGroup]="view.form" novalidate>
                <div class="row">
                  <div class="col-md-12">
                    <label for="name" class="mandatory d-block m-b-5">Name</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Name" maxlength="100" formControlName="billingName">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <label for="email" class="d-block m-b-5">Email</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Email" maxlength="100" formControlName="email">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <label for="contactNumber" class="d-block m-b-5">Contact Number</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Contact Number" maxlength="100" formControlName="phone">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                    <label for="VAT Number" class=" d-block m-b-5">VAT Number</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter VAT Number" maxlength="100" formControlName="vat">
                    </mat-form-field>
                    <label for="country" class="mandatory d-block m-b-5">Country</label>
                    <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
              <input matInput placeholder="Enter Country" maxlength="100" formControlName="country">
            </mat-form-field> -->

                    <!-- <p-autoComplete formControlName="country" [suggestions]="filteredCountry" styleClass="w-100"
              (completeMethod)="filterCountry($event)" [minLength]="0" placeholder="Enter country."
              autocomplete="off" forceSelection="true" field="name" [dropdown]="true">
            </p-autoComplete> -->

                    <ng-select [items]="countryList" appearance="outline" #comboCountries bindValue="name"
                      bindLabel="name" labelForId="country" [virtualScroll]="true" dropdownPosition="auto"
                      placeholder="Select country" formControlName="country" (change)="filterCountry($event)"
                      (clear)="onCountryClear($event)" appendTo="body">
                      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        {{item.name}}
                      </ng-template>
                    </ng-select>
                    <label for="Region" class="mandatory d-block m-b-5"
                      *ngIf="view.value('country')=='UNITED STATES OF AMERICA'">Region</label>
                    <ng-select [items]="regionList" appearance="outline" #comboregion bindValue="name"
                      *ngIf="view.value('country')=='UNITED STATES OF AMERICA'" bindLabel="name" labelForId="region"
                      [virtualScroll]="true" dropdownPosition="auto" placeholder="Select Region"
                      formControlName="region" (change)="filterCountry($event)" (clear)="onCountryClear($event)"
                      appendTo="body">
                      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        {{item.name}}
                      </ng-template>
                    </ng-select>

                    <!-- <mat-error>Error Message</mat-error> -->

                    <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                <input type="text" matInput formControlName="country" required [matAutocomplete]="autoGroup"
                  placeholder="Choose Country">
                <mat-autocomplete #autoGroup="matAutocomplete">
                  <mat-option *ngFor="let name of countries" [value]="name">{{name}}</mat-option>
                </mat-autocomplete>
              </mat-form-field> -->
                  </div>
                  <div class="col-md-12">
                    <label for="Company/Organization Name" class="mandatory m-b-5">Company/Organization
                      Name</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Company/Organization Name" maxlength="100"
                        formControlName="company">
                    </mat-form-field>
                    <label for="address" class="mandatory d-block m-b-5">Address Line 1</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter Address" maxlength="255" rows="7" formControlName="address">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                    <label for="address" class="mandatory d-block m-b-5">City</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter City" maxlength="255" rows="7" formControlName="city">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                    <label for="address" class="mandatory d-block m-b-5">Zip Code</label>
                    <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                      <input matInput placeholder="Enter ZIP Code" maxlength="255" rows="7" formControlName="zip"
                        type="text">
                      <!-- <mat-error>Error Message</mat-error> -->
                    </mat-form-field>
                  </div>

                </div>
                <!-- <small class="text-danger f-light info">Paid plans are only available to customers in the UK, US,
          and EU countries.</small> -->
                <div class="t-c" *ngIf="!waitflag">
                  <button type="button" [routerLink]="['/project']" matRipple
                    class="btn btn-lg btn-default m-x-5">Back</button>
                  <button type="button" (click)="checkout(selectedPlan)" matRipple class="btn btn-lg btn-primary m-x-5"
                    [disabled]="checkoutdisablecheck()">Checkout</button>
                </div>
                <div class="t-c" *ngIf="waitflag">
                  <small class="text-accent f-light info">Check out in Progress.Please Wait.</small>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="col-xl-10 col-lg-10 col-md-12" *ngIf="checkoutFlag">
          <mat-card class="mat-elevation-z8 billPayment">
            <mat-card-header>Billing</mat-card-header>
            <mat-card-content>
              <!-- <mat-form-field class="fw flat" floatLabel="never" appearance="fill">
                 <mat-label>Choose an option</mat-label> 
                <mat-select>
                  <mat-option value="month" selected>Monthly Plan</mat-option>
                  <mat-option value="annual">Annual Plan</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="d-flex text-primary m-b-20 f-medium">
                <span>Switch to annual and save</span>
                <span class="m-l-auto f-bold">US$60.00/yr</span>
              </div> -->
              <div class="d-flex text-dark m-y-20 h4">
                <span class="f-light">Plan Amount</span>
                <!-- <span class="m-l-auto" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}}
                  USD</span> -->
                <span class="m-l-auto">{{avaTaxResponse?.actual}} USD</span>
              </div>

              <div class="d-flex text-dark m-y-20 h4">
                <span class="f-light">Total Tax</span>
                <!-- <span class="m-l-auto" *ngIf="selectedPlan.offerStatus==1">0
                  USD</span> -->
                <span class="m-l-auto">{{avaTaxResponse?.taxCalculated}} USD</span>
              </div>
              <hr>
              <div class="d-flex text-dark m-y-20 h4">
                <span class="f-medium">Total Amount to Pay</span>
                <!-- <span class="m-l-auto f-black" *ngIf="selectedPlan.offerStatus==1">{{selectedPlan.offerPrice}}
                  USD</span> -->
                <span class="m-l-auto f-black">{{avaTaxResponse?.totalAmount}} USD</span>
              </div>

              <hr>
              <mat-checkbox (change)="changeCheck($event)" class="w-100" style="white-space: break-spaces;">
                <div class="m-l-5" style="white-space: break-spaces;">By continuing, you are agreeing to our <a
                    href="javascript:;" (click)="openTnC('subterms')" class="link">Subscription Terms</a> and our <a
                    href="javascript:;" (click)="openTnC('payterms')" class="link">Payment Terms.</a>
                </div>
                <!-- <div class="m-l-5" style="white-space: break-spaces;">Any requests for refunds should be made within 7
                  days of the billing date.
                </div>
                <div class="m-l-5" style="white-space: break-spaces;">You may cancel at any time prior to the next
                  billing cycle.
                </div> -->
              </mat-checkbox>

              <div class="message _warning">
                <i class="i i-warning"></i>
                <span>Don't refresh your page on payment submit</span>
              </div>
              <div class="t-c">
                <button type="button" (click)="backtocheckout()" matRipple class="btn btn-danger m-x-5">Cancel
                  Payment</button>
                <button type="button" (click)="payNowHere(selectedPlan)" matRipple class="btn btn-primary m-x-5"
                  [disabled]="disablecheck()">Proceed to Pay</button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>
</app-page-template>