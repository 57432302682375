<div class="w-100 h-100 loginMain">
  <div class="wrapper">
    <div class="leftSection loginWrap">
      <div class="d-flex align-items-center h-100 t-c">
        <div class="form">
          <figure>
            <img src="./assets/images/icon-insiq.png" alt="">
          </figure>
          <!-- <figure>
            <img src="./assets/images/insiq.png" alt="">
          </figure> -->
          <h2 class="sr-only sr-only-focusable">Login</h2>

          <form [formGroup]="view.form" (ngSubmit)="onSubmit()">
            <mat-form-field class="w-100">
              <input type="password" matInput placeholder="New Password *" formControlName="inputPwd">
              <mat-error *ngIf="view.invalid('inputPwd')">{{view.error('inputPwd')}}</mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
              <input type="password" matInput placeholder="Confirm Password *" formControlName="cpassword"
                [errorStateMatcher]="matcher">
              <mat-error *ngIf="view.form.hasError('notSame')">Password and Confirm password does not match</mat-error>
            </mat-form-field>

            <div class="m-b-15 m-x--20">
            </div>

            <button [disabled]="view.invalid()" mat-raised-button color="primary" class="curved m-b-15 w-100">Reset
              Password</button>
          </form>
          <!--
          <a *ngIf="!isAdminUrl" [routerLink]="['/register']" [queryParams]="{url: view.returnUrl}" class="curved btn-link">Create
            an Account?</a> -->
          <a [routerLink]="['/login']" [queryParams]="{url: view.returnUrl}" class="curved btn-link">Go to Login
            Page</a>
        </div>
      </div>
    </div>
    <div class="rightSection mainWrap" tabindex="0">
      <div class="describe">
        <picture>
          <source media="(max-width: 280px)" srcset="./assets/images/screenshot.png">
          <img src="./assets/images/screenshot.png" alt="screenshot">
        </picture>
        <h1 class="h1 f-bold">
          InsIQ
        </h1>
        <p>
          AI-powered Product Development for everyone. Everything you need to transform your idea into a successful
          product.
        </p>

        <div class="bar-T p-y-10 m-b-30">
          <a href="https://www.insiq.ai/about/" target="_blank" class="p-r-20">About Us</a>
          <a [routerLink]="['/contact']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Contact Us</a>
          <a [routerLink]="['/terms-and-conditions']" [queryParams]="{url: view.returnUrl}" class="p-r-20">Terms and
            Conditions</a>
          <a href="https://www.insiq.ai/product/#screenfq-Accordian" target="_blank" class="p-r-20">Help</a>
          <a href="https://www.insiq.ai/pricing/#faq_prcng" target="_blank" class="p-r-20">FAQ</a>
        </div>

        <div class="copyright">
          <span>&copy; {{config.currentYear}} InsIQ. All rights reserved</span>
          <br>
          <a href="https://www.insiq.ai" target="_blank">www.insiq.ai</a>
        </div>
      </div>
    </div>
  </div>
</div>