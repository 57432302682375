import {
  OnInit,
  Component,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
} from '@angular/router';
import {
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  MatTable,
  MatTableDataSource
} from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';

import * as _moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { AlertService } from '../../../_services';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';

import {
  View,
  Config,
  FormDefault
} from '../../../config/global.config';
import {
  WarningalertsComponent
} from '../../warningalerts/warningalerts.component';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
interface Currency {
  value: string;
  viewValue: string;
}

const ELEMENT_DATA: any[] = [
  {},
];

@Component({
  selector: 'app-usersachet',
  templateUrl: './usersachet.component.html',
  styleUrls: ['./usersachet.component.scss']
})
export class UsersachetComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['select', 'position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  selection = new SelectionModel<any>(true, []);
  planPartial = 0;
  public view: View;
  public pricingview: View;
  public featureview: View;
  trialflg = true;
  showPricing = false;
  showFeature = false;
  newfeatureflg = false;
  newpricingflg = false;
  featureList: any[];
  priceListAll: any[];
  featureFiltered: any;
  featureListAll: any[];
  featureListAllMain: any[];

  selectedValue: string;
  isReadonly = false;
  public isPlanPublished = false;
  dsPriceListAll = new MatTableDataSource([]);
  featureDataTable = new MatTableDataSource([]);
  currentPlan;
  // For user role combo
  featureSelected: any; // temp variable to select users
  dsFeatureList = new MatTableDataSource([]);
  dsFeatureListAll = new MatTableDataSource([]);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('planFeatures', { static: false }) table: MatTable<any>;

  @ViewChild('dialogAddFeature', { static: false }) dialogAddFeature: TemplateRef<any>;
  @ViewChild('dialogAddPricing', { static: false }) dialogAddPricing: TemplateRef<any>;
  selected: number;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;

  constructor(
    public router: Router,
    public config: Config,
    public fb: FormBuilder,
    public http: HttpClient,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public alertService: AlertService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {
    this.view = new View(http, router, route, alertService);
    this.pricingview = new View(http, router, route, alertService);
    this.featureview = new View(http, router, route, alertService);


    this.view.initForm('usersachet', this.getNewForm(), this.getNewDefault(), true); // need to change
    //  this.view.refreshList = false;
    // this.pricingview.initForm('planpricing',this.getPriceForm());
    // this.view.controls['startdate'].setValue(this.view.value('startdate'));
  }

  currencys: Currency[] = [
    {
      value: '$ US Dollar',
      viewValue: '$ US Dollar'
    },
    {
      value: '₹ Indian Rupee',
      viewValue: '₹ Indian Rupee'
    },
    {
      value: '€ Euro',
      viewValue: '€ Euro'
    }
  ];

  getNewDefault() {
    return new FormDefault(['category'], ['1']);
  }



  ngAfterViewInit() {
    this.view.initPage(this.paginator, this.sort);
    // this.featureDataTable = new MatTableDataSource(this.getFeatureDataTable());
  }

  ngOnInit() {

    this.dataSource.paginator = this.paginator;
    // this.view.initForm('plan', this.getNewForm(),null,false);
    this.view.initForm('usersachet', this.getNewForm(), this.getNewDefault(), true); // need to change


    //  this.pricingview.initForm('planpricing',this.getPriceForm());
  }

  onSubmit() {
    Config.loading = true;


    this.view.set('sachetType', 'USER');
    this.view.set('sachetTypeid', 3);
    if (this.view.isNew()) {
      this.view.set('active', '0');

    } else {
      this.view.set('active', this.view.form.value.active);
    }

    this.view.save();


    if (this.view.isNew()) {
      this.selected = this.view.list?.length;




      setTimeout(() => {
        this.currentPlan = this.view.list[this.view.list.length - 1];
        //   console.log(this.currentPlan);
        Config.loading = false;

      }, 100);
      // console.log(this.currentPlan);

    }

  }
  publishPrice(pu) {

    this.testFn(pu, '1').subscribe(
      event => {

        const objIndex = this.view.list.findIndex((obj => obj.id === pu.id));
        this.view.list[objIndex].active = '1';

      },
      _err => {
        console.log(_err);

      });


  }
  testFn(pu, active) {
    const req = new HttpRequest('POST', `${Config.apiUrl}/usersachet/${active}/${pu.id}`, {
      responseType: 'json'
    });
    return this.http.request(req);
  }
  unPublishPrice(pu) {
    this.testFn(pu, '0').subscribe(
      event => {
        const objIndex = this.view.list.findIndex((obj => obj.id === pu.id));
        this.view.list[objIndex].active = '0';
      }, _err => {
        console.log(_err);
      });
  }
  editPlan(plan: any) {
    this.currentPlan = plan;

    //   plan.category = this.getStatus('category', plan.category);
    this.view.edit(plan);
    this.planPartial = 0;

    // console.log(this.planPartial);
  }

  getIndex(i: number) {
    let j: number;
    j = i + 1;
    return j;
  }

  openSnackBar(s: any) {
    s = (s === undefined || typeof s !== 'object') ? {
      duration: 3,
      type: 'simple',
      action: 'CLOSE',
      message: 'No More Features Available!'
    } : s;

    s.duration = s.duration === undefined ? 2000 : s.duration;
    s.message = s.message === undefined ? 'InsIQ Message' : s.message;
    s.action = s.action === undefined ? 'ok' : s.action;

    if (s.type === 'custom') {
      this._snackBar.openFromComponent(s.component, {
        duration: s.duration * 1000,
        panelClass: 'InsIQ'
      });
    } else {
      this._snackBar.open(s.message, s.action, {
        duration: s.duration,
        panelClass: 'InsIQ'
      });
    }
  }

  newplanCreate() {
    // console.log('ffffff');
    this.planPartial = 0;
    this.view.newform();

    this.currentPlan = null;
    this.dsFeatureList = new MatTableDataSource();
    this.dsPriceListAll = new MatTableDataSource();

    this.dsFeatureList.data = null;
    this.dsPriceListAll.data = null;

    this.trialflg = true;
    this.dsPriceListAll._updateChangeSubscription();

    this.changeDetectorRefs.detectChanges();

    // console.log( this.currentPlan);
  }

  getNewForm() {
    return this.fb.group({
      id: [''],
      active: [0],
      sachetName: ['', Validators.required],
      sachetTypeid: [],
      sachetType: [],

      price: ['', Validators.required],
      category: ['1'],
      sachetLimit: ['']
    });
  }

  // planedit(row) {
  //   this.view.edit(row);
  //   // this.tab.index == 1
  // }

  deletePlan(row) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to delete this User Sachet ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    // console.log(this.chartCode);
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.view.deleteList(row.id);
      }
    });
  }

  updatePlanStatus(event: { checked: any; }, data: any) {
    if (event.checked) {
      this.publishPrice(data);
    } else {
      this.unPublishPrice(data);
    }
  }


  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cancel() { }

  getCurrency() {
    return Config.subscriptionCurrency;
  }
  getCurrency1() {
    return Config.subscriptionCurrencytype;
  }
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dsFeatureListAll.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dsFeatureListAll.data.forEach(row => this.selection.select(row));
  }


  changed = (evt) => {
    //  console.log(evt);
  }

}
