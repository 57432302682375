import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private triggerOnInitSubject = new Subject<void>();

  constructor() { }

  triggerOnInit() {
    this.triggerOnInitSubject.next();
  }

  onTriggerOnInit() {
    return this.triggerOnInitSubject.asObservable();
  }
}
