<div class="container-fluid d-flex h-100" [ngClass]="view.isList() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <app-admin-menu *ngIf="config.isAdmin()"></app-admin-menu>

      <!-- <div class="footer t-c">
          &copy; {{config.currentYear}} InsIQ. All rights reserved
          <a href="https://www.devtom.ai" target="_blank">www.devtom.ai</a>
        </div> -->
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex pageHeader">
        <h1 class="h1 smartHead">
          <i class="i i-user"></i>
          <span>
            <b>Manage</b>
            <small>Admins</small>
          </span>
        </h1>
        <div class="spacer"></div>
        <span>
          <button mat-stroked-button class="curved btn-sm m-t-15" (click)="newplanCreate(); selected=-1">Add
            New
          </button>
        </span>
      </div>
      <!-- trans-light -->
      <div class="d-flex">
        <div class="spacer"></div>
        <!-- <mat-form-field>
            <input matInput (keyup)="view.filter()" placeholder="Filter" [(ngModel)]="view.keyword" />
          </mat-form-field> -->
      </div>
      <div class="mat-elevation-z8 rel">
        <!-- mat-elevation-z8 -->
        <!-- <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
              No Records Found!!
            </div>
          </div> -->
        <table mat-table [dataSource]="view.list" matSort class="w-100 InsIQTable">
          <!-- photo Column -->
          <!-- <ng-container matColumnDef="photo">
              <th class="w-100px" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true"></th>
              <td mat-cell *matCellDef="let row">
                <figure>
                  <img src="assets/images/users/default.png" alt="default">
                </figure>
              </td>
            </ng-container>-->

          <!-- name Column -->
          <!-- <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
              <td mat-cell *matCellDef="let row">
                <span class="ib-m">{{row.name}}</span>
                <i *ngIf="row.new" class="ib-m i i-1_3x i-fiber_new"></i>
              </td>
            </ng-container> -->

          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let row">{{row.rolecodevalue}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title </th>
              <td mat-cell *matCellDef="let row">{{row.title}}</td>
            </ng-container>-->
          <!-- description Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
          </ng-container>
          <ng-container matColumnDef="loginkey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Loginkey</th>
            <td mat-cell *matCellDef="let row">{{row.loginKey}}</td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let row">{{row.email}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="startdate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Active From</th>
              <td mat-cell *matCellDef="let row">{{getDate(row.startdate)}}</td>
            </ng-container>
            <ng-container matColumnDef="enddate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Till</th>
              <td mat-cell *matCellDef="let row">{{getDate(row.enddate)}}</td>
            </ng-container> -->
          <ng-container matColumnDef="planstat">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let pu">
              <!-- <button mat-icon-button (click)="publishPrice(pu)" title="Publish Now"
                  matTooltipPosition="below" *ngIf="pu.active==0">
                  <mat-icon fontSet="i" fontIcon="i-publish" aria-label="edit"></mat-icon>
                </button>
                <button mat-icon-button (click)="unPublishPrice(pu)" title="Un Publish" *ngIf="pu.active==1"
                  matTooltipPosition="below">
                  <mat-icon fontSet="i" fontIcon="i-unpublished" aria-label="remove" class="i-1_3x">remove_circle_outline</mat-icon>
                </button> -->
              <!-- {{pu | json}} -->
              <!-- <mat-slide-toggle class="InsIQToggle boolToggle" [checked]="pu.active==1 ? true: false"
                  (change)="updatePlanStatus($event, pu)"></mat-slide-toggle> -->

              <!-- {{pu.active}} -->
              <div class="label f-bold" [ngClass]="{
                  'text-success': pu.active === 1,
                  'text-danger': pu.active === 0
                }">
                <div>{{pu.active===1 ? 'Active' : 'Inactive'}}</div>
              </div>
              <!-- <div class="label f-bold text-danger" *ngIf="pu.active===0">
                  <div>Inactive</div>
                </div> -->
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="w-120px" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row; let i=index">
              <button mat-icon-button (click)="editPlan(row); selected=i" title="Edit" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
              </button>
              <button mat-icon-button (click)="deleteAdmin(row)" title="Delete" matTooltipPosition="below">
                <mat-icon fontSet="i" fontIcon="i-trash" aria-label="delete" color="warn"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['category','name','loginkey','email','planstat','action']">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['category','name','loginkey','email','planstat','action']">
          </tr>
        </table>
        <mat-paginator [length]="view.total" [pageSize]="view.pageSize" [pageSizeOptions]="view.pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid d-flex h-100" [ngClass]="view.isForm() ? 'active' : 'hidden'">
  <div class="wrapper">
    <div class="leftSection">
      <button mat-icon-button class="close" color="warn" (click)="view.cancel()" title="Close"
        matTooltipPosition="below">
        <mat-icon fontSet="i" class="i-close"></mat-icon>
      </button>
      <figure>
        <img src="./assets/images/icon-insiq.png" alt="">
      </figure>
      <!-- <figure>
        <img src="./assets/images/insiq.png" alt="">
      </figure> -->

      <ul class="quickyMenu">
        <li *ngFor="let row of view.list; let i = index">
          <button class="btn btn-md btn-primary lighten t-c fw t-normal" [ngClass]="{'active': selected === i}"
            matRipple (click)="view.edit(row); selected = i;">
            {{row.loginKey}}
          </button>
        </li>
      </ul>
    </div>
    <div class="rightSection" tabindex="0">
      <div class="d-flex m-b-40">
        <h1 class="h1 smartHead">
          <i class="i" [ngClass]="{
              'i-add_circle': view.isNew(),
              'i-edit2': !view.isNew()
            }"></i>
          <span>
            <small *ngIf="view.isNew()">Add</small>
            <small *ngIf="!view.isNew()">Modify</small>
            <b>{{view.form.controls.name.value}} <small> - Admin</small></b>

          </span>
        </h1>
        <div class="spacer"></div>
        <!-- <span>
            <button *ngIf="!view.isNew()" class="curved btn-sm m-t-15" mat-stroked-button (click)="newplanCreate()">New
              Plan</button>
          </span> -->
      </div>

      <mat-card class="InsIQCard mat-elevation-z8">
        <mat-tab-group mat-align-tabs="center" color="accent" [selectedIndex]="planPartial">
          <mat-tab label="Add Admin">
            <!-- {{view.form.value | json}} -->
            <form [formGroup]="view.form" (ngSubmit)="onSubmit()">
              <!-- Test print:{{view.value('category')}} -->
              <!-- <pre>{{view.form.value | json}}</pre> -->
              <div class="">
                <!-- class="trans-light" -->
                <div class="row">
                  <div class="col-xl-8">
                    <div class="form-group">
                      <ul class="dataList" *ngIf="view.isEdit()">
                        <li>
                          <div class="lbl">Admin Category : </div>
                          <div class="val">
                            <strong>
                              {{view.value('rolecodevalue')}}
                            </strong>
                            <!-- <small> <button mat-icon-button title="Edit"
                              matTooltipPosition="below">
                              <mat-icon fontSet="i" fontIcon="i-edit" aria-label="edit"></mat-icon>
                            </button></small> -->
                          </div>
                        </li>
                      </ul>
                      <div class="d-flex" *ngIf="view.isNew()">
                        <label class="bigLabel">Admin Category</label>
                        <div class="ib-m">
                          <!-- <app-quick-help [layout]="projTitle"></app-quick-help> -->
                        </div>
                      </div>
                      <ng-select [items]="rolecode" appearance="outline" #comboRole bindValue="id" *ngIf="view.isNew()"
                        bindLabel="description" labelForId="rolecodevalue" [virtualScroll]="true"
                        dropdownPosition="auto" placeholder="Select Category" formControlName="rolecodevalue"
                        appendTo="body">
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                          {{item.description}}
                        </ng-template>
                      </ng-select>
                    </div>
                    <div class="form-group">
                      <div class="d-flex">
                        <label class="bigLabel">Name</label>

                        <div class="ib-m">

                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Provide the Admin Name to Identify" formControlName="name">

                      </mat-form-field>
                    </div>
                    <div class="form-group">
                      <div class="d-flex">
                        <label class="bigLabel">Loginkey</label>

                        <div class="ib-m">

                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder="Provide the Login key" formControlName="loginKey">

                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <div class="d-flex">
                        <label class="bigLabel">Email</label>
                        <div class="ib-m">

                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input matInput placeholder=" Provide the Email" formControlName="email">
                        <mat-error *ngIf="view.invalid('email')">{{view.error('email')}}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <div class="d-flex">
                        <label class="bigLabel">Password</label>
                        <div class="ib-m">
                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input type="password" matInput placeholder=" Provide the Password" formControlName="inputPwd">
                      </mat-form-field>
                    </div>
                    <div class="form-group">
                      <div class="d-flex">
                        <label class="bigLabel">Confirm Password</label>
                        <div class="ib-m">
                        </div>
                      </div>
                      <mat-form-field class="fw flat" floatLabel="never" appearance="fill" title=""
                        matTooltipPosition="above">
                        <input type="password" matInput placeholder=" Confirm the Password"
                          [errorStateMatcher]="matcher" formControlName="cpassword">
                        <mat-error *ngIf="view.form.hasError('notSame')">Password and Confirm
                          password does not match</mat-error>
                      </mat-form-field>
                    </div>

                  </div>
                </div>


                <!-- {{view.form.value | json}} -->

                <div class="bar-T m-b-10">
                  <button type="button" (click)="view.cancel()" mat-raised-button
                    class="curved m-x-5 btn-cancel">Back</button>
                  <button type="submit" [disabled]="view.invalid()" *ngIf="view.isNew()" mat-raised-button
                    color="primary" #create class="curved m-x-5">Create</button>
                  <button type="submit" [disabled]="!view.valid()" *ngIf="view.isEdit()" mat-raised-button
                    color="primary" #update class="curved m-x-5">Update</button>
                </div>
              </div>
            </form>
          </mat-tab>


        </mat-tab-group>
      </mat-card>
    </div>
  </div>
</div>




<br><br>