import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Config} from 'src/app/config/global.config';

@Component({
  selector: 'app-msteamhelp-dialog',
  templateUrl: 'msteamhelp-dialog.html',
  styleUrls: ['msteamhelp-dialog.scss']
})
export class MsteamhelpDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<MsteamhelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, public router: Router,
 
  ) {
  }

  backtoPage() {
    this.dialogRef.close();
  }
  backtoHome() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
geturl()
{
  return Config.LocalhostUrl + "/profile";
//  let url;
//
//      if (location.host.includes(Config.PREPROD_URL)) {
//      url='https://devtom-qa.infospica.com/profile';
//    } else if (location.host.includes(Config.LocalhostUrl)) {
//         url='http://localhost:4200/profile';
//    } else {
//         url='https://app.devtom.ai/profile';
// 
//}
//return url;
}
}
