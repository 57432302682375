import {
  Input,
  OnInit,
  Output,
  Component,
  TemplateRef,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss']
})
export class PageTemplateComponent implements OnInit {
  @Input() title: string;
  @Input() hasBackNav: boolean;
  @Input() layout: TemplateRef<any>;
  @Output() closeHandler = new EventEmitter<any>();

  constructor() { }

  onBackHandler(event: any) {
    // console.log(event);
    this.closeHandler.emit(this.title);
  }

  ngOnInit(): void {
  }

}
