import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Validators, FormBuilder } from '@angular/forms';
import { Config, View } from '../../../config/global.config';

@Component({
  selector: 'app-requirement',
  templateUrl: './requirement.component.html',
  styleUrls: ['./requirement.component.scss']
})
export class RequirementComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public view: View, private fb: FormBuilder, public config: Config) {
    this.view.initForm('requirementtype', this.getNewForm());
  }

  ngAfterViewInit() {
    this.view.initPage(this.paginator, this.sort);
  }

  ngOnInit() {
  }

  onSubmit() {
    this.view.save();
  }

  getNewForm() {
    return this.fb.group({
      id: ['', Validators.required],
      description: ['', Validators.required],
      mandatory: ['1'],
      active: ['1']
    });
  }
}
