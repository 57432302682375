<div class="container h-100">
  <div class="row align-items-center loginWrapper">
    <div class="col-md-8 offset-md-2">
      <picture>
        <source media="(min-width: 0px)" srcset="./assets/images/insiq_logo.png">
        <img src="./assets/images/insiq_logo.png" alt="InsIQ">
      </picture>

      <h1 class="title">
        Invalid<br>InsIQ Domain !
      </h1>

      <p>
        This is not a valid domain belonging to InsIQ Public users.Please try using <a
          href="https://app.insiq.ai">https://app.insiq.ai</a>.
      </p>
    </div>
  </div>
</div>