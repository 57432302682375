import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(router: Router) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next({ type: '', text: '' });
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false, timeout = 3000) {
    // console.log('success');
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message });
    setTimeout(() => {
      this.subject.next({ type: '', text: '' });
    }, timeout);
  }

  error(message: string, keepAfterNavigationChange = false, timeout = 3000) {
    // console.log('Error', message, keepAfterNavigationChange);
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'error', text: message });
    setTimeout(() => {
      this.subject.next({ type: '', text: '' });
    }, timeout);
  }

  fatal(error, message: string, keepAfterNavigationChange = false, timeout = 3000) {
    // console.log(error);
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'error', text: error });
    setTimeout(() => {
      this.subject.next({ type: '', text: '' });
    }, timeout);
  }

  getMessage(): Observable<any> {
    // console.log('getMessage');
    return this.subject.asObservable();
  }
}
