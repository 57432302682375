import {
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  SafeUrl,
  SafeHtml,
  SafeStyle,
  SafeScript,
  DomSanitizer,
  SafeResourceUrl
} from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
      case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      // throw new Error(`Invalid safe type specified: ${type}`);
    }
  }

}
