import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// export const TITLE = new InjectionToken<string>('app.title');
// export const MESSAGE = new InjectionToken<string>('app.message');

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent implements OnInit {
  @Input() title: string;
  @Input() infoGraphic = 'paidplan.png';
  @Input() message: string;

  constructor(
    public dialog: MatDialog,
    public router: Router,

    // @Inject(TITLE) private titleInjected: string,
    // @Inject(MESSAGE) private messageInjected: string
  ) {

  }

  ngOnInit(): void {
    this.title = this.title; // || this.titleInjected;
    this.message = this.message; // || this.messageInjected;
    // console.log(this.title, this.message);
  }

  upgrade() {
    this.dialog.closeAll();
    this.router.navigate(['/subscription']);
  }

}
