import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomThemeService {
  theme = 'theme-purple';
  logo = 'logo.png';
  dynamiclogo = false;
  constructor() { }

  changeTheme(styleName: string, logo: string, dynamiclogo: boolean): void {
    this.theme = styleName;
    this.logo = logo;
    this.dynamiclogo = dynamiclogo;
    const head = document.getElementsByTagName('head')[0];
    const themeLink = document.getElementById('theme-style') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `${styleName}.css`;
    } else {
      const style = document.createElement('link');
      style.id = 'theme-style';
      style.rel = 'stylesheet';
      // style.type = 'text/stylesheet';
      style.href = `${styleName}.css`;

      head.appendChild(style);
    }
  }
}
