import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'; import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_services';
import { Config, View } from 'src/app/config';
import { WarningalertsComponent } from '../warningalerts/warningalerts.component';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-cancellationform',
  templateUrl: './cancellationform.component.html',
  styleUrls: ['./cancellationform.component.scss']
})
export class CancellationformComponent {
  currentUserData: any;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;
  transactionLog;
  currentUsr;
  showStatus: boolean = false;
  norefund: boolean = false;
  isConfirmed: boolean = false;
  currentDate: Date = new Date();
  //  feedbackForm: FormGroup; // Declare a FormGroup for your form
  ratingControl = new FormControl();
  constructor(
    public view: View,
    public config: Config,
    public router: Router,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public alertService: AlertService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    this.view = new View(http, router, route, alertService);
    this.view.initForm('subscription/cancelsubcription', this.getNewForm());
    this.showStatus = false;
    this.norefund = false;
    this.currentUserData = JSON.parse(localStorage.getItem('currentUserStore'));
    this.currentUsr = JSON.parse(localStorage.getItem('currentUserStore'));
    const reasonControls = this.cancellationReasons.reduce((acc, reason) => {
      acc[reason] = new FormControl(false);
      return acc;
    }, {});

    // this.feedbackForm = this.formBuilder.group({
    //   cancellationReasons: this.formBuilder.group(reasonControls),
    //   overallExperience: this.ratingControl, // Add the rating FormControl      
    //   reSubscribe: [''],
    //   contactSupport: [''],
    //   feedback: [''],
    //   confirmationCheckbox: []
    // });
  }
  getNewForm() {
    const reasonControls = this.cancellationReasons.reduce((acc, reason) => {
      acc[reason] = new FormControl(false);
      return acc;
    }, {});
    return this.formBuilder.group({
      reason: this.formBuilder.group(reasonControls),
      experience: this.ratingControl, // Add the rating FormControl      
      resub: [''],
      support: [''],
      suggestions: [''],
      confirmationCheckbox: []
    });
  }
  formData = {
    fullName: '',
    email: '',
    organization: '',
    subscription: 'Free',
    cancellationReasons: {},
    overallExperience: 3, // Default value

    feedback: '',
    date: '', reSubscribe: new FormControl(''), // Initialize with a default value if needed
    contactSupport: new FormControl(''),
    choice: new FormControl('Option 1') // Initialize with a default value if needed
  };
  stars = [1, 2, 3, 4, 5]; // Array representing the stars

  setRating(rating: number) {
    this.formData.overallExperience = rating;
  }
  updateButtonState(checked: boolean) {
    this.isConfirmed = checked;
  }

  ngOnInit() {



  }
  submitCancel() {
    const formData = this.view.form.value;

    // Log the form data (you can send it to a server or perform other actions)
    //console.log(formData);

    const selectedReasons = Object.keys(formData.reason)
      .filter(reason => formData.reason[reason])
      .join(', ');
    //console.log(selectedReasons);
    formData.reason = selectedReasons;



    this.view.get('subscription/refundinitiate').subscribe(res1 => {

      //  console.log(res.transactionId);

      this.view.get('subscription/cancelplan').subscribe(res => {


        if (res) {
          Config.loading = true;
          this.initiateRefund(res1.transactionId.trim());
          // localStorage.setItem('paymentIntent', res1.transactionId.trim());

        }
        else {
          this.norefund = true;
        }

        this.view.save('subscription/cancelsubcription', 'Subscription Cancelled Successfully.');

      });

    });


  }
  initiateRefund(paymentIntentId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${environment.stripe}`);

    //const paymentIntentId = 'your_payment_intent_id'; // Replace with your payment intent ID

    this.http.post(`${environment.serverUrl}/initiate`, `${paymentIntentId}`, { headers })
      .subscribe(
        (response: any) => {
          // console.log(response); // Handle success 
          this.view.get('transactionlog/refundstatus/' + paymentIntentId).subscribe(res => {
            this.transactionLog = res;
            this.showStatus = true;
            Config.loading = false;

            // console.log(res);
          });
          if (response === 'Refund initiated successfully.') {

          } else {
            // Handle other success cases or errors
          }
        },
        (error) => {
          console.error(error); // Handle error
          // You can display an error message to the user
        }
      );
  }
  payNowHere() {
    this.router.navigate(['/']);

  }
  extractNumericValue(originalValue) {
    // Use regular expression to extract numeric part
    const numericPart = parseFloat(originalValue.replace(/[^0-9.]/g, ''));
    return isNaN(numericPart) ? undefined : numericPart;
  }
  cancellationReasons = [
    'Switching to a different service',
    'Not using the service enough to justify the cost',
    'Found the platform too complicated',
    'Missing features/functionality',
    'Dissatisfied with customer support',
    'Pricing concerns',
    'Technical issues',
    'Other'
  ];

  submitForm() {
    console.log(this.formData); // You can replace this with your form submission logic
  }
}