import {
  OnInit,
  Component,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
} from '@angular/router';
import {
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  MatTable,
  MatTableDataSource
} from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import {
  HttpClient,
  HttpRequest
} from '@angular/common/http';

import * as _moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { AlertService } from '../../../_services';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';

import {
  View,
  Config,
  FormDefault
} from '../../../config/global.config';
import {
  WarningalertsComponent
} from '../../warningalerts/warningalerts.component';
import {
  CommonDialogComponent
} from 'src/app/common-dialog/common-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
interface Currency {
  value: string;
  viewValue: string;
}

const ELEMENT_DATA: any[] = [
  {},
];

@Component({
  selector: 'app-subscriptionplan',
  templateUrl: './subscriptionplan.component.html',
  styleUrls: ['./subscriptionplan.component.scss']
})
export class SubscriptionplanComponent implements OnInit, AfterViewInit {

  public view: View;
  public pricingview: View;
  public featureview: View;

  displayedColumns: string[] = ['select', 'position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  selection = new SelectionModel<any>(true, []);
  planPartial = 0;
  trialflg = true;
  redemption = false;
  isReadonly = false;
  showPricing = false;
  showFeature = false;
  newfeatureflg = false;
  newpricingflg = false;
  activePublish = false;

  currentPlan: any;
  featureList: any[];
  priceListAll: any[];
  featureFiltered: any;
  featureListAll: any[];
  featureListAllMain: any[];

  apiSachetList: any[];
  datasachetList: any[];
  usersachetList: any[];

  sachetPlansList: any[];
  selectedValue: string;
  public isPlanPublished = false;
  dsPriceListAll = new MatTableDataSource([]);
  featureDataTable = new MatTableDataSource([]);
  // For user role combo
  featureSelected: any; // temp variable to select users
  dsFeatureList = new MatTableDataSource([]);
  dsFeatureListAll = new MatTableDataSource([]);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('planFeatures', { static: false }) table: MatTable<any>;

  @ViewChild('dialogAddFeature', { static: false }) dialogAddFeature: TemplateRef<any>;
  @ViewChild('dialogAddPricing', { static: false }) dialogAddPricing: TemplateRef<any>;
  selected: number;
  warningDialogRef: MatDialogRef<WarningalertsComponent, any>;

  constructor(
    public router: Router,
    public config: Config,
    public fb: FormBuilder,
    public http: HttpClient,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public alertService: AlertService,
    private changeDetectorRefs: ChangeDetectorRef,
    public commonDialogRef: MatDialogRef<CommonDialogComponent>
  ) {
    this.view = new View(http, router, route, alertService);
    this.pricingview = new View(http, router, route, alertService);
    this.featureview = new View(http, router, route, alertService);
    this.pricingview.initForm('planpricing', this.getPriceForm(), null, false);
    this.featureview.initForm('planfeature', this.getFeatureForm(), null, false);

    this.view.initForm('plan', this.getNewForm(), this.getNewDefault(), true); // need to change
    //  this.view.refreshList = false;
    // this.pricingview.initForm('planpricing',this.getPriceForm());
    // this.view.controls['startdate'].setValue(this.view.value('startdate'));
  }

  currencys: Currency[] = [
    {
      value: '$ US Dollar',
      viewValue: '$ US Dollar'
    },
    {
      value: '₹ Indian Rupee',
      viewValue: '₹ Indian Rupee'
    },
    {
      value: '€ Euro',
      viewValue: '€ Euro'
    }
  ];

  getNewDefault() {
    return new FormDefault(['category'], ['1']);
  }

  changeplanstatus() {
    this.activePublish = true;
    (
      this.pricingview.form.value.active === 1 || this.pricingview.form.value.active === '1'
    ) ? this.pricingview.set('active', 0) : this.pricingview.set('active', 1);
  }

  updatePlanStatus(event: { checked: any; }, type: string, data: any) {

    if (type === 'pricing') {
      if (event.checked) {
        this.publishPrice(data);
      } else {
        this.unPublishPrice(data);
      }
    }
  }
  addRedumption() {
    this.redemption = true;
  }
  getButton() {
    var num1 = ((document?.getElementById("codeno") as HTMLInputElement)?.value);
    // console.log(num1);
    if (num1) {
      return false;
    }
    else {
      return true;
    }
  }
  redeemCode() {
    Config.loading = true;
    var num1 = ((document.getElementById("codeno") as HTMLInputElement).value);
    console.log(num1);
    let planid = this.currentPlan.id;

    this.view.get('plan/codegenerator/' + planid + '/' + num1).subscribe(res => {
      Config.loading = false;

      this.alertService.success('Appsumo Reddem Codes Generated Successfully', true);
      this.redemption = false;

    }, (err) => { }, () => {
      setTimeout(() => {
        this.changeDetectorRefs.detectChanges();
      }, 100);
    });
  }
  updateSachet(data: any, type) {
    // console.log(data);

    if (type === 1) {
      this.addSachet(data);
    } else {
      this.removeSachet(data);
    }

  }

  addSachet(pu: { id: any; }) {
    // console.log(this.currentPlan.id);
    // console.log(pu.id);
    // this.view.save('apisachetplans/'+this.currentPlan.id+'/'+pu.id);
    this.testFn(pu).subscribe(event => {

      // console.log('sucess');
      let counter = 0;
      for (let j = 0; j < this.apiSachetList.length; j++) {
        if (pu.id === this.apiSachetList[j].id) {
          counter++;
          this.apiSachetList[j].enabledStatus = counter;
        }

      }
      for (let j = 0; j < this.datasachetList.length; j++) {
        if (pu.id === this.datasachetList[j].id) {
          counter++;
          this.datasachetList[j].enabledStatus = counter;
        }

      }

      for (let j = 0; j < this.usersachetList.length; j++) {
        if (pu.id === this.usersachetList[j].id) {
          counter++;
          this.usersachetList[j].enabledStatus = counter;
        }

      }

    }, _err => {
      console.log(_err);
    });


  }
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  testFn(pu) {
    const req = new HttpRequest('POST', `${Config.apiUrl}/apisachetplans/${this.currentPlan.id}/${pu.id}`, {
      responseType: 'json'
    });
    return this.http.request(req);
  }
  removeSachet(pu: { id: any; }) {


    this.testFn(pu).subscribe(
      event => {

        //    console.log('sucess');
        const counter = 0;
        for (let j = 0; j < this.apiSachetList.length; j++) {
          if (pu.id === this.apiSachetList[j].id) {
            this.apiSachetList[j].enabledStatus = 0;

          }
        }
        for (let j = 0; j < this.datasachetList.length; j++) {
          if (pu.id === this.datasachetList[j].id) {
            this.datasachetList[j].enabledStatus = 0;

          }
        }
        for (let j = 0; j < this.usersachetList.length; j++) {
          if (pu.id === this.usersachetList[j].id) {
            this.usersachetList[j].enabledStatus = 0;

          }
        }
        // this.apiSachetList = this.apiSachetList.filter(item => item.id !== pu.id);
        // this.datasachetList = this.datasachetList.filter(item => item.id !== pu.id);

      },
      _err => {
        console.log(_err);

      });

  }
  ngAfterViewInit() {
    this.view.initPage(this.paginator, this.sort);
    // this.featureDataTable = new MatTableDataSource(this.getFeatureDataTable());
  }

  ngOnInit() {

    this.dataSource.paginator = this.paginator;
    // this.view.initForm('plan', this.getNewForm(),null,false);
    this.view.initForm('plan', this.getNewForm(), this.getNewDefault(), true); // need to change

    this.view.get('featurelist/all').subscribe(res => {
      this.featureListAll = res;

      this.featureListAllMain = res;
      this.featureFiltered = res;

      this.dsFeatureListAll.data = res;

      // console.log(res);
    });

    this.view.get('apisachetplans/sachetplans').subscribe(sachetPlans => {

      // console.log(sachetPlans);

      this.sachetPlansList = sachetPlans;


    });
    //  this.pricingview.initForm('planpricing',this.getPriceForm());


  }

  onSubmit() {
    Config.loading = true;
    // console.log(this.currentPlan);
    // const sdate = Date.parse(this.view.value('startdate'));
    // const edate = Date.parse(this.view.value('enddate'));
    // this.view.set('startdate', sdate);
    // this.view.set('enddate', edate);
    this.view.set('description', this.view.value('description'));
    this.view.set('active', '0');
    this.view.set('plancode', this.view.value('plancode'));
    this.view.set('status', '0');
    // console.log(this.view.form.value.planFeaturesList);
    this.view.set('category', this.view.form.value.category);
    this.view.set('planFeaturesList', [...new Map(this.view.form.value.planFeaturesList?.map(item => [item['id'], item])).values()]);

    // console.log([...new Map(this.view.form.value.planFeaturesList.map(item => [item["id"], item])).values()]);
    this.view.save();
    // console.log(this.getStatus('category', this.view.form.value.category));
    // setTimeout(() => {
    //   this.view.set('category', this.getStatus('category', this.view.form.value.category));
    // }, 500);
    if (this.currentPlan) {
      this.currentPlan.planFeaturesList = this.view.form.value?.planFeaturesList;

    }
    if (this.view.isNew()) {

      this.selected = this.view.list.length;
      //  console.log(this.view.list);

      Config.loading = true;
      setTimeout(() => {
        this.currentPlan = this.view.list[this.view.list.length - 1];
        // console.log(this.currentPlan);
        Config.loading = false;
        this.refreshPricingTable('planpricing/pricinglist/0');
      }, 5000);
      console.log('currentPlanchnhgees URL string 200');

    }
    const datapoint = [];
    this.dsFeatureList = new MatTableDataSource(datapoint);
    // this.dsPriceListAll = new MatTableDataSource();

    this.dsFeatureList.data = datapoint;
    this.sachetmanager(0);
  }
  sachetmanager(plan) {


    this.view.get('apisachet/apisachetList').subscribe(res => {
      this.apiSachetList = res;
      //  console.log(this.apiSachetList);

      this.view.get('datasachet/datasachetslist').subscribe(res => {
        this.datasachetList = res;
        this.view.get('usersachet/usersachetlist').subscribe(res => {
          this.usersachetList = res;

          this.view.get('apisachetplans/sachetplans/' + plan).subscribe(sachetPlansList => {
            const counter = 0;
            for (let i = 0; i < this.apiSachetList.length; i++) {
              for (let j = 0; j < sachetPlansList.length; j++) {
                if (sachetPlansList[j].apiSachetId.id === this.apiSachetList[i].id) {
                  this.apiSachetList[i].enabledStatus = 1;
                  // console.log('found');
                }

              }
            }

            const counter1 = 0;
            for (let i = 0; i < this.datasachetList.length; i++) {
              for (let j = 0; j < sachetPlansList.length; j++) {
                if (sachetPlansList[j].apiSachetId.id === this.datasachetList[i].id) {
                  this.datasachetList[i].enabledStatus = 1;
                  // console.log('found');

                }

              }
            }

            const counter2 = 0;
            for (let i = 0; i < this.usersachetList.length; i++) {
              for (let j = 0; j < sachetPlansList.length; j++) {
                if (sachetPlansList[j].apiSachetId.id === this.usersachetList[i].id) {
                  this.usersachetList[i].enabledStatus = 1;
                  // console.log('found');

                }

              }
            }
          });

        });
      });
    });



  }
  editPlan(plan: any) {
    this.redemption = false;

    this.currentPlan = plan;
    this.pricingview.get('planpricing/pricinglist/' + plan.id).subscribe(res => {

      if (res != '') {
        this.trialflg = false;

      } else {
        this.trialflg = true;

      }

    }, (err) => { }, () => {
      setTimeout(() => {
        this.changeDetectorRefs.detectChanges();
      }, 100);
    });
    //   plan.category = this.getStatus('category', plan.category);
    this.view.edit(plan);
    this.planPartial = 0;
    this.sachetmanager(plan.id);

    // console.log(this.planPartial);
  }

  getIndex(i: number) {
    let j: number;
    j = i + 1;
    return j;
  }

  featurefilterFn(array: any[], anotherOne: any[]) {
    // console.log(array);
    // console.log(anotherOne);

    if (anotherOne && anotherOne?.length != 0) {
      const filteredArray = array.filter(function (array_el) {
        return anotherOne?.filter(function (anotherOne_el) {
          return anotherOne_el.featureId.id == array_el.id;
        }).length == 0;
      });
      //  console.log(filteredArray);
      this.featureListAll = filteredArray;
      this.dsFeatureListAll.data = filteredArray;
    } else {
      this.dsFeatureListAll.data = this.featureListAllMain;
    }

    // this.ELEMENT_DATA= this.dsFeatureListAll.data;
  }

  addnewFeature() {
    const array = this.featureListAllMain;

    const anotherOne = this.currentPlan?.planFeaturesList;
    this.featurefilterFn(array, anotherOne);
    this.newfeatureflg = true;

    if (this.dsFeatureListAll.filteredData.length !== 0) {
      this.commonDialogRef = this.dialog.open(CommonDialogComponent, {
        width: '95vw',
        maxHeight: '95vh',
        maxWidth: '1024px',
        minWidth: '1024px',
        panelClass: 'commonDialog',
        data: {
          title: 'Choose Features',
          template: this.dialogAddFeature,
        }
      });
    } else {

      this.openSnackBar({
        type: 'simple',
        message: 'No More Features Available!',
        action: 'CLOSE',
        duration: 3000
      });

    }
  }

  openSnackBar(s: any) {
    s = (s === undefined || typeof s !== 'object') ? {
      duration: 3,
      type: 'simple',
      action: 'CLOSE',
      message: 'No More Features Available!'
    } : s;

    s.duration = s.duration === undefined ? 2000 : s.duration;
    s.message = s.message === undefined ? 'InsIQ Message' : s.message;
    s.action = s.action === undefined ? 'ok' : s.action;

    if (s.type === 'custom') {
      this._snackBar.openFromComponent(s.component, {
        duration: s.duration * 1000,
        panelClass: 'InsIQ'
      });
    } else {
      this._snackBar.open(s.message, s.action, {
        duration: s.duration,
        panelClass: 'InsIQ'
      });
    }
  }

  closenewFeature() {
    this.newfeatureflg = false;
  }

  addNewPricing() {
    //  console.log('gfghgh');
    // this.pricingview.initForm('planpricing', this.getPriceForm(), null, false);
    this.activePublish = false;
    this.newpricingflg = true;
    this.pricingview.newform();
    this.pricingview.set('offerStatus', 2);
    this.pricingview.set('paymode', 1);
    this.pricingview.set('dataUnit', 'GB');

    if (this.view.form.value.category === 1 || this.view.form.value.category === '1') {
      this.pricingview.set('offerStatus', 2);
      this.pricingview.set('price', 0);
      this.pricingview.set('offerPrice', 0);
      this.pricingview.set('offerEnddate', null);

      this.isReadonly = true;

    } else {
      this.isReadonly = false;
    }
    this.dialog.open(CommonDialogComponent, {
      width: '95vw',
      maxHeight: '95vh',
      maxWidth: '1024px',
      minWidth: '1024px',
      panelClass: 'commonDialog',
      data: {
        title: 'Add Pricing',
        template: this.dialogAddPricing
      }
    });
  }

  closePricingDialog() {
    // this.newpricingflg = false;
    this.commonDialogRef.close();
    this.commonDialogRef.afterClosed().subscribe(() => {
      this.refreshPricingTable('planpricing/pricinglist/' + this.currentPlan.id);
    });
  }

  newplanCreate() {
    // console.log('Fixes');
    // console.log(this.dsPriceListAll);
    // console.log(this.dsFeatureList);
    this.planPartial = 0;
    this.view.newform();
    // this.dsPriceListAll = null;
    // this.dsFeatureList = null;

    this.currentPlan = null;

    // this.dsPriceListAll = new MatTableDataSource(null);
    // this.dsPriceListAll.data = [];
    // this.dsPriceListAll.filteredData = [];
    // this.dsFeatureList = new MatTableDataSource(null);
    // this.dsFeatureList.data = [];
    // this.dsFeatureList.filteredData = [];
    // this.dsFeatureList = new MatTableDataSource();
    // this.dsPriceListAll = new MatTableDataSource();

    // this.dsFeatureList.data = null;
    // this.dsPriceListAll.data = null;

    this.trialflg = true;
    // this.dsPriceListAll._updateChangeSubscription();

    // this.changeDetectorRefs.detectChanges();
    // this.dsPriceListAll.data.length = 0;
    // this.dsFeatureList.data.length = 0;
    // console.log(this.dsPriceListAll.data.length);
    // console.log(this.dsFeatureList.data.length);

    // this.dsPriceListAll._filterData([]);
    // this.dsFeatureList._filterData([]);
    // this.dsPriceListAll.filteredData = [];
    // this.dsFeatureList.filteredData = [];

    // this.changeDetectorRefs.detectChanges();
    const datapoint = [];
    this.priceListAll = datapoint;
    this.dsPriceListAll.data = this.priceListAll;



    // this.priceListAll = [];
    // this.dsPriceListAll.data = this.priceListAll;
    // console.log('Update Fixes 898989');
  }

  onPriceSubmit() {
    this.pricingview.set('enddate', _moment(this.pricingview.value('enddate')).format('YYYY-MM-DD hh:mm:ss'));
    this.pricingview.set('startdate', _moment(this.pricingview.value('startdate')).format('YYYY-MM-DD hh:mm:ss'));
    if ((this.view.form.value.category === 1 || this.view.form.value.category === '1') && this.pricingview.value('trialdays') == null) {
      this.alertService.error(
        'Trial days mandatory for this Plan !!!',
        true, 10 * 1000);
    } else if ((this.view.form.value.category === 2 || this.view.form.value.category === '2') && this.pricingview.value('price') <= 0) {
      this.alertService.error(
        'For a Paid Plan,Price is Mandatory!!',
        true, 10 * 1000);
    } else if (this.pricingview.value('enddate') < this.pricingview.value('startdate')) {
      this.alertService.error(
        'End date Should be greater than Start Date !!!',
        true, 10 * 1000);
    } else {
      // const tilldate = Date.parse(this.pricingview.value('offerEnddate'));
      // console.log(this.view.value('id'));
      this.pricingview.set('price', this.pricingview.value('price'));

      // console.log(this.pricingview.value('offerStatus'));
      // console.log(this.getStatus('offerStatus', this.pricingview.value('offerStatus')));
      this.pricingview.set('offerPrice', this.pricingview.value('offerPrice'));

      // if (this.getStatus('offerStatus', this.pricingview.value('offerStatus'))) {
      //   this.pricingview.set('offerStatus', 1);
      // } else {
      //   this.pricingview.set('offerStatus', 2);
      // }

      // if (this.pricingview.value('paymode')) {
      //   this.pricingview.set('paymode', '2');
      // } else {
      //   this.pricingview.set('paymode', '1');
      // }

      this.pricingview.set('active', this.pricingview.value('active'));
      if (this.view.form.value.category === 1 || this.view.form.value.category === '1') {
        this.pricingview.set('offerStatus', 2);
        this.pricingview.set('price', 0);
        this.pricingview.set('offerPrice', 0);
        // this.pricingview.set('offerEnddate', null);

        this.pricingview.set('offerEnddate', _moment(this.pricingview.value('startdate')).format('YYYY-MM-DD hh:mm:ss'));

      } else {
        // console.log(this.pricingview.value('offerEnddate'));

        this.pricingview.set('offerEnddate', _moment(this.pricingview.value('offerEnddate')).format('YYYY-MM-DD hh:mm:ss'));
      }

      if (this.pricingview.value('offerStatus') == 2) {
        this.pricingview.set('offerEnddate', _moment(this.pricingview.value('startdate')).format('YYYY-MM-DD hh:mm:ss'));
        this.pricingview.set('offerPrice', 0);

      } else {
        this.pricingview.set('offerEnddate', _moment(this.pricingview.value('offerEnddate')).format('YYYY-MM-DD hh:mm:ss'));

      }
      this.pricingview.set('planId', this.view.value('id'));
      //    console.log(this.getStatus('offerStatus', this.pricingview.form.value.offerStatus));
      if (this.view.form.value.category === 2 || this.view.form.value.category === '2') {
        this.pricingview.set('trialdays', 0);

      }
      if (
        this.getStatus('offerStatus', this.pricingview.form.value.offerStatus) &&
        (
          this.pricingview.value('offerEnddate') < this.pricingview.value('startdate') ||
          this.pricingview.value('offerEnddate') > this.pricingview.value('enddate')
        )
      ) {
        this.alertService.error(
          'Offer End date Should be between the Start Date and End Date !!!',
          true, 10 * 1000);
        this.pricingview.set('offerEnddate', _moment(this.pricingview.value('startdate')).format('YYYY-MM-DD hh:mm:ss'));
        this.pricingview.set('active', this.pricingview.value('active'));

      } else if (
        this.getStatus('offerStatus', this.pricingview.form.value.offerStatus) &&
        (this.pricingview.value('price') <= this.pricingview.value('offerPrice'))
      ) {
        this.alertService.error(
          'Offer Price Should be less than Actual price !!!',
          true, 10 * 1000);
        this.pricingview.set('active', this.pricingview.value('active'));

      } else if (
        this.getStatus('offerStatus', this.pricingview.form.value.offerStatus) &&
        (this.pricingview.value('offerPrice') === null)
      ) {
        this.pricingview.set('active', this.pricingview.value('active'));

        this.alertService.error(
          'Offer Price is Mandatory !!!',
          true, 10 * 1000);
      } else {
        this.pricingview.save();

        // this.pricingview.set('paymode', this.getStatus('paymode', this.pricingview.value('paymode')));
        // this.pricingview.set('offerStatus', this.getStatus('offerStatus', this.pricingview.value('offerStatus')));
        this.dialog.closeAll();

        setTimeout(() => { this.refreshPricingTable('planpricing/pricinglist/' + this.currentPlan?.id); }, 500);
      }
    }

    // this.pricingview.set('offerEnddate', tilldate);
  }

  getNewForm() {
    return this.fb.group({
      id: [''],
      active: [0],
      plancode: ['', Validators.required],
      status: [false],
      description: ['', Validators.required],
      // title: [''],
      category: ['1'],
      planFeaturesList: ['']
    });
  }

  getPriceForm() {
    return this.fb.group({
      id: [''],
      price: ['', Validators.required],
      planId: [''],
      enddate: ['', Validators.required],
      active: ['0'],
      paymode: ['1'],
      startdate: ['', Validators.required],
      offerPrice: [''],
      offerStatus: [2],
      offerEnddate: [''],
      trialdays: [],
      apiUsageMonthly: [0],
      dataUnit: [],
      userLimit: [0],
      dataUsage: [],
      apiUsageAnually: [],

    });
  }


  getFeatureForm() {
    return this.fb.group({
      id: [''],
      planId: [''],
      featureId: ['']
    });
  }

  filterFeature(event: { query: any; }) {
    let query = event.query;
    query = query.toLowerCase();
    // this.featureFiltered = this.featureList.filter(features => features.feature.toLowerCase().indexOf(query) === 0);
  }

  addFeature() {
    this.showFeature = true;
    this.featureview.set('planId', this.view.value('id'));
    // this.getPlanFeatures();
    // console.log(this.view.list);
    this.featureList = this.currentPlan?.planFeaturesList;
    this.dsFeatureList.data = this.featureList;
  }

  private getPlanFeatures() {
    const url = 'planfeature/featurelist/' + this.currentPlan?.id;
    this.featureview.get(url).subscribe(res => {
      // console.log(res);
      this.featureList = res;

      this.dsFeatureList.data = this.featureList;
    });
  }

  tabClick(tab: { index: number; }) {
    // console.log(tab);
    this.planPartial = tab.index;
    if (tab.index === 1) {
      this.addPricing();
    } else if (tab.index === 2) {
      this.addFeature();
    } else if (tab.index === 3 || tab.index === 4) {
      this.sachetmanager(this.currentPlan.id);
      // console.log(this.view);
    }
  }

  getFeatureDataTable() {
    // return this.view.form.get('planFeatureList').value;
  }

  addFeatureToPlan(feature_id: { id: any; }) {
    this.featureview.set('planId', this.view.value('id'));
    this.featureview.set('featureId', feature_id);
    this.featureview.save('planfeature');
    this.featureListAll = this.featureListAll.filter(item => item.id !== feature_id.id);
    // this.addFeature();

    this.dsFeatureListAll.data = this.featureListAll;

    this.getPlanFeatures();
  }

  refreshFeatureDataTable() {
    // this.featureDataTable.data = this.getFeatureDataTable();
  }

  addPricing() {
    // console.log('pricing check');
    // console.log(Config.subscriptionCurrency);
    // console.log('check');
    // const planId = this.view.value('id');
    // console.log(this.view.value('id'));
    this.showPricing = true;
    this.pricingview.set('planId', this.view.value('id'));

    // this.pricingview.initForm('planpricing', this.getPriceForm(), null, false);
    // this.pricingview.refreshList = false;
    // const url = 'planpricing/pricinglist/' + this.pricingview.value('planId');
    if (this.currentPlan) {
      this.refreshPricingTable('planpricing/pricinglist/' + this.currentPlan?.id);

    }
    // this.pricingview.set('planId', this.view.value('id'));
  }

  private refreshPricingTable(url: string) {
    this.pricingview.get(url).subscribe(res => {
      //console.log({ res });
      // this.dsPriceListAll.data = res;
      // this.dsPriceListAll = new MatTableDataSource(res);
      this.dsPriceListAll = new MatTableDataSource(res);
      this.dsPriceListAll.data = res;
      // setTimeout(() => {
      //   this.dsPriceListAll.paginator = this.tableOnePaginator;
      //   this.dsPriceListAll.sort = this.tableOneSort;
      // });
      this.dsPriceListAll._updateChangeSubscription();
    }, (err) => { }, () => {
      setTimeout(() => {
        this.changeDetectorRefs.detectChanges();
      }, 200);
    });
  }

  // planedit(row) {
  //   this.view.edit(row);
  //   // this.tab.index == 1
  // }
  priceEdit(pu: any) {
    // console.log({ pu });
    this.newpricingflg = true;
    // console.log({ pu });
    // pu.startdate = _moment(pu.startDate).toISOString();

    // console.log(pu.paymode);
    // pu.paymode = this.getStatus('paymode', pu.paymode);
    // pu.offerStatus = this.getStatus('offerStatus', pu.offerStatus);

    this.pricingview.edit(pu);
    this.addPricing();
    if (this.view.form.value.category === 1 || this.view.form.value.category === '1') {

      this.isReadonly = true;

    } else {
      this.isReadonly = false;
    }
    // this.pricingview.newform();
    this.commonDialogRef = this.dialog.open(CommonDialogComponent, {
      width: '95vw',
      maxHeight: '95vh',
      maxWidth: '1024px',
      minWidth: '1024px',
      panelClass: 'commonDialog',
      data: {
        title: 'Add Pricing',
        template: this.dialogAddPricing
      }
    });

    this.commonDialogRef.afterClosed().subscribe(result => {
      this.refreshPricingTable('planpricing/pricinglist/' + this.currentPlan.id);
      // if (result) {
      //   if (result.status && this.dsPriceListAll.data.length > 0) {
      //     const index = this.dsPriceListAll.data.findIndex(x => x.id === pu.ID);
      //     if (index >= 0) {
      //       this.dsPriceListAll.data.splice(index, 1);
      //       this.dsPriceListAll._updateChangeSubscription();
      //       this.dataSource1.data.unshift(result.data);
      //       this.dataSource1._updateChangeSubscription();
      //     }
      //   }
      // }
    });

  }
  deletePlan(row) {
    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to delete this Plan ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    // console.log(this.chartCode);
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        this.view.deleteList(row.id);
      }
    });
  }

  priceDelete(pu: { id: any; }) {

    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: { title: 'Confirm Deletion', message: 'Are you sure want to remove this Pricing ?', cancelbtn: 'No', okbtn: 'Yes' },
    });
    // console.log(this.chartCode);
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result.data === 'confirm') {
        // Config.loading = true;
        this.pricingview.deleteList(pu.id);
        // this.priceListAll = this.priceListAll.filter(obj => obj !== pu.id);
        // this.priceListAll
        //this.priceListAll = this.dsPriceListAll.data;
        // this.dsPriceListAll.data = this.priceListAll.filter(item => item.id !== pu.id);
        setTimeout(() => {
          this.refreshPricingTable('planpricing/pricinglist/' + this.currentPlan.id);
          // Config.loading = false;
        }, 500);



      }
    });

  }

  removeFeature(pu) {

    //  console.log(pu);

    this.warningDialogRef = this.dialog.open(WarningalertsComponent, {
      data: {
        okbtn: 'Yes',
        cancelbtn: 'No',
        title: 'Confirm Deletion',
        message: 'Are you sure want to remove this Feature?'
      },
    });
    // console.log(this.chartCode);
    this.warningDialogRef.afterClosed().subscribe(result => {
      if (result?.data === 'confirm') {
        if (pu.id !== undefined) {
          this.featureview.deleteList(pu.id);
          // this.priceListAll = this.priceListAll.filter(obj => obj !== pu.id);

          this.featureList = this.featureList.filter(item => item.id !== pu.id);
        } else {

          this.view.get('planfeature/deletefeature/' + this.currentPlan.id + '/' + pu.featureId.id).subscribe(res => {

          });
          this.featureList = this.featureList.filter(item => item.featureId.id !== pu.featureId.id);
          this.alertService.success(
            'Successfully Deleted',
            true, 10 * 1000);
          this.currentPlan.planFeaturesList = this.featureList;


        }

        this.dsFeatureList.data = this.featureList;

        // this.table.renderRows();
        // const array = this.featureFiltered;
        const anotherOne = this.featureList;

        const array = this.featureListAllMain;

        // const anotherOne = this.currentPlan?.planFeaturesList;

        this.featurefilterFn(array, anotherOne);
        this.view.set('planFeaturesList', this.featureList);
        // this.view.save();
        for (let i = 0; i < this.view.list.length; i++) {
          if (this.currentPlan.id === this.view.list[i].id) {
            this.view.list[i].planFeaturesList = this.featureList;
          }
        }
      }
    });

  }
  publishPrice(pu: { id: any; }) {
    this.pricingview.edit(pu);
    this.pricingview.set('active', '1');
    this.pricingview.save('planpricing');
    // this.priceListAll=null;
    // this.addPricing();
    // this.priceEdit(pu);
    this.priceListAll = this.dsPriceListAll.data;
    const objIndex = this.priceListAll.findIndex((obj => obj.id == pu.id));
    this.priceListAll[objIndex].active = '1';
  }
  unPublishPrice(pu: { id: any; }) {
    this.pricingview.edit(pu);
    this.pricingview.set('active', '0');
    this.pricingview.save('planpricing');
    // this.priceListAll=null;
    // this.addPricing();
    // this.priceEdit(pu);
    this.priceListAll = this.dsPriceListAll.data;
    const objIndex = this.priceListAll.findIndex((obj => obj.id == pu.id));
    this.priceListAll[objIndex].active = '0';
  }
  getOfferstatus() {
    // console.log(this.pricingview.value('offerStatus'), typeof this.pricingview.value('offerStatus'));
    if (this.pricingview.value('offerStatus') === 2 || this.pricingview.value('offerStatus') === false) {
      return false;
    } else {
      return true;
    }
  }
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  cancel() { }
  cancelpricing() {
    this.showPricing = false;
  }
  cancelfeature() {
    this.showFeature = false;
  }
  getPayMode(paymode: string) {
    let mode: string;
    if (paymode === '1') {
      mode = 'Monthly ';
    } else if (paymode === '2') {
      mode = 'Annually';
    }
    return mode;
  }
  getOfferMode(offerStatus: number) {
    let mode: string;
    if (offerStatus === 1) {
      mode = 'Yes';
    } else if (offerStatus === 2) {
      mode = 'No';
    }
    return mode;
  }
  getCurrency() {
    return Config.subscriptionCurrency;
  }
  getCurrency1() {
    return Config.subscriptionCurrencytype;
  }
  getDate(date: string) {
    if (date != null) {
      const x = date.split('T');
      return x[0];
    } else {
      return null;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dsFeatureListAll.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dsFeatureListAll.data.forEach(row => this.selection.select(row));
  }

  logSelection() {
    const updatedList = this.view.value('planFeaturesList', []);
    this.selection.selected.forEach(s => updatedList.push({ featureId: s, limitCount: 0 }));
    const list = [];
    this.selection.selected.forEach(s => list.push({ featureId: s, limitCount: 0 }));
    this.view.set('planFeaturesList', list);
    this.view.save();
    this.commonDialogRef.close();
    this.commonDialogRef.afterClosed().subscribe(() => {
      // this.featureList = updatedList;
      // this.dsFeatureList.data = this.featureList;
      this.view.set('planFeaturesList', updatedList);
      this.featureList = this.view.value('planFeaturesList');
      this.dsFeatureList.data = this.featureList;
      this.currentPlan.planFeaturesList = this.view.value('planFeaturesList');
      //
      // this.planPartial = 0;
      // this.view.set('planFeaturesList', temp)
      // this.view.initForm('plan', this.getNewForm()); // need to change
    });
    this.selection.clear();
    for (let i = 0; i < this.view.list.length; i++) {
      if (this.currentPlan?.id === this.view.list[i]?.id) {
        this.view.list[i].planFeaturesList = this.featureList;
      }
    }
    // this.dsFeatureListAll= this.dsFeatureListAll.data.filter(item => item.id !== list.id);
    // this.featureview.set('planId', this.view.value('id'));
    // this.featureview.set('featureList', s);
    // this.featureview.save('planfeature');
    // this.featureListAll = this.featureListAll.filter(item => item.id !== s.id);
    // // this.addFeature();
    // this.dsFeatureListAll.data = this.featureListAll;
    // this.getPlanFeatures();
  }
  changed = (evt) => {
    //  console.log(evt);
  }
  getStatus(type: string, value: any) {
    // console.log(type, value, typeof value);
    switch (type) {
      case 'category':
        if ((value === 2) || (value === '2') || (value === true)) {
          return true;
        } else {
          return false;
        }
      case 'paymode':
        if (value === 2 || value === '2' || value === true) {
          return true;
        } else {
          return false;
        }
      case 'hasOffer':
      case 'offerStatus':
        if (
          (this.pricingview.form.value.offerStatus === 2) ||
          (this.pricingview.form.value.offerStatus === '2') ||
          (this.pricingview.form.value.offerStatus === false)
        ) {
          return false;
        } else {
          return true;
        }
      default:
        break;
    }
  }
}


