<div class="nitro-tabs-wrapper" [ngClass]="{
  vertical: isVertical(),
  horizontal: !isVertical()
}">
  <ul class="nitro-tabs">
    <li *ngFor="let tab of tabs" class="nitro-tab">
      <button type="button" class="nitro-link" [ngClass]="{
        active: tab.active === true
      }" (click)="selectTab(tab)">{{ tab.label }}</button>
    </li>
  </ul>
  <ng-content></ng-content>
</div>